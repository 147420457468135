import { useEffect, useState } from "react";
import "./Css/CoprativeSolution.css";
import "./Css/ListYourHotel.css";
import "./Css/TravelAgent.css";
import Header from "./Header";

import AroPlanDiv from "../Assets/AroPlanDiv.svg";
import HelpLineheadPhone from "../Assets/HelpLineheadPhone.svg";
import HotlNetwork from "../Assets/HotlNetwork.svg";
import SelectedHotel from "../Assets/SelectedHotel.svg";
import WomenBussniss from "../Assets/WomenBussniss.svg";
import Clouds from "../Assets/cloudsBtm.png";
import commisionIcon from "../Assets/commisionIcon.svg";
import compreSiveDash from "../Assets/compreSiveDash.svg";
import extraIncenIcon from "../Assets/extraIncenIcon.svg";
import extraMoreIcon from "../Assets/extraMoreIcon.svg";
import leftImgFormTraverlAgent from "../Assets/leftImgFormTraverlAgent.svg";
import BookingLoader from "./BookingLoader.js";
import weekPayicon from "../Assets/weekPayicon.svg";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";
import {
  get_website_faq,
  save_forms_enquiry,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import Footer from "./Footer";
import HeroBtmListData from "./HeroBtmListData.js";
import Faq from "./Reapeting Componets/Faq";
import SuccessFullModal from "./SuccessFullModal";
import Testimonial from "./Testimonial";
let click_time = 0;
export default function TravelAgent() {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const increaseVisiData = [
    {
      imgSrc: commisionIcon,
      title: "Commission",
      description:
        "Earn guaranteed commission on every booking you make through our platform, ensuring consistent revenue.",
    },
    {
      imgSrc: weekPayicon,
      title: "Weekly payments",
      description:
        "Receive your earnings on a weekly basis, providing you with timely and reliable payouts.",
    },
    {
      imgSrc: extraIncenIcon,
      title: "Extra incentive",
      description:
        "Boost your income with quarterly incentives based on your performance and sales targets.",
    },
    {
      imgSrc: extraMoreIcon,
      title: "Earn more",
      description:
        "Maximize your profits by offering upsell opportunities or applying mark-ups on selected bookings.",
    },

    {
      imgSrc: HelpLineheadPhone,
      title: "Helpline",
      description:
        "Our 24/7 priority support is always available to assist with any urgent booking inquiries or changes.",
    },
    {
      imgSrc: SelectedHotel,
      title: "Selected hotels",
      description:
        "Offer your clients access to a curated selection of premium, high-quality hotels that guarantee satisfaction.",
    },
    {
      imgSrc: HotlNetwork,
      title: "Hotel network",
      description:
        "Book from an extensive network of properties, including hotels, resorts, and event venues across multiple cities.",
    },
    {
      imgSrc: compreSiveDash,
      title: "Comprehensive dashboard",
      description:
        "Easily manage your bookings and commissions through our intuitive dashboard, keeping you in control of your business.",
    },
  ];
  const steps = [
    {
      number: "01",
      title: "Sign-up process",
      description: "Create an account and fill in basic details.",
    },
    {
      number: "02",
      title: "Browse & Book",
      description: "Create an account and fill in basic property details.",
    },
    {
      number: "03",
      title: "Manage Bookings",
      description:
        "Keep track of all your bookings in one place, with easy modifications and cancellations.",
    },
  ];

  const [testinomial, settestinomial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [testimonial_image_link, settestimonial_image_link] = useState("");
  // const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("enquiry_type", "3");
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          let data1 = Response.split("~@~");
          if (parseInt(data1[0]) === 1) {
            handleError(data1[1]);
          } else {
            click_time = 0;
            handleShowSucessModal();
            empty_form(form_data);
            setTimeout(() => {
              handleLinkClick("/");
            }, 2000);
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 250,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="contList">
        {" "}
        <div className="ListYourHotelCont  traverl_agent_hero">
          <div className="heroUnderListHead">
            {/* <Header /> */}
            <div className="HeroLIstUnderHeading  heaCop  ">
              <div className="travllHeroHead santosiFOntCLass">
                <h1>
                  Partner with Us:<span> Unlock Exclusive Hotel Deals </span>
                  as a Travel Agent
                </h1>
              </div>

              <label>
                Grow your travel business with access to our extensive hotel
                network and special offers for your clients
              </label>

              <button
                type="button"
                style={{ fontSize: "16px" }}
                onClick={() => handleActive("formCOntainerCreate")}
              >
                Become a Partner
              </button>
            </div>

            {/*--------hero bottm content Section -------*/}
            <HeroBtmListData />
          </div>
        </div>{" "}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p className="santosiFOntCLass">Why partner with us</p>
            <div className="quickHotlsubhading CopHotlSubHead ">
              {" "}
              <h2> Grow with us as a </h2>
              <span> travel partner</span>
            </div>
          </div>

          <div className="increaseVisiImgCOnt  ContnrCoprateStay">
            <div className="row">
              {increaseVisiData.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <div>
                    <div className=" dataCopreStay">
                      <div className="CopratImgDIv">
                        <img
                          src={item.imgSrc}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="d-flex align-items-center p-0 arrowImgOrenge titleCopretestay">
                        <h2>{item.title}</h2>
                      </div>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col ArrowImgCont">
                <div className="ArrowImg">
                  <img src={AroPlanDiv} alt="AroPlanDiv"></img>
                </div>
              </div>
            </div>
          </div>

          {/**----------How it work for hotel ------*/}
        </div>
        <div className="howItworkDivHotel position-relative HowItwrkTravl">
          <div className="container-lg">
            <div className="QuicklyHotl santosiFOntCLass">
              {" "}
              <p>How it works</p>
              <div className="quickHotlsubhading CopHotlSubHead">
                {" "}
                <h2> Step-by-step</h2>
                <span> process</span>
              </div>
            </div>

            <div className="howItWorksHotel__steps">
              <div className="row">
                {steps.map((step, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4">
                    <div className="width80 coprtHowitwork">
                      <h1>{step.number}</h1>
                      <div className="horIzontlist"></div>
                      <h3>{step.title}</h3>
                      <p>{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <img src={Clouds} alt="Swagstay" className="cloudesImg" />
        </div>
        {/**------------- Ready To grow your Bussiness-------*/}
        <div className="ReadyBussiNessDiv">
          <div className="container-lg">
            <div className="row ReadyDivContent">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="leftReadyDiv marginBttm">
                  <div className="readyHead ">
                    <h2 className="santosiFOntCLass">
                      Ready to Partner with Us?
                    </h2>
                    <p>
                      Unlock a world of exclusive hotel deals, seamless
                      bookings, and rewarding commissions
                    </p>
                  </div>

                  <button className="ListYourBssnssButton BecomPtnrTrivlag">
                    Become a Partner
                  </button>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 RIghtReadyDiv text-center mt-4 mt-md-0 bussinessRight">
                <img src={WomenBussniss} alt="property"></img>
              </div>
            </div>
          </div>
        </div>
        {/*------Testimonial--- */}
        <Testimonial
          testinomial={testinomial}
          testimonial_image_link={testimonial_image_link}
        />
        {/*--------Ready to boost-----------*/}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p>Unlock exclusive partner perks</p>
            <div className="quickHotlsubhading CopHotlSubHead">
              {" "}
              <h2>travel </h2>
              <span>agent partnership</span>
            </div>
          </div>

          <div className="row listPropFOrm">
            <div className="col-md-4 col-12 mb-3 mb-md-0  FormLftdata  ">
              <img
                src={leftImgFormTraverlAgent}
                alt="PropertyImage"
                className="img-fluid"
              />
            </div>

            {/* Right Column - Form */}
            <div className="col-lg-6 col-12" id="formCOntainerCreate">
              <form className="rightFOrm" id="listmyhotel">
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="fullName">Full name</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="fullName"
                        name="fullName"
                        placeholder="Name"
                        onChange={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputContainer">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control trio_mandatory"
                        id="email"
                        name="email"
                        placeholder="abc@xyz.com"
                        onChange={handleEmailChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="contactNumber">Contact number</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="contactNumber"
                        name="contactNumber"
                        maxLength={10}
                        placeholder="+91-XXXXXXXXXX"
                        onChange={handleNumbersChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputContainer">
                      <label htmlFor="cityName">Company name</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="cityName"
                        name="companyName"
                        placeholder="Company name"
                        onChange={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="inputContainer">
                    <label htmlFor="fullName">Company email id</label>
                    <input
                      type="text"
                      className="form-control trio_mandatory"
                      id="fullName"
                      name="companyEmail"
                      onChange={handleEmailChange}
                      placeholder="Type Here"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="inputContainer">
                    <label htmlFor="fullName">City name</label>
                    <input
                      type="text"
                      className="form-control trio_mandatory"
                      id="fullName"
                      name="city"
                      onChange={handleEmailChange}
                      placeholder="Type Here"
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>

                <button
                  className="suFOrm fontSizeSUbdiv"
                  type="button"
                  onClick={() => {
                    handleSaveChangesdynamic("listmyhotel", save_forms_enquiry);
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>{" "}
        {/*------Testimonial--- */}
        <div className="container-lg">
          <Faq faq={faq} />
        </div>
        <Footer />
      </div>

      {/*---------Successsfull Modal------------ */}
      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
