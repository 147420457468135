import { useState, useEffect, useRef } from "react";
import "./Css/Searchdata.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import orengePluse from "../Assets/orengePluse.svg";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import {
  search_list_type,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection";
import InImg from "../Assets/inImg.png";
import OutImg from "../Assets/outImg.png";
import OutImgB from "../Assets/IocnBlackOut.png";
import InImgB from "../Assets/IocnBlackin.png";

let search_data = true;
export default function SearchData() {
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";

  const MAX_ADULTS = 3;
  const MAX_CHILDREN = 3;
  const [totalAdults, settotalAdults] = useState(1);
  const [totalChildren, settotalChildren] = useState(0);
  const FromDate = new Date();
  const ToDateddd = FromDate;
  ToDateddd.setDate(ToDateddd.getDate() + 1);
  const [checkInDate, setCheckInDate] = useState(FromDate);
  const [checkOutDate, setCheckOutDate] = useState(ToDateddd);
  const [TotalNight, setTotalNight] = useState(1);

  const [showLocation, setshowLocation] = useState(false);
  const [showLocationhotels, setshowLocationhotels] = useState(false);
  const [AddRoom, setAddRoom] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isCheckInMode, setIsCheckInMode] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [Hotel_id, setHotel_id] = useState(0);
  const [HotelID, setHotelID] = useState(0);
  const [Allhotels, setAllhotels] = useState([]);
  const [AllCityList, setCityList] = useState([]);
  const [AllOthersList, setOthersList] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [ClickType, setClickType] = useState(0);

  const handleCheckInClick = () => {
    setIsCheckInMode(true);
    setIsCalendarOpen(true);
  };

  const handleCheckOutClick = () => {
    if (checkInDate) {
      setIsCheckInMode(false);
      setIsCalendarOpen(true);
    }
  };

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setCheckOutDate(null);
    setIsCheckInMode(false); // Switch to check-out selection
  };
  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
    setIsCalendarOpen(false); // Close the calendar after selecting check-out
  };

  //addd room buttons

  useEffect(() => {
    let customer_rooms = retrieveData("RoomData");
    let customer_checkInDate = retrieveData("CheckIndate");
    let customer_checkOutDate = retrieveData("CheckOutdate");

    const currentDate = new Date(); // Current date
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + 1); // Next day's date

    if (customer_rooms === null || customer_rooms === 0) {
      customer_rooms = 0;
    }

    if (customer_rooms !== "0") {
      setRooms(JSON.parse(customer_rooms));
    }

    if (customer_checkInDate !== "0") {
      const parsedCheckInDate = new Date(customer_checkInDate);
      // If the check-in date is before the current date, set it to the current date
      if (parsedCheckInDate < currentDate) {
        setCheckInDate(currentDate);
      } else {
        setCheckInDate(parsedCheckInDate);
      }
    } else {
      setCheckInDate(currentDate);
    }

    if (customer_checkOutDate !== "0") {
      const parsedCheckOutDate = new Date(customer_checkOutDate);
      if (parsedCheckOutDate < currentDate) {
        const updatedCheckOutDate = new Date(currentDate);
        updatedCheckOutDate.setDate(currentDate.getDate() + 1);
        setCheckOutDate(updatedCheckOutDate);
      } else {
        parsedCheckOutDate.setDate(parsedCheckOutDate.getDate());
        setCheckOutDate(parsedCheckOutDate);
      }
    } else {
      setCheckOutDate(nextDate);
    }
  }, []);

  const handleShowAddroom = () => {
    setAddRoom(!AddRoom);

    setshowLocation(false);
    setIsCalendarOpen(false);
  };

  const [rooms, setRooms] = useState([{ adults: 1, children: 0 }]);

  const handleSHowlocations = () => {
    setshowLocation(!showLocation);

    setAddRoom(false);
    setAddRoom(false);

    setIsCalendarOpen(false);
  };

  const match_and_return_seo_link = (HotelID, hotel_id) => {
    let data_seo_link_final = "hotel_detail/" + HotelID + "/" + hotel_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const getSearchableData = async (searchTextdata) => {
    if (search_data) {
      search_data = false;

      const fd = new FormData();
      fd.append("search_keywords", searchTextdata);
      fd.append("next_limit", 0);
      await server_post_data(search_list_type, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setshowLocationhotels(true);

            setAllhotels(final_data.hotel_list);
            setCityList(final_data.other_city_list);
            setOthersList(final_data.other_data_list);
            setSEOloop(final_data.seo_list);
          }

          search_data = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear the previous timeout if it exists
    }

    // Set a new timeout to call the function after a delay (e.g., 500ms)
    setDebounceTimeout(
      setTimeout(() => {
        getSearchableData(value);
      }, 500)
    );
  };

  const handleBooking = () => {
    if (ClickType !== 0) {
      setIsCalendarOpen(false);
      storeData("RoomData", JSON.stringify(rooms));
      storeData("CheckIndate", checkInDate.toISOString().split("T")[0]);
      storeData("CheckOutdate", checkOutDate.toISOString().split("T")[0]);
      let total_adults_count = 0;
      let total_children_count = 0;
      Object.entries(rooms).forEach(([key, value]) => {
        total_adults_count = total_adults_count + parseInt(value.adults);
        total_children_count = total_children_count + parseInt(value.children);
      });
      storeData(
        "TotalAdultAndChild",
        rooms.length + "~@~" + total_adults_count + "~@~" + total_children_count
      );

      if (ClickType == 1) {
        let link_url = match_and_return_seo_link(HotelID, Hotel_id);
        handleLinkClick("/" + link_url);
      } else if (ClickType == 2) {
        handleLinkClick("/search" + HotelID);
      } else if (ClickType == 3) {
        let link_url = match_and_return_city_link(HotelID);
        handleLinkClick("/" + link_url);
      }
    } else {
      handleError("Please select a destination from the list");
    }
  };

  const handleAdultChildIncrementDecrement = (index, min_plus, adultchild) => {
    setRooms((prev) => {
      let newRooms = [...prev];
      let shouldCallHitRoom = true;
      if (adultchild === "adult") {
        if (min_plus === 1 && newRooms[index].adults < MAX_ADULTS) {
          newRooms[index].adults += 1;
        } else if (min_plus === 1 && newRooms[index].adults >= MAX_ADULTS) {
          shouldCallHitRoom = false;
        } else if (min_plus === -1) {
          if (index === 0 && newRooms[index].adults > 1) {
            newRooms[index].adults -= 1;
          } else if (index !== "0" && newRooms[index].adults > 0) {
            newRooms[index].adults -= 1;
          }
        }
      } else if (adultchild === "child") {
        if (min_plus === 1) {
          if (newRooms[index].children < MAX_CHILDREN) {
            newRooms[index].children += 1;
          } else {
            // Prevent function_hit_room from being called when MAX_CHILDREN is exceeded
            shouldCallHitRoom = false;
          }
        } else if (min_plus === -1 && newRooms[index].children > 0) {
          newRooms[index].children -= 1;
        } else {
          shouldCallHitRoom = false;
        }
      } else {
        // Adding a new room
        newRooms = [...newRooms, { id: Date.now(), adults: 1, children: 0 }];
      }
      // Filter out empty rooms, except for Room 1
      newRooms = newRooms.filter(
        (room, idx) => idx === 0 || room.adults > 0 || room.children > 0
      );
      let total_adults_count = 0;
      let total_children_count = 0;
      Object.entries(newRooms).forEach(([key, value]) => {
        total_adults_count = total_adults_count + parseInt(value.adults);
        total_children_count = total_children_count + parseInt(value.children);
      });
      settotalAdults(total_adults_count);
      settotalChildren(total_children_count);

      // Call function_hit_room with the updated room data only if shouldCallHitRoom is true

      return newRooms;
    });
  };

  const handlehotelClick = (item, flag) => {
    if (flag == 1) {
      setSearchText(item.hotel_name);
      setHotel_id(item.hotel_id);
      setHotelID(item.HotelID);
    } else if (flag == 2) {
      setSearchText(item.address);
      setHotelID(
        "?location=" +
          item.address +
          "&latitude=" +
          item.latitude +
          "&longitude=" +
          item.longitude +
          "&searchType=locality&city_data=" +
          item.city_name +
          "&roomConfig=1&showSearchElements=false&country=" +
          item.country_name +
          "&guests=1&rooms=1"
      );
      setHotel_id(0);
    } else if (flag == 3) {
      setSearchText(
        item.city_name + " , " + item.state_name + " , " + item.country_name
      );
      setHotelID(item.city_id);
      setHotel_id(0);
    }
    setClickType(flag);
    setshowLocationhotels(false);
  };

  const match_and_return_city_link = (v_id) => {
    let data_seo_link_final = "hotel_list_location/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const dropdownRefs = [useRef(null), useRef(null)];
  const dateContrRef = useRef(null);

  //----------------------------------Calander DropDOwn-----------------------------------
  const handleClickOutside2 = (event) => {
    if (dateContrRef.current && !dateContrRef.current.contains(event.target)) {
      setIsCalendarOpen(false); // Close the calendar
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside2);
    return () => {
      document.removeEventListener("click", handleClickOutside2);
    };
  }, []);
  const handleClickOutside = (event) => {
    const isClickInsideDropdown =
      dropdownRefs[0].current && dropdownRefs[0].current.contains(event.target);

    const isClickInsideCategoryDropdown =
      dropdownRefs[1].current && dropdownRefs[1].current.contains(event.target);

    const isClickInsideDecrementButton = event.target.closest(".decrement-btn");

    if (
      isClickInsideDropdown ||
      isClickInsideCategoryDropdown ||
      isClickInsideDecrementButton
    ) {
      return;
    }

    if (dropdownRefs[0].current && !isClickInsideDropdown) {
      setAddRoom(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  ///populer SHow Location Div

  const poplllerDesignationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        poplllerDesignationRef.current &&
        !poplllerDesignationRef.current.contains(event.target)
      ) {
        setshowLocation(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const calculateNights = (checkInDate, checkOutDate) => {
    if (!checkInDate || !checkOutDate) {
      return 0; // If either date is not selected, return 0
    }

    // Normalize dates by setting time to midnight (00:00:00)
    const normalizeDate = (date) => {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0); // Reset time to midnight
      return d;
    };

    const normalizedCheckIn = normalizeDate(checkInDate);
    const normalizedCheckOut = normalizeDate(checkOutDate);

    // Calculate the time difference in milliseconds
    const timeDifference =
      normalizedCheckOut.getTime() - normalizedCheckIn.getTime();

    // Convert milliseconds to days
    const nights = timeDifference / (1000 * 60 * 60 * 24);

    return nights > 0 ? nights : 0; // Ensure result is not negative
  };

  return (
    <div className={`${isHomeRoute ? "barWidthIn" : "container-lg"}`}>
      {" "}
      <div className="my-4 ">
        <div
          className={
            isHomeRoute
              ? "card  shadow-sm HomeSearchbr"
              : "card p-4 shadow-sm border-0 rounded-3 Searchbr"
          }
          ref={dateContrRef}
        >
          <div className="col-lg-12 col-md-10 m-auto">
            <div className="row align-items-center text-center text-md-start ">
              {/*----Location----*/}
              <div
                className={`col-xl-3 col-sm-8 mb-3 d-flex justiCOnt ${
                  isHomeRoute ? "VerticlIneHome" : "VerticlIne"
                }`}
                ref={poplllerDesignationRef}
              >
                <div className="contSearch" onClick={handleSHowlocations}>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-geo-alt me-2 fs-5"></i>
                      <p className="form-label fw-bold mb-0">Location</p>
                    </div>

                    <label
                      className="form-label fw-bold "
                      htmlFor="hotelSearchInput"
                    >
                      Search by city, region,hotel name
                    </label>
                  </div>

                  <input
                    type="text"
                    className="form-control placeCOlorIn border-0 p-0 text-muted"
                    id="hotelSearchInput"
                    placeholder="City, Region, Hotel Name"
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                </div>

                {showLocation &&
                  showLocationhotels &&
                  ((AllCityList && AllCityList.length > 0) ||
                    (AllOthersList && AllOthersList.length > 0)) && (
                    <div className="poplllerDesignation">
                      <div className="popHead">
                        <h1>Popular Location</h1>
                      </div>

                      {AllCityList.map((item, index) => (
                        <div className="citysSHow" key={index}>
                          <button
                            className="address"
                            onClick={() => handlehotelClick(item, 3)}
                          >
                            {item.city_name}, {item.state_name},{" "}
                            {item.country_name}
                          </button>
                        </div>
                      ))}
                      {AllOthersList.map((item, index) => (
                        <div className="citysSHow" key={index}>
                          <button
                            className="address"
                            onClick={() => handlehotelClick(item, 2)}
                          >
                            {item.address}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                {showLocation &&
                  showLocationhotels &&
                  AllCityList &&
                  AllCityList.length === 0 &&
                  AllOthersList &&
                  AllOthersList.length === 0 &&
                  Allhotels &&
                  Allhotels.length > 0 && (
                    <div className="poplllerDesignation">
                      <div className="popHead">
                        <h1>Popular Hotels</h1>
                      </div>

                      {Allhotels.map((item, index) => (
                        <div className="citysSHow" key={index}>
                          <button
                            className="address"
                            onClick={() => handlehotelClick(item, 1)}
                          >
                            {item.hotel_name}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div className="col-12 d-sm-none d-block classHoriLine"></div>

              {/*----Guests----*/}
              <div
                className={`col-xl-2 col-sm-4 mb-3 d-flex justiCOnt ${
                  isHomeRoute ? "gustVeticalIneHome" : "gustVeticalIne"
                } noBorder`}
                ref={dropdownRefs[0]}
              >
                <div className="contSearch">
                  <div
                    className="d-flex flex-column"
                    onClick={handleShowAddroom}
                  >
                    <div className="d-flex align-items-center">
                      <i className="bi bi-person me-2 fs-5"></i>
                      <p className="form-label fw-bold m-0">Guests</p>
                    </div>

                    <label className="form-label fw-bold ">
                      {" "}
                      {rooms.length} Room
                    </label>
                  </div>

                  <input
                    type="text"
                    className="form-control border-0 p-0 text-muted"
                    value={`${
                      totalAdults < 10 ? `0${totalAdults} Adults` : totalAdults
                    },${
                      totalChildren < 10 ? `0${totalChildren}` : totalChildren
                    } Children`}
                    readOnly
                    style={{ cursor: "pointer" }}
                    onClick={handleShowAddroom}
                  />
                </div>

                <div className={`roomDrodownHome   ${AddRoom ? "open" : ""}`}>
                  {rooms &&
                    rooms.length > 0 &&
                    rooms.map((room, roomIndex) => (
                      <div key={roomIndex}>
                        <div className="roomHead">
                          <h1 style={{ textAlign: "start" }}>
                            Room {roomIndex + 1}
                          </h1>
                        </div>

                        {/* Adults Section */}
                        <div className="roomHeadData">
                          <div className="leftROomCOnt">
                            <h1>Adults</h1>
                            <p>Ages 5 or above</p>
                          </div>
                          <div className="rightROoomtCOnt">
                            <button
                              className={`minsssBttm decrement-btn ${
                                room.adults === 1 && roomIndex === 0
                                  ? "ENableMinusbtn"
                                  : ""
                              }`}
                              onClick={() =>
                                handleAdultChildIncrementDecrement(
                                  roomIndex,
                                  -1,
                                  "adult"
                                )
                              }
                              style={{
                                opacity:
                                  roomIndex === 0
                                    ? room.adults > 1
                                      ? 1
                                      : 0.5
                                    : 1,
                              }}
                              disabled={roomIndex === 0 && room.adults <= 1}
                            >
                              -
                            </button>

                            <label>{room.adults}</label>

                            <button
                              className={`plusBttm ${
                                room.adults === 3 ? "ENablePluse" : ""
                              }`}
                              onClick={() =>
                                handleAdultChildIncrementDecrement(
                                  roomIndex,
                                  1,
                                  "adult"
                                )
                              }
                              style={{
                                opacity: room.adults < 3 ? 1 : 0.5,
                              }}
                              disabled={room.adults >= 3}
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="roomHeadData">
                          <div className="leftROomCOnt">
                            <h1>Children</h1>
                            <p>Age 0-5</p>
                          </div>
                          <div className="rightROoomtCOnt">
                            <button
                              className={`minsssBttm ${
                                room.children === 0 ? "ENableMinusbtn" : ""
                              }`}
                              onClick={() =>
                                handleAdultChildIncrementDecrement(
                                  roomIndex,
                                  -1,
                                  "child"
                                )
                              }
                              style={{
                                opacity: room.children > 0 ? 1 : 0.5,
                              }}
                              disabled={room.children <= 0}
                            >
                              -
                            </button>

                            <label>{room.children}</label>

                            <button
                              className={`plusBttm ${
                                room.children === 3 ? "ENablePluse" : ""
                              }`}
                              onClick={() =>
                                handleAdultChildIncrementDecrement(
                                  roomIndex,
                                  1,
                                  "child"
                                )
                              }
                              style={{
                                opacity: room.children < 3 ? 1 : 0.5,
                              }}
                              disabled={room.children >= 3}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        {roomIndex === rooms.length - 1 && (
                          <div
                            className="addrromBotttm"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent click event from propagating to the outside
                              handleAdultChildIncrementDecrement(0, 0, "add");
                            }}
                            style={{ marginBottom: "0.5rem" }}
                          >
                            <img src={orengePluse} alt="orengePluse" />
                            <p>Add room</p>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 d-xl-none d-block classHoriLine"></div>

              {/*----Check-in----*/}
              <div
                className={`col-xl-1 p-xl-0 col-sm-4 col-5 mb-3 d-flex justiCOnt`}
                onClick={handleCheckInClick}
              >
                <div className="contSearch">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      {isHomeRoute ? (
                        <img
                          src={InImg}
                          className="inOutimg"
                          alt="Check-Out Img"
                        />
                      ) : (
                        <img
                          src={InImgB}
                          className="inOutimg"
                          alt="Check-Out Img"
                        />
                      )}
                      <p className="form-label fw-bold m-0 ">check-in</p>
                    </div>

                    <label className="form-label fw-bold">
                      {checkInDate
                        ? checkInDate.toLocaleDateString()
                        : "Select date"}
                    </label>
                  </div>

                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control border-0 p-0 text-muted"
                      value="Starting date"
                      style={{ cursor: "pointer" }}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-1 col-sm-4 col-2 m-auto p-0">
                <div className="nightsNumber">
                  <div
                    className={`nightsNumberLine ${
                      !isHomeRoute ? "nightsNumberLineBlack" : ""
                    }`}
                  ></div>
                  <div
                    className={`nightN9 ${!isHomeRoute ? "nightN9Black" : ""}`}
                  >
                    <p>{TotalNight}N</p>
                  </div>
                </div>
              </div>

              {isCalendarOpen && (
                <div
                  className={`${
                    isHomeRoute
                      ? "BookingCLanderHome"
                      : "checkInCalanderHotelDetail"
                  }`}
                >
                  <DatePicker
                    selected={isCheckInMode ? checkInDate : checkOutDate}
                    onChange={(date) => {
                      let nights = 0;

                      if (isCheckInMode) {
                        let check_dtae = date;
                        let check_out_dtae = checkOutDate;
                        if (checkOutDate && date >= checkOutDate) {
                          // Ensure check-out date is at least 1 day ahead
                          const nextDay = new Date(date);
                          nextDay.setDate(nextDay.getDate() + 1);
                          setCheckOutDate(nextDay);
                        }

                        handleCheckInDateChange(date);
                        nights = calculateNights(check_dtae, check_out_dtae);
                      } else {
                        let data_date = date;
                        if (checkInDate && date <= checkInDate) {
                          // Ensure check-out is at least 1 day ahead of check-in
                          const nextDay = new Date(checkInDate);
                          nextDay.setDate(nextDay.getDate() + 1);
                          data_date = nextDay;
                        }

                        handleCheckOutDateChange(data_date);
                        nights = calculateNights(checkInDate, data_date);
                      }

                      console.log(nights);
                      setTotalNight(nights);
                    }}
                    className="form-control border-0 p-0 text-muted"
                    placeholderText={
                      isCheckInMode
                        ? "Select check-in date"
                        : "Select check-out date"
                    }
                    dateFormat="dd MMM yyyy"
                    inline
                    selectsStart={isCheckInMode}
                    selectsEnd={!isCheckInMode}
                    startDate={checkInDate}
                    endDate={checkOutDate}
                    minDate={isCheckInMode ? new Date() : checkInDate} // For check-out, start from check-in date
                    monthsShown={2} // Show two months side-by-side
                  />
                </div>
              )}

              {/*----Check-out----*/}
              <div
                className="col-xl-2 col-sm-4 col-5 mb-3 d-flex justiCOnt "
                onClick={handleCheckOutClick}
              >
                <div className="contSearch">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      {isHomeRoute ? (
                        <img
                          src={OutImg}
                          className="inOutimg"
                          alt="Check-Out Img"
                        />
                      ) : (
                        <img
                          src={OutImgB}
                          className="inOutimg"
                          alt="Check-Out Img"
                        />
                      )}
                      <p className="form-label fw-bold m-0">check-Out</p>
                    </div>

                    <label className="form-label fw-bold">
                      {checkOutDate
                        ? checkOutDate.toLocaleDateString()
                        : "Select date"}
                    </label>
                  </div>

                  <input
                    type="text"
                    className="form-control border-0 p-0 text-muted"
                    value="End date"
                    readOnly
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div className="col-12 d-xl-none d-block classHoriLine"></div>

              {/*----Search Button----*/}
              <div
                className="col-xl-1 col-12 d-flex align-items-center justify-content-center text-lg-end text-md-center text-center"
                style={{ paddingLeft: "0" }}
              >
                <button
                  className="btn btn-danger px-4 py-2 SearchButtn"
                  onClick={() => handleBooking()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
