import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";

const appauth_key = "www.swagstay.com";
let APL_LINK = "http://192.168.1.7/swagstay_website/";
APL_LINK = "https://adminpanel.swagstay.com/";
let Website_URL = "https://www.swagstay.com/";

const local_server_link_react = APL_LINK + "ReactMaster/";
const GOOGLE_PLACES_API_KEY = "AIzaSyC3WmMY1uBgZmeiAPftovjM_tY0vbUi3Zs";

const Login_url = local_server_link_react + "login_user";
const front_dashboard = local_server_link_react + "front_dashboard";
const hotel_details = local_server_link_react + "hotel_details";
const hotel_rating_details = local_server_link_react + "hotel_rating_details";
const front_city_click = local_server_link_react + "front_city_click";
const front_city_click_more = local_server_link_react + "front_city_click_more";
const booking_all = local_server_link_react + "booking_all";
const booking_details_all = local_server_link_react + "booking_details_all";
const front_discount_click = local_server_link_react + "front_discount_click";
const front_discount_click_more =
  local_server_link_react + "front_discount_click_more";
const front_friendly_click = local_server_link_react + "front_friendly_click";
const front_friendly_click_more =
  local_server_link_react + "front_friendly_click_more";
const add_remove_favorite_hotel =
  local_server_link_react + "add_remove_favorite_hotel";
const favorite_details_all = local_server_link_react + "favorite_details_all";
const save_booking_request = local_server_link_react + "save_booking_request";
const refund_status_all = local_server_link_react + "refund_status_all";
const wallet_details_all = local_server_link_react + "wallet_details_all";
const get_refer_earn_page = local_server_link_react + "get_refer_earn_page";
const get_website_faq = local_server_link_react + "get_website_faq_reactjs";
const get_website_faq_hotel = local_server_link_react + "get_website_faq_hotel";
const save_property_url = local_server_link_react + "save_property_url";
const save_business_url = local_server_link_react + "save_business_url";
const save_forms_enquiry = local_server_link_react + "save_forms_enquiry";
const website_content = local_server_link_react + "website_content";
const website_information = local_server_link_react + "website_information";
const get_wallet_offer_data = local_server_link_react + "get_wallet_offer_data";
const wallet_real_details_all =
  local_server_link_react + "wallet_real_details_all";
const add_amount_wallet_real =
  local_server_link_react + "add_amount_wallet_real";
const add_amount_wallet_final_real =
  local_server_link_react + "add_amount_wallet_final_real";
const save_booking_online_pre =
  local_server_link_react + "save_booking_online_pre";
const save_booking_online = local_server_link_react + "save_booking_online";
const offers_list = local_server_link_react + "offers_list";
const booking_calculation = local_server_link_react + "booking_calculation";
const search_list_type = local_server_link_react + "search_list_type";
const search_list_type_hotels =
  local_server_link_react + "search_list_type_hotels";
const search_list_type_hotels_more =
  local_server_link_react + "search_list_type_hotels_more";
const update_payment_pre = local_server_link_react + "update_payment_pre";
const update_booking_payment =
  local_server_link_react + "update_booking_payment";
const cancellationpolicy = local_server_link_react + "cancellationpolicy";
const verify_email_send_link =
  local_server_link_react + "verify_email_send_link";
const update_gst_url = local_server_link_react + "update_gst_url";
const update_guest_name = local_server_link_react + "update_guest_name";
const show_hide_booking_reactnative =
  local_server_link_react + "show_hide_booking_reactnative";
const cancel_booking = local_server_link_react + "cancel_booking";
const hotelpolicy = local_server_link_react + "hotelpolicy";
const update_rating_reactNative =
  local_server_link_react + "update_rating_reactNative";
const report_hotel_reactNative =
  local_server_link_react + "report_hotel_reactNative";
const offer_url_data = local_server_link_react + "offer_url_data";
const profile_data_url = local_server_link_react + "profile_data_url";
const update_profile_gst_url =
  local_server_link_react + "update_profile_gst_url";
const hotel_details_next_data =
  local_server_link_react + "hotel_details_next_data";
const save_express_checkin_reactnative =
  local_server_link_react + "save_express_checkin_reactnative";
const getNearbyTransitStations =
  local_server_link_react + "getNearbyTransitStations";

const update_reschedule_date =
  local_server_link_react + "update_reschedule_date";
const blog_website = local_server_link_react + "blog_website";
const job_apply_website = local_server_link_react + "job_apply_website";
const blog_detail_website = local_server_link_react + "blog_detail_website";

// by sumit chourey
const career_website_react = local_server_link_react + "career_website_react";
const feedback_form_website = local_server_link_react + "feedback_form_website";
const aadhar_number_search = local_server_link_react + "aadhar_number_search";
const aadhar_otp_validate = local_server_link_react + "aadhar_otp_validate";

/** make under */
const get_airquality =
  "https://airquality.googleapis.com/v1/currentConditions:lookup?key=" +
  GOOGLE_PLACES_API_KEY;
const GET_PLACE_DATA_NEARBY =
  "https://maps.googleapis.com/maps/api/place/nearbysearch/json?";
//new api for websites
const city_list_website = local_server_link_react + "city_list_website";
const save_subscribe_website =
  local_server_link_react + "save_subscribe_website";

// new api by shivam
const view_testimonial = local_server_link_react + "view_testimonial";
const get_seo_data_website = local_server_link_react + "get_seo_data_website";

const server_post_data = async (url_for, Data) => {
  try {
    if (Data === null) {
      Data = new FormData();
    }

    let customer_id = retrieveData("customer_id");
    if (customer_id === null || customer_id === 0) {
      customer_id = 0;
    }
    let city_lat = retrieveData("customer_lat");
    if (city_lat === null || city_lat === 0) {
      city_lat = 23.25840597;
    }
    let city_long = retrieveData("customer_long");
    if (city_long === null || city_long === 0) {
      city_long = 77.4019227;
    }
    Data.append("latitude", city_lat);
    Data.append("longitude", city_long);
    Data.append("token", "");
    Data.append("key_secret", "swagstayhotel_key@2022");
    Data.append("web_app", "Reactnative");
    Data.append("platform_type", "website");
    Data.append("cd_id", customer_id);
    const response = await axios.post(url_for, Data, {
      headers: {
        "Content-Type": "multipart/form-data", // If sending FormData
        // Include other headers as needed for your backend
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const server_get_airquality = async (latitude, longitude) => {
  try {
    const response = await axios.post(
      get_airquality, // Make sure to define this environment variable
      {
        location: {
          latitude: latitude,
          longitude: longitude,
        },
        extraComputations: [
          "HEALTH_RECOMMENDATIONS",
          "LOCAL_AQI",
          "POLLUTANT_CONCENTRATION",
        ],
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return just the data instead of entire response
  } catch (error) {
    throw new Error(`Failed to fetch air quality data: ${error.message}`);
  }
};

const server_get_nearby = async (latitude, longitude, type) => {
  try {
    const params = new URLSearchParams({
      location: `${latitude},${longitude}`,
      radius: "1500",
      type: type,
      key: GOOGLE_PLACES_API_KEY,
    });
    const response = await axios.get(GET_PLACE_DATA_NEARBY + params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Return just the results array
  } catch (error) {
    throw new Error(`Failed to fetch nearby places: ${error.message}`);
  }
};

export {
  add_amount_wallet_final_real,
  add_amount_wallet_real,
  add_remove_favorite_hotel,
  APL_LINK,
  appauth_key,
  blog_detail_website,
  blog_website,
  booking_all,
  booking_calculation,
  booking_details_all,
  cancel_booking,
  cancellationpolicy,
  //by sumit chourey
  career_website_react,
  // new api for website
  city_list_website,
  favorite_details_all,
  feedback_form_website,
  front_city_click,
  front_city_click_more,
  front_dashboard,
  front_discount_click,
  front_discount_click_more,
  front_friendly_click,
  front_friendly_click_more,
  get_refer_earn_page,
  get_seo_data_website,
  get_wallet_offer_data,
  get_website_faq,
  get_website_faq_hotel,
  getNearbyTransitStations,
  GOOGLE_PLACES_API_KEY,
  hotel_details,
  hotel_details_next_data,
  hotel_rating_details,
  hotelpolicy,
  job_apply_website,
  Login_url,
  offer_url_data,
  offers_list,
  profile_data_url,
  refund_status_all,
  report_hotel_reactNative,
  save_booking_online,
  save_booking_online_pre,
  save_booking_request,
  save_business_url,
  save_express_checkin_reactnative,
  save_forms_enquiry,
  save_property_url,
  save_subscribe_website,
  search_list_type,
  search_list_type_hotels,
  search_list_type_hotels_more,
  server_get_airquality,
  server_get_nearby,
  server_post_data,
  show_hide_booking_reactnative,
  update_booking_payment,
  update_gst_url,
  update_guest_name,
  update_payment_pre,
  update_profile_gst_url,
  update_rating_reactNative,
  update_reschedule_date,
  verify_email_send_link,
  aadhar_number_search,
  aadhar_otp_validate,
  // new api by shivam
  view_testimonial,
  wallet_details_all,
  wallet_real_details_all,
  website_content,
  website_information,
  Website_URL,
};
