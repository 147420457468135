import React, { useState } from "react";
import "./Css/ApplyCoupon.css";
import topImage from "../Assets/topImage.png";
import OfferSign from "../Assets/offerSign.png";

function ApplyCoupon({
  isSidebarOpen,
  setcoupon,
  coupondata,
  applyManualCouponAddon,
}) {
  const [couponCodedata, setCouponCodedata] = useState("");
  return (
    <div>
      {isSidebarOpen && (
        <div className="overlaySidebar">
          <div className="CouponSidebar">
            <div className="couponTop">
              <div className="couponInput">
                <p>Discount coupon</p>
                <div className="couponInputContianer">
                  <input
                    type="text"
                    onChange={(e) => setCouponCodedata(e.target.value)}
                  />
                  <button
                    className="applyCBtn"
                    onClick={() => applyManualCouponAddon(couponCodedata)}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <img className="topImg" src={topImage} alt="Swagstay" />
            </div>
            <div className="couponBottom">
              <div className="couponContainer">
                <p className="couponHead">All coupon</p>
                <div className="couponList">
                  {coupondata.map((coupon, index) => (
                    <div className="couponItem" key={index}>
                      <div className="couponLeft">
                        <div className="couponName">
                          <img src={OfferSign} alt="Swagstay" />
                          <p className="couponCode">{coupon.promo_code}</p>
                        </div>
                        <p className="couponDetail">{coupon.disc_msg}</p>
                      </div>
                      <div className="couponRight">
                        <button
                          className="applyCBtn"
                          onClick={() => {
                            setcoupon(index);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplyCoupon;
