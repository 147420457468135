import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Css/RewardPage.css";
import BackBtn from "../Assets/backIcon.svg";
import rightRwd from "../Assets/rightRwd.svg";
import rwardRightHero from "../Assets/rwardRightHero.svg";
import { useNavigate } from "react-router-dom";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  get_website_faq,
} from '../ServiceConnection/serviceconnection';
export default function ReferFaq() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [WalletList, setWalletList] = useState([]);
  const [openFaq, setOpenFaq] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/refer_earn_page");
    window.location.reload();
  };

  useEffect(() => {
    master_data_get()
  }, [])

  const toggleFaq = (index) => {
    if (openFaq === index) {
      setOpenFaq(null); // Close if already open
    } else {
      setOpenFaq(index); // Open selected
    }
  };
  const master_data_get = async () => {
    setShowLoaderAdmin(true);

    await server_post_data(get_website_faq, null)
      .then(Response => {
        let data = Response.split('~@~');
        if (parseInt(data[0]) === 1) {
          handleError( data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setWalletList(final_data.faq_data);
        }
        setShowLoaderAdmin(false);
      })
      .catch(error => {
        setShowLoaderAdmin(false);
      });
  };


  return (
    <div>
      <Header />
      <div className="container-lg">
        <div
          className="backBtttn"
          onClick={handleNavigate}
          style={{ cursor: "pointer" }}
        >
          <img src={BackBtn} alt="BackBtn"></img>
          <p>Faq</p>
        </div>
        <p>Find out how our referral program works and start enjoying exclusive rewards by inviting your friends to book with us</p>
        {/**-----------My Rewards--------- */}
        {/* <div className="myRwrds">
          <div className="col-lg-4 col-md-4 col-12 lefRwrd">
            <h1>My rewards</h1>
          </div>
          <div className="col RIghtRwrd">
            <img src={rwardRightHero} alt="rightRwd"></img>
          </div>
        </div> */}
        {/**----------SignUpBonus--------- */}

        <div className="faqData">
                {WalletList &&
                  WalletList.length > 0 &&
                  WalletList.map((item, index) => (
                    <div
                      key={index}
                      className={`faqItem ${openFaq === index ? "open" : ""}`}
                    >
                      <div
                        className="faqQuestion d-flex justify-content-between align-items-center"
                        onClick={() => toggleFaq(index)}
                      >
                        <p>{item.question_add}</p>
                        <span
                          className={`arrow ${
                            openFaq === index ? "rotate" : ""
                          }`}
                        >
                          &#9662;
                        </span>
                      </div>
                      <div className="faqAnswer">
                        <p>{item.answer_add}</p>
                      </div>
                    </div>
                  ))}
              </div>
      </div>
    </div>
  );
}
