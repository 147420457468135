import React, { useState, useEffect } from "react";
import "./Css/whatsnew.css";
import offerImg from "../Assets/offerImg.png";
import stayImg from "../Assets/stayImg.svg";
import clockedImg from "../Assets/clockedImg.svg";
import lineCUrl from "../Assets/lineCUrl.svg";
import coinImg from "../Assets/coinImg.svg";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_refer_earn_page,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export default function WhatsNew() {
  const [shareablecoin, setshareablecoin] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  useEffect(() => {
    master_data_get_refer_coin();
  }, []);

  const master_data_get_refer_coin = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_refer_earn_page, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setshareablecoin(final_data.reffer_coin);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div>
      <div className="container-lg my-4">
        <div className="QuicklyHotl">
          {" "}
          {/* <p>What's new</p> */}
          <div className="quickHotlsubhading">
            {" "}
            <h2>
              Discover the
              <span> top deals </span> and <span>special offers </span>
              tailored to you
            </h2>
          </div>
        </div>
        <div className="container-lg">
          <div className="row">
            {/* First Column: Offer Information */}
            <div className="col-md-4 col-sm-4 paddinLeftAfter768 padLB0 magin1">
              <Link to="/flat_discount_page">
                <div className="percentecontainr">
                  <img src={offerImg} alt="Offer Image" />
                </div>
              </Link>
            </div>

            {/* Second Column: Stay Information */}
            <div className="col-md-5 col-sm-4 whatnewRightdata p-0">
              <div className="d-flex flex-column gap-1 gap576 h-100 w-100">
                {/* Stay by AQI Section */}
                <Link to="/aqi_page" className="h-100">
                  <div
                    className="staycontainer"
                    style={{ marginBottom: "16px" }}
                  >
                    <div className="stayIndiv">
                      <h1
                        className="h1HeadNew"
                        style={{ color: "var(--text-black)" }}
                      >
                        Stay in
                      </h1>
                      <h1
                        className="h1HeadNew"
                        style={{ color: "var(--primary-color)" }}
                      >
                        fresh air
                      </h1>
                      <h2 className="BookSTy">
                        book your stay by{" "}
                        <span style={{ color: "var(--primary-color)" }}>
                          AQI
                        </span>
                      </h2>
                    </div>
                    <div
                      className="imgStay"
                      style={{ padding: "0.5rem 1rem 0.5rem 0" }}
                    >
                      <img src={stayImg} alt="Stay Image" />
                    </div>
                  </div>
                </Link>

                {/* Express Check-in Section */}
                <Link to="/express_checking" className="h-100">
                  <div className="staycontainer mt-2">
                    <div className="stayIndiv">
                      <h1
                        className="h1HeadNew"
                        style={{ color: "var(--text-black)" }}
                      >
                        Get{" "}
                        <span
                          style={{
                            color: "var(--primary-color)",
                          }}
                          className="h1HeadNew"
                        >
                          express
                        </span>
                      </h1>
                      <h1
                        style={{
                          color: "var(--primary-color)",
                        }}
                        className="h1HeadNew"
                      >
                        Check-in{" "}
                        <span
                          style={{
                            color: "var(--text-black)",
                          }}
                          className="h1HeadNew"
                        >
                          in
                        </span>
                      </h1>
                      <h1 className="h1HeadNew">90 Seconds</h1>
                    </div>
                    <div className="imgStay">
                      <img src={clockedImg} alt="Clocked Image" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* Third Column: Welcome Bonus */}
            <div className="col-md-3 col-sm-4 paddinRightAfter768 padRB0 magin1">
              <Link to="/welcome_bonus">
                <div className="curlLIne">
                  <img className="lineCUrl" src={lineCUrl} alt="Line Image" />
                  <div className="curlText">
                    <h1
                      style={{
                        color: "var(--primary-color)",
                        textAlign: "center",
                      }}
                      className="h1HeadNew"
                    >
                      {shareablecoin}
                    </h1>
                    <h1
                      style={{
                        color: "var(--primary-color)",
                        textAlign: "center",
                      }}
                      className="h1HeadNew"
                    >
                      Swagcoin
                    </h1>
                    <h2
                      style={{
                        margin: 0,
                        color: "var(--text-black)",
                        fontWeight: "400",
                        textAlign: "center",
                        lineHeight: "30px",
                      }}
                    >
                      welcome bonus
                    </h2>
                  </div>
                  <img className="coinImg" src={coinImg} alt="Coin Image" />
                </div>
              </Link>
            </div>

            {/* Second Column: Stay Information */}
          </div>
        </div>
      </div>
    </div>
  );
}
