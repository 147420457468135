import React, { useEffect } from "react";

const RazorpaySubscriptionButton = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src =
      "https://cdn.razorpay.com/static/widget/subscription-button.js";
    script.setAttribute("data-subscription_button_id", "pl_PLCaZ6SSJD1Qxj");
    script.setAttribute("data-button_theme", "rzp-outline-standard");
    script.async = true;

    // Append the script to the form element
    document.getElementById("razorpay-form").appendChild(script);

    // Cleanup function
    return () => {
      const form = document.getElementById("razorpay-form");
      if (form) {
        form.innerHTML = ""; // Clear the form element
      }
    };
  }, []);

  return <form id="razorpay-form"></form>;
};

export default RazorpaySubscriptionButton;
