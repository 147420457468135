import React, { useState, useEffect } from "react";
import "./Css/CreateBooking.css";
import Header from "./Header";
import RightArrow from "../Assets/rightAngle.png";
import CopyIcon from "../Assets/copyIcon.png";
import Download from "../Assets/DownloadICon.png";
import Share from "../Assets/ShareIcon.png";
import Location from "../Assets/locationIcon.png";
import CancelRound from "../Assets/cancelRound.png";
import Shield from "../Assets/sheild.png";
import CallImg from "../Assets/CallImg.png";
import { Modal } from "react-bootstrap";
import BackBar from "./Reapeting Componets/BackBar";
import { useLocation } from "react-router-dom";
import {
  server_post_data,
  booking_details_all,
  update_gst_url,
} from "../ServiceConnection/serviceconnection";
import { useNavigate } from "react-router-dom";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import { handleError } from "../CommonJquery/CommonJquery";

export default function InhoueBookings() {
  const location = useLocation();
  const [useSwagCoin, setUseSwagCoin] = useState(false);
  const [gstModal, setgstModal] = useState(false);
  const [hotelHelpModal, setHotelHelpModal] = useState(false);
  const [swagHelpModal, setSwagHelpModal] = useState(false);
  const [bookingdetails, setbookingdetails] = useState([]);
  const [hoteldetails, sethoteldetails] = useState([]);
  const [websitedetails, setwebsitedetails] = useState([]);
  const [data, setData] = useState(location.state?.data);
  const navigate = useNavigate();
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const handleSwagCoin = () => {
    setUseSwagCoin(!useSwagCoin);
  };

  const copyCode = () => {
    const code = bookingdetails.booking_id;
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("HotelID", data.HotelID);
    fd.append("hotel_id", data.hotel_id);
    fd.append("book_id", data.book_id);
    fd.append("booking_id", data.booking_id);
    console.log(data.HotelID);
    console.log(data.hotel_id);
    console.log(data.book_id);
    console.log(data.booking_id);
    await server_post_data(booking_details_all, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          console.log(final_data);
          if (final_data.booking_details.length > 0) {
            setbookingdetails(final_data.booking_details[0]);
          }
          sethoteldetails(final_data.hotel_details[0]);
          setwebsitedetails(final_data.website_content);
          setisSharePopupContent(final_data.share_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const handleSaveChangesdynamic = async (
    booking_id,
    your_address,
    legal_entity_name,
    gst_number
  ) => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("your_address", your_address);
    form_data.append("legal_entity_name", legal_entity_name);
    form_data.append("gst_number", gst_number);
    form_data.append("gst_email", "");
    form_data.append("gst_mobile_no", "");
    await server_post_data(update_gst_url, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          setgstModal(false);
          handleError("Success", data[1]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
        <BackBar label={"In House"} />
      </div>
      <div className="container-lg">
        <div className="createBookingContainer">
          {/* Booking Details COntianer */}
          <section className="bookDetails mt-4">
            {/* <div className="StatusMsg">
              <h5>Your booking has been confirmed</h5>
            </div> */}
            <div className="bookDetailsContainer bookingReciptCon mb-4">
              <div className="booldetailTop">
                <div className="left_detail">
                  <div className="bookingId">
                    <p>
                      Booking id: <span>{bookingdetails.booking_id}</span>
                    </p>
                    <img onClick={copyCode} src={CopyIcon} />
                  </div>
                </div>
                <div className="right_detail">
                  <div className="bookingId">
                    <p>
                      Status: <span>Confirmed</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="bookDetailsBottom">
                <div className="leftSection">
                  <div className="checkin-out-container">
                    <div className="check-section">
                      <p className="label">Check in</p>
                      <p className="date">
                        {bookingdetails.check_in_date
                          ? bookingdetails.check_in_date.split(",")[0]
                          : ""}
                      </p>
                      <p className="time">
                        {bookingdetails.check_in_date
                          ? bookingdetails.check_in_date.split(",")[1].trim()
                          : ""}
                      </p>
                    </div>

                    <div className="duration">
                      <span className="nights">1 N</span>
                    </div>

                    <div className="check-section">
                      <p className="label">Check out</p>
                      <p className="date">
                        {bookingdetails.check_out_date
                          ? bookingdetails.check_out_date.split(",")[0]
                          : ""}
                      </p>
                      <p className="time">
                        {bookingdetails.check_out_date
                          ? bookingdetails.check_out_date.split(",")[1].trim()
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="saperatorVertical"></div>
                  <div className="hotelText">
                    <p className="cardHeading mb-2">
                      {hoteldetails.hotel_name}
                    </p>
                    <p className="cardText mb-1">{hoteldetails.address}</p>
                    <div className="discount_price">
                      <button
                        className="reciptBtns"
                        onClick={() => {
                          setSharePopupOpen(true);
                        }}
                      >
                        <img src={Share} alt="Swagstay" />
                      </button>
                      <button
                        className="reciptBtns"
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&destination=${hoteldetails.latitude},${hoteldetails.longitude}`,
                            "_blank"
                          )
                        }
                      >
                        <img src={Location} alt="Swagstay" />
                      </button>
                      <button className="reciptBtns">
                        <img src={Download} alt="Swagstay" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reciptBookingTxt">
                <p>
                  {bookingdetails.per_name} your booking has been{" "}
                  <span>confirmed.</span>
                </p>
              </div>
            </div>
          </section>

          {/* Left Section Items  */}
          <div className="row m-0">
            <section className="col-md-6 paddinLeftAfter768">
              {/* Bill Details Section */}
              <div className="earlyLateColumn mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Booking details
                  </h5>
                  <div className="billDetails">
                    <div className="billDescp">
                      <p className="billLabl">Booking for</p>
                      <p className="cardText">{bookingdetails.per_name}</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Guest count</p>
                      <p className="cardText">
                        {bookingdetails.adult} Adults |{" "}
                        {bookingdetails.no_of_room} Room
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Room type</p>
                      <p className="cardText">{bookingdetails.room_type}</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Booking payement</p>
                      <p className="cardText">
                        ₹{bookingdetails.total_payment}
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Advance payment</p>
                      <p className="cardText">
                        ₹{bookingdetails.advacnce_amount || 0}
                      </p>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
              </div>

              {/* GST Details Section */}
              <div className="earlyLateColumn position-relative mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5 className="headingh5 mb-3">GST details</h5>
                  <div className="selectTimeWrapper gstBtn">
                    <div className="mealOption couponBtn m-0">
                      <input
                        type="checkbox"
                        id="swagCoin"
                        name="swagCoin"
                        checked={useSwagCoin}
                        onChange={handleSwagCoin}
                        className="customCheckbox"
                      />
                      <label
                        htmlFor="swagCoin"
                        style={{ fontWeight: 600, color: "var(--text-black)" }}
                      >
                        <span className="customCheckboxIcon"></span>
                        Save GST on this booking
                      </label>
                      <span
                        className="swagCoins"
                        onClick={() => setgstModal(true)}
                      >
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="col-md-6 paddinRightAfter768">
              {/* Need Help Section */}
              <div className="earlyLateColumn pb-0 mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Need Help
                  </h5>
                  <div className="billDetails">
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setHotelHelpModal(true)}
                    >
                      <p className="billLabl">Call hotel helpline</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setSwagHelpModal(true)}
                    >
                      <p className="billLabl">Call SwagStay customer care</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator mb-0"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* Policies Section */}
          <div style={{ paddingBottom: "2rem" }}>
            <button
              className="policyBtn reciptBtns"
              onClick={() => navigate("/guest_policy")}
            >
              <img src={Shield} alt="Swagstay" />
              <p>Hotel rules & policy</p>
            </button>
            <button
              className="policyBtn reciptBtns"
              onClick={() => navigate("/terms_condition")}
            >
              <img src={CancelRound} alt="Swagstay" />
              <p>Cancellation policy</p>
            </button>
          </div>
        </div>
        <SharePopup
          isOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>
      {/* Modals */}

      {/* GST Modal */}
      <Modal
        show={gstModal}
        onHide={() => setgstModal(false)}
        className="GSTModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4>GST details</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="formCOntainer" id="gstForm">
            <div className="inputContainer">
              <label>Company name</label>
              <div className="w-100">
                <input
                  type="text"
                  id="legal_entity_name"
                  name="legal_entity_name"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="inputContainer">
              <label>GSTIN number</label>
              <div className="w-100">
                <input
                  type="text"
                  id="gst_number"
                  name="gst_number"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="inputContainer">
              <label>Address</label>
              <div className="w-100">
                <input
                  type="text"
                  id="your_address"
                  name="your_address"
                  placeholder="Type here"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="DarkButtonClass"
            onClick={() => {
              const booking_id = bookingdetails.booking_id; // Replace with actual booking_id
              const your_address =
                document.getElementById("your_address").value;
              const legal_entity_name =
                document.getElementById("legal_entity_name").value;
              const gst_number = document.getElementById("gst_number").value;

              handleSaveChangesdynamic(
                booking_id,
                your_address,
                legal_entity_name,
                gst_number
              );
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>

      {/* Hotel HelpLine Modal */}
      <Modal
        show={hotelHelpModal}
        onHide={() => setHotelHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Hotel helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{hoteldetails.hotel_manager_no}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>

      {/* Swagstay HelpLine Modal */}
      <Modal
        show={swagHelpModal}
        onHide={() => setSwagHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Swagstay helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{websitedetails.customer_care}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
