import React, { useState, useRef, useEffect } from "react";
import "./Css/CreateBooking.css";
import Header from "./Header";
import RightArrow from "../Assets/rightAngle.png";
import CopyIcon from "../Assets/copyIcon.png";
import Download from "../Assets/DownloadICon.png";
import Share from "../Assets/ShareIcon.png";
import Location from "../Assets/locationIcon.png";
import CancelRound from "../Assets/cancelRound.png";
import Shield from "../Assets/sheild.png";
import CallImg from "../Assets/CallImg.png";
import { Modal } from "react-bootstrap";
import BackBar from "./Reapeting Componets/BackBar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import {
  server_post_data,
  booking_details_all,
  hotel_details,
  cancel_booking,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";

export default function CancelBooking() {
  const location = useLocation();
  const [hotelHelpModal, setHotelHelpModal] = useState(false);
  const [swagHelpModal, setSwagHelpModal] = useState(false);
  const [isCancelBooking, setisCancelBooking] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const textareaRef = useRef(null);
  const [bookingdetails, setbookingdetails] = useState([]);
  const [hoteldetails, sethoteldetails] = useState([]);
  const [websitedetails, setwebsitedetails] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isloading, seIsloading] = useState(false);
  const navigate = useNavigate();
  const data = location.state?.data;
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const handleRadioChange = (event) => {
    const labelText = event.target
      .closest(".custom-radio")
      .querySelector(".billLabl").textContent;
    setSelectedReason(labelText);
  };

  useEffect(() => {
    if (selectedReason === "Other" && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [selectedReason]);

  const copyCode = () => {
    const code = bookingdetails.booking_id;
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("HotelID", data.HotelID);
    fd.append("hotel_id", data.hotel_id);
    fd.append("book_id", data.book_id);
    fd.append("booking_id", data.booking_id);
    await server_post_data(booking_details_all, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          if (final_data.booking_details.length > 0) {
            setbookingdetails(final_data.booking_details[0]);
          }
          sethoteldetails(final_data.hotel_details[0]);
          setwebsitedetails(final_data.website_content);
          setisSharePopupContent(final_data.share_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const handleCancelBooking = () => {
    // Add your cancellation logic here
    handleSaveChangesdynamic();
  };

  const handleSaveChangesdynamic = async () => {
    seIsloading(true);
    const form_data = new FormData();
    form_data.append("booking_id", data.booking_id);
    form_data.append("hotel_id", data.hotel_id);
    form_data.append("HotelID", data.HotelID);
    form_data.append("remarks", selectedReason);
    console.log("selectedReason", selectedReason);
    await server_post_data(cancel_booking, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
        }
        seIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        seIsloading(false);
      });
  };

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
        <BackBar label={"Cancel Booking"} />
      </div>
      <div className="container-lg">
        <div className="createBookingContainer">
          {!isCancelBooking && (
            <>
              {/* Booking Details COntianer */}
              <section className="bookDetails">
                <div className="cancelMsgs">
                  <h5>Tussi ja rahe ho, tussi na jao :(</h5>
                  <p>
                    Arre bhai, booking cancel karni hai? Soch lo yaar! 🤔 plan
                    banaya tha mast stay ke liye, ab kyun cancel karna hai?
                  </p>
                </div>
                <div className="continueBtn_container cnclBtns">
                  <button className="primaryBtn">No fomo,let’s go</button>
                  <button
                    className="secondaryBtn"
                    onClick={() => setisCancelBooking(true)}
                  >
                    Kar de cancel !!
                  </button>
                </div>
                {/* Detaisl Container */}
                <div className="bookDetailsContainer bookingCancelCon mb-4">
                  <div className="booldetailTop">
                    <div className="left_detail">
                      <div className="bookingId">
                        <p>
                          Booking id: <span>{bookingdetails.booking_id}</span>
                        </p>
                        <img onClick={copyCode} src={CopyIcon} />
                      </div>
                    </div>
                    <div className="right_detail">
                      <div className="bookingId">
                        <p>
                          Status: <span>Confirmed</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bookDetailsBottom">
                    <div className="leftSection">
                      <div className="checkin-out-container">
                        <div className="check-section">
                          <p className="label">Check in</p>
                          <p className="date">
                            {bookingdetails.check_in_date
                              ? bookingdetails.check_in_date.split(",")[0]
                              : ""}
                          </p>
                          <p className="time">
                            {bookingdetails.check_in_date
                              ? bookingdetails.check_in_date
                                  .split(",")[1]
                                  .trim()
                              : ""}
                          </p>
                        </div>

                        <div className="duration">
                          <span className="nights">1 N</span>
                        </div>

                        <div className="check-section">
                          <p className="label">Check out</p>
                          <p className="date">
                            {bookingdetails.check_out_date
                              ? bookingdetails.check_out_date.split(",")[0]
                              : ""}
                          </p>
                          <p className="time">
                            {bookingdetails.check_out_date
                              ? bookingdetails.check_out_date
                                  .split(",")[1]
                                  .trim()
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="saperatorVertical"></div>
                      <div className="hotelText">
                        <p className="cardHeading mb-2">
                          {hoteldetails.hotel_name}
                        </p>
                        <p className="cardText mb-1">{hoteldetails.address}</p>
                        <div className="discount_price">
                          <button
                            className="reciptBtns"
                            onClick={() => {
                              setSharePopupOpen(true);
                            }}
                          >
                            <img src={Share} alt="Swagstay" />
                          </button>
                          <button
                            className="reciptBtns"
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${hoteldetails.latitude},${hoteldetails.longitude}`,
                                "_blank"
                              )
                            }
                          >
                            <img src={Location} alt="Swagstay" />
                          </button>
                          <button className="reciptBtns">
                            <img src={Download} alt="Swagstay" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reciptBookingTxt">
                    <p>
                      {bookingdetails.per_name} your booking has been{" "}
                      <span>confirmed.</span>
                    </p>
                  </div>
                </div>
              </section>

              {/* Left Section Items  */}
              <div className="row m-0">
                <section className="col-md-6 paddinLeftAfter768">
                  {/* Bill Details Section */}
                  <div className="earlyLateColumn mb-4">
                    <div className="earlyLatecontainer mb-4">
                      <h5
                        className="headingh5 mb-3"
                        style={{ color: "var(--text-black)" }}
                      >
                        Booking details
                      </h5>
                      <div className="billDetails">
                        <div className="billDescp">
                          <p className="billLabl">Booking for</p>
                          <p className="cardText">{bookingdetails.per_name}</p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Guest count</p>
                          <p className="cardText">
                            {bookingdetails.adult} Adults |{" "}
                            {bookingdetails.no_of_room} Room
                          </p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Room type</p>
                          <p className="cardText">{bookingdetails.room_type}</p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Booking payement</p>
                          <p className="cardText">
                            ₹{bookingdetails.total_payment}
                          </p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Advance payment</p>
                          <p className="cardText">
                            ₹{bookingdetails.advacnce_amount || 0}
                          </p>
                        </div>
                        <div className="separator"></div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="col-md-6 paddinRightAfter768">
                  {/* Need Help Section */}
                  <div className="earlyLateColumn pb-0 mb-4">
                    <div className="earlyLatecontainer mb-4">
                      <h5
                        className="headingh5 mb-3"
                        style={{ color: "var(--text-black)" }}
                      >
                        Need Help
                      </h5>
                      <div className="billDetails">
                        <div
                          className="billDescp popUpBtns"
                          onClick={() => setHotelHelpModal(true)}
                        >
                          <p className="billLabl">Call hotel helpline</p>
                          <p className="cardText">
                            <img
                              src={RightArrow}
                              className="rightArrow"
                              alt="Icon"
                            />
                          </p>
                        </div>
                        <div className="separator"></div>
                        <div
                          className="billDescp popUpBtns"
                          onClick={() => setSwagHelpModal(true)}
                        >
                          <p className="billLabl">
                            Call SwagStay customer care
                          </p>
                          <p className="cardText">
                            <img
                              src={RightArrow}
                              className="rightArrow"
                              alt="Icon"
                            />
                          </p>
                        </div>
                        <div className="separator mb-0"></div>
                      </div>
                    </div>
                  </div>

                  {/* Cancel Button */}
                  <div className="continueBtn_container">
                    <button
                      className="secondaryBtn"
                      onClick={() => setisCancelBooking(true)}
                    >
                      Cancel Booking
                    </button>
                  </div>
                </section>
              </div>

              {/* Policies Section */}
              <div style={{ paddingBottom: "2rem" }}>
                <button
                  className="policyBtn reciptBtns"
                  onClick={() => navigate("/guest_policy")}
                >
                  <img src={Shield} alt="Swagstay" />
                  <p>Hotel rules & policy</p>
                </button>
                <button
                  className="policyBtn reciptBtns"
                  onClick={() => navigate("/terms_condition")}
                >
                  <img src={CancelRound} alt="Swagstay" />
                  <p>Cancellation policy</p>
                </button>
              </div>
            </>
          )}
          {isCancelBooking && (
            <>
              <div className="row m-0">
                <section className="col-md-6 paddinLeftAfter768">
                  {/* Bill Details Section */}
                  <div className="detailsTxt">
                    <p>
                      We’re sorry to hear that you need to cancel your booking.
                      Please select the reason for your cancellation from the
                      options below:
                    </p>
                  </div>
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Reason to cancel
                  </h5>
                  <div className="earlyLateColumn mb-4 pb-0">
                    <div className="earlyLatecontainer mb-4">
                      <div className="billDetails">
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Changes of travel plans
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Medical reason
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Work or business commitments
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Personal reasons
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Found a better rate
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Weather conditions
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Other
                            </p>
                          </label>
                        </div>
                        <div className="separator mb-0"></div>
                      </div>
                    </div>
                  </div>

                  {/* Other Reason Section */}
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Other reason type here
                  </h5>
                  <div className="earlyLateColumn mb-4 p-1">
                    <textarea
                      className="reasonTextarea"
                      placeholder="Type here..."
                      disabled={selectedReason !== "Other"}
                      ref={textareaRef}
                    ></textarea>
                  </div>

                  {/* Cancel Button */}
                  <div className="continueBtn_container">
                    <Link to="/bookings_details" state={{ data: data }}>
                      <button
                        className="DarkButtonClass"
                        onClick={() => {
                          setisCancelBooking(false);
                          handleCancelBooking();
                        }}
                      >
                        Cancel Booking
                      </button>
                    </Link>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
        <SharePopup
          isOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>

      {/* Modals */}

      {/* Hotel HelpLine Modal */}
      <Modal
        show={hotelHelpModal}
        onHide={() => setHotelHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Hotel helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{hoteldetails.hotel_manager_no}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>

      {/* Swagstay HelpLine Modal */}
      <Modal
        show={swagHelpModal}
        onHide={() => setSwagHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Swagstay helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{websitedetails.customer_care}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
