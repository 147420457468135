import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import dummyAadhar from "../Assets/dummyAadhar.jpg";
import DummyUser from "../Assets/dummyUser.png";
import indiaFlag from "../Assets/indflag.png";
import CrossImg from "../Assets/modalCloseIcn.svg";
import profilelocation from "../Assets/profilelocation.png";
import UploadImg from "../Assets/uploadImg.png";
import verified from "../Assets/verified.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleSuccess,
  validateName,
} from "../CommonJquery/CommonJquery";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection";
import {
  profile_data_url,
  server_post_data,
  update_profile_gst_url,
  verify_email_send_link,
  aadhar_number_search,
  aadhar_otp_validate,
} from "../ServiceConnection/serviceconnection";
import "./Css/Profile.css";
import Header from "./Header";
import BookingLoader from "./BookingLoader";

function Profile() {
  const [activeTab, setActiveTab] = useState("Personal details");

  const [isVerified, setIsVerified] = useState(false);
  const [IsVerifiedAadhar, setIsVerifiedAadhar] = useState(false);
  const [gender, setGender] = useState("Male");
  const [maritalStatus, setMaritalStatus] = useState("Unmarried");
  const [isWhatsappChecked, setWhatsappChecked] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [ImageLink, setImageLink] = useState("");
  const [aadharLink, setaadharLink] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setdob] = useState("");
  const [marriageAnniversary, setmarriageAnniversary] = useState("");
  const [gstName, setgstName] = useState("");
  const [AddharNo, setAddharNo] = useState("");
  const [gstAddress, setgstAddress] = useState("");
  const [location_client, setlocation_client] = useState("");
  const [gstNumber, setgstNumber] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");

  const [frontImage, setFrontImage] = useState(null);
  const [ProfileImg, setProfileImage] = useState(null);
  const [fullName, setfullname] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [ProfileImagePreview, setProfileImagePreview] = useState(null);
  const [FrontImagePreview, setFrontImagePreview] = useState(null);
  const [BackImagePreview, setBackImagePreview] = useState(null);
  const personalDetailsRef = useRef(null);
  const locationDetailsRef = useRef(null);
  const identityProofRef = useRef(null);
  const gstDetailsRef = useRef(null);
  const [dobDate, setDobDate] = useState(null);
  const [anniversaryDate, setAnniversaryDate] = useState(null);

  const parseDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string") return null;

    // Remove any leading/trailing whitespace
    dateStr = dateStr.trim();

    // Handle different date formats
    const formats = [
      { regex: /^(\d{2})\/(\d{2})\/(\d{2})$/, type: "DD/MM/YYYY" }, // DD/MM/YY
      { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, type: "DD/MM/YYYY" }, // DD/MM/YYYY
      { regex: /^(\d{4})-(\d{2})-(\d{2})$/, type: "YYYY-MM-DD" }, // YYYY-MM-DD
    ];

    for (const format of formats) {
      const match = dateStr.match(format.regex);
      if (match) {
        try {
          let year, month, day;

          if (format.type === "DD/MM/YYYY" || format.type === "DD/MM/YYYY") {
            [, day, month, year] = match;
            // Convert 2-digit year to 4-digit
            if (year.length === 2) {
              year = "20" + year;
            }
          } else {
            // YYYY-MM-DD
            [, year, month, day] = match;
          }

          // Convert strings to integers
          year = parseInt(year, 10);
          month = parseInt(month, 10) - 1; // JS months are 0-based
          day = parseInt(day, 10);

          // Validate date components
          if (month < 0 || month > 11) return null;
          if (day < 1 || day > 31) return null;
          if (year < 1900 || year > 2100) return null;

          const date = new Date(year, month, day);

          // Verify the date is valid (handles edge cases like 31/04/2023)
          if (
            date.getMonth() !== month ||
            date.getDate() !== day ||
            date.getFullYear() !== year
          ) {
            return null;
          }

          return date;
        } catch (e) {
          console.warn("Date parsing error:", e);
          return null;
        }
      }
    }
    return null;
  };

  // Format date as DD/MM/YY
  const formatDate = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) return "";
    try {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(-2);
      return `${day}/${month}/${year}`;
    } catch (e) {
      console.warn("Date formatting error:", e);
      return "";
    }
  };

  // Updated handlers for date changes
  const handleDobChange = (date) => {
    if (date && date instanceof Date && !isNaN(date.getTime())) {
      setDobDate(date);
      setdob(formatDate(date));
    } else {
      setDobDate(null);
      setdob("");
    }
  };

  const handleAnniversaryChange = (date) => {
    if (date && date instanceof Date && !isNaN(date.getTime())) {
      setAnniversaryDate(date);
      setmarriageAnniversary(formatDate(date));
    } else {
      setAnniversaryDate(null);
      setmarriageAnniversary("");
    }
  };

  const tabs = [
    "Personal details",
    "Location detail",
    "Identity Proof",
    "GST Number",
  ];
  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "Personal details":
        personalDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Location detail":
        locationDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Identity Proof":
        identityProofRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "GST Number":
        gstDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const tabName = entry.target.getAttribute("data-tab");
          if (entry.isIntersecting) {
            setActiveTab(tabName);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    personalDetailsRef.current && observer.observe(personalDetailsRef.current);
    locationDetailsRef.current && observer.observe(locationDetailsRef.current);
    identityProofRef.current && observer.observe(identityProofRef.current);
    gstDetailsRef.current && observer.observe(gstDetailsRef.current);

    return () => {
      if (personalDetailsRef.current)
        observer.unobserve(personalDetailsRef.current);
      if (locationDetailsRef.current)
        observer.unobserve(locationDetailsRef.current);
      if (identityProofRef.current)
        observer.unobserve(identityProofRef.current);
      if (gstDetailsRef.current) observer.unobserve(gstDetailsRef.current);
    };
  }, []);

  const handleCheckboxChange = (e) => {
    setWhatsappChecked(e.target.checked);
  };

  const handleAadharChange = (e) => {
    e.target.value = e.target.value.trim();

    let value = e.target.value;

    value = value.replace(/\D/g, "");

    if (value.length > 12) {
      value = value.slice(0, 12);
    }

    e.target.value = value;
  };

  const handleGSTINChange = (e) => {
    let value = e.target.value;

    value = value.replace(/[^a-zA-Z0-9]/g, "");

    value = value.toUpperCase();

    if (value.length > 15) {
      value = value.slice(0, 15);
    }

    e.target.value = value;

    if (value.length === 15) {
      console.log("GSTIN is valid:", value);
    }
  };

  const calculateProfileCompletion = () => {
    const fields = [
      firstName,
      lastName,
      email,
      dob,
      gender,
      maritalStatus,
      gstName,
      frontImage,
      backImage,
      ProfileImg,
      AddharNo,
      gstAddress,
      location_client,
      gstNumber,
      MobileNumber,
      maritalStatus === "Married" ? marriageAnniversary : "optional",
    ];
    const completedFields = fields.filter(
      (field) => field && field.trim() !== ""
    ).length;
    const totalFields = fields.length;
    return Math.round((completedFields / totalFields) * 100);
  };

  useEffect(() => {
    master_data_get();
    getDatafromlocalstorage();
  }, []);

  const handlesubmitData = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      let fd_from = combiled_form_data(form_data, "");
      fd_from.append("gender", gender);
      fd_from.append("married_status", maritalStatus);
      fd_from.append(
        "your_birthday",
        new Date(dobDate).toISOString().split("T")[0]
      );
      fd_from.append(
        "user_aniversary",
        new Date(anniversaryDate).toISOString().split("T")[0]
      );
      fd_from.append("notification_whatapp", isWhatsappChecked ? "1" : "0");

      if (
        !fd_from.has("a_images_mobile") ||
        !fd_from.get("a_images_mobile")?.size
      ) {
        console.log("Appending ProfileImg:", ProfileImg);
        fd_from.append("a_images_mobile_old", ProfileImg);
      } else {
        console.log(
          "Valid file already exists for a_images_mobile:",
          fd_from.get("a_images_mobile")
        );
      }

      if (
        !fd_from.has("aadhar_front_image") ||
        !fd_from.get("aadhar_front_image")?.size
      ) {
        console.log("Appending frontImage:", frontImage);
        fd_from.append("aadhar_front_image_old", frontImage);
      } else {
        console.log(
          "Valid file already exists for aadhar_front_image:",
          fd_from.get("aadhar_front_image")
        );
      }

      if (
        !fd_from.has("aadhar_back_image") ||
        !fd_from.get("aadhar_back_image")?.size
      ) {
        console.log("Appending frontImage:", backImage);
        fd_from.append("aadhar_back_image_old", backImage);
      } else {
        console.log(
          "Valid file already exists for aadhar_front_image:",
          fd_from.get("aadhar_back_image")
        );
      }

      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          console.log(Response);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const master_data_get = async () => {
    await server_post_data(profile_data_url, null)
      .then((Response) => {
        let data = Response.split("~@~");
        let final_data = JSON.parse(data[1]);
        console.log(final_data);
        setImageLink(final_data.user_image_link);
        setaadharLink(final_data.aadhar_image_link);
        setfirstName(final_data.profile_data[0].first_name);
        setlastName(final_data.profile_data[0].last_name);
        setEmail(final_data.profile_data[0].user_email);
        // setdob(final_data.profile_data[0].user_dob);
        // setmarriageAnniversary(final_data.profile_data[0].user_anniversary)
        setgstName(final_data.profile_data[0].legal_entity_name);
        setAddharNo(final_data.profile_data[0].aadhar_number);
        setgstAddress(final_data.profile_data[0].gst_address);
        setlocation_client(final_data.profile_data[0].city_of_residence);
        setgstNumber(final_data.profile_data[0].gst_number);
        setMaritalStatus(final_data.profile_data[0].married_status);
        setGender(final_data.profile_data[0].user_sex);
        setBackImage(final_data.profile_data[0].aadhar_back_image);
        setFrontImage(final_data.profile_data[0].aadhar_front_image);
        setMobileNumber(final_data.profile_data[0].user_moblie_no);
        setProfileImage(final_data.profile_data[0].user_image);
        setfullname(
          final_data.profile_data[0].first_name +
            " " +
            final_data.profile_data[0].last_name
        );
        storeData("customer_first_name", final_data.profile_data[0].first_name);
        storeData("customer_last_name", final_data.profile_data[0].last_name);
        storeData(
          "customer_full_name",
          final_data.profile_data[0].first_name +
            " " +
            final_data.profile_data[0].last_name
        );
        // Handle dates carefully
        const parsedDob = parseDate(final_data.profile_data[0].user_dob);
        const parsedAnniversary = parseDate(
          final_data.profile_data[0].user_anniversary
        );

        setdob(final_data.profile_data[0].user_dob || "");
        setmarriageAnniversary(
          final_data.profile_data[0].user_anniversary || ""
        );

        if (parsedDob) setDobDate(parsedDob);
        if (parsedAnniversary) setAnniversaryDate(parsedAnniversary);

        storeData(
          "customer_image",
          final_data.user_image_link + final_data.profile_data[0].user_image
        );

        if (parseInt(final_data.profile_data[0].notification_whatapp) === 1) {
          setWhatsappChecked(true);
        }
        if (parseInt(final_data.profile_data[0].verify_email) === 1) {
          setIsVerified(true);
        }
        if (parseInt(final_data.profile_data[0].aadhar_status) === 1) {
          setIsVerifiedAadhar(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDatafromlocalstorage = () => {
    const first_name = retrieveData("customer_first_name");
    const last_name = retrieveData("customer_last_name");
    const userImage = retrieveData("customer_image");
    setfirstName(first_name);
    setlastName(last_name);
    setfullname(first_name + " " + last_name);
  };

  const handlePressEmail = async (email) => {
    const fd = new FormData();
    fd.append("user_email", email);
    fd.append("user_name", firstName + " " + lastName);
    await server_post_data(verify_email_send_link, fd)
      .then((Response) => {
        console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
        }
      })
      .catch((error) => {});
  };
  const [varifOtp, setvarifOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(true);
  const [referenceid, setreferenceid] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const handleVerifyModal = () => {
    if (AddharNo.length == 12) {
      SearchAadharNo(AddharNo);
    }
  };

  const SearchAadharNo = async (aadhar_no) => {
    const fd = new FormData();
    fd.append("flag_from", 1);
    fd.append("aadhar_number", aadhar_no);
    fd.append("cec_id", 0);
    setIsLoading(true);
    await server_post_data(aadhar_number_search, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          setreferenceid(data[1]);
          setvarifOtp(!varifOtp);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const ValidateAadharOTP = async (otp) => {
    const fd = new FormData();
    fd.append("flag_from", 1);
    fd.append("aadhar_otp_validate", otp);
    fd.append("referenceid", referenceid);
    fd.append("cec_id", 0);
    setIsLoading(true);
    await server_post_data(aadhar_otp_validate, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          setIsValidOtp(true);
          setIsVerifiedAadhar(true);
          setvarifOtp(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    if (error) {
      setError("");
    }
  };
  const handleSubmitOtp = () => {
    if (!otp) {
      setError("Please fill in the OTP");
      setIsValidOtp(false);
    } else {
      ValidateAadharOTP(otp);
    }
  };
  return (
    <div>
      {isLoading && <BookingLoader />}
      <Header />
      <div className="background">
        <div className="linear-container"></div>
        <div className="container-lg">
          <form id="personalDetailsForm">
            <div className="boxContent mb-2">
              <div className="profile-header">
                <div className="profile-info">
                  <input
                    type="file"
                    id="a_images_mobile"
                    name="a_images_mobile"
                    hidden
                    onChange={(e) => {
                      handleImageChange(e, setProfileImage);
                      handleImageChange(e, setProfileImagePreview);
                    }}
                  />
                  <label htmlFor="a_images_mobile">
                    <img
                      onError={(e) => {
                        e.target.src = DummyUser;
                      }}
                      src={
                        ProfileImagePreview ||
                        (ImageLink && ProfileImg
                          ? ImageLink + ProfileImg
                          : null) ||
                        DummyUser
                      }
                      alt="Profile"
                      className="profile-image"
                    />
                  </label>
                  <div>
                    <h2>
                      {fullName && fullName.length > 0 && fullName !== " "
                        ? fullName
                        : "User"}
                    </h2>
                    <p className="profile-completion">
                      Profile completion: {calculateProfileCompletion()}%
                    </p>
                  </div>
                </div>
                {/* </form> */}
              </div>
              <div className="tabrow">
                <div className="profile-tabs">
                  {tabs.map((tab) => (
                    <button
                      type="button"
                      key={tab}
                      className={`tab-button ${
                        activeTab === tab ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
                <div className="secure-message">
                  <span role="img" aria-label="lock">
                    🔒
                  </span>{" "}
                  Your details are secured with us
                </div>
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "calc(100vh - 380px )",
                padding: "0 5px",
              }}
            >
              <div
                ref={personalDetailsRef}
                data-tab="Personal details"
                className="boxContent mt-4 form-section"
              >
                <h3>• Personal details</h3>
                <div className="formCOntainer">
                  <div>
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="inputContainer">
                          <label>First Name</label>
                          <div className="w-100">
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              defaultValue={firstName}
                              onInput={handleAphabetsChange}
                              onChange={(e) => {
                                setfirstName(e.target.value);
                                validateName(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="inputContainer">
                          <label>Last Name</label>
                          <div className="w-100">
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              defaultValue={lastName}
                              onChange={(e) => {
                                setlastName(e.target.value);
                                validateName(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="inputContainer">
                          <label>Enter your mobile number</label>
                          <div
                            className="phone-input"
                            style={{ marginTop: "16px" }}
                          >
                            <span className="flag-code">
                              <img
                                src={indiaFlag}
                                alt="India Flag"
                                className="flag-icon"
                              />
                              +91
                            </span>
                            <input
                              style={{ border: "none", marginTop: "0px" }}
                              type="text"
                              id="phoneNo"
                              name="phoneNo"
                              placeholder="8109000000"
                              defaultValue={
                                MobileNumber
                                  ? MobileNumber.replace(
                                      /^(\d{4})(\d{6})$/,
                                      "$1XXXXXX"
                                    )
                                  : ""
                              }
                              readOnly
                            />
                          </div>
                          <div className="whatsapp-notification">
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={isWhatsappChecked}
                                onChange={handleCheckboxChange}
                                className="checkbox"
                              />
                              <span className="checkmark"></span>
                              Notification on WhatsApp
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="inputContainer">
                          <label>Email id</label>
                          <div className="email-input-container">
                            <input
                              type="text"
                              placeholder="Email"
                              id="edit_email"
                              name="edit_email"
                              className="email-input"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                handleEmailChange(e);
                              }}
                              readOnly={isVerified} // Use camelCase `readOnly` and set its value conditionally
                            />

                            <span
                              className={`verified-badge ${
                                isVerified ? "verified" : "verify"
                              }`}
                              onClick={() => handlePressEmail(email)}
                            >
                              {isVerified ? "Verified" : "Verify"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="inputContainer">
                        <label>DOB</label>
                        <DatePicker
                          selected={dobDate}
                          onChange={handleDobChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          maxDate={new Date()}
                          yearDropdownItemNumber={100}
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control"
                          id="your_birthday_old"
                          name="your_birthday_old"
                          autoComplete="off"
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="genderRow">
                    <div className="inputContainer">
                      <label>Gender</label>
                      <div className="button-group">
                        <button
                          type="button"
                          className={`gender-button ${
                            gender === "Male" ? "active" : ""
                          }`}
                          onClick={() => setGender("Male")}
                        >
                          Male
                        </button>
                        <button
                          type="button"
                          className={`gender-button ${
                            gender === "Female" ? "active" : ""
                          }`}
                          onClick={() => setGender("Female")}
                        >
                          Female
                        </button>
                        <button
                          type="button"
                          className={`gender-button ${
                            gender === "Prefer not to say" ? "active" : ""
                          }`}
                          onClick={() => setGender("Prefer not to say")}
                        >
                          Prefer not to say
                        </button>
                      </div>
                    </div>
                    <div className="inputContainer">
                      <label>Marital Status</label>
                      <div className="button-group">
                        <button
                          type="button"
                          className={`status-button ${
                            maritalStatus === "Married" ? "active" : ""
                          }`}
                          onClick={() => setMaritalStatus("Married")}
                        >
                          Married
                        </button>
                        <button
                          type="button"
                          className={`status-button ${
                            maritalStatus === "Unmarried" ? "active" : ""
                          }`}
                          onClick={() => setMaritalStatus("Unmarried")}
                        >
                          Unmarried
                        </button>
                        <button
                          className={`status-button ${
                            maritalStatus === "Prefer not to say"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => setMaritalStatus("Prefer not to say")}
                        >
                          Prefer not to say
                        </button>
                      </div>
                    </div>
                    {maritalStatus === "Married" && (
                      <div className="inputContainer">
                        <label>Marriage Anniversary</label>
                        <DatePicker
                          selected={anniversaryDate}
                          onChange={handleAnniversaryChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          maxDate={new Date()}
                          yearDropdownItemNumber={100}
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control"
                          id="user_aniversary_old"
                          name="user_aniversary_old"
                          autoComplete="off"
                          isClearable={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                ref={locationDetailsRef}
                data-tab="Location detail"
                className="boxContent mt-4 form-section"
              >
                <h3>• Location Details</h3>
                <div className="col-md-4">
                  <div className="inputContainer">
                    <label>Current City</label>
                    <div className="phone-input">
                      <input
                        type="text"
                        id="city_of_residence"
                        name="city_of_residence"
                        placeholder="City"
                        defaultValue={location_client}
                        onChange={(e) => setlocation_client(e.target.value)}
                        className="border-0 mt-0 onFOcusNoe"
                      />
                      <span className="profilelocation">
                        <img
                          src={profilelocation}
                          alt="Location Icon"
                          className="loca-icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={identityProofRef}
                data-tab="Identity Proof"
                className="boxContent mt-4 form-section"
              >
                <h3>• Indentity Proof</h3>
                <div className="col-md-4">
                  <div className="inputContainer" style={{ marginBottom: 10 }}>
                    <label>Aadhar Card Details</label>
                    <div>
                      <div className="variFyandAhdarDiv">
                        {" "}
                        <input
                          type="text"
                          id="aadhar_no"
                          name="aadhar_no"
                          className="form-control"
                          placeholder="Enter 12 digit aadhar card number"
                          defaultValue={AddharNo}
                          onChange={(e) => {
                            setAddharNo(e.target.value);
                            handleAadharChange(e);
                          }}
                          readOnly={IsVerifiedAadhar} // Use camelCase `readOnly` and set its value conditionally
                        />
                        {IsVerifiedAadhar ? (
                          <img src={verified} alt="verified" />
                        ) : (
                          <p onClick={handleVerifyModal}>Verify</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputContainer">
                    <div className="row g-3">
                      <div className="addharImags col-6">
                        <input
                          type="file"
                          id="aadhar_front_image"
                          name="aadhar_front_image"
                          hidden
                          onChange={(e) => {
                            handleImageChange(e, setFrontImage);
                            handleImageChange(e, setFrontImagePreview);
                          }}
                        />
                        <div className="accordionExpform">
                          {frontImage && frontImage !== "null" ? (
                            <>
                              <img
                                onError={(e) => {
                                  e.target.src = dummyAadhar;
                                }}
                                src={
                                  FrontImagePreview || aadharLink + frontImage
                                }
                                alt="Aadhar Front"
                                className="selectedImg"
                              />
                              <div
                                className="crossImg"
                                onClick={() => setFrontImage(null)}
                              >
                                <img src={CrossImg} alt="Swagstay" />
                              </div>
                            </>
                          ) : (
                            <label htmlFor="aadhar_front_image">
                              <img
                                src={UploadImg}
                                alt="Swagstay"
                                className="upImg"
                              />
                              <p>Aadhar Front side</p>
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="addharImags col-6">
                        <input
                          id="aadhar_back_image"
                          name="aadhar_back_image"
                          type="file"
                          hidden
                          onChange={(e) => {
                            handleImageChange(e, setBackImage);
                            handleImageChange(e, setBackImagePreview);
                          }}
                        />
                        <div className="accordionExpform">
                          {backImage && backImage !== "null" ? (
                            <>
                              <img
                                onError={(e) => {
                                  e.target.src = dummyAadhar;
                                }}
                                src={BackImagePreview || aadharLink + backImage}
                                alt="Aadhar Back"
                                className="selectedImg"
                              />
                              <div
                                className="crossImg"
                                onClick={() => setBackImage(null)}
                              >
                                <img src={CrossImg} alt="Swagstay" />
                              </div>
                            </>
                          ) : (
                            <label htmlFor="aadhar_back_image">
                              <img
                                src={UploadImg}
                                alt="Swagstay"
                                className="upImg"
                              />
                              <p>Aadhar Back side</p>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={gstDetailsRef}
                data-tab="GST Number"
                className="boxContent mt-4 form-section"
              >
                <h3>• GST Details</h3>
                <div className="row m-0">
                  <div className="col-md-4">
                    <div className="inputContainer">
                      <div>
                        <label>Company Name</label>
                        <div className="w-100">
                          <input
                            type="text"
                            id="legal_entity_name"
                            name="legal_entity_name"
                            placeholder="Type here"
                            value={
                              gstName && gstName.length > 0 && gstName != "null"
                                ? gstName
                                : ""
                            }
                            onChange={(e) => setgstName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputContainer">
                      <label>GST Number</label>
                      <div className="w-100">
                        <input
                          type="text"
                          id="gst_number"
                          name="gst_number"
                          placeholder="Type here"
                          defaultValue={
                            gstNumber &&
                            gstNumber.length > 0 &&
                            gstNumber != "null"
                              ? gstNumber
                              : ""
                          }
                          onChange={(e) => {
                            setgstNumber(e.target.value);
                            handleGSTINChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ marginLeft: 12 }}>
                    <div className="inputContainer">
                      <label>Address</label>
                      <div className="email-input-container">
                        <input
                          type="text"
                          id="gst_address"
                          name="gst_address"
                          placeholder="Type here"
                          className="email-input"
                          defaultValue={gstAddress}
                          onChange={(e) => setgstAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="saveProfileDiv">
                  <button
                    className="DarkButtonClass"
                    type="button"
                    onClick={() =>
                      handlesubmitData(
                        "personalDetailsForm",
                        update_profile_gst_url
                      )
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/**-----------------------varification Otp Modal------------------- */}
      <Modal
        show={varifOtp}
        onHide={() => setvarifOtp(false)}
        className="otpVarModal"
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <h4>Otp Verification</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="varificatioNotpDiv">
            <div className="inputContainer">
              <div>
                <div className="w-100">
                  <input
                    className={`form-control ${
                      !isValidOtp
                        ? "border-danger"
                        : isValidOtp && otp
                        ? "border-success"
                        : ""
                    }`}
                    type="text"
                    name="legal_entity_name"
                    placeholder="Enter the otp"
                    maxLength={6}
                    value={otp}
                    onChange={handleOtpChange}
                  />
                  {error && (
                    <span className="text-danger otpVaConditon">{error}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="submtotpbttn">
              <button
                type="button"
                className="DarkButtonClass"
                onClick={handleSubmitOtp}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Profile;
