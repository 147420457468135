import React from "react";
import "./Css/WhyChoose.css";
import icn1 from "../Assets/icn1.svg";
import icn2 from "../Assets/icn2.svg";
import icn3 from "../Assets/icn3.svg";
import icn4 from "../Assets/icn4.svg";
import icn5 from "../Assets/icn5.svg";
import icn6 from "../Assets/icn6.svg";
export default function WhyChoose() {
  const services = [
    {
      title: "Guaranteed check-in",
      img: icn1,
      underTitle: "Easy-to-use platform for quick and efficient booking.",
    },
    {
      title: "Wide selection",
      img: icn2,
      underTitle:
        "Access to a broad range of affordable hotels to suit every budget.",
    },
    {
      title: "Best price guarantee",
      img: icn3,
      underTitle: "Ensuring competitive pricing for your accommodation needs.",
    },
    {
      title: "Transparent pricing",
      img: icn4,
      underTitle: "Clear breakdown of costs and no hidden fees.",
    },
    {
      title: "Customer reviews",
      img: icn5,
      underTitle: "Access to genuine feedback to help make informed decisions.",
    },
    {
      title: "24/7 support",
      img: icn6,
      underTitle:
        "Assistance available round-the-clock for any booking queries.",
    },
  ];

  return (
    <div className="container-lg my-4">
      <div className="WhyChooseContainer">
        <div className="QuicklyHotl">
          {" "}
          {/* <p>Why choose us</p> */}
          <div className="quickHotlsubhading">
            {" "}
            <h2>
              Your gateway to <span>comfort, convience </span> and{" "}
              <span>unbeatable </span>
              rates
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          {services.map((service, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <div className="whyChoose-item">
                <div className="smallIcn">
                  <img src={service.img} alt="whychoose img"></img>
                </div>

                <div className="chooMapdata">
                  <h2>{service.title}</h2>
                  <p>{service.underTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
