import React, { useRef } from "react";
import { Link } from "react-router-dom";
import AppstoreLogo from "../Assets/AppstoreLogo.svg";
import Facebook from "../Assets/Facebook.svg";
import giveLInkIcom from "../Assets/giveLInkIcom.svg";
import Instagram from "../Assets/Instagram.svg";
import Linkedin from "../Assets/Linkedin.svg";
import musicIcon from "../Assets/musicIcon.svg";
import PlayLogo from "../Assets/PlayLogo.svg";
import FooterBgg from "../Assets/footerBgg.png";
import Twitter from "../Assets/Twitter.svg";
import swagsterAudio from "../Assets/swagsterAudio.mp3";
import "./Css/Footer.css";

const footerSections = [
  {
    title: "Company",
    links: [
      { name: "Blogs", to: "/blog" },
      { name: "Career", to: "/career" },
      { name: "About us", to: "/about_us" },
      { name: "Contact", to: "/help" },
    ],
  },
  {
    title: "Business",
    links: [
      { name: "Influencer program", to: "/influencer" },
      { name: "Travel agent", to: "/travel_agent" },
      { name: "List your hotel", to: "/list_your_hotel" },
      { name: "Corporate stay", to: "/coprative_solution" },
      { name: "Booking Request", to: "/booking_request" },
    ],
  },
  {
    title: "Our policies",
    links: [
      { name: "Privacy policy", to: "/privacy_policy" },
      { name: "Guest policy", to: "/privacy_policy" },
      { name: "Terms & conditions", to: "/terms_condition" },
    ],
  },
];

export default function Footer() {
  const audioRef = useRef(null);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play(); // Play the audio
    }
  };

  const handleLinkClick = (url, platform) => {
    console.log(`Navigating to ${platform}: ${url}`);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <footer className="bg-light py-5 FooterSection">
      <img src={FooterBgg} className="footerBggg" alt="Swagstay" />
      <div
        className="container-lg"
        style={{ position: "relative", zIndex: "1" }}
      >
        <div className="FOoterTOp">
          <div className="leftSide">
            <h5>SwagStay</h5>
            <p>Empowering travelers with tech and hospitality expertise.</p>
          </div>
          <div className="rightSide">
            <div className="d-flex g-3 justify-content-center align-items-center">
              <p>Pronounced swæɡsteɪ and sounds like</p>
              <img
                className="musicIcon"
                src={musicIcon}
                alt="music"
                onClick={handlePlay}
                style={{ cursor: "pointer" }}
              />
              <audio ref={audioRef} src={swagsterAudio} />
            </div>
            <div className="d-flex g-3 align-items-center ButtonsBottm">
              <button>Playday</button>
              <button>Holiday</button>
              <button>Runway</button>
            </div>
          </div>
        </div>

        <div className="col-lg-11 mx-auto">
          <div className="row m-0 justify-content-evenly FooterDowndata">
            {footerSections.map((section, index) => (
              <div
                key={index}
                className={`col-lg-3 col-sm-4 ${
                  index === 2 ? "" : "col-6"
                } mb-4`}
              >
                <h5>{section.title}</h5>
                <ul className="list-unstyled ListData">
                  {section.links.map((link, i) => (
                    <li key={i}>
                      <Link to={link.to}>{link.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Contact Section */}
            <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
              <h5>Contact SwagStay</h5>
              <div className="d-flex gap-3 ContactSec">
                <p className="mb-1">
                  <i className="bi bi-envelope-fill me-2"></i>{" "}
                  support@swagstay.com
                </p>
                <p className="mb-3">
                  <i className="bi bi-phone-fill me-2"></i> +91-9209403405
                </p>
              </div>
              <div className="mt-3 giveLink">
                <h5>Share your feedback</h5>
                <div
                  className="d-flex GiveLinkSection"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLinkClick("/feedbackform")}
                >
                  <img src={giveLInkIcom} className="giveLInkIcom" alt="Link" />
                  <label style={{ color: "var(--black)", cursor: "pointer" }}>
                    Give us feedback
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="socialMediaLink">
            <div className="row m-0 w-100">
              <div className="col-lg-3 col-md-4 p0left">
                <div className="mt-4">
                  <h5>Follow Us</h5>
                  <div className="d-flex SocialIcons">
                    <a href="https://www.facebook.com/p/Swagstay-100077841649169/">
                      <img src={Facebook} alt="Facebook Icon" />
                    </a>
                    <a href="https://www.instagram.com/swagstay_hotels/">
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                    <a href="https://x.com/hotelsinnagpur">
                      <img src={Twitter} alt="Twitter Icon" />
                    </a>
                    <a href="https://www.linkedin.com/company/swagstay/?originalSubdomain=in">
                      <img src={Linkedin} alt="LinkedIn Icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-lg-flex d-none"></div>
              <div className="col-lg-3 col-md-4"></div>
              <div className="col-lg-3 col-md-4">
                <div className="mt-4">
                  <h5>Get the App</h5>
                  <div className="d-flex">
                    <a href="#" className="me-2">
                      <img
                        src={AppstoreLogo}
                        alt="App Store"
                        style={{ width: "120px" }}
                        onClick={() =>
                          handleLinkClick(
                            "https://apps.apple.com/in/app/swagstay-hotel-booking/id6447533152",
                            "App Store"
                          )
                        }
                      />
                    </a>
                    <a href="#">
                      <img
                        src={PlayLogo}
                        alt="Google Play"
                        style={{ width: "120px" }}
                        onClick={() =>
                          handleLinkClick(
                            "https://play.google.com/store/apps/details?id=com.swagstay.swagstayhotel",
                            "Play Store"
                          )
                        }
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="borderTopENd">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <p>
                Made in India with <span style={{ color: "red" }}>❤️</span>
              </p>
            </div>
            <div className="text-end copyRightText">
              <p>Swagstay © 2024 | All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
