import React, { useState } from "react";
import "./Css/SortByBar.css";

export default function SortByBar({ handleFilters1 }) {
  const sortOptions = [
    "Price-Low to High",
    "Price-High to Low",
    "Rating-Best to Average",
    "Rating-Average to Best",
    "Distance-Nearest First",
    "Distance-Farthest First",
  ];

  const [activeSort, setActiveSort] = useState("Price-low to high");

  const handleSortClick = (option) => {
    const filterData = {
      sorting: {
        price: option.includes("Price")
          ? option === "Price-Low to High"
            ? "asc"
            : "desc"
          : null,
        rating: option.includes("Rating")
          ? option === "Rating-Best to Average"
            ? "desc"
            : "asc"
          : null,
        distance: option.includes("Distance")
          ? option === "Distance-Nearest First"
            ? "asc"
            : "desc"
          : null,
      },
    };
    setActiveSort(option);
    handleFilters1(filterData);
  };
  return (
    <div>
      <div className="shorByContent">
        <div className="container-xl">
          <div className="shortByListadat">
            <h1>Sort by:</h1>

            <div className="sortBybttn">
              {sortOptions.map((option, index) => (
                <button
                  key={index}
                  type="button"
                  className={activeSort === option ? "activesortby" : ""}
                  onClick={() => handleSortClick(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
