import React from "react";
import "./Css/BookingLoader.css";

export default function BookingLoader() {
  return (
    <div className="containerBookingLoader">
      <div className="loader">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
      </div>
    </div>
  );
}
