import React from "react";
import "./Css/Discount.css";
import QRCODE from "../Assets/qrcode.png";

export default function Discount() {
  return (
    <div className="qrContCover">
      <div className="container-lg p-0">
        <div className="row m-0">
          <div className="col-md-6">
            <div className="d-flex LeftSideDis">
              <img src={QRCODE} alt="Qr"></img>
              <div className="percentageDiscount">
                <h1>Upto</h1>
                <h1>50% Off</h1>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex RightSideDis h-100">
              <div className="discountTxts">
                <h2>Get Ready for amazing stay with SwagStay</h2>
                <p>Download mobile app for more discount ✨</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
