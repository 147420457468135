import { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/SwagStayWallet.css";
import swagStayWallImg from "../Assets/swagStayWallImg.svg";
import BackBtn from "../Assets/backIcon.svg";
import amountDataArrow from "../Assets/amountDataArrow.svg";
import SuccessPaymentModal from "./SuccessPayment";
import {
  server_post_data,
  get_wallet_offer_data,
  add_amount_wallet_final_real,
  add_amount_wallet_real,
  wallet_real_details_all,
} from "../ServiceConnection/serviceconnection";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError, handleNumbersChange } from "../CommonJquery/CommonJquery";
import { useRazorpay } from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { CookingPot, Flashlight } from "lucide-react";
let customer_id = "0";
let customer_name = "";
let customer_mobile_no = "";
let customer_email = "";
export default function SwagStayWallet() {
  const { Razorpay } = useRazorpay();
  const navigate = useNavigate();
  const [SecondScreen, setSecondScreen] = useState(false);
  const [LastScreen, setLastScreen] = useState(false);
  const [walletdata, setWalletdata] = useState();
  const [WalletList, setWalletList] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const HanndleBackscreen = () => {
    setSecondScreen(false);
    setLastScreen(false);
  };
  const handleNextScreen = (item) => {
    setWalletdata(item);
    if (item && item.wallet_amount > 0) {
      setWalletAmount(item.wallet_amount);
    } else {
      setWalletAmount(1);
    }

    setSecondScreen(true);
  };
  customer_id = retrieveData("customer_id");
  customer_name = retrieveData("customer_full_name");
  customer_mobile_no = retrieveData("customer_user_moblie_no");
  customer_email = retrieveData("customer_user_email");

  const HanndleBackscreenLastscreen = () => {
    setSecondScreen(false);
    setLastScreen(false);
  };
  const handleSucessPaymentModal = () => {
    setLastScreen(true);
  };
  const [WalletAmount, setWalletAmount] = useState(0);
  const [walletid, setwalletid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [RealWalletAmount, setRealWalletAmount] = useState("0");
  const [RupessIcon, setRupessIcon] = useState("");
  const [offers, setoffers] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(true);
  const master_data_get_data = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_wallet_offer_data, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response);
        } else {
          const final_data = JSON.parse(data1[1]);

          setoffers(final_data.wallet_offer);
          setRealWalletAmount(final_data.real_wallet_amount);
          setRupessIcon(final_data.rupees_icon);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get_data();
    master_data_get_history();
  }, []);

  const handlePayment = async (data_server) => {
    setIsLoading(true);
    setwalletid(data_server[1]);

    try {
      const options = {
        description: "Swagstay Wallet Payment",
        image: "https://www.swagstay.com/logo.png",
        currency: "INR",
        key: data_server[4], // Replace with your actual Razorpay key
        amount: data_server[2],
        name: "Swagstay",
        order_id: data_server[3],
        prefill: {
          email: customer_email,
          contact: customer_mobile_no,
          name: customer_name,
        },
        theme: { color: "#F86855" },
        handler: function (response) {
          // Successful payment handler
          master_data_get_final(response.razorpay_payment_id, data_server[1]);
        },
        modal: {
          ondismiss: function () {
            handleError("Payment process was cancelled. Please try again.");
          },
        },
      };
      // Create Razorpay instance
      const razorpayInstance = new Razorpay(options);
      // Open the Razorpay payment interface
      razorpayInstance.open();
      // Optional: Handle errors when Razorpay fails to initialize
      razorpayInstance.on("payment.failed", function (response) {
        handleError("Payment failed. Please try again.");
      });
    } catch (error) {
      handleError("Payment failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const master_data_get = async (data_wallet) => {
    if (WalletAmount !== "" && WalletAmount !== "0") {
      setIsLoading(true);
      const fd = new FormData();
      fd.append("user_moblie_no", customer_mobile_no);
      fd.append("amount", WalletAmount);
      fd.append("payment_id", "");
      fd.append("wtr_id", data_wallet.wo_id);

      await server_post_data(add_amount_wallet_real, fd)
        .then((Response) => {
          setIsLoading(false);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            handlePayment(data);
          }
        })
        .catch((error) => {
          console.log("2");
          console.log(error);
          setIsLoading(false);
        });
    } else {
      handleError("Error", "Enter Vaild Amount");
    }
  };
  const master_data_get_final = async (trans_id, walletidreal) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("user_moblie_no", customer_mobile_no);
    fd.append("amount", WalletAmount);
    fd.append("payment_id", trans_id);
    fd.append("wtr_id", walletidreal);

    await server_post_data(add_amount_wallet_final_real, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          navigate("/success_payment", {
            state: {
              // Use `state` for passing navigation state
              paymentSuccess: true,
              wallet_offer: data[1],
              amount_add: RupessIcon + data[2],
              payment_id: data[3],
            },
          });
        }
      })
      .catch((error) => {
        console.log("3");
        console.log(error);
        setIsLoading(false);
      });
  };

  const master_data_get_history = async () => {
    setshowLoaderAdmin(true);

    await server_post_data(wallet_real_details_all, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setWalletList(final_data.wallethis_list);
          setfilteredData(final_data.wallethis_list);
          setRupessIcon(final_data.rupees_icon);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleNumbersChangesss = (e) => {
    setWalletAmount(e.target.value);
  };

  const fiklterdata = async (selectedTabddd) => {
    const filteredDatadate = WalletList.filter((item) => {
      if (selectedTabddd === "all") return true;
      return selectedTabddd === "credit"
        ? item.crd_ded === "0"
        : item.crd_ded === "1";
    });
    setfilteredData(filteredDatadate);
    setSelectedTab(selectedTabddd);
  };

  return (
    <div>
      <Header />
      <div className="paddingLeftrIGHT">
        {!SecondScreen && !LastScreen && (
          <>
            <div className="swagWllteHeading">
              <h2>Swag wallet</h2>
            </div>
            <div className="row swagDIvBith">
              <div className="col-12 col-lg-6 col-sm-12">
                <div className="leftwalltDiv">
                  <h2>What is swag wallet</h2>
                  <p>
                    Enjoy seamless bookings and exclusive perks with swag
                    wallet!
                  </p>

                  <div className="walltDivimg">
                    <div>
                      <h2>
                        Swag <br />
                        Wallet
                      </h2>
                    </div>
                    <div className="leftImgWall">
                      <img src={swagStayWallImg} alt="swagStayWallImg"></img>
                    </div>
                  </div>

                  <div className="wallTLIstData">
                    <div>
                      <h2>1. Extra discounts on every booking</h2>
                      <p>
                        With Swag Wallet, you save more on every booking! Add
                        money to your wallet through UPI and enjoy special
                        discounts that are only available to Swag Wallet users.
                      </p>
                    </div>
                    <div>
                      <h2>2. Express check-In</h2>
                      <p>
                        Skip the long lines and get to your room faster with our
                        express check-in feature. Swag Wallet users enjoy
                        priority service, making your check-in process smooth
                        and hassle-free.
                      </p>
                    </div>
                    <div>
                      <h2>3. Exclusive offers and deals</h2>
                      <p>
                        Unlock a world of exclusive offers and deals that are
                        just for Swag Wallet users. From complimentary room
                        upgrades to free breakfast, our exclusive offers will
                        enhance your stay.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-sm-12">
                <div className="RightwalltDiv">
                  <div className="rightdivWalletcont">
                    <div className="leftWallHist">
                      <h2>
                        {" "}
                        {RupessIcon}
                        {RealWalletAmount === null ? 0.0 : RealWalletAmount}
                      </h2>
                      <p>Available balance </p>
                    </div>
                    <div className="wlltHisty">
                      <button onClick={handleSucessPaymentModal}>
                        Wallet History
                      </button>
                    </div>
                  </div>

                  <div className="addMoneyDiv">
                    <h2>Add money to wallet</h2>
                    <p>Choose from the available offer packs</p>

                    <div className="col-lg-12 col-md-8">
                      <div className="pricingCoupnsDiv row">
                        {offers &&
                          offers.length > 0 &&
                          offers.map((item, index) => (
                            <div className="col-6 col-sm-4 mb-3" key={index}>
                              {item.wallet_amount !== "0" ? (
                                <div
                                  className="coupon-card"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleNextScreen(item)}
                                >
                                  <div className="leftCicleCoupn"></div>
                                  <div className="RightCicleCoupn"></div>
                                  <h3>{item.wallet_amount}</h3>
                                  <p>Upto {item.wallet_offer}% off</p>
                                </div>
                              ) : (
                                <div
                                  className="coupon-card other-card"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleNextScreen(item)}
                                >
                                  <h3>Other</h3>
                                  <p>Add manually</p>
                                  <div className="leftCicleCoupn"></div>
                                  <div className="RightCicleCoupn"></div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <div className="nextButton">
                      <button type="button " >
                        Next
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/*---------Second Screen------------- */}{" "}
        {SecondScreen && !LastScreen && (
          <>
            <div className="backBttn">
              <button type="button" onClick={HanndleBackscreen}>
                <img src={BackBtn} alt="BackBtn"></img>
                <h2>Back</h2>
              </button>{" "}
            </div>
            <div className="priceDivSencnd">
              {walletdata.wallet_amount !== "0" ? (
                <div className="priceDivSencndImgbar">
                  <h2>₹{walletdata.wallet_amount}</h2>
                  <p>Upto {walletdata.wallet_offer}% off</p>
                  {/* <h2>₹1440</h2>
                <p>Upto 6% Off</p> */}
                </div>
              ) : (
                <div className="priceDivSencndImgbar">
                  <input
                    type="text"
                    placeholder="Enter Custom Amount "
                    maxLength={4}
                    onChange={(e) => handleNumbersChangesss(e)}
                    onInput={handleNumbersChange}
                  />
                </div>
              )}

              <div className="underatascreentwo">
                <h2>Details</h2>

                <p>{walletdata.wallet_detail}</p>
              </div>
              <div className="underatascreentwo">
                <h2>Terms & Conditions</h2>
                {walletdata.wallet_teams_conditions}
              </div>
              <button
                type="button"
                className="addMny"
                // onClick={handleSucessPaymentModal}
                onClick={() => master_data_get(walletdata)}
              >
                Add Money
              </button>
            </div>
          </>
        )}
        {/*--------Payment Sucess SCreen- ------------ */}
        {/* we have the payment screen so first route the paymen then the show the the last screen  */}
        {/*--------Last Wallt Histry  SCreen- ------------ */}
        {LastScreen && (
          <>
            <div className="backBttn">
              <button type="button" onClick={HanndleBackscreenLastscreen}>
                <img src={BackBtn} alt="BackBtn"></img>
                <h2>Back</h2>
              </button>{" "}
            </div>
            <div className="priceDivSencnd  contAMount">
              <div className="priceDivSencndImgbar   LastScreen">
                <h2>Wallet history</h2>
              </div>

              <div className="dataShowTYpes">
                <button
                  type="button"
                  className={`${selectedTab === "all" && "selectedDataTypes"}`}
                  onClick={() => fiklterdata("all")}
                >
                  All Transactions
                </button>
                <button
                  type="button"
                  className={`${
                    selectedTab === "debit" && "selectedDataTypes"
                  }`}
                  onClick={() => fiklterdata("debit")}
                >
                  Debit
                </button>
                <button
                  type="button"
                  className={`${
                    selectedTab === "credit" && "selectedDataTypes"
                  }`}
                  onClick={() => fiklterdata("credit")}
                >
                  Credit
                </button>
              </div>

              <div className="col-12 p-0">
                {filteredData &&
                  filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <div key={index} className="AmountSHowData">
                      <div className="amountdata">
                        <div className="leftSideAMountData">
                          <img src={amountDataArrow} alt="amountDataArrow" />
                          <div className="leftAmoutnDatatitle">
                            <h2>
                              {item.crd_ded === "1"
                                ? "Amount debited"
                                : "Amount credited"}
                            </h2>
                            <p>{item.through}</p>
                          </div>
                        </div>
                        <div className="rightAMountNumshow ">
                          {item.crd_ded === "1" ? (
                            <>
                              <span>
                                {item.crd_ded === "1" ? "- " : "+ "}
                                {RupessIcon}
                                {item.amount}
                              </span>
                            </>
                          ) : (
                            <>
                              {" "}
                              <h2>
                                {item.crd_ded === "1" ? "- " : "+ "}
                                {RupessIcon}
                                {item.amount}
                              </h2>
                            </>
                          )}

                          <p>{item.coverted_date}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
