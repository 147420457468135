import React, { useState, useEffect, useRef } from "react";
import "./Css/Header.css";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import orengeLogoSwag from "../Assets/orengeLogoSwag.svg";
import SwagLogoHome from "../Assets/SwagLogoHome.svg";
import headerToggle from "../Assets/headerToggle.svg";
import headerToggleBlack from "../Assets/headerToggleBlack.svg";
import orengePluse from "../Assets/orengePluse.svg";
import downArow from "../Assets/downArow.svg";
import downIconWhite from "../Assets/downIconWhite.svg";
import { useCallback } from "react";
import locationMapImg from "../Assets/locationMapImg.svg";
import playStoreLogo from "../Assets/playStoreLogo.svg";
import appStoreLogoHead from "../Assets/appStoreLogoHead.svg";
import ErrorImage from "../Assets/profileImgHeader.png";
import DummyUser from "../Assets/dummyUser.png";
import LoginPage from "./LoginPage";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Logoutmodal from "./Logoutmodal";

import {
  removeData,
  retrieveData,
  storeData,
} from "../LocalConnection/LocalConnection";
import SideBar from "./SideBar";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import {
  search_list_type,
  server_post_data,
} from "../ServiceConnection/serviceconnection";

let search_data = true;
const MAX_ADULTS = 3;
const MAX_CHILDREN = 3;
export default function Header() {
  const navigate = useNavigate();
  const [showLocationPermission, setshowLocationPermission] = useState(false);
  const [UserName, setUserName] = useState("");
  const [UserImage, setUserImage] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const [Islogin, setIslogin] = useState(true);
  const [toggleActive, setToggleActive] = useState(false);
  const [totalAdults, settotalAdults] = useState(1);
  const [totalChildren, settotalChildren] = useState(0);
  const not_ask_popup = retrieveData("not_ask_popup");
  const [showLocation, setshowLocation] = useState(false);
  const [showLocationhotels, setshowLocationhotels] = useState(false);
  const [AddRoom, setAddRoom] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [Hotel_id, setHotel_id] = useState(0);
  const [HotelID, setHotelID] = useState(0);
  const [Allhotels, setAllhotels] = useState([]);
  const [AllCityList, setCityList] = useState([]);
  const [AllOthersList, setOthersList] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [ClickType, setClickType] = useState(0);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0 }]);

  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [isCheckInMode, setIsCheckInMode] = useState(true);
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";
  const isSearchNavbar = location.pathname === "/home_hotel_map_view";
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };
  const handleLogOutClick = () => {
    setShowLogOut(true);
  };

  const handleCloseLogOutModal = () => {
    setShowLogOut(false);
  };
  const handleCloseLogOutModalYes = () => {
    setShowLogOut(false);
    removeData();
  };

  const handleCloseModal = () => {
    setShowLogin(false);
  };
  const handleCencelLocation = () => {
    storeData("not_ask_popup", "1");
    setshowLocationPermission(false);
  };
  const handleToggle = () => {
    if (Islogin) {
      setExpanded(!expanded);
      setToggleActive(!toggleActive);
    } else {
      setSidebarOpen(!isSidebarOpen);
    }
  };

  const navItems = [
    { path: "/list_your_hotel", icon: "bi-building", label: "List your hotel" },
    {
      path: "/coprative_solution",
      icon: "bi-briefcase",
      label: "Corporate solution",
    },
    { path: "/offer", icon: "bi-gift", label: "Offer" },
    { path: null, icon: "bi-phone", label: "Download app" },
  ];

  const handleNavigate = useCallback(
    (path) => {
      if (path) navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    let customer_id = retrieveData("customer_id");

    if (customer_id === null || customer_id === 0) {
      customer_id = 0;
    }
    let customer_name = retrieveData("customer_full_name");
    setUserName(customer_name);
    let customer_image = retrieveData("customer_image");
    setUserName(customer_name);
    setUserImage(customer_image);
    if (customer_id !== "0") {
      setIslogin(false);
    }
  }, []);

  //location allowedd

  const checkLocationPermissions = () => {
    // Check if geolocation is supported
    if (!navigator.geolocation) {
      console.error("Geolocation not supported");
      return;
    }

    // iOS-specific configuration options
    const options = {
      enableHighAccuracy: true, // Most accurate location
      timeout: 10000, // 10 seconds timeout
      maximumAge: 0, // Prevent caching of old location data
    };

    // Request location permissions
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Success callback
        storeData("customer_lat", position.coords.latitude);
        storeData("customer_long", position.coords.longitude);
        handleCencelLocation();
      },
      (error) => {
        // Error handling with more detailed iOS-specific checks
        console.error("Geolocation error:", error);

        switch (error.code) {
          case error.PERMISSION_DENIED:
            // Specifically for iOS: prompt user to enable location in settings
            if (parseInt(not_ask_popup) == 0) {
              setshowLocationPermission(true);
            }
            if (parseInt(not_ask_popup) == 0) {
              handleError(
                "Please enable location permissions in Settings > Privacy > Location Services"
              );
            }
            break;
          case error.POSITION_UNAVAILABLE:
            handleError(
              "Location information is unavailable. Check your device settings."
            );
            handleCencelLocation();
            break;
          case error.TIMEOUT:
            handleError("Location request timed out. Please try again.");
            handleCencelLocation();
            break;
          default:
            handleError(
              "An unknown error occurred while trying to get location."
            );
            handleCencelLocation();
        }
      },
      options // Pass the configuration options
    );
  };
  useEffect(() => {
    checkLocationPermissions();
  }, []);

  $("#login_check_jquery").on("customEvent", function () {
    handleLoginClick();
  });
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //tarun

  const handleSHowlocations = () => {
    setshowLocation(!showLocation);

    setAddRoom(false);
    setAddRoom(false);

    setIsCalendarOpen(false);
  };

  const getSearchableData = async (searchTextdata) => {
    if (search_data) {
      search_data = false;

      const fd = new FormData();
      fd.append("search_keywords", searchTextdata);
      fd.append("next_limit", 0);
      await server_post_data(search_list_type, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setshowLocationhotels(true);

            setAllhotels(final_data.hotel_list);
            setCityList(final_data.other_city_list);
            setOthersList(final_data.other_data_list);
            setSEOloop(final_data.seo_list);
          }

          search_data = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear the previous timeout if it exists
    }

    // Set a new timeout to call the function after a delay (e.g., 500ms)
    setDebounceTimeout(
      setTimeout(() => {
        getSearchableData(value);
      }, 500)
    );
  };

  const handleBooking = () => {
    if (ClickType !== 0) {
      setIsCalendarOpen(false);
      storeData("RoomData", JSON.stringify(rooms));
      storeData("CheckIndate", checkInDate.toISOString().split("T")[0]);
      storeData("CheckOutdate", checkOutDate.toISOString().split("T")[0]);
      let total_adults_count = 0;
      let total_children_count = 0;
      Object.entries(rooms).forEach(([key, value]) => {
        total_adults_count = total_adults_count + parseInt(value.adults);
        total_children_count = total_children_count + parseInt(value.children);
      });
      storeData(
        "TotalAdultAndChild",
        rooms.length + "~@~" + total_adults_count + "~@~" + total_children_count
      );

      if (ClickType == 1) {
        let link_url = match_and_return_seo_link(HotelID, Hotel_id);
        handleLinkClick("/" + link_url);
      } else if (ClickType == 2) {
        handleLinkClick("/search" + HotelID);
      } else if (ClickType == 3) {
        let link_url = match_and_return_city_link(HotelID);
        handleLinkClick("/" + link_url);
      }
    } else {
      handleError("Please select a destination from the list");
    }
  };

  const match_and_return_seo_link = (HotelID, hotel_id) => {
    let data_seo_link_final = "hotel_detail/" + HotelID + "/" + hotel_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const handleAdultChildIncrementDecrement = (index, min_plus, adultchild) => {
    setRooms((prev) => {
      let newRooms = [...prev];
      let shouldCallHitRoom = true;
      if (adultchild === "adult") {
        if (min_plus === 1 && newRooms[index].adults < MAX_ADULTS) {
          newRooms[index].adults += 1;
        } else if (min_plus === 1 && newRooms[index].adults >= MAX_ADULTS) {
          shouldCallHitRoom = false;
        } else if (min_plus === -1) {
          if (index === 0 && newRooms[index].adults > 1) {
            newRooms[index].adults -= 1;
          } else if (index !== "0" && newRooms[index].adults > 0) {
            newRooms[index].adults -= 1;
          }
        }
      } else if (adultchild === "child") {
        if (min_plus === 1) {
          if (newRooms[index].children < MAX_CHILDREN) {
            newRooms[index].children += 1;
          } else {
            // Prevent function_hit_room from being called when MAX_CHILDREN is exceeded
            shouldCallHitRoom = false;
          }
        } else if (min_plus === -1 && newRooms[index].children > 0) {
          newRooms[index].children -= 1;
        } else {
          shouldCallHitRoom = false;
        }
      } else {
        // Adding a new room
        newRooms = [...newRooms, { id: Date.now(), adults: 1, children: 0 }];
      }
      // Filter out empty rooms, except for Room 1
      newRooms = newRooms.filter(
        (room, idx) => idx === 0 || room.adults > 0 || room.children > 0
      );

      let total_adults_count = 0;
      let total_children_count = 0;
      Object.entries(newRooms).forEach(([key, value]) => {
        total_adults_count = total_adults_count + parseInt(value.adults);
        total_children_count = total_children_count + parseInt(value.children);
      });
      settotalAdults(total_adults_count);
      settotalChildren(total_children_count);

      // Call function_hit_room with the updated room data only if shouldCallHitRoom is true

      return newRooms;
    });
  };

  //addd room buttons

  useEffect(() => {
    let customer_rooms = retrieveData("RoomData");
    let customer_checkInDate = retrieveData("CheckIndate");
    let customer_checkOutDate = retrieveData("CheckOutdate");

    const currentDate = new Date(); // Current date
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + 1); // Next day's date

    if (customer_rooms === null || customer_rooms === 0) {
      customer_rooms = 0;
    }

    if (customer_rooms !== "0") {
      setRooms(JSON.parse(customer_rooms));
    }

    if (customer_checkInDate !== "0") {
      const parsedCheckInDate = new Date(customer_checkInDate);
      // If the check-in date is before the current date, set it to the current date
      if (parsedCheckInDate < currentDate) {
        setCheckInDate(currentDate);
      } else {
        setCheckInDate(parsedCheckInDate);
      }
    } else {
      setCheckInDate(currentDate);
    }

    if (customer_checkOutDate !== "0") {
      const parsedCheckOutDate = new Date(customer_checkOutDate);
      if (parsedCheckOutDate < currentDate) {
        // If the parsed check-out date is before the current date, set it to one day after the current date
        const updatedCheckOutDate = new Date(currentDate);
        updatedCheckOutDate.setDate(currentDate.getDate() + 1);
        setCheckOutDate(updatedCheckOutDate);
      } else {
        parsedCheckOutDate.setDate(parsedCheckOutDate.getDate() + 1);
        setCheckOutDate(parsedCheckOutDate);
      }
    } else {
      setCheckOutDate(nextDate);
    }
  }, []);

  const handleShowAddroom = () => {
    setAddRoom(!AddRoom);

    setshowLocation(false);
    setIsCalendarOpen(false);
    setIsCheckInMode(false);
    setIsCalendarOpen(false);
  };

  const handlehotelClick = (item, flag) => {
    if (flag == 1) {
      setSearchText(item.hotel_name);
      setHotel_id(item.hotel_id);
      setHotelID(item.HotelID);
    } else if (flag == 2) {
      setSearchText(item.address);
      setHotelID(
        "?location=" +
          item.address +
          "&latitude=" +
          item.latitude +
          "&longitude=" +
          item.longitude +
          "&searchType=locality&city_data=" +
          item.city_name +
          "&roomConfig=1&showSearchElements=false&country=" +
          item.country_name +
          "&guests=1&rooms=1"
      );
      setHotel_id(0);
    } else if (flag == 3) {
      setSearchText(
        item.city_name + " , " + item.state_name + " , " + item.country_name
      );
      setHotelID(item.city_id);
      setHotel_id(0);
    }
    setClickType(flag);
    setshowLocationhotels(false);
  };

  const match_and_return_city_link = (v_id) => {
    let data_seo_link_final = "hotel_list_location/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  const dropdownRefs = [useRef(null), useRef(null)];
  const dateContrRef = useRef(null);
  const poplllerDesignationRef = useRef(null);

  //----------------------------------PupulerDiv-----------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        poplllerDesignationRef.current &&
        !poplllerDesignationRef.current.contains(event.target)
      ) {
        setshowLocation(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //----------------------------------Calander DropDOwn-----------------------------------
  const handleClickOutside2 = (event) => {
    if (dateContrRef.current && !dateContrRef.current.contains(event.target)) {
      setIsCalendarOpen(false); // Close the calendar
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside2);
    return () => {
      document.removeEventListener("click", handleClickOutside2);
    };
  }, []);

  //---------------------------------Rooms AND Location--------------------------------
  const handleClickOutside = (event) => {
    const isClickInsideDropdown =
      dropdownRefs[0].current && dropdownRefs[0].current.contains(event.target);

    const isClickInsideCategoryDropdown =
      dropdownRefs[1].current && dropdownRefs[1].current.contains(event.target);

    const isClickInsideDecrementButton = event.target.closest(".decrement-btn");

    if (
      isClickInsideDropdown ||
      isClickInsideCategoryDropdown ||
      isClickInsideDecrementButton
    ) {
      return;
    }

    if (dropdownRefs[0].current && !isClickInsideDropdown) {
      setAddRoom(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCheckInClick = () => {
    setIsCheckInMode(true);
    setIsCalendarOpen(true);

    setAddRoom(false);

    setshowLocation(false);
  };

  const handleCheckOutClick = () => {
    if (checkInDate) {
      setIsCheckInMode(false);
      setIsCalendarOpen(true);
      setAddRoom(false);
      setshowLocation(false);
    }
  };

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setCheckOutDate(null);
    setIsCheckInMode(false); // Switch to check-out selection
  };
  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
    setIsCalendarOpen(false); // Close the calendar after selecting check-out
  };

  const handleLinkClick = (url, platform) => {
    console.log(`Navigating to ${platform}: ${url}`);
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className={isHomeRoute ? "posFixedHome" : "posFixed"}>
      <div className="navBarContainer w-100">
        <Navbar
          expand="lg"
          expanded={expanded}
          className={`${isHomeRoute ? "NaVbarTopHome" : "NaVbarTop"} ${
            isHomeRoute && isScrolled ? "scrlllBg" : ""
          } ${toggleActive ? "toggleHeaderActive" : ""}`}
        >
          <div className="container-xl">
            <div className="NAVcOTNNR">
              <div className="HeadLogNav">
                <Navbar.Brand
                  href="/"
                  className="text-danger"
                  onClick={() => (window.location.href = "/")}
                >
                  <img
                    className="LogoHome"
                    src={isHomeRoute ? SwagLogoHome : orengeLogoSwag}
                    alt="Logo"
                  />
                </Navbar.Brand>

                <Navbar.Toggle
                  className="toggleSHOW"
                  aria-controls="basic-navbar-nav"
                  onClick={handleToggle}
                >
                  <img
                    src={isHomeRoute ? headerToggle : headerToggleBlack}
                    alt="Toggle Menu"
                    style={{ width: "30px", height: "30px" }}
                  />
                </Navbar.Toggle>
              </div>
              <div
                className={`${
                  isSearchNavbar ? "clipseachbar" : "cllpseNavshow"
                }`}
              >
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className={`NavBarcontents ${
                    location.pathname.includes("bookings_details")
                      ? "justify-content-end"
                      : ""
                  }`}
                >
                  {!location.pathname.includes("bookings_details") && (
                    <Nav
                      className={
                        isHomeRoute
                          ? "ml-auto NavbartextHome"
                          : "ml-auto Navbartext"
                      }
                    >
                      {navItems.map((item, index) => (
                        <>
                          <Nav.Link
                            key={index}
                            href={item.path || "#"}
                            className={`text-light ${
                              isSearchNavbar ? "navbarToglelinks" : ""
                            }`}
                            onClick={() => {
                              if (item.label !== "Download app") {
                                handleNavigate(item.path);
                              }
                            }}
                            style={{ position: "relative" }}
                          >
                            {item.label !== "Download app" ? (
                              <>
                                <i className={`bi ${item.icon}`}></i>{" "}
                                {item.label}
                              </>
                            ) : (
                              <Dropdown align="end" className="d-inline-block">
                                <Dropdown.Toggle
                                  as="div"
                                  className="downLoadAppToggle"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="bi bi-phone"></i> {item.label}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="downLoadAppCOntainer">
                                  {/* Play Store Link */}
                                  <Dropdown.Item
                                    as="a"
                                    onClick={() =>
                                      handleLinkClick(
                                        "https://play.google.com/store/apps/details?id=com.swagstay.swagstayhotel",
                                        "Play Store"
                                      )
                                    }
                                    className="d-flex align-items-center"
                                  >
                                    <img
                                      src={playStoreLogo}
                                      alt="Play Store Logo"
                                    />
                                    <p className="mb-0 ms-2">Play Store</p>
                                  </Dropdown.Item>

                                  {/* App Store Link */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleLinkClick(
                                        "https://apps.apple.com/in/app/swagstay-hotel-booking/id6447533152",
                                        "App Store"
                                      )
                                    }
                                    className="d-flex align-items-center"
                                  >
                                    <img
                                      src={appStoreLogoHead}
                                      alt="App Store Logo"
                                    />
                                    <p className="mb-0 ms-2">App Store</p>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </Nav.Link>
                        </>
                      ))}

                      {isSearchNavbar && (
                        <div className={`searchBrrROw MapSearhBarHead`}>
                          {/* Location Input */}
                          <div
                            className="input-group"
                            onClick={handleSHowlocations}
                            ref={poplllerDesignationRef}
                          >
                            <span className="input-group-text bg-white border-0">
                              <i className="bi bi-geo-alt"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-0"
                              placeholder="Search by city, region, hotel name"
                              value={searchText}
                              onChange={handleSearchChange}
                            />
                            {showLocation &&
                              showLocationhotels &&
                              ((AllCityList && AllCityList.length > 0) ||
                                (AllOthersList &&
                                  AllOthersList.length > 0)) && (
                                <div className="poplllerDesignationhEADEER">
                                  <div className="popHead">
                                    <h1>Popular Location</h1>
                                  </div>

                                  {AllCityList.map((item, index) => (
                                    <div className="citysSHow" key={index}>
                                      <button
                                        className="address"
                                        onClick={() =>
                                          handlehotelClick(item, 3)
                                        }
                                      >
                                        {item.city_name}, {item.state_name},{" "}
                                        {item.country_name}
                                      </button>
                                    </div>
                                  ))}
                                  {AllOthersList.map((item, index) => (
                                    <div className="citysSHow" key={index}>
                                      <button
                                        className="address"
                                        onClick={() =>
                                          handlehotelClick(item, 2)
                                        }
                                      >
                                        {item.address}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            {showLocation &&
                              showLocationhotels &&
                              AllCityList &&
                              AllCityList.length === 0 &&
                              AllOthersList &&
                              AllOthersList.length === 0 &&
                              Allhotels &&
                              Allhotels.length > 0 && (
                                <div className="poplllerDesignationhEADEER">
                                  <div className="popHead">
                                    <h1>Popular Hotels</h1>
                                  </div>

                                  {Allhotels.map((item, index) => (
                                    <div className="citysSHow" key={index}>
                                      <button
                                        className="address"
                                        onClick={() =>
                                          handlehotelClick(item, 1)
                                        }
                                      >
                                        {item.hotel_name}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>

                          {/* Divider */}
                          <div className="vr mx-2"></div>

                          {/* Room and Guest Selection */}
                          <div
                            className="input-group"
                            style={{ position: "relative" }}
                            ref={dropdownRefs[0]}
                          >
                            <div
                              className="roomViewHeader"
                              onClick={handleShowAddroom}
                            >
                              {" "}
                              <span className="input-group-text bg-white border-0">
                                <i className="bi bi-person"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control border-0"
                                value={`${rooms.length} Rooms · ${totalAdults} Guest`}
                                readOnly
                              />
                            </div>

                            <div
                              className={`roomDrodownHeader   ${
                                AddRoom ? "open" : ""
                              }`}
                            >
                              {rooms &&
                                rooms.length > 0 &&
                                rooms.map((room, roomIndex) => (
                                  <div key={roomIndex}>
                                    <div className="roomHead">
                                      <h1 style={{ textAlign: "start" }}>
                                        Room {roomIndex + 1}
                                      </h1>
                                    </div>

                                    {/* Adults Section */}
                                    <div className="roomHeadData">
                                      <div className="leftROomCOnt">
                                        <h1>Adults</h1>
                                        <p>Ages 5 or above</p>
                                      </div>
                                      <div className="rightROoomtCOnt">
                                        <button
                                          className={`minsssBttm decrement-btn ${
                                            room.adults === 1 && roomIndex === 0
                                              ? "ENableMinusbtn"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleAdultChildIncrementDecrement(
                                              roomIndex,
                                              -1,
                                              "adult"
                                            )
                                          }
                                          style={{
                                            opacity:
                                              roomIndex === 0
                                                ? room.adults > 1
                                                  ? 1
                                                  : 0.5
                                                : 1,
                                          }}
                                          disabled={
                                            roomIndex === 0 && room.adults <= 1
                                          }
                                        >
                                          -
                                        </button>

                                        <label>{room.adults}</label>

                                        <button
                                          className={`plusBttm ${
                                            room.adults === 3
                                              ? "ENablePluse"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleAdultChildIncrementDecrement(
                                              roomIndex,
                                              1,
                                              "adult"
                                            )
                                          }
                                          style={{
                                            opacity: room.adults < 3 ? 1 : 0.5,
                                          }}
                                          disabled={room.adults >= 3}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>

                                    <div className="roomHeadData">
                                      <div className="leftROomCOnt">
                                        <h1>Children</h1>
                                        <p>Age 0-5</p>
                                      </div>
                                      <div className="rightROoomtCOnt">
                                        <button
                                          className={`minsssBttm ${
                                            room.children === 0
                                              ? "ENableMinusbtn"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleAdultChildIncrementDecrement(
                                              roomIndex,
                                              -1,
                                              "child"
                                            )
                                          }
                                          style={{
                                            opacity:
                                              room.children > 0 ? 1 : 0.5,
                                          }}
                                          disabled={room.children <= 0}
                                        >
                                          -
                                        </button>

                                        <label>{room.children}</label>

                                        <button
                                          className={`plusBttm ${
                                            room.children === 3
                                              ? "ENablePluse"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleAdultChildIncrementDecrement(
                                              roomIndex,
                                              1,
                                              "child"
                                            )
                                          }
                                          style={{
                                            opacity:
                                              room.children < 3 ? 1 : 0.5,
                                          }}
                                          disabled={room.children >= 3}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                    {roomIndex === rooms.length - 1 && (
                                      <div
                                        className="addrromBotttm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleAdultChildIncrementDecrement(
                                            0,
                                            0,
                                            "add"
                                          );
                                        }}
                                        style={{ marginBottom: "0.5rem" }}
                                      >
                                        <img
                                          src={orengePluse}
                                          alt="orengePluse"
                                        />
                                        <p>Add room</p>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>

                          {/* Divider */}
                          <div className="vr mx-2"></div>

                          {/* Date Picker */}
                          <div
                            style={{ position: "relative" }}
                            ref={dateContrRef}
                          >
                            <div className="input-group">
                              <div className="d-flex gap-2 HeaderdateCheck">
                                <button
                                  type="button"
                                  onClick={handleCheckInClick}
                                >
                                  {checkInDate
                                    ? checkInDate.toLocaleDateString()
                                    : "CheckIn"}
                                </button>
                                <button
                                  type="button"
                                  onClick={handleCheckOutClick}
                                >
                                  {checkOutDate
                                    ? checkOutDate.toLocaleDateString()
                                    : "CheckOut"}
                                </button>
                              </div>
                            </div>{" "}
                            {isCalendarOpen && (
                              <div
                                className={"checkInCalanderHotelDetailHeader "}
                              >
                                <DatePicker
                                  selected={
                                    isCheckInMode ? checkInDate : checkOutDate
                                  }
                                  onChange={
                                    isCheckInMode
                                      ? handleCheckInDateChange
                                      : handleCheckOutDateChange
                                  }
                                  className="form-control border-0 p-0 text-muted"
                                  placeholderText={
                                    isCheckInMode
                                      ? "Select check-in date"
                                      : "Select check-out date"
                                  }
                                  dateFormat="dd MMM yyyy"
                                  inline
                                  selectsStart={isCheckInMode}
                                  selectsEnd={!isCheckInMode}
                                  startDate={checkInDate}
                                  endDate={checkOutDate}
                                  minDate={
                                    isCheckInMode ? new Date() : checkInDate
                                  } // For check-out, start from check-in date
                                  monthsShown={2} // Show two months side-by-side
                                />
                              </div>
                            )}
                          </div>

                          {/* Search Button */}
                          <button
                            className="btn btn-dark"
                            onClick={() => handleBooking()}
                          >
                            Search
                          </button>
                        </div>
                      )}
                    </Nav>
                  )}
                  {Islogin ? (
                    <div className="LogInButton" onClick={handleLoginClick}>
                      Login/Signup
                    </div>
                  ) : (
                    <Dropdown align="end" className="profile-container">
                      <Dropdown.Toggle
                        variant="link"
                        className="profile-toggle"
                        onClick={toggleSidebar}
                      >
                        <img
                          src={UserImage ? UserImage : DummyUser}
                          alt="Profile"
                          className="profile-img"
                          onError={(event) =>
                            (event.currentTarget.src = DummyUser)
                          }
                        />
                        <span
                          className={
                            isHomeRoute ? "profile_Name_home" : "profile_Name"
                          }
                        >
                          {UserName ? UserName : "User"}
                        </span>
                        <img
                          src={isHomeRoute ? downIconWhite : downArow}
                          alt="Dropdown Arrow"
                          className="custom-dropdown-arrow"
                        />
                      </Dropdown.Toggle>
                    </Dropdown>
                  )}
                </Navbar.Collapse>
              </div>
            </div>
          </div>
        </Navbar>

        {/*---------------Location  permission -----*/}
        <Modal
          className="loginModal"
          backdrop="static"
          show={showLocationPermission}
          onHide={handleCencelLocation}
          centered
          size="lg"
        >
          <Modal.Body>
            <div className="locationPermission">
              <div className="LocationDIv">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6 d-flex justify-content-center">
                    <img src={locationMapImg} alt="locationMapImg"></img>
                  </div>
                  <div className="col-md-6  locatioPerrightdiv">
                    <h2>Location permission</h2>
                    <p>
                      Where in the world are you? Let us know, and we'll find
                      the best hotels nearby! Where in the world are you? Let us
                      know, and we'll find the best hotels nearby!
                    </p>
                  </div>
                </div>
              </div>
              <div className="perMissBtn">
                <button
                  type="button"
                  className="CancelBttn"
                  onClick={handleCencelLocation}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="Allowbttn"
                  onClick={() => checkLocationPermissions()}
                >
                  Allow
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <LoginPage show={showLogin} handleClose={handleCloseModal} />
        <div id="login_check_jquery"></div>
        <div id="reload_page_data"></div>
      </div>
      <SideBar
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        handleLogOutClick={() => handleLogOutClick()}
      />

      <Logoutmodal
        showLogOut={showLogOut}
        handleCloseLogOutModal={handleCloseLogOutModal}
        handleCloseLogOutModalYes={handleCloseLogOutModalYes}
      />
    </div>
  );
}
