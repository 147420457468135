import React, { useEffect, useState } from "react";
import "./Css/Career.css";
import Discount from "./Discount";
import Footer from "./Footer";
import Header from "./Header";
import {
  server_post_data,
  career_website_react,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import { useNavigate } from "react-router-dom";
const Career = () => {
  const navigate = useNavigate();
  const [career11, settestinomial] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(career_website_react, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.carrer_view);
          setSEOloop(final_data.seo_list);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 20;

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pages = [];
    pages.push(
      <span
        key="prev"
        className="pagination-arrow"
        onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
      >
        ‹
      </span>
    );
    pages.push(
      <span
        key={1}
        className={`page-number ${currentPage === 1 ? "active" : ""}`}
        onClick={() => handlePageClick(1)}
      >
        1
      </span>
    );
    if (currentPage > 4) {
      pages.push(
        <span key="ellipsis-start" className="ellipsis">
          ...
        </span>
      );
    }
    if (currentPage > 2 && currentPage < totalPages - 1) {
      if (currentPage - 1 > 1) {
        pages.push(
          <span
            key={currentPage - 1}
            className="page-number"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            {currentPage - 1}
          </span>
        );
      }
      pages.push(
        <span key={currentPage} className="page-number active">
          {currentPage}
        </span>
      );
      if (currentPage + 1 < totalPages) {
        pages.push(
          <span
            key={currentPage + 1}
            className="page-number"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            {currentPage + 1}
          </span>
        );
      }
    }
    if (currentPage < totalPages - 3) {
      pages.push(
        <span key="ellipsis-end" className="ellipsis">
          ...
        </span>
      );
    }
    pages.push(
      <span
        key={totalPages}
        className={`page-number ${currentPage === totalPages ? "active" : ""}`}
        onClick={() => handlePageClick(totalPages)}
      >
        {totalPages}
      </span>
    );
    pages.push(
      <span
        key="next"
        className="pagination-arrow"
        onClick={() =>
          currentPage < totalPages && handlePageClick(currentPage + 1)
        }
      >
        ›
      </span>
    );
    return pages;
  };

  const HandleNavigate = (dataclick) => {
    handleLinkClick("/" + dataclick);
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "career_details/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  return (
    <div className="career-page">
      <Header />
      {/* Join our team section */}
      <section className="join-team">
        <h1>Join our team</h1>
        <p>
          Join our team and help us shape the future of innovation and growth
        </p>
      </section>

      <div className="container-lg">
        <div className="main-Career">
          {/* About us section */}
          <section className="about-us">
            <h2>About us</h2>
            <h3>
              Why work<span> with us?</span>
            </h3>
            <p>
              At SwagStay, we believe that our people are our greatest asset. We
              foster an environment where creativity, passion, and excellence
              thrive. By joining our team, you become part of a culture that
              values growth, innovation, and collaboration. Together, we drive
              success and make a real impact in our industry.
            </p>
          </section>

          {/* Job listings section */}
          <section className="job-listings">
            <h4>Current position</h4>
            <p className="toptxt">
              Be a part of a dynamic, innovative, and collaborative team
            </p>
            {career11.map((job, index) => (
              <div className="job-card" key={index}>
                <div className="job-details">
                  <h3>{job.designation}</h3>
                  <div className="job-info d-md-flex d-none">
                    <p className="jobtxt">
                      Exp:
                      <span>
                        {" "}
                        {job.min_experience}-{job.max_experience} year
                      </span>
                    </p>
                    <p className="jobtxt">
                      Location:<span>&nbsp; Nagpur</span>
                    </p>
                    <p className="jobtxt">
                      Education:<span> {job.qualification}</span>
                    </p>
                    <p className="jobtxt">
                      Package:<span> {job.salary_details}</span>
                    </p>
                  </div>
                  <div className="row m-0 mt-4 d-md-none">
                    <div className="col-6">
                      <p className="jobtxt">
                        Exp:
                        <span>
                          {" "}
                          {job.min_experience}-{job.max_experience} year
                        </span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="jobtxt">
                        Location:<span>&nbsp; Nagpur</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="jobtxt">
                        Education:<span> {job.qualification}</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="jobtxt">
                        Package:<span> {job.salary_details}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="apply-button"
                  onClick={() =>
                    HandleNavigate(match_and_return_seo_link(job.v_id))
                  }
                >
                  Apply Now
                </button>
              </div>
            ))}

            <div className="pagination">{renderPagination()}</div>
          </section>
        </div>
      </div>

      {/*----------Discount  Section--------*/}
      <div className=" d-none d-lg-block">
        <Discount />
      </div>
      <Footer />
    </div>
  );
};

export default Career;
