import React from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import "./Css/SucessPayment.css";
import "./Css/FailledPayment.css";
import whiteCross from "../Assets/whiteCross.svg";
import blackCross from "../Assets/blackCross.svg";
import cuttedLineHorizontle from "../Assets/cuttedLineHorizontle.svg";
import try_againImg from "../Assets/try_againImg.svg";

export default function FailledPayment() {
  const navigate = useNavigate();
  const handleSucessPaymentModalCLose = () => {
    navigate("/swag_stay_wallet");
    window.location.reload();
  };
  return (
    <>
      <Header />
      <div className="pymentCOntnr">
        <div className="success-modal">
          <button
            className="success-modal__close"
            onClick={handleSucessPaymentModalCLose}
          >
            <img src={blackCross} alt="blackCross"></img>
          </button>
          <div className="success-modal__body">
            <div className="topSUcessData">
              {" "}
              <div className="SuccesssImg   Faild">
                <img src={whiteCross} alt="mdi_tick"></img>
              </div>
              <h2 className="success-modal__title">Payment failed!</h2>
              <p className="FaildTextt_P">your payment has failed </p>
              <span className="success-modal__amount FaildTextt">
                ₹1440 !!
              </span>{" "}
              <p className="success-modal__reference">
                Reference ID : 2374YFHTUR94IR
              </p>
              <div className="cirleLeftpaymnt"></div>
              <div className="cirleRIghtpaymnt"></div>
            </div>
            <img src={cuttedLineHorizontle} alt="cuttedLineHorizontle"></img>
            <div className="botttmFaildData">
              <img src={try_againImg} alt="try_againImg"></img>
              <button type="button">Try Again</button>

              <div className="circlesCutted">
                <div className="cutCir1"></div>
                <div className="cutCir2"></div>
                <div className="cutCir3"></div>
                <div className="cutCir4"></div>
                <div className="cutCir5"></div>
                <div className="cutCir6"></div>
                <div className="cutCir7"></div>
                <div className="cutCir8"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
