import React, { useEffect, useState } from "react";
import {
  booking_all,
  show_hide_booking_reactnative,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";
import BookingCard from "./BookingCard";
import "./Css/Popup.css"; // Include the CSS for the popup
import Shimmer from "./Shimmer.js";
import NodataFound from "../Assets/NodataFound.png";
function Completed() {
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [showbooking_id, setshowbooking_id] = useState(0);
  const [ImageLink, setImageLink] = useState("");
  const [cancelledData, setcancelledData] = useState([]);
  const [rupeyIcon, setRupeyIcon] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const handleOpenPopup = (id) => {
    setshowbooking_id(id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirm = async () => {
    // Handle the confirm action

    setShowLoaderAdmin(true);
    // setIsModalVisible(false);
    let fd = new FormData();
    fd.append("booking_id", showbooking_id);
    await server_post_data(show_hide_booking_reactnative, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          setShowPopup(false);
          handleSuccess(data[1]);
          master_data_get();
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });

    alert("Booking hidden!"); // Example action after confirming
  };

  useEffect(() => {
    master_data_get();
  }, []);
  console.log(showbooking_id);
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "2");
    await server_post_data(booking_all, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcancelledData(final_data.completed_list);
          setImageLink(final_data.hotel_image_link);
          setRupeyIcon(final_data.rupees_icon);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  return (
    <div>
      {showLoaderAdmin && <Shimmer />}

      {cancelledData && cancelledData.length > 0 ? (
        cancelledData.map((item, index) => (
          <BookingCard
            key={index}
            buttonIcon={
              <img
                src={require("../Assets/HideIcon.png")}
                alt="Upcoming Icon"
                className="icon"
              />
            }
            onButtonClick={() => handleOpenPopup(item.booking_id)} // Trigger popup on button click
            datass={item}
            image={ImageLink}
            rupey={rupeyIcon}
            route={"bookings_details"}
          />
        ))
      ) : (
        <div className="nobookingDiv">
          <img
            src={NodataFound}
            alt="No booking data available"
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}

      {/* Popup modal */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <img
              src={require("../Assets/wallet.png")}
              alt="Hide Icon"
              className="popup-icon"
            />
            <h4 className="popheading">
              Are you sure you want to hide this booking?
            </h4>
            <p>Remember, this action cannot be undone.</p>
            <div className="popup-buttons">
              <button className="popup-button confirm" onClick={handleConfirm}>
                Yes
              </button>
              <button
                className="popup-button cancel"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Completed;
