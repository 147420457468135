import { useEffect, useState } from "react";
import "./Css/CoprativeSolution.css";
import "./Css/ListYourHotel.css";
import Header from "./Header";

import AnnualShare from "../Assets/AnnualShare.svg";
import cencelPoll from "../Assets/cencelPoll.svg";
import Clouds from "../Assets/cloudsBtm.png";
import compreSiveDash from "../Assets/compreSiveDash.svg";
import leftImgFormCop from "../Assets/corporatePeople.png";
import gstIcon from "../Assets/gstIcon.svg";
import hightquality from "../Assets/hightquality.svg";
import keyAccManager from "../Assets/keyAccManager.svg";
import peopleBussniss from "../Assets/peoples.png";
import supperHeadPhoneIcon from "../Assets/supperHeadPhoneIcon.svg";
import vastNetwork from "../Assets/vastNetwork.svg";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";
import {
  get_website_faq,
  save_business_url,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import BookingLoader from "./BookingLoader.js";
import Footer from "./Footer";
import Faq from "./Reapeting Componets/Faq";
import SuccessFullModal from "./SuccessFullModal";
import Testimonial from "./Testimonial";
import HeroBtmListData from "./HeroBtmListData";
import { Link } from "react-router-dom";
let click_time = 0;
export default function CoprativeSolution() {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);

  const [testinomial, settestinomial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [testimonial_image_link, settestimonial_image_link] = useState("");

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  const increaseVisiData = [
    {
      imgSrc: gstIcon,
      title: "GST Invoice",
      description:
        "Our service ensures GST-compliant invoices for all corporate bookings, simplifying tax credit claims and financial reconciliation.",
    },
    {
      imgSrc: keyAccManager,
      title: "Key account manager",
      description:
        "We assign a dedicated account manager to oversee your travel arrangements, providing a personalized and seamless experience.",
    },
    {
      imgSrc: AnnualShare,
      title: "Annually saving",
      description:
        "Leverage our exclusive corporate rates to reduce your company’s travel spend by up to 24% annually.",
    },
    {
      imgSrc: supperHeadPhoneIcon,
      title: "Priority helpline",
      description:
        "Our 24/7 priority helpline is available to address any urgent booking requests or changes, ensuring uninterrupted service.",
    },
    {
      imgSrc: hightquality,
      title: "High quality hotles",
      description:
        "We offer access to a curated selection of premium hotels, ensuring only high standards of comfort and quality for your team.",
    },
    {
      imgSrc: vastNetwork,
      title: "Vast network",
      description:
        "Our vast network covers hotels, resorts, and event spaces like banquet and conference halls, designed to meet any corporate requirement.",
    },
    {
      imgSrc: cencelPoll,
      title: "Cancellation policy",
      description:
        "Our flexible cancellation policy provides peace of mind with easy changes or cancellations at no additional cost.",
    },
    {
      imgSrc: compreSiveDash,
      title: "Comprehensive dashboard",
      description:
        "Gain full control over your corporate travel expenses and bookings with our integrated, easy-to-use management dashboard.",
    },
  ];
  const steps = [
    {
      number: "01",
      title: "Sign-up process",
      description: "Create an account and fill in basic details.",
    },

    {
      number: "02",
      title: "Upload your requirement",
      description: "Explore hotel options based on location and budget.",
    },
    {
      number: "03",
      title: "Book effortlessly",
      description:
        "Manage bookings and invoicing from your corporate dashboards.",
    },
  ];
  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log("Response", Response);
          let data = Response.split("~@~");
          console.log("data", data);
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            // let final_data = JSON.parse(data[1]);
            click_time = 0;
            handleShowSucessModal();
            empty_form(form_data);
            setTimeout(() => {
              handleLinkClick("/");
            }, 2000);
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };
  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 250,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="contList">
        {" "}
        <div className="ListYourHotelCont  heroCoprateSolution">
          <div className="heroUnderListHead">
            {/* <Header /> */}
            <div className="HeroLIstUnderHeading  heaCop ">
              <div className="TailHead">
                <h1>
                  Tailored{" "}
                  <span style={{ color: "var(--primary-color)" }}>
                    hotel solutions
                  </span>{" "}
                  for corporate needs
                </h1>
              </div>

              <label>
                Book accommodations for corporate training, events, and employee
                stays with exclusive benefits and customized packages
              </label>

              <button
                type="button"
                style={{ fontSize: "16px" }}
                onClick={() => handleActive("formCOntainerCreate")}
              >
                Explore Corporate Plans
              </button>
            </div>

            {/*--------hero bottm content Section -------*/}
            <HeroBtmListData />
          </div>
        </div>{" "}
        <div className="container-lg">
          <div className="QuicklyHotl copSolHead">
            {" "}
            <p>Why choose us?</p>
            <div className="quickHotlsubhading   CopHotlSubHead ">
              {" "}
              <h2>
                Corporate <span>stays simplified</span>
              </h2>
            </div>
          </div>

          <div className="increaseVisiImgCOnt container-lg ContnrCoprateStay">
            <div className="row">
              {increaseVisiData.map((item, index) => (
                <div className="col-lg-4 col-sm-6 " key={index}>
                  <div className="dataCopreStay">
                    <div className="CopratImgDIv">
                      <img
                        src={item.imgSrc}
                        alt={item.title}
                        className="img-fluid"
                      />
                    </div>
                    <div className="d-flex align-items-center p-0 arrowImgOrenge titleCopretestay">
                      <h2 className="text-center">{item.title}</h2>
                    </div>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/**----------How it work for hotel ------*/}
        </div>
        <div className="howItworkDivHotel">
          <div className="container-lg">
            <div className="QuicklyHotl copSolHead">
              {" "}
              <p>How it works</p>
              <div className="quickHotlsubhading">
                {" "}
                <h2>
                  {" "}
                  Step-by-step <span> process</span>
                </h2>
              </div>
            </div>

            <div className="howItWorksHotel__steps mb-0">
              <div className="row">
                {steps.map((step, index) => (
                  <div key={index} className="col-12 col-sm-6 col-lg-4 mb-5">
                    <div className="coprtHowitwork stepbyStep">
                      <h1>{step.number}</h1>
                      <div className="horIzontlist"></div>
                      <h3>{step.title}</h3>
                      <p>{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <img src={Clouds} alt="Swagstay" className="cloudesImg" />
        </div>
        {/**------------- Ready To grow your Bussiness-------*/}
        <div className="ReadyBussiNessDiv">
          <div className="container-lg">
            <div className="row ReadyDivContent">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="leftReadyDiv">
                  <div className="readyHead">
                    <h2 className="santosiFOntCLass">
                      Ready to streamline your corporate stays?
                    </h2>
                    <p>
                      Join hundreds of businesses that rely on us for
                      hassle-free corporate hotel solutions
                    </p>
                  </div>

                  <Link to="/help">
                    <button
                      className="primaryBtn"
                      style={{ width: "fit-content" }}
                    >
                      Contact Us for a Corporate Plan
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 RIghtReadyDiv text-center mt-4 mt-md-0 bussinessRight p-0">
                <img src={peopleBussniss} alt="property"></img>
              </div>
            </div>
          </div>
        </div>
        {/*------Testimonial--- */}
        <Testimonial
          testinomial={testinomial}
          testimonial_image_link={testimonial_image_link}
        />
        {/*--------Ready to boost-----------*/}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p>Unlock exclusive corporate perks</p>
            <div className="quickHotlsubhading CopHotlSubHead">
              {" "}
              <h2>
                Corporate <span>partnership</span>
              </h2>
            </div>
          </div>

          <div className="row listPropFOrm">
            {/* Left Column - Image and Heading */}
            <div className="col-md-4 col-12 mb-3 mb-md-0  FormLftdata  ">
              <img
                src={leftImgFormCop}
                alt="Property Image"
                className="img-fluid"
              />
            </div>

            {/* Right Column - Form */}
            <div className="col-md-6 col-12" id="formCOntainerCreate">
              <form className="rightFOrm" id="listmyhotel">
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="fullName">Full name</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="fullName"
                        name="fullName"
                        placeholder="Name"
                        onChange={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputContainer">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control trio_mandatory"
                        id="email"
                        name="email"
                        placeholder="abc@xyz.com"
                        onChange={handleEmailChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="contactNumber">Contact number</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="contactNumber"
                        name="contactNumber"
                        maxLength={10}
                        placeholder="+91-XXXXXXXXXX"
                        onChange={handleNumbersChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputContainer">
                      <label htmlFor="cityName">Company name</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="cityName"
                        name="companyName"
                        placeholder="Company name"
                        onChange={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="inputContainer">
                    <label htmlFor="fullName">City</label>
                    <input
                      type="text"
                      className="form-control trio_mandatory"
                      id="fullName"
                      placeholder="City Name"
                      name="city"
                      onChange={handleAphabetsChange}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="propertyName">
                    Brief description of your requirement
                  </label>
                  <textarea
                    type="text"
                    className="form-control trio_mandatory"
                    id="propertyName"
                    name="requirementDescription"
                    placeholder="Type here"
                  />
                  <span className="condition_error"></span>
                </div>
                <button
                  className="suFOrm fontSizeSUbdiv"
                  type="button"
                  onClick={() => {
                    handleSaveChangesdynamic("listmyhotel", save_business_url);
                  }}
                  // onClick={handleShowSucessModal}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>{" "}
        {/*------Testimonial--- */}
        <div className="container-lg">
          <Faq faq={faq} />
        </div>
        <Footer />
      </div>

      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
