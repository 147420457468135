import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import filterIcon from "../Assets/filterICon.svg";
import "./Css/Filter.css";

const Filter = ({
  onApplyFilters,
  priceInRangemin,
  priceInRangemax,
  Amenities,
  matchesLocality,
  FilterLandmark,
}) => {
  const DEFAULT_MIN_PRICE = priceInRangemin;
  const DEFAULT_MAX_PRICE = priceInRangemax;
  const MIN_LIMIT = 100;
  const MAX_LIMIT = 5000;

  const [minPrice, setMinPrice] = useState(DEFAULT_MIN_PRICE);
  const [maxPrice, setMaxPrice] = useState(DEFAULT_MAX_PRICE);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [searchText, setSearchText] = useState(matchesLocality);

  const handleSliderChange = ([min, max]) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const handleMinInputChange = (e) => {
    const value = parseInt(e.target.value) || MIN_LIMIT;
    const validValue = Math.max(MIN_LIMIT, Math.min(value, maxPrice));
    setMinPrice(validValue);
  };

  const handleMaxInputChange = (e) => {
    const value = parseInt(e.target.value) || MAX_LIMIT;
    const validValue = Math.max(minPrice, Math.min(value, MAX_LIMIT));
    setMaxPrice(validValue);
  };
  const handleClear = () => {
    setMinPrice(DEFAULT_MIN_PRICE);
    setMaxPrice(DEFAULT_MAX_PRICE);
  };
  const toggleAmenity = (amenity) => {
    setSelectedAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((a) => a !== amenity)
        : [...prev, amenity]
    );
  };

  const handleChipClick = (text) => {
    setSearchText(text);
  };

  const handleApplyFilters = () => {
    const formattedAmenities = selectedAmenities.join("~@~");
    // Construct filter object
    const filterData = {
      priceRange: {
        min: minPrice,
        max: maxPrice,
      },
      amenities: formattedAmenities,
      locality: searchText,
    };
    // Pass filter data to parent component
    onApplyFilters(filterData);
  };

  return (
    <div className="filter-container">
      <div className="filter-header">
        <h2 className="heading">Filter</h2>
        <img src={filterIcon} alt="Filter Icon" className="filter-icon" />
      </div>

      <div className="filter-section">
        <label className="filter-label">Popular localities</label>
        <input
          className="filter-input"
          placeholder="e.g. Scheme number 54"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="chip-container">
          {FilterLandmark &&
            FilterLandmark.length > 0 &&
            FilterLandmark.map((loc, index) => (
              <div
                key={index}
                className="chip"
                onClick={() => handleChipClick(loc.landmark_name)}
              >
                {loc.landmark_name}
              </div>
            ))}
        </div>
        <hr className="divider-line" />
      </div>

      <div className="filter-section">
        <div className="pricing-header">
          <label className="filter-label">Pricing</label>
          <div className="clear-button" onClick={handleClear}>
            Clear
          </div>
        </div>

        <ReactSlider
          className="slider"
          thumbClassName="thumb"
          trackClassName="track"
          key={0}
          min={MIN_LIMIT}
          max={MAX_LIMIT}
          value={[minPrice, maxPrice]}
          onChange={handleSliderChange}
          renderThumb={(props, state) => (
            <div {...props} className="thumb">
              <div className="thumb-label">{state.valueNow}</div>
            </div>
          )}
          renderTrack={(props, state) => (
            <div
              {...props}
              key={state.index}
              className={
                state.index === 1
                  ? "track track-filled"
                  : "track track-unfilled"
              }
            />
          )}
        />

        <div className="price-labels">
          <div>
            <span>Minimum Amount</span>
            <input
              type="number"
              className="price-input"
              defaultValue={minPrice}
              onChange={handleMinInputChange}
              min={MIN_LIMIT}
              max={MAX_LIMIT}
            />
          </div>
          <div>
            <span>Maximum Amount</span>
            <input
              type="number"
              className="price-input"
              defaultValue={maxPrice}
              onChange={handleMaxInputChange}
              min={MIN_LIMIT}
              max={MAX_LIMIT}
            />
          </div>
        </div>
      </div>

      <hr className="divider-line" />
      <div className="filter-section">
        <label className="filter-label">Amenities</label>
        <div className="chip-container">
          {[
            "Free Wifi",
            "Restaurant",
            "Parking",
            "Room Service",
            "24X7 Front Desk",
            "Balcony",
          ].map((amenity, index) => (
            <div
              key={index}
              className={`chip ${
                selectedAmenities.includes(amenity) ? "selected" : ""
              }`}
              onClick={() => toggleAmenity(amenity)}
            >
              {amenity}
            </div>
          ))}
        </div>
      </div>
      <button
        className="btn btn-dark filter_button"
        onClick={() => handleApplyFilters()}
      >
        Filter Data
      </button>
      {/* <button className="filter_button">Filter Data</button> */}
    </div>
  );
};

export default Filter;
