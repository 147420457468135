import React, { useState, useRef, useEffect } from "react";
import "./Css/CreateBooking.css";
import Header from "./Header";
import RightArrow from "../Assets/rightAngle.png";
import CopyIcon from "../Assets/copyIcon.png";
import Download from "../Assets/DownloadICon.png";
import Share from "../Assets/ShareIcon.png";
import Location from "../Assets/locationIcon.png";
import CancelRound from "../Assets/cancelRound.png";
import Shield from "../Assets/sheild.png";
import CallImg from "../Assets/CallImg.png";
import CheckIcon from "../Assets/Checkbox1.png";
import { Modal } from "react-bootstrap";
import BackBar from "./Reapeting Componets/BackBar";
import { useLocation } from "react-router-dom";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import {
  server_post_data,
  booking_details_all,
  hotel_details,
} from "../ServiceConnection/serviceconnection";

export default function NoSHowBooking() {
  const location = useLocation();
  const { isRefundStatus } = location.state || {};
  console.log(isRefundStatus);
  const [swagHelpModal, setSwagHelpModal] = useState(false);
  const [bookingdetails, setbookingdetails] = useState([]);
  const [hoteldetails, sethoteldetails] = useState([]);
  const [websitedetails, setwebsitedetails] = useState([]);
  const [data, setData] = useState(location.state?.data);
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const copyCode = () => {
    const code = "SWAG094847NR";
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  console.log("cancelData :", data);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("HotelID", data.HotelID);
    fd.append("hotel_id", data.hotel_id);
    fd.append("book_id", data.book_id);
    fd.append("booking_id", data.booking_id);
    await server_post_data(booking_details_all, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        // console.log(data1)
        if (parseInt(data1[0]) === 1) {
          alert(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          if (final_data.booking_details.length > 0) {
            setbookingdetails(final_data.booking_details[0]);
          }
          sethoteldetails(final_data.hotel_details[0]);
          setwebsitedetails(final_data.website_content);
          setisSharePopupContent(final_data.share_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
        <BackBar label={"Cancelled Booking"} />
      </div>
      <div className="container-lg">
        <div className="createBookingContainer">
          {/* Booking Details COntianer */}
          <section className="bookDetails">
            {/* Detaisl Container */}
            <div className="bookDetailsContainer bookingCancelCon mb-4">
              <div className="booldetailTop">
                <div className="left_detail">
                  <div className="bookingId">
                    <p>
                      Booking id: <span>{bookingdetails.booking_id}</span>
                    </p>
                    <img onClick={copyCode} src={CopyIcon} />
                  </div>
                </div>
                <div className="right_detail">
                  <div className="bookingId">
                    <p>
                      Status:{" "}
                      <span>
                        {isRefundStatus
                          ? bookingdetails.refund_status
                          : bookingdetails.checkin_status}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="bookDetailsBottom">
                <div className="leftSection">
                  <div className="checkin-out-container">
                    <div className="check-section">
                      <p className="label">Check in</p>
                      <p className="date">
                        {bookingdetails.check_in_date
                          ? bookingdetails.check_in_date.split(",")[0]
                          : ""}
                      </p>
                      <p className="time">
                        {bookingdetails.check_in_date
                          ? bookingdetails.check_in_date.split(",")[1].trim()
                          : ""}
                      </p>
                    </div>

                    <div className="duration">
                      <span className="nights">1 N</span>
                    </div>

                    <div className="check-section">
                      <p className="label">Check out</p>
                      <p className="date">
                        {bookingdetails.check_out_date
                          ? bookingdetails.check_out_date.split(",")[0]
                          : ""}
                      </p>
                      <p className="time">
                        {bookingdetails.check_out_date
                          ? bookingdetails.check_out_date.split(",")[1].trim()
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="saperatorVertical"></div>
                  <div className="hotelText">
                    <p className="cardHeading mb-2">
                      {hoteldetails.hotel_name}
                    </p>
                    <p className="cardText mb-1">{hoteldetails.address}</p>
                    <div className="discount_price">
                      <button
                        className="reciptBtns"
                        onClick={() => {
                          
                          setSharePopupOpen(true);
                        }}
                      >
                        <img src={Share} alt="Swagstay" />
                      </button>
                      <button
                        className="reciptBtns"
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&destination=${hoteldetails.latitude},${hoteldetails.longitude}`,
                            "_blank"
                          )
                        }
                      >
                        <img src={Location} alt="Swagstay" />
                      </button>
                      <button className="reciptBtns">
                        <img src={Download} alt="Swagstay" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reciptBookingTxt">
                <p>
                  {bookingdetails.per_name}, you haven't arrived at the hotel.{" "}
                </p>
              </div>
            </div>
          </section>

          {/* Left Section Items  */}
          <div className="row m-0">
            <section className="col-md-6 paddinLeftAfter768">
              {/* Refund Process Section */}
              <div className="earlyLateColumn pb-0 mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    What is no show
                  </h5>
                  <div className="billDetails">
                    <ul>
                      <li>
                        A no-show occurs when a guest does not arrive at the
                        hotel on the scheduled check-in date and does not cancel
                        the reservation in advance.
                      </li>
                      <li>
                        To avoid no-show, please ensure you cancel your
                        reservation within the specified cancellation period.
                        You can cancel your reservation through our app,
                        website, or by contacting the hotel directly.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="col-md-6 paddinRightAfter768">
              {/* Need Help Section */}
              <div className="earlyLateColumn pb-0 mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Need Help
                  </h5>
                  <div className="billDetails">
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setSwagHelpModal(true)}
                    >
                      <p className="billLabl">Call SwagStay customer care</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator mb-0"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* Policies Section */}
          <div style={{ paddingBottom: "2rem" }}>
            <button className="policyBtn reciptBtns">
              <img src={Shield} alt="Swagstay" />
              <p>Hotel rules & policy</p>
            </button>
            <button className="policyBtn reciptBtns">
              <img src={CancelRound} alt="Swagstay" />
              <p>Cancellation policy</p>
            </button>
          </div>
        </div>
        <SharePopup
          isOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>

      {/* Modals */}

      {/* Swagstay HelpLine Modal */}
      <Modal
        show={swagHelpModal}
        onHide={() => setSwagHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Swagstay helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{websitedetails.customer_care}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
