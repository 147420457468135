import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import downIcon from "../Assets/downIcon.svg";
import subRequ from "../Assets/subRequ.svg";
import "./Css/BookingRequest.css";
import BookingLoader from "./BookingLoader.js";
import Header from "./Header";
import {
  server_post_data,
  save_booking_request,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery.js";

export default function BookingRequest() {
  const ToDateddd = new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // To control visibility of the calendar
  const [isCheckInMode, setIsCheckInMode] = useState(true);
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(ToDateddd);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    city: "",
    checkInDate: "",
    checkOutDate: "",
    adults: 1,
    children: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setIsSubmitted(true);
    handleSaveChangesdynamic();
  };

  const [childrenCount, setChildrenCount] = useState(0);
  const [AdultCount, setAdultCount] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleIncrement = () => {
    if (childrenCount < 3) {
      setChildrenCount(childrenCount + 1);
      setFormData((prevFormData) => ({
        ...prevFormData,
        children: childrenCount + 1, // Update children count in formData
      }));
    }
  };

  const handleIncrementAdult = () => {
    if (AdultCount < 3) {
      setAdultCount(AdultCount + 1);
      setFormData((prevFormData) => ({
        ...prevFormData,
        adults: AdultCount + 1, // Update adult count in formData
      }));
    }
  };

  const handleDecrement = () => {
    if (childrenCount > 0) {
      setChildrenCount(childrenCount - 1);
      setFormData((prevFormData) => ({
        ...prevFormData,
        children: childrenCount - 1, // Update children count in formData
      }));
    }
  };

  // const handleDecrementadult = () => {
  //   if (AdultCount > 1) {
  //     setAdultCount(AdultCount - 1);
  //   }
  // };

  const handleDecrementadult = () => {
    if (AdultCount > 1) {
      setAdultCount(AdultCount - 1);
      setFormData((prevFormData) => ({
        ...prevFormData,
        adults: AdultCount - 1, // Update adult count in formData
      }));
    }
  };
  const handleCheckInClick = () => {
    setIsCheckInMode(true);

    setIsCalendarOpen(true);
  };

  const handleCheckOutClick = () => {
    if (checkInDate) {
      // Ensure check-in is selected before opening check-out
      setIsCheckInMode(false);

      setIsCalendarOpen(true);
    }
  };

  const handleCheckInDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setCheckInDate(date);
    setCheckOutDate(null); // Reset check-out when check-in changes
    setFormData((prevFormData) => ({
      ...prevFormData,
      checkInDate: formattedDate, // Save check-in date in formData
      checkOutDate: "", // Reset check-out date in formData when check-in changes
    }));
    setIsCheckInMode(false); // Switch to check-out selection
  };

  const handleCheckOutDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setCheckOutDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      checkOutDate: formattedDate, // Save check-out date in formData
    }));
    setIsCalendarOpen(false); // Close the calendar after selecting check-out
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoaderAdmin(true);

    const form_data = new FormData();
    form_data.append("booking_name", formData.fullName);
    form_data.append("booking_email", formData.email);
    form_data.append("booking_city", formData.city);
    form_data.append("booking_checkin", formData.checkInDate);
    form_data.append("booking_checkout", formData.checkOutDate);
    form_data.append("total_adult", formData.adults);
    form_data.append("total_child", formData.children);

    await server_post_data(save_booking_request, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          setTimeout(() => {
            setshowLoaderAdmin(false);
          }, 5000);
          // Delay setting `setIsSubmitted` for 5 seconds
          handleSuccess(data[1]);
          setIsSubmitted(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="container-lg">
        {/**--------Booking Requet Hero Section------------- */}
        <div className="bookingReqHero">
          <div className="col-12 col-lg-9 col-md-12">
            <h1>Booking request</h1>
            <p>
              Can't find a hotel in the city you're traveling to? No worries!
              Send us a booking request, and we'll arrange a stay for you in
              cities where our services aren't available yet.
            </p>
          </div>
        </div>

        {/**--------Booking Requet Form Section------------- */}

        {!isSubmitted ? (
          <div className="FormDiv">
            <div className="headBookinReq">
              <h1>Booking request</h1>

              <form>
                <div className="col-12 col-md-9">
                  <div className="form-row">
                    {/* Row for Full Name and Email */}
                    <div className="row gpCLass">
                      <div className="form-group col-12 col-md-6">
                        <label htmlFor="fullName">
                          Full name<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          name="fullName"
                          placeholder="Full name"
                          value={formData.fullName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label htmlFor="email">
                          Email<span>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    {/* Row for City and Check-in/Check-out Date */}
                    <div className="row gpCLass">
                      <div className="form-group col-12 col-md-6">
                        <label htmlFor="city">
                          Select city<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          placeholder="City"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          Select check-in/out date<span>*</span>
                        </label>

                        <div className="dateSHowDrps">
                          <div className="date-section gridNone">
                            <div
                              className="checkInData"
                              onClick={handleCheckInClick}
                            >
                              <p>Check-in date</p>
                              <p className="dateTxt">
                                {checkInDate
                                  ? checkInDate.toLocaleDateString()
                                  : "Select date"}
                              </p>
                              <img src={downIcon} alt="downIcon"></img>
                            </div>
                            <div
                              className="checkOutData"
                              onClick={handleCheckOutClick}
                            >
                              <p>Check-out date</p>
                              <p className="dateTxt">
                                {checkOutDate
                                  ? checkOutDate.toLocaleDateString()
                                  : "Select date"}
                              </p>
                              <img src={downIcon} alt="downIcon"></img>
                            </div>
                            {isCalendarOpen && (
                              <div className="checkInCalanderHotelDEtail">
                                <DatePicker
                                  selected={
                                    isCheckInMode ? checkInDate : checkOutDate
                                  }
                                  onChange={
                                    isCheckInMode
                                      ? handleCheckInDateChange
                                      : handleCheckOutDateChange
                                  }
                                  className="form-control border-0 p-0 text-muted"
                                  placeholderText={
                                    isCheckInMode
                                      ? "Select check-in date"
                                      : "Select check-out date"
                                  }
                                  dateFormat="dd MMM yyyy"
                                  inline
                                  selectsStart={isCheckInMode}
                                  selectsEnd={!isCheckInMode}
                                  startDate={checkInDate}
                                  endDate={checkOutDate}
                                  minDate={
                                    isCheckInMode ? new Date() : checkInDate
                                  } // For check-out, start from check-in date
                                  monthsShown={2} // Show two months side-by-side
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Row for Guest Selection */}
                    <div className="row gpCLass">
                      <div className="form-group col-12 col-md-6">
                        <label>Select Guest</label>
                        <div className="containerAdultDIv">
                          <div className="adultsDIv">
                            <div className="leftDiv">
                              <label>Adults</label>
                            </div>
                            <div className="incmtnDecrement">
                              <button
                                type="button"
                                onClick={handleDecrementadult}
                                style={{
                                  opacity: AdultCount === 1 ? 0.5 : 1,
                                  pointerEvents:
                                    AdultCount === 1 ? "none" : "auto",
                                }}
                              >
                                -
                              </button>
                              <label>{AdultCount}</label>
                              <button
                                type="button"
                                onClick={handleIncrementAdult}
                                style={{
                                  opacity: AdultCount === 3 ? 0.5 : 1,
                                  pointerEvents:
                                    AdultCount === 3 ? "none" : "auto",
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="adultsDIv">
                            <div className="leftDiv">
                              <label> Children</label>
                            </div>
                            <div className="incmtnDecrement">
                              <button
                                type="button"
                                onClick={handleDecrement}
                                style={{
                                  opacity: childrenCount === 0 ? 0.5 : 1,
                                  pointerEvents:
                                    childrenCount === 0 ? "none" : "auto",
                                }}
                              >
                                {" "}
                                -
                              </button>
                              <label>{childrenCount}</label>
                              <button
                                type="button"
                                onClick={handleIncrement}
                                style={{
                                  opacity: childrenCount === 3 ? 0.5 : 1,
                                  pointerEvents:
                                    childrenCount === 3 ? "none" : "auto",
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Submit Button */}

                    <div className="form-group col-lg-6 col-12 text-center mt-3">
                      <button
                        type="submit"
                        className="SUbmnttttn"
                        onClick={handleSubmit}
                      >
                        Submit Request
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="afterBookingCOnfirmDiv">
            <div className="SubmitRequ">
              {" "}
              <img className="imgCicle" src={subRequ} alt="subRequ"></img>
              <div className="col-lg-7 col-12 ">
                <h1>
                  Your request has been submitted successfully. Our team will
                  contact you soon!
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
