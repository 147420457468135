import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/WelcomeBonus.css"; // We'll use this for custom styles

import QuickHotel from "./QuickHotel";
import Discount from "./Discount";
import Footer from "./Footer";
import SearchData from "./SearchData";
import CelebrateBoxMobile from "../Assets/CelebrateBoxMobile.svg";
import HotelData from "./Reapeting Componets/HotelData";
import {
  server_post_data,
  city_list_website,
  front_city_click,
  get_refer_earn_page,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export default function WelcomeBonus() {
  const [CityList, setCityList] = useState([]);
  const [CityImage, setCityImage] = useState("");
  const [distance_lat, setdistance_lat] = useState("");
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [cityindex, setcityindex] = useState(0);
  const [hotel, sethotel] = useState("");
  const [SEOloop, setSEOloop] = useState([]);
  const [shareablecoin, setshareablecoin] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(city_list_website, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          console.log(data[1]);
        } else {
          const final_data = JSON.parse(data[1]);
          console.log(final_data);
          setCityList(final_data.hotel_citylist);
          setCityImage(final_data.city_image_link);
          setSEOloop(final_data.seo_list);
          setdistance_lat(final_data.distance_lat);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
    // master_data_get_hotel();
    master_data_get_refer_coin();
  }, []);

  useEffect(() => {
    if (CityList.length > 0) {
      master_data_get_hotel(0);
    }
  }, [CityList]);

  console.log(shareablecoin);
  const master_data_get_hotel = async (index) => {
    setIsLoading(true);
    if (CityList) {
      const fd = new FormData();
      fd.append("custom_latitude", CityList[index].city_lat);
      fd.append("custom_longitude", CityList[index].city_long);
      fd.append("city_id", CityList[index].city_long);
      fd.append("distance_call", CityList[index].distance_in_km);
      await server_post_data(front_city_click, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setSilderHotelImage(final_data.hotel_image_link);
            sethotel(final_data.hotel_all);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const master_data_get_refer_coin = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_refer_earn_page, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setshareablecoin(final_data.reffer_coin);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleCityClick = (index) => {
    master_data_get_hotel(index);
    setcityindex(index);
  };

  const HowItwork = [
    {
      title: "Sign up & earn",

      description: `"Create an account, and we'll instantly credit ${shareablecoin} coins to your wallet – no strings attached!"`,
    },
    {
      title: "Book your stay",
      description: `"Choose from our wide range of cozy stays, premium hotels, or vacation homes. Use your ${shareablecoin} coins at checkout for an instant discount on your first booking."`,
    },
    {
      title: "Save & enjoy",
      description:
        "No need to wait at the reception desk – check in quickly and easily.",
    },
  ];
  const WelcomeBonus = [
    {
      title: "Instant savings",
      description: `"Start with ${shareablecoin} coins in your account, ready to use!"`,
    },
    {
      title: "Exclusive  discounts",

      description:
        " Use the coins toward your first booking for a reduced price.",
    },
    {
      title: " Seamless experience",
      description:
        " Sign up and apply your coins at checkout in just a few taps.",
    },
  ];

  return (
    <div>
      <Header />

      {/* Hero Section */}
      <div className="container-lg">
        <div className="row  WelcomeheroSection ">
          <div className="col-md-6 text-md-left text-center">
            <h1 className="display-4 font-weight-bold">Welcome Bonus</h1>

            <p className="WelcomunderText">
              Enjoy <h1>{shareablecoin} Coins</h1>
              <p>on your</p>
            </p>
            <p>First Booking!</p>
          </div>
          <div className="MobileCelebrateImg">
            <img src={CelebrateBoxMobile} alt="CelebrateBoxMobile"></img>
          </div>
        </div>
      </div>

      {/* Search Section */}
      <SearchData />
      {/* Description Section */}

      <div className="container-lg  my-4">
        <div className="getStartText">
          {" "}
          <h2>Get start with {shareablecoin} coins - just for signing up!</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <p>
            New here? We’re excited to welcome you with {shareablecoin} coins
            the moment you sign up! Use them on your first booking and enjoy a
            special discount to make your stay even more affordable.
          </p>
        </div>
      </div>
      {/* Benifits Section */}

      <div className="container-lg my-4">
        <div className="getStartText">
          {" "}
          <h2>Benifits of express check-in</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <ul className="space-y-6 LisData">
            {HowItwork.map((benefit, index) => (
              <li key={index}>
                <div className="text-gray-800">
                  <strong>{benefit.title}: </strong>

                  <span className="text-gray-600">{benefit.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* How  it  Section */}

      <div className="container-lg my-4">
        <div className="getStartText">
          {" "}
          <h2>How it works</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <ul className="space-y-6 LisData">
            {WelcomeBonus.map((howit, index) => (
              <li key={index}>
                <div className="text-gray-800">
                  <strong>{howit.title}: </strong>

                  <span className="text-gray-600">{howit.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Hotel Section */}

      <QuickHotel
        citylist={CityList}
        distance_lat={distance_lat}
        cityimage={CityImage}
        seoData={SEOloop}
        func={handleCityClick}
        isaqi={true}
      />

      {/* HotelData Section */}
      <div className="container-lg">
        <div className="whatExpress">
          <h2>Top Hotels in {CityList?.[cityindex]?.city_name || "Nagpur"} </h2>
        </div>
        <HotelData
          image={SilderHotelImage}
          isaqi={true}
          hotelData={hotel}
          seoData={SEOloop}
        />
      </div>

      {/* Discount Section */}
      <Discount />

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
