import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import accodinationRed from "../Assets/accodinationRed.svg";
import Acodi from "../Assets/Acodi.svg";
import editIcon from "../Assets/editIcon.png";
import indiaIcon from "../Assets/indiaIcon.svg";
import modalCloseIcn from "../Assets/modalCloseIcn.svg";
import unLockBeniIcon from "../Assets/unLockBeniIcon.svg";
import WelcomPageImg from "../Assets/WelcomPageImg.svg";
import {
  cancelChanges,
  handleAphabetsChange,
  handleError,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery";
import { storeData } from "../LocalConnection/LocalConnection";
import {
  Login_url,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import "./Css/LoginPage.css";
let click_one = 0;
export default function LoginPage({ show, handleClose }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isOtpScreenVisible, setIsOtpScreenVisible] = useState(false);
  const [shakeEffect, setShakeEffect] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isCounting, setIsCounting] = useState(false);
  const [shake, setShake] = useState(false);
  const [counter, setCounter] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [welcomePage, setWelcomePage] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [countryCode, setcountryCode] = useState("+91");
  const [UserName, setUserName] = useState("");
  const [UserWalletAmount, setUserWalletAmount] = useState(0);
  const [correctOtp, setcorrectOtp] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handlePhoneNumberChange = (e) => {
    handleNumbersChange(e);
    setPhoneNumber(e.target.value);
    setShakeEffect(false);
    setValidationMessage("");
  };

  const isActive = phoneNumber.length > 0;

  // Handle Send OTP button click
  const handleSendOtp = () => {
    if (phoneNumber.length === 0) {
      setValidationMessage("Please enter your number.");
      setShakeEffect(true);
    } else if (phoneNumber.length !== 10) {
      setValidationMessage("Please enter the full number.");
      setShakeEffect(true);
    } else {
      handleSubmit("1");
    }
  };

  const handleSubmit = async (flag) => {
    if (click_one == 0) {
      click_one = 1;
      setIsLoading(true);

      var formdata = new FormData();
      formdata.append("user_email", email);
      formdata.append("user_name", firstName + " " + lastName);
      formdata.append("referral_id", referralCode);
      formdata.append("zip_code", countryCode);
      formdata.append("mobile_no", phoneNumber);
      formdata.append("waid_data", "");
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("deal_offer", isChecked ? "Yes" : "No");
      formdata.append("flag", flag);
      await server_post_data(Login_url, formdata)
        .then(async (Response) => {
          click_one = 0;
          setIsLoading(false);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let reload_page = 0;
            let final_data = JSON.parse(data[1]);
            console.log(final_data[0].user_otp);
            if (flag == 1) {
              setcorrectOtp(final_data[0].user_otp);
              setIsOtpScreenVisible(true);
              setIsCounting(true);
              setCounter(30);
            } else if (flag == 2) {
              if (final_data[0].full_name < 3) {
                setIsNewUser(true);
              } else {
                reload_page = 1;
              }
            } else if (flag == 3) {
              reload_page = 2;
              setUserWalletAmount(parseInt(final_data[0].wallet_amount));
              setUserName(final_data[0].first_name);
            }
            console.log(final_data[0].cd_id);
            console.log(final_data[0].last_name);
            if (reload_page >= 1) {
              let image_data = data[2];
              storeData("customer_id", final_data[0].cd_id);
              storeData("customer_last_name", final_data[0].last_name);
              storeData("customer_first_name", final_data[0].first_name);
              storeData("customer_full_name", final_data[0].full_name);
              storeData("customer_reffer_code", final_data[0].reffer_code);
              storeData("customer_user_email", final_data[0].user_email);
              storeData(
                "customer_user_moblie_no_show",
                final_data[0].zip_code + "-" + final_data[0].user_moblie_no
              );
              storeData(
                "customer_user_moblie_no",
                final_data[0].user_moblie_no
              );

              if (final_data[0].user_image !== "") {
                storeData(
                  "customer_image",
                  image_data + final_data[0].user_image
                );
              } else {
                storeData("customer_image", "0");
              }
              let time_reload = 100;
              if (
                parseInt(final_data[0].wallet_amount) > 0 &&
                reload_page === 2
              ) {
                setWelcomePage(true);
                time_reload = 3000;
              }

              setTimeout(() => {
                cancelChanges();
              }, time_reload);
            }
          }
        })
        .catch((error) => {
          click_one = 0;
          setIsLoading(false);
          handleError("network");
        });
    }
  };

  const HandleResendOtp = () => {
    // Logic to resend the OTP
    handleSubmit("1");
  };

  useEffect(() => {
    let timer;
    if (isCounting && counter > 0) {
      timer = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000); // Decrease counter every second
    }

    if (counter === 0) {
      setIsCounting(false);
    }

    return () => clearInterval(timer);
  }, [isCounting, counter]);

  ///  otp container

  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleChange = (index, value) => {
    // Only allow numbers
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== "" && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  //otp number auto jump next input

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        inputRefs[index - 1].current.focus();
      } else {
        // Clear current input
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  // otp number past in the input
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 4).split("");
    if (!/^\d*$/.test(pasteData.join(""))) return;

    const newOtp = [...otp];
    pasteData.forEach((value, index) => {
      if (index < 4) {
        newOtp[index] = value;
      }
    });
    setOtp(newOtp);
    const lastFilledIndex = newOtp.findLastIndex((val) => val !== "");
    if (lastFilledIndex < 3) {
      inputRefs[lastFilledIndex + 1].current.focus();
    }
  };

  const handleVerification = () => {
    console.log(otp);
    if (otp.every((digit) => digit !== "")) {
      const enteredOtp = otp.join("");
      if (enteredOtp === correctOtp) {
        handleSubmit("2");
      } else {
        setErrorMessage("Wrong OTP. Please enter a valid OTP");
        triggerShakeEffect(); // Trigger shake effect
      }
    } else {
      setErrorMessage("Please fill all input fields.");
    }
  };

  const triggerShakeEffect = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false); // Stop shake after animation
    }, 500);

    // Clear OTP input and error message after shake
    setTimeout(() => {
      setOtp(["", "", "", ""]);
      setErrorMessage("");
    }, 1000);
  };

  ////new user functions
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
    };

    // First Name Validation
    if (!firstName) {
      newErrors.firstName = "Please enter your first name";
      isValid = false;
    } else if (firstName.length < 2 || firstName.length > 20) {
      newErrors.firstName = "First name must be between 2 and 20 characters";
      isValid = false;
    }

    // Last Name Validation
    if (!lastName) {
      newErrors.lastName = "Please enter your last name";
      isValid = false;
    } else if (lastName.length < 2 || lastName.length > 20) {
      newErrors.lastName = "Last name must be between 2 and 20 characters";
      isValid = false;
    }

    // Email Validation
    if (!email) {
      newErrors.email = "Please enter your email";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    if (validateForm()) {
      handleSubmit("3");
    }
  };

  const toggleToLoginScreen = () => {
    setIsOtpScreenVisible(false);
  };

  return (
    <div className="LoginPageCont">
      <Modal
        className="loginModal"
        backdrop="static"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="BothDivs">
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              className="modalCloseIcn"
              src={modalCloseIcn}
              alt="modalCloseIcn"
            />
            <div className="row">
              {!isNewUser && !welcomePage && (
                <>
                  <div className="col-md-6 leftSideLogin">
                    <h1>Welcome to SwagStay</h1>
                    <h2>Unlock Benefits</h2>
                    <div className="UnlkdBanifits">
                      <li>
                        <img src={unLockBeniIcon} alt="unLockBeniIcon" />
                        Express check-in facility
                      </li>
                      <li>
                        <img src={unLockBeniIcon} alt="unLockBeniIcon" />
                        Get swagcoin by signing
                      </li>
                      <li>
                        <img src={unLockBeniIcon} alt="unLockBeniIcon" />
                        Early check-in/out facility
                      </li>
                      <li>
                        <img src={unLockBeniIcon} alt="unLockBeniIcon" />
                        Pickup & drop facility
                      </li>
                      <li>
                        <img src={unLockBeniIcon} alt="unLockBeniIcon" />
                        Loyalty benefits
                      </li>
                    </div>
                  </div>

                  <div className="col-md-6 RightSideLogin">
                    {!isOtpScreenVisible ? (
                      <div className="rightTopContent">
                        <div
                          className={`LoginHEad ${shakeEffect ? "shake" : ""}`}
                        >
                          <h2>Login/Signup</h2>
                          <label>Enter your mobile number</label>
                          <div
                            className={`Numberfill ${isActive ? "Numbractive" : ""
                              }`}
                          >
                            <div
                              className="d-flex"
                              style={{ paddingRight: "0.4rem" }}
                            >
                              <img
                                src={indiaIcon}
                                alt="India Flag"
                                className="flag-icon"
                              />
                              <span className="country-code">
                                {countryCode}
                              </span>
                            </div>
                            <input
                              type="text"
                              className="NumberInpt"
                              placeholder="WhatsApp number preferred"
                              inputMode="numeric"
                              value={phoneNumber}
                              onChange={handlePhoneNumberChange}
                              maxLength={10}
                            />
                          </div>

                          {validationMessage && (
                            <span className="validation-message">
                              {validationMessage}
                            </span>
                          )}
                        </div>

                        <div className="cheknom">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                          />
                          <label>
                            Yes, inform me about deals and new offers
                          </label>
                        </div>

                        <button
                          className="SendOtpButon"
                          type="button"
                          onClick={handleSendOtp}
                          style={{
                            opacity: phoneNumber.length === 10 ? "1" : "0.5",
                          }}
                        >
                          Send OTP
                        </button>
                      </div>
                    ) : (
                      // OTP Container screen
                      <div className="OtpContainer">
                        <h2>OTP verification</h2>

                        <label>
                          Enter the OTP sent to{" "}
                          <span>
                            {countryCode}-{phoneNumber}
                          </span>
                          <img
                            onClick={toggleToLoginScreen}
                            src={editIcon}
                            alt="editIcon"
                            className="modlEditNumber"
                          ></img>
                        </label>
                        <div
                          className={`otpBoxfill flex gap-4 ${shake ? "shake" : ""
                            }`}
                        >
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              ref={inputRefs[index]}
                              type="text"
                              inputMode="numeric"
                              maxLength={1}
                              value={digit}
                              onChange={(e) =>
                                handleChange(index, e.target.value)
                              }
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              onPaste={handlePaste}
                              className={digit ? "hasValue" : ""}
                            />
                          ))}
                        </div>
                        {errorMessage && (
                          <div className="conditionError">
                            <img
                              src={accodinationRed}
                              alt="accodinationRed"
                            ></img>
                            <span>{errorMessage}</span>
                          </div>
                        )}
                        <button
                          className="VerifyOtpButton"
                          type="button"
                          onClick={handleVerification}
                          disabled={otp.some((d) => d === "")}
                          style={{
                            pointerEvents: otp.some((d) => d === "")
                              ? "none"
                              : "auto",
                            opacity: otp.some((d) => d === "") ? 0.5 : 1,
                          }}
                        >
                          Verify OTP
                        </button>
                        {!isCounting ? (
                          <button
                            className="ResndOTP"
                            type="button"
                            onClick={HandleResendOtp}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <div className="resetCoumeDOwn">
                            <span>
                              ⏳ Your OTP will be sent again in{" "}
                              <strong>{counter}</strong> seconds.
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {!isOtpScreenVisible && (
                      <div className="ByProccesing">
                        <img src={Acodi} alt="Acodi" />
                        <span>
                          By proceeding, you agree to SwagStay privacy policy
                          and terms of service
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/*----for new User -----*/}
              <div >
                {isNewUser && !welcomePage && (
                  <div className="">
                    <div className="container-fluid NewUserDiv">
                      <h2>Create your account</h2>

                      <div className="SignUpData">
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className={`FisTName ${errors.firstName ? "shake" : ""
                                }`}
                            >
                              <label>
                                First name <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Type here"
                                value={firstName}
                                maxLength={20}
                                onChange={(e) => {
                                  handleAphabetsChange(e);
                                  setFirstName(e.target.value);
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    firstName: "",
                                  }));
                                }}
                              />
                              {errors.firstName && (
                                <div className="conditionError">
                                  <img
                                    src={accodinationRed}
                                    alt="accodinationRed"
                                  />
                                  <span>{errors.firstName}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`FisTName ${errors.lastName ? "shake" : ""
                                }`}
                            >
                              <label>
                                Last name <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Type here"
                                value={lastName}
                                maxLength={20}
                                onChange={(e) => {
                                  handleAphabetsChange(e);
                                  setLastName(e.target.value);
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    lastName: "",
                                  }));
                                }}
                              />
                              {errors.lastName && (
                                <div className="conditionError">
                                  <img
                                    src={accodinationRed}
                                    alt="accodinationRed"
                                  />
                                  <span>{errors.lastName}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col">
                            <div
                              className={`FisTName ${errors.email ? "shake" : ""
                                }`}
                            >
                              <label>
                                Email address <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Type here"
                                value={email}
                                maxLength={50}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    email: "",
                                  }));
                                }}
                              />
                              {errors.email && (
                                <div className="conditionError">
                                  <img
                                    src={accodinationRed}
                                    alt="accodinationRed"
                                  />
                                  <span>{errors.email}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="FisTName">
                              <label>Referral code</label>
                              <input
                                type="text"
                                placeholder="Type here"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                                maxLength={50}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="NexBtttn">
                            <button onClick={handleNext}> Next</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
              {/*--------------Welcome page -----*/}

              {welcomePage && (
                <div className="WelcomepAge">
                  <div className="ImgWelcmContent">
                    <img src={WelcomPageImg} alt="WelcomPageImg"></img>

                    <h2>Welcome {UserName}! </h2>

                    <p>
                      Congratulations on onboarding SwagStay! You've earned{" "}
                      {UserWalletAmount} SwagPoints!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
