import React, { useEffect, useState } from "react";
import { useRazorpay } from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import DateIcon from "../Assets/calendar.svg"; // Add icon paths here
import PersonIcon from "../Assets/door.svg";
import RoomIcon from "../Assets/roomCata.svg";
import { formatDateweekdate, handleError } from "../CommonJquery/CommonJquery";
import { retrieveData } from "../LocalConnection/LocalConnection";
import {
  booking_calculation,
  save_booking_online,
  save_booking_online_pre,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import BookingLoader from "./BookingLoader.js";
import BookingSuccessScreen from "./BookingSuccessScreen.js";
import "./Css/CreateBooking.css";
import Header from "./Header";
import BackBar from "./Reapeting Componets/BackBar";
export default function BookingConfirm() {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationData = location.state?.data;
  const { Razorpay } = useRazorpay();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [CustomerName, setCustomerName] = useState("");
  const [CustomerMobileNo, setCustomerMobileNo] = useState("");
  const [CustomerEmail, setCustomerEmail] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [HotelDetails, setHotelDetails] = useState([]);
  const [HotelImageLink, setHotelImageLink] = useState("");
  const [PriceShow, setPriceShow] = useState("");
  const [bookingData, setbookingData] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  useEffect(() => {
    let customer_id = retrieveData("customer_id");
    let customer_name = retrieveData("customer_full_name");
    let customer_user_moblie_no = retrieveData("customer_user_moblie_no");
    let customer_user_email = retrieveData("customer_full_name");
    if (customer_id !== "0") {
      setCustomerName(customer_name);
      setCustomerEmail(customer_user_email);
      setCustomerMobileNo(customer_user_moblie_no);
      setCustomerId(customer_id);
    }

    master_data_get_calculation(
      navigationData.hotel_id,
      navigationData.HotelID,
      navigationData.cate_id,
      navigationData.from_date,
      navigationData.to_date,
      navigationData.beackfast_check,
      navigationData.lunch_check,
      navigationData.dinner_check,
      navigationData.checkin_time,
      navigationData.checkout_time,
      navigationData.pickup_location,
      navigationData.dropoff_location,
      navigationData.roomdates,
      navigationData.swagcoin_check,
      navigationData.real_amountcheck,
      navigationData.discount_id,
      navigationData.coupon_name,
      navigationData.express_checkin,
      navigationData.self_other,
      navigationData.uuiddata,
      0,
      ""
    );

    if (customerId == 0) {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  }, []);
  let total_rooms = 0;
  const master_data_get_calculation = async (
    hotel_id,
    HotelID,
    cate_id,
    checkInDate,
    checkOutDate,
    breakfast,
    lunch,
    dinner,
    checkin_time,
    checkout_time,
    pickup_location,
    dropoff_location,
    rooms_data,
    swagcoin_check,
    real_amountcheck,
    discount_id,
    discount_name,
    express_checkin,
    self_other,
    uuiddata,
    click_from,
    url_current
  ) => {
    if (click_from == 0) {
      setshowLoaderAdmin(true);
    } else {
      setIsLoading(true);
    }

    const fd = new FormData();
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    fd.append("from_date", checkInDate);
    fd.append("to_date", checkOutDate);
    fd.append("beackfast_check", breakfast);
    fd.append("lunch_check", lunch);
    fd.append("dinner_check", dinner);
    fd.append("cate_id", cate_id);
    fd.append("checkin_time", checkin_time);
    fd.append("checkout_time", checkout_time);
    fd.append("pickup_location", pickup_location);
    fd.append("dropoff_location", dropoff_location);
    fd.append("express_checkin", express_checkin);
    fd.append("self_other", self_other);
    fd.append("swagcoin_check", swagcoin_check);
    fd.append("real_amountcheck", real_amountcheck);
    fd.append("discount_id", discount_id);
    fd.append("coupon_name", discount_name);
    fd.append("uuiddata", uuiddata);
    fd.append("url_current", url_current);

    let total_adults_loop = "";
    let total_children_loop = "";
    Object.entries(rooms_data).forEach(([key, value]) => {
      total_rooms++;
      total_adults_loop = total_adults_loop + value.adults + "~@~";
      total_children_loop = total_children_loop + value.children + "~@~";
    });
    fd.append("total_rooms", total_rooms);
    fd.append("total_adults_loop", total_adults_loop);
    fd.append("total_children_loop", total_children_loop);

    await server_post_data(booking_calculation, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          // Show confetti first

          let final_data = JSON.parse(data[1]);
          // final_data.hotel_details[0].booking_type = 1;
          setHotelDetails(final_data.hotel_details[0]);
          setHotelImageLink(final_data.hotel_image_link);
          setPriceShow(final_data.room_booking_price);
          setRupessIcon(final_data.rupees_icon);

          setbookingData({
            bookingFor: CustomerName,
            guestCount: final_data.guest_count_show,
            roomType: final_data.category_name,
            dates: final_data.dates_for_show,
            breakfastMeal: final_data.breakfast_price,
            lunchMeal: final_data.lunch_price,
            dinnerMeal: final_data.dinner_price,
            checkinprice: final_data.checkinprice,
            checkoutprice: final_data.checkoutprice,
            discount_user_id: final_data.discount_user_id,
            discount_name: final_data.discount_name,
            discount_description: final_data.discount_description,
            discount_from_wallet: final_data.discount_from_wallet,
            discount_from_wallet_actual: final_data.discount_from_wallet_actual,
            discount_from_real_wallet: final_data.discount_from_real_wallet,
            total_use_wallet: final_data.total_use_wallet,
            rupees_icon: final_data.rupees_icon,
            swagCoinBalance: final_data.discount_from_wallet,
            RealBalance: final_data.discount_from_real_wallet,
            roomBooking: final_data.room_booking_price,
            extracharge: final_data.extra_amount,
            couponDiscount: final_data.discount_charge,
            totalPayableAmount: final_data.final_amount_to_be_pay_full,
            used_swag_coin: final_data.used_swag_coin,
            used_swag_real: final_data.used_swag_real,
            advance_amount: final_data.advance_amount,
            total_room: final_data.total_room,
            total_adult: final_data.total_adult,
            total_child: final_data.total_child,
            total_plus_amount: final_data.only_plus_data,
            uuiddata: final_data.uuiddata,
          });
        }
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      })

      .catch((error) => {
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const click_for_finalpayment = async (click_from, amount, payment_type) => {
    setshowLoaderAdmin(true);
    online_submit_booking(amount, payment_type);
  };
  const online_submit_booking = async (amount, payment_type) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("hotel_id", navigationData.hotel_id);
    fd.append("HotelID", navigationData.HotelID);
    fd.append("payamount", amount);
    fd.append("payment_type", payment_type);
    fd.append("total_room", bookingData.total_room);
    fd.append("cat_id", navigationData.cate_id);
    fd.append("from_date", navigationData.from_date);
    fd.append("to_date", navigationData.to_date);
    fd.append("guest_name", CustomerName);
    fd.append("total_adult", bookingData.total_adult);

    await server_post_data(save_booking_online_pre, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          if (payment_type === "Cash") {
            online_submit_booking_final("", data[2], data[1], payment_type);
          } else if (parseFloat(amount) === 0) {
            online_submit_booking_final("", data[2], data[1], "Cash");
          } else {
            handlePayment(data, payment_type);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const handlePayment = async (data_server, payment_type) => {
    setIsLoading(true);
    try {
      const options = {
        description: "Swagstay Booking Payment",
        image: "https://www.swagstay.com/logo.png",
        currency: "INR",
        key: data_server[3], // Replace with your actual Razorpay key
        amount: data_server[2],
        name: "Swagstay",
        order_id: data_server[1],
        prefill: {
          email: CustomerEmail,
          contact: CustomerMobileNo,
          name: CustomerName,
        },
        theme: { color: "#F86855" },
        handler: function (response) {
          // Successful payment handler
          online_submit_booking_final(
            response.razorpay_payment_id,
            data_server[2],
            data_server[1],
            payment_type
          );
        },
        modal: {
          ondismiss: function () {
            setIsLoading(false);
            setshowLoaderAdmin(false);
            // handleError("Payment process was cancelled. Please try again.");
          },
        },
      };
      // Create Razorpay instance
      const razorpayInstance = new Razorpay(options);
      // Open the Razorpay payment interface
      razorpayInstance.open();
      // Optional: Handle errors when Razorpay fails to initialize
      razorpayInstance.on("payment.failed", function (response) {
        handleError("Payment failed. Please try again.");
        setshowLoaderAdmin(false);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      setshowLoaderAdmin(false);
      handleError("Payment failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const online_submit_booking_final = async (
    trans_id,
    amount_payonline,
    order_id,
    payment_type
  ) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("total_adult", bookingData.total_adult);
    fd.append("total_child", bookingData.total_child);
    fd.append("total_room", bookingData.total_room);
    fd.append("hotel_id", navigationData.hotel_id);
    fd.append("HotelID", navigationData.HotelID);
    fd.append("cat_id", navigationData.cate_id);
    fd.append("mapped_cat_name", bookingData.roomType);
    fd.append("from_date", navigationData.from_date);
    fd.append("to_date", navigationData.to_date);
    fd.append("final_amount", bookingData.totalPayableAmount);
    fd.append("advance_amount", bookingData.advance_amount);
    fd.append("room_amount", bookingData.roomBooking);
    fd.append("breakfast_price", bookingData.breakfastMeal);
    fd.append("lunch_price", bookingData.lunchMeal);
    fd.append("dinner_price", bookingData.dinnerMeal);
    fd.append("checkin_time", navigationData.checkin_time);
    fd.append("checkout_time", navigationData.checkout_time);
    fd.append("checkinprice", bookingData.checkinprice);
    fd.append("checkoutprice", bookingData.checkoutprice);
    fd.append("discount_id", bookingData.discount_user_id);
    fd.append("payment_id", trans_id);
    fd.append("payment_type", payment_type);
    fd.append("order_id", order_id);
    fd.append("swagcoin_check", navigationData.swagcoin_check);
    fd.append("real_amountcheck", navigationData.real_amountcheck);
    fd.append("swagcoin_check_amount", bookingData.used_swag_coin);
    fd.append("real_amountcheck_amount", bookingData.used_swag_real);
    fd.append("total_plus_amount", bookingData.total_plus_amount);
    fd.append("online_payment", amount_payonline);
    fd.append("pickup_location", navigationData.pickup_location);
    fd.append("dropoff_location", navigationData.dropoff_location);
    fd.append("uuiddata", navigationData.uuiddata);
    fd.append("express_checkin", navigationData.express_checkin);
    fd.append("self_other", navigationData.self_other);
    let total_adults_loop = "";
    let total_children_loop = "";
    Object.entries(navigationData.roomdates).forEach(([key, value]) => {
      total_rooms++;
      total_adults_loop = total_adults_loop + value.adults + ",";
      total_children_loop = total_children_loop + value.children + ",";
    });
    fd.append("total_children_loop", total_children_loop);
    fd.append("total_adults_loop", total_adults_loop);
    await server_post_data(save_booking_online, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          // Show success screen
          setShowSuccessScreen(true);

          // Navigate after a delay
          setTimeout(() => {
            navigate("/bookings_details", {
              state: {
                data: {
                  hotel_id: navigationData.hotel_id,
                  HotelID: navigationData.HotelID,
                  book_id: data[3],
                  booking_id: data[2],
                },
              },
            });
          }, 5000); // 3 second delay to show the success screen
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {showSuccessScreen ? (
        <BookingSuccessScreen />
      ) : (
        <div style={{ backgroundColor: "var(--light-bg)" }}>
          <div className="navBarCOntainer">
            <Header />
            {showLoaderAdmin && <BookingLoader />}
            <BackBar label={"Payment Option"} />
          </div>
          <div className="container-lg">
            <div className="createBookingContainer">
              {/* Booking Details and Bill Details*/}
              <section>
                <div className="checkInSection">
                  <div className="row m-0">
                    <div className="col-md-6 pad000 paddinLeftAfter768 mb-4">
                      {/* Booking Details COntianer */}
                      <section className="bookDetails">
                        <div className="bookDetailsContainer mb-4">
                          <div className="booldetailTop">
                            <div className="left_detail">
                              <div className="hotelImg">
                                <img
                                  src={HotelImageLink + HotelDetails.image_name}
                                  alt={HotelDetails.hotel_name}
                                />
                              </div>
                              <div className="hotelText">
                                <p className="cardHeading">
                                  {HotelDetails.hotel_name}
                                </p>
                                <p className="cardText mb-2">
                                  {HotelDetails.address}
                                </p>
                                <div className="discount_price">
                                  {parseInt(HotelDetails.discount) > 0 && (
                                    <span className="discountTxt">
                                      {HotelDetails.discount}% off
                                    </span>
                                  )}
                                  {parseInt(HotelDetails.discount) > 0 && (
                                    <p className="oldPrice">
                                      {RupessIcon}
                                      {PriceShow *
                                        (100 / HotelDetails.discount)}
                                    </p>
                                  )}
                                  <h4 className="current-price">
                                    {RupessIcon}
                                    {PriceShow}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bookDetailsBottom detailsBottomSap">
                            <div className="leftSection bookingInfoFlex">
                              <div className="bookingInfo">
                                <img
                                  src={DateIcon}
                                  alt="Booking Date"
                                  className="cardicon"
                                />
                                <p>
                                  {formatDateweekdate(navigationData.from_date)}{" "}
                                  - {formatDateweekdate(navigationData.to_date)}
                                </p>
                              </div>
                              <div className="bookingInfo">
                                <img
                                  src={PersonIcon}
                                  alt="Number of Persons"
                                  className="cardicon"
                                />
                                <p>
                                  Room {bookingData.total_room} : Adults -{" "}
                                  {bookingData.total_adult} | Children -{" "}
                                  {bookingData.total_child}
                                </p>
                              </div>
                              <div className="bookingInfo">
                                <img
                                  src={RoomIcon}
                                  alt="Room Category"
                                  className="cardicon"
                                />
                                <p>Category: {bookingData.roomType}</p>
                              </div>
                              {navigationData.pickup_location !== "" && (
                                <div className="bookingInfo">
                                  <p>
                                    Pickup: {navigationData.pickup_location}
                                  </p>
                                </div>
                              )}
                              {navigationData.dropoff_location !== "" && (
                                <div className="bookingInfo">
                                  <p>Drop: {navigationData.dropoff_location}</p>
                                </div>
                              )}
                            </div>
                          </div>

                          {parseInt(HotelDetails.booking_type) === 1 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      1,
                                      bookingData.totalPayableAmount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span>
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.totalPayableAmount} Now
                                  </span>
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="DarkButtonClass"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      1,
                                      bookingData.advance_amount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span className="paylettertext">
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.advance_amount} Pay rest later
                                  </span>
                                </button>
                              </div>
                              <p className="paymentNote">
                                Half of the amount will be paid now, and the
                                rest will be paid by you when you check in at
                                the hotel.
                              </p>
                            </div>
                          )}
                          {parseInt(HotelDetails.booking_type) === 2 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="DarkButtonClass"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      2,
                                      bookingData.totalPayableAmount,
                                      "Cash"
                                    )
                                  }
                                >
                                  <span className="paylettertext">
                                    Pay at hotel
                                  </span>
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      2,
                                      bookingData.totalPayableAmount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span>
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.totalPayableAmount} Now
                                  </span>
                                </button>
                              </div>
                            </div>
                          )}
                          {parseInt(HotelDetails.booking_type) === 3 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="DarkButtonClass"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      3,
                                      bookingData.totalPayableAmount,
                                      "Cash"
                                    )
                                  }
                                >
                                  <span className="paylettertext">
                                    Pay at hotel
                                  </span>
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      3,
                                      bookingData.advance_amount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span>
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.advance_amount} Pay rest later
                                  </span>
                                </button>
                              </div>
                              <p className="paymentNote">
                                Half of the amount will be paid now, and the
                                rest will be paid by you when you check in at
                                the hotel.
                              </p>
                            </div>
                          )}
                          {parseInt(HotelDetails.booking_type) === 4 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      4,
                                      bookingData.totalPayableAmount,
                                      "Cash"
                                    )
                                  }
                                >
                                  <span>Pay at hotel</span>
                                </button>
                              </div>
                            </div>
                          )}
                          {parseInt(HotelDetails.booking_type) === 5 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      5,
                                      bookingData.totalPayableAmount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span>
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.totalPayableAmount} Now
                                  </span>
                                </button>
                              </div>
                            </div>
                          )}
                          {parseInt(HotelDetails.booking_type) === 6 && (
                            <div>
                              <div className="continueBtn_container">
                                <button
                                  className="primaryBtn mb-3"
                                  onClick={() =>
                                    click_for_finalpayment(
                                      6,
                                      bookingData.advance_amount,
                                      "rozarpay"
                                    )
                                  }
                                >
                                  <span>
                                    Pay {bookingData.rupees_icon}
                                    {bookingData.advance_amount} Pay rest later
                                  </span>
                                </button>
                              </div>
                              <p className="paymentNote">
                                Half of the amount will be paid now, and the
                                rest will be paid by you when you check in at
                                the hotel.
                              </p>
                            </div>
                          )}
                          {(parseInt(HotelDetails.booking_type) === 7 ||
                            parseInt(HotelDetails.booking_type) === -1) && (
                            <div>
                              <div className="continueBtn_container">
                                <button className="DarkButtonClass">
                                  <span>Sold Out</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    </div>

                    <div className="col-md-6 pad000 paddinRightAfter768 mb-4">
                      {/* Bill Details Section */}
                      <div className="earlyLateColumn mb-4">
                        <div className="earlyLatecontainer mb-4">
                          <h5
                            className="headingh5 mb-3"
                            style={{ color: "var(--primary-color)" }}
                          >
                            Bill details
                          </h5>

                          <div className="billDetails">
                            <div className="billDescp">
                              <p className="billLabl">Booking for</p>
                              <p className="cardText">
                                {bookingData.bookingFor}
                              </p>
                            </div>
                            <div className="separator"></div>
                            <div className="billDescp">
                              <p className="billLabl">Room booking</p>
                              <p className="cardText">{`${bookingData.rupees_icon}${bookingData.roomBooking}`}</p>
                            </div>
                            {parseInt(bookingData.breakfastMeal) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Breakfast meal</p>
                                  <p className="cardText">{`${bookingData.rupees_icon}${bookingData.breakfastMeal}`}</p>
                                </div>
                              </>
                            )}

                            {parseInt(bookingData.lunchMeal) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Lunch meal</p>
                                  <p className="cardText">{`${bookingData.rupees_icon}${bookingData.lunchMeal}`}</p>
                                </div>
                              </>
                            )}

                            {parseInt(bookingData.dinnerMeal) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Dinner meal</p>
                                  <p className="cardText">{`${bookingData.rupees_icon}${bookingData.dinnerMeal}`}</p>
                                </div>
                              </>
                            )}
                            {parseInt(bookingData.checkinprice) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Early Check-in</p>
                                  <p className="cardText">{`${bookingData.rupees_icon}${bookingData.checkinprice}`}</p>
                                </div>
                              </>
                            )}
                            {parseInt(bookingData.checkoutprice) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Late Check-Out</p>
                                  <p className="cardText">{`${bookingData.rupees_icon}${bookingData.checkoutprice}`}</p>
                                </div>
                              </>
                            )}
                            {parseInt(bookingData.couponDiscount) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Coupon discount</p>
                                  <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.couponDiscount}`}</p>
                                </div>
                              </>
                            )}
                            {parseInt(bookingData.used_swag_coin) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">SwagCoin discount</p>
                                  <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.used_swag_coin}`}</p>
                                </div>
                              </>
                            )}
                            {parseInt(bookingData.used_swag_real) > 0 && (
                              <>
                                <div className="separator"></div>
                                <div className="billDescp">
                                  <p className="billLabl">Wallet discount</p>
                                  <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.used_swag_real}`}</p>
                                </div>
                              </>
                            )}

                            <div className="separator"></div>
                            <div className="billDescp">
                              <p className="billLabl total">
                                Total payable amount
                              </p>
                              <p className="cardText total">{`${bookingData.rupees_icon}${bookingData.totalPayableAmount}`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
