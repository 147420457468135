import React from "react";
import CopyIcon from "../Assets/copyIcon.png";
import { Link } from "react-router-dom";

function CompleteRefund({ refundCompletesList, hotel_image_link }) {
  const copyCode = () => {
    const code = "SWAG094847NR";
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };
  console.log(refundCompletesList);
  return (
    <div>
      {refundCompletesList &&
        refundCompletesList.length > 0 &&
        refundCompletesList.map((item, index) => (
          <Link
            to="/bookings_details"
            state={{
              isRefundStatus: true,
              data: {
                HotelID: item.HotelID,
                booking_id: item.booking_id,
                book_id: item.book_id,
                hotel_id: item.hotel_id,
                cd_id: item.cd_id,
              },
            }}
          >
            <div key={index} className="refundCard">
              <div className="booldetailTop refundBookTop">
                <div className="left_detail">
                  <div className="bookingId refundBookId">
                    <p>
                      Booking id: <span>{item.booking_id}</span>
                    </p>
                    <img onClick={copyCode} src={CopyIcon} />
                  </div>
                </div>
                <div className="right_detail">
                  <div className="bookingId refundBookId">
                    <p>
                      Status:{" "}
                      <span style={{ color: "var(--dark-green)" }}>
                        {item.refund_status}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="refundBookBottom">
                <img
                  src={`${hotel_image_link}${item.image_name}`}
                  alt="Hotel"
                  className="hotel-image"
                />
                <div className="booking-info">
                  <h3>{item.hotel_name}</h3>
                  <p className="elepsisClassP">{item.address}</p>
                  <div className="bookingId refundBookId">
                    {item.refund_method === null ? (
                      ""
                    ) : (
                      <p style={{ fontWeight: 500 }} className="cancelRefundP">
                        Payment: <span>{item.refund_method}</span> | Booking
                        cancellation refund
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
}

export default CompleteRefund;
