
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headerImage from "../Assets/feedbackmobile.png";
import backArrow from "../Assets/whitebackarrow.svg";
import "./Css/FeedbackForm.css";
import Header from "./Header";
import { server_post_data, feedback_form_website } from "../ServiceConnection/serviceconnection";
import SuccessFullModal from "./SuccessFullModal";
import { empty_form, handleError } from "../CommonJquery/CommonJquery";
const feedbackQuestions = [

  {
    section: "Booking Process Feedback",
    questions: [
      {
        id: 1,
        name:'booking_process_ease',
        question: "How easy was it to navigate the booking process?",
        type: "radio",
        options: [
          "Very easy",
          "Easy",
          "Neutral",
          "Difficult",
          "Very difficult",
        ],
      },
      {
        id: 2,
        name:'booking_issues_encountered',
        names:'booking_issues_description',
        question: "Did you encounter any issues while booking?",
        type: "radioWithText",
        options: ["Yes", "No"],
        placeholder: "Please describe",
      },
      {
        id: 3,
        name:'booking_speed',
        question: "How would you rate the speed of the booking process?",
        type: "radio",
        options: ["Very fast", "Fast", "Average", "Slow", "Very slow"],
      },
      {
        id: 4,
        question: "Were you satisfied with the payment options available?",
        type: "radio",
        name:'payment_satisfaction',
        options: [
          "Very satisfied",
          "Satisfied",
          "Neutral",
          "Dissatisfied",
          "Very dissatisfied",
        ],
      },
      {
        id: 5,
        name:'information_availability',
        names:'missing_information',
        question:
          "Did you find all the information you needed during the booking process?",
        type: "radioWithText",
        options: ["Yes", "No"],
        placeholder: "Please specify what was missing",
      },
    ],
  },
  {
    section: "Customer Experience Feedback",
    questions: [
      {
        id: 6,
        name:'ui_rating',
        question:
          "How would you rate the overall user interface of the website/mobile app?",
        type: "radio",
        options: ["Excellence", "Good", "Average", "Poor", "Very poor"],
      },
      {
        id: 7,
        name:'design_appeal',
        question: "How visually appealing did you find the design?",
        type: "radio",
        options: [
          "Very appealing",
          "Appealing",
          "Neutral",
          "Unappealing",
          "Very unappealing",
        ],
      },
      {
        id: 8,
        name:'navigation_intuition',
        question: "How intuitive did you find the navigation?",
        type: "radio",
        options: [
          "Very intuitive",
          "Intuitive",
          "Neutral",
          "Confusing",
          "Very confusing",
        ],
      },
      {
        id: 9,
        name:'recommendation_likelihood',
        question: "How likely are you to recommend our service to others?",
        type: "radio",
        options: [
          "Very likely",
          "Likely",
          "Neutral",
          "Unlikely",
          "Very unlikely",
        ],
      },
      {
        id: 10,
        name:'useful_features',
        question: "What features do you find most useful on our platform?",
        type: "text",
        placeholder: "Type here",
      },
      {
        id: 11,
        name:'improvement_suggestions',
        question: "What features or improvements would you like to see?",
        type: "text",
        placeholder: "Type here",
      },
    ],
  },
  {
    section: "Suggestion for New City Addition",
    questions: [
      {
        id: 12,
        name:'suggested_city',
        question:
          "Are there any cities you would like to see added to our service?",
        type: "radio",
        options: ["Excellence", "Good", "Average", "Poor", "Very poor"],
      },
      {
        id: 13,
        name:'interest_in_updates',
        question:
          "Would you be interested in receiving updates about new city additions?",
        type: "radio",
        options: ["Yes", "No"],
      },
      {
        id: 14,
        name:'additional_comments',
        question:
          "Any additional comments or suggestions for expanding our services?",
        type: "text",
        placeholder: "Type here",
      },
    ],
  },
];
const FeedbackForm = () => {
  const navigate = useNavigate();
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [formData, setFormData] = useState({});
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRadioWithTextChange = (name, value, description) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      [`${name}_description`]: value === "Yes" ? description : ""
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const requiredFields = feedbackQuestions.flatMap(section => 
      section.questions.map(q => q.name)
    );

    for (let field of requiredFields) {
      if (!formData[field] || formData[field].trim() === "") {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      alert("Please fill in all required fields");
      return;
    }

    setShowLoaderAdmin(true);

    const fd = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      fd.append(key, value);
    });

      await server_post_data(feedback_form_website, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
          empty_form(fd)
        } else {
          // const final_data = JSON.parse(data1[1]);
          setFormData({})
          handleShowSucessModal()
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="container-lg">
        <div className="feedback-container">
          <div className="feedback-header">
            <div className="feedback-text">
              <div className="back-section" onClick={() => navigate(-1)}>
                <img src={backArrow} alt="Back Arrow" className="back-arrow" />
                <button type="button" className="back-button">Back to home</button>
              </div>
              <div className="feedback-title">
                <h1>Booking process feedback</h1>
                <p>Your opinion matters! Share your experiences to help us improve our booking process and offer a seamless service every time.</p>
              </div>
            </div>
            <div className="feedback-image">
              <img src={headerImage} alt="Feedback Header" />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {feedbackQuestions.map((section, sectionIndex) => (
              <section key={sectionIndex} className="feedback-section">
                <h2>{section.section}</h2>
                {section.questions.map((q, questionIndex) => (
                  <div key={q.id} className="form-group">
                    <label className="question-label">
                      {`${questionIndex + 1}. ${q.question}`}
                    </label>
                    <div className="options-container">
                      {q.type === "radio" && (
                        q.options.map((option, idx) => (
                          <div key={idx} className="radio-option">
                            <input
                              type="radio"
                              id={`${q.id}-${idx}`}
                              name={q.name}
                              value={option}
                              checked={formData[q.name] === option}
                              onChange={(e) => handleInputChange(q.name, e.target.value)}
                            />
                            <label htmlFor={`${q.id}-${idx}`}>{option}</label>
                          </div>
                        ))
                      )}
                      {q.type === "radioWithText" && (
                        <>{console.log(formData)}
                          {q.options.map((option, idx) => (
                            <div key={idx} className="radio-option">
                              <input
                                type="radio"
                                id={`${q.id}-${idx}`}
                                name={q.name}
                                value={option}
                                checked={formData[q.name] === option}
                                onChange={(e) => handleRadioWithTextChange(
                                  q.name,
                                  e.target.value,
                                  formData[`${q.name}_description`] || ""
                                )}
                              />
                              <label htmlFor={`${q.id}-${idx}`}>{option}</label>
                            </div>
                          ))}
                          {formData[q.name] === "Yes" && (
                            <textarea
                              placeholder={q.placeholder}
                              value={formData[`${q.name}_description`] || ""}
                              onChange={(e) => handleRadioWithTextChange(
                                q.name,
                                "Yes",
                                e.target.value
                              )}
                              className="textarea-input"
                            />
                          )}
                        </>
                      )}
                      {q.type === "text" && (
                        <textarea
                          placeholder={q.placeholder}
                          value={formData[q.name] || ""}
                          onChange={(e) => handleInputChange(q.name, e.target.value)}
                          className="textarea-input"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </section>
            ))}
            <div className="col-md-2 col-12">
              <div className="saveProfileDiv">
                <button
                  className="DarkButtonClass SubmtFeedBck"
                  type="submit"
                  disabled={showLoaderAdmin}
                >
                  {showLoaderAdmin ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </div>
  );
};

export default FeedbackForm;