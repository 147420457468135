import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import {
  blog_website,
  save_subscribe_website,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import "./Css/Blog.css";
import Discount from "./Discount";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
let click_time = 0;
export default function Blog() {
  const [ImageLink, setImageLink] = useState("");
  const [blogPosts, setcancelledData] = useState([]);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleBlogs, setVisibleBlogs] = useState(6); // Initial visible blog count
  const [showMore, setShowMore] = useState(true);
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "blog/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "5");
    await server_post_data(blog_website, fd)
      .then((Response) => {
        console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.blogs);
          setImageLink(final_data.blog_image_link);
          setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setIsLoading(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            handleSuccess(data[1]);
            empty_form(form_data);
          }
          click_time = 0;
          setIsLoading(false);
        })
        .catch((error) => {
          click_time = 0;
          setIsLoading(false);
        });
    }
  };
  const handleShowMoreLess = () => {
    if (showMore) {
      if (visibleBlogs + 6 >= blogPosts.length) {
        setVisibleBlogs(blogPosts.length);
        setShowMore(false);
      } else {
        setVisibleBlogs(visibleBlogs + 6);
      }
    } else {
      setVisibleBlogs(6);
      setShowMore(true);
    }
  };
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  return (
    <>
      <Header />
      <div className="container-lg">
        {/*-----------blog Hero Section--------------------*/}
        <div className="blogHEroSection">
          <h1>Travel inspiration & tips</h1>

          <p>
            Explore our curated travel guides, tips, and stories to make your
            journeys unforgettable
          </p>
        </div>

        {/*----------Email Section--------------------*/}

        <div className="EMailDicBlogCOnter">
          <div className="EMailDicBlog">
            <form id="communitylist">
              {" "}
              <input
                type="text"
                placeholder="Enter your email"
                id="email"
                name="email"
                onChange={handleEmailChange}
              ></input>
            </form>

            <div className="SubDivv">
              <button
                type="button"
                onClick={() => {
                  handleSaveChangesdynamic(
                    "communitylist",
                    save_subscribe_website
                  );
                }}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>

        {/*----------Recent Blog Post Section--------------------*/}
        <div className="RecentBLogDiv">
          <div className="RecentBlogHead">
            {" "}
            <h1>Recent Blog Posts</h1>{" "}
          </div>
          <div className="row BlogData">
            {blogPosts.slice(0, visibleBlogs).map((post, index) => (
              <Link
                // to={`/blogdetail/${post.b_id}`}
                onClick={() =>
                  handleLinkClick("/" + match_and_return_seo_link(post.b_id))
                }
                key={index}
                className="col-sm-6 col-lg-4  mb-4 text-dark"
              >
                <div className="blogcard">
                  <img
                    src={`${ImageLink}${post.banner_image}`}
                    className="blogcard-img-top"
                    alt={`Blog post ${index + 1}`}
                  />

                  <div className="blogcard-body">
                    <small className="text-muted">
                      {/* {onchange={handleDateChange}} */}
                      {post.author} • {post.entry_date}
                    </small>
                    <h5 className="blogcard-title mt-2">
                      {truncateWords(post.title_name, 3)}
                    </h5>
                    <p
                      className="blogcard-text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          truncateWords(post.description, 6)
                        ),
                      }}
                    ></p>

                    <button className="blogReadmore" type="button">
                      {" "}
                      Read more
                    </button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="showoreBlogDiv">
        <button
          type="button"
          className="DarkButtonClass"
          onClick={handleShowMoreLess}
        >
          {showMore ? "Show More" : "Show Less"}
        </button>
      </div>
      {/*----------Tranding blog Div----------- */}{" "}
      <div className="TrandingBlogg">
        {/*<div className="container-lg">
           <div className="RecentBlogHead TradHead">
            {" "}
            <h1>Trending blogs</h1>{" "}
          </div>
          <div className="row BlogData">
            {blogPosts.map((post, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4"
              >
                <div className="blogcard">
                  <img
                    src={post.image}
                    className="blogcard-img-top"
                    alt={`Blog post ${index + 1}`}
                  />

                  <div className="blogcard-body">
                    <small className="text-muted">
                      {post.author} • {post.date}
                    </small>
                    <h5 className="blogcard-title mt-2">{post.title}</h5>
                    <p className="blogcard-text">{post.description}</p>

                    <button className="blogReadmore" type="button">
                      {" "}
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        {/*--------Discout Section -----*/}
        <Discount />
        {/*--------Discout Section -----*/}
        <Footer />
      </div>
    </>
  );
}
