import React from "react";
import "./Css/QuickHotel.css";
import ErrorImage from "../Assets/city1.png";
import { Link } from "react-router-dom";
export default function QuickHotel({
  citylist,
  distance_lat,
  cityimage,
  func,
  isaqi,
  seoData,
}) {
  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "hotel_list_location/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (seoData) {
      const matchedItem = seoData.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  return (
    <div>
      <div className="container-lg my-4">
        <div className="QuicklyHotl">
          {/* <p>Quickly hotel search</p> */}
          <div className="quickHotlsubhading">
            <h2>
              Find <span>SwagStay </span>
              at
            </h2>
          </div>
        </div>
        <div className="Hotelimgscontainer">
          {citylist &&
            citylist.length > 0 &&
            citylist.map((city, index) => (
              <Link
                key={index}
                className="relative rounded-lg  group cursor-pointer"
                style={{ cursor: "pointer" }}
                to={isaqi ? "#" : match_and_return_seo_link(city.city_id)}
                onClick={(event) => {
                  if (isaqi) {
                    event.preventDefault(); // Prevent navigation
                    func(index); // Call the function
                  }
                }}
              >
                <div className="image-container aspect-square relative">
                  <img
                    src={cityimage + city.banner_image}
                    onError={(event) => (event.currentTarget.src = ErrorImage)}
                    alt={city.city_name}
                    className="city-image w-full h-full object-cover"
                  />
                  <div className="overlay absolute inset-0">
                    <h3 className="city-name text-white font-bold text-xl">
                      {city.city_name}
                    </h3>
                    <p className="city-count text-white text-sm">
                      {city.total_hotels} SwagStay
                    </p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        {/* <div className="borderBottomLine"></div> */}
      </div>
    </div>
  );
}
