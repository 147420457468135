import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/Blog.css";
import "./Css/Offer.css";
import HotelData from "./Reapeting Componets/HotelData";
import Footer from "./Footer";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  offer_url_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection";

export default function Offer() {
  const [coupondata, setcoupondata] = useState([]);
  const [carddata, setcarddata] = useState([]);
  const [city, setCity] = useState('');
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [viewAllHotels, setViewAllHotels] = useState({}); // Manage view-all state per coupon
  const [SEOloop, setSEOloop] = useState([]);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    await server_post_data(offer_url_data, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcoupondata(final_data.hotel_silder_discount);
          const updatedCardData = final_data.hotel_data.map(hotel => ({
            ...hotel, // Spread the existing hotel properties
            tab: '', // Add the new tab property
          }));
          setcarddata(updatedCardData);
          if (updatedCardData.length > 0) {
            setCity(updatedCardData[1].city_name);
          }

          setSilderHotelImage(final_data.hotel_image_link);
          setShowLoaderAdmin(false);
          setSEOloop(final_data.seo_list)
        }
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
        console.log(error);
      });
  };

  const toggleViewAllHotels = (couponId) => {
    setViewAllHotels((prevState) => ({
      ...prevState,
      [couponId]: !prevState[couponId], // Toggle view-all for the specific coupon
    }));
  };

  return (
    <div className="FontSizeCLass">
      <Header />
      <div className="container-lg">
        {/*-----------blog Hero Section--------------------*/}
        <div className="OffrHeroSection">
          <h1 className="offerTOpHead">Offer in {city} hotel</h1>
          {coupondata &&
            coupondata.length > 0 &&
            coupondata.map((coupen, index) => {
              const matchingHotels = carddata.filter(
                (hotel) => hotel.discount === coupen.discount_amount
              );

              if (matchingHotels.length === 0) {
                return null;
              }
              const isViewAll = viewAllHotels[coupen.id] || false; // Check view-all state for this coupon

              return (
                <React.Fragment key={index}>
                  <div className="heroUnderDataOfffer" key={index}>
                    <div className="col-lg-8">
                      <div className="offerWelcomeHead">
                        <h1>Welcome Swagger</h1>
                        <button className="primaryBtn" type="button">
                          Flat {coupen.discount_amount}% Off
                        </button>
                      </div>
                      <p>Coupon Details</p>
                      <label>
                        Lorem ipsum dollar summit Lorem ipsum dollar summit
                        Lorem ipsum dollar summitLorem ipsum dollar summit Lorem
                        ipsum summitLorem ipsum dollar summit Lorem ipsum{" "}
                      </label>
                    </div>
                  </div>
                  <HotelData
                    image={SilderHotelImage}
                    isfav={false}
                    hotelData={
                      isViewAll ? matchingHotels : matchingHotels.slice(0, 3) // Show only 3 hotels initially
                    }
                    seoData={SEOloop}
                  />
                  <div className="showoreBlogDiv">
                    <button
                      type="button"
                      className="DarkButtonClass"
                      onClick={() => toggleViewAllHotels(coupen.id)}
                    >
                      {isViewAll ? "Show Less" : "View All"}
                    </button>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
