import React, { useEffect, useState } from "react";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  booking_all,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import BookingCard from "./BookingCard";
import "./Css/SharePopup.css";
import Shimmer from "./Shimmer";
import SharePopup from "./SharePopup";
import Nobookingdata from "../Assets/Nobookingdata.png";
function Upcoming() {
  // Function to handle sharing functionality
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  // const handleShare = () => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: "Check out this booking!",
  //         text: "I found this booking and wanted to share it with you.",
  //         url: window.location.href, // Share the current page URL or change to a specific link
  //       })
  //       .then(() => console.log("Successfully shared"))
  //       .catch((error) => console.error("Error sharing", error));
  //   } else {
  //     alert("Share functionality is not supported in this browser.");
  //   }
  // };
  const [ImageLink, setImageLink] = useState("");
  const [cancelledData, setcancelledData] = useState([]);
  const [rupeyIcon, setRupeyIcon] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  useEffect(() => {
    master_data_get();
  }, []);
  // Function to handle showing the share popup
  //  const handleShareClick = (url) => {
  //   setShareUrl(url); // Set the URL to share
  //   setShowSharePopup(true); // Show the popup
  // };

  // Function to close the share popup

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "1");
    await server_post_data(booking_all, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.upcomming_list);
          setImageLink(final_data.hotel_image_link);
          setRupeyIcon(final_data.rupees_icon);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  return (
    <div>
      {showLoaderAdmin && <Shimmer />}
      {cancelledData && cancelledData.length > 0 ? (
        cancelledData.map((item, index) => (
          <BookingCard
            key={index}
            buttonIcon={
              <img
                src={require("../Assets/shareBIcon.png")}
                alt="Upcoming Icon"
                className="icon"
                onClick={() => {
                  setisSharePopupContent(
                    `Hey! 🌟 I just found this amazing hotel: ${item.hotel_name}.\n\nIf you're looking for a comfortable stay, check it out! They offer great amenities.\n\n📍 Location: ${item.address}\n⭐ Rating: ${item.total_rating}`
                  );
                  setSharePopupOpen(true);
                }} // Trigger share function on click
              />
            }
            datass={item}
            image={ImageLink}
            rupey={rupeyIcon}
            route={"bookings_details"}
          />
        ))
      ) : (
        <div className="nobookingDiv">
          <img
            src={Nobookingdata}
            alt="No booking data available"
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}

      <SharePopup
        isOpen={isSharePopupOpen}
        onRequestClose={() => setSharePopupOpen(false)}
        Content={isSharePopupContent}
      />
    </div>
  );
}

export default Upcoming;
