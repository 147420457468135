import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Css/RewardPage.css";
import BackBtn from "../Assets/backIcon.svg";
import rightRwd from "../Assets/rightRwd.svg";
import rwardRightHero from "../Assets/rwardRightHero.svg";
import { useNavigate } from "react-router-dom";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  wallet_details_all,
} from "../ServiceConnection/serviceconnection";
export default function RewardPage() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [WalletList, setWalletList] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/refer_earn_page");
    window.location.reload();
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);

    await server_post_data(wallet_details_all, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setWalletList(final_data.wallethis_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="container-lg">
        <div
          className="backBtttn"
          onClick={handleNavigate}
          style={{ cursor: "pointer" }}
        >
          <img src={BackBtn} alt="BackBtn"></img>
          <p>Rewards</p>
        </div>

        {/**-----------My Rewards--------- */}
        <div className="myRwrds">
          <div className="lefRwrd">
            <h1>My rewards</h1>
          </div>
          <div className="col RIghtRwrd">
            <img src={rwardRightHero} alt="rightRwd"></img>
          </div>
        </div>
        {/**----------SignUpBonus--------- */}

        <div className="sigUpBonus">
          {WalletList.map((item, index) => {
            let title = "Other bonus";
            if (
              item.through.includes(
                "Credited in Your Wallet Against Other Bonus"
              )
            ) {
              title = "Welcome bonus";
            } else if (
              item.through.includes(
                "Credited in Your Wallet Against Referral Cashback"
              )
            ) {
              title = "Signup bonus";
            }
            return (
              <div className="contntSignIn" key={index}>
                <div className="leftSIgn">
                  <img src={rightRwd} alt="rightRwd" />
                  <div className="signData">
                    {/* <h1>{item.coverted_date}</h1> */}
                    <h1>
                      {item.coverted_date
                        ? item.coverted_date.split(" ")[0]
                        : ""}
                    </h1>
                    <h1>
                      {item.coverted_date
                        ? item.coverted_date.split("  ")[1]?.slice(0, 3)
                        : ""}
                    </h1>
                    {/* <p>{item.coverted_year}</p> */}
                  </div>
                </div>
                {console.log(item.title)}
                <div className="RIghtSIgn">
                  <h1>{title}</h1>
                  <p>{item.through}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
