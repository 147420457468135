import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import DOMPurify from "dompurify";
import "./Css/PrivacyPolicy.css";
import {
  server_post_data,
  website_content,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

// import privacyimg from "../Assets/herosection.svg";

const PrivacyPolicy = () => {
  const [testinomial, settestinomial] = useState([]);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("which_page", 1);
    await server_post_data(website_content, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.website_content.application_text);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  console.log(testinomial);
  return (
    <>
      <Header />
      <div className="containersssss ">
        <div className="heroPrivacy">
          <h1>SwagStay privacy policy</h1>
          <p>
            Your privacy is our priority, and we are dedicated to safeguarding
            your information with complete transparency. Our Privacy Policy
            details how we handle and protect your data, reflecting our
            commitment to maintaining the highest standards of security. Your
            trust means everything to us, and we work tirelessly to ensure your
            peace of mind.
          </p>
        </div>

        <div className="container-lg">
          <div className="privacy-policy-link">
            <h2 className="pppp">Privacy policy</h2>
          </div>
          <div className="privacy-policy-link1"
          
          dangerouslySetInnerHTML=
          {{
            __html: DOMPurify.sanitize(testinomial),
          }}

          >
            
            {/*<ul>
              <li>
                SWAGSTAY respects the importance of its users & privacy as well
                as the confidentiality of the information submitted by its users
                as a responsible data controller and data processor.
              </li>
              <li>
                This Privacy Policy outlines SWAGSTAY & its subsidiaries and
                affiliates' practices for processing and securing users &
                Personal Information (as defined below).
              </li>
              <li>
                This privacy statement applies to anybody (referred to as
                "User") who purchases, intends to purchase, or inquires about
                any product or service offered by SWAGSTAY through any of its
                customer interface channels, including its website, mobile site,
                mobile app, and offline channels, such as call centers and
                offices (collectively referred herein as "Sales Channels").
              </li>
              <li>
                For this Privacy Policy, "you" or "your" refers to the User, and
                "we," "us," or "our" refers to SWAGSTAY.
              </li>
            </ul>*/}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
