import React from "react";
import "./Css/ReferEarn.css";
import phoneReferImg from "../Assets/phoneReferImg.svg";
import CloudsBtm from "../Assets/cloudsBtm.png";
import { useLocation, useNavigate } from "react-router-dom";

export default function ReferEarn() {
  const navigate = useNavigate();
  return (
    <div className="ReferEarnContainer">
      <div className="container-lg">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-7 LefRefer">
            <div className="referHeading">
              <h2>Refer and Earn</h2>
              <div className="quickHotlsubhading">
                <p>
                  Share the <span>Love,</span> reap the reward
                </p>
              </div>
            </div>
            <div className="referCOntent">
              <h1>Refer to loved ones & get 100 Swag coins</h1>
              <p>
                Refer a friend or family member and earn SwagCoins! Redeem them
                on your next booking with SwagStay for even more savings and
                perks
              </p>
              <div className="refrBtttn mt-4">
                <button
                  type="button"
                  className="DarkButtonClass"
                  style={{ width: "fit-content" }}
                  onClick={()=>navigate("/refer_earn_page")}
                >
                  Refer Now {">>"}
                </button>
              </div>
            </div>
          </div>

          <div className=" col-lg-5 col-sm-5 col-6 mx-auto rightRefer">
            <img
              src={phoneReferImg}
              alt="phoneReferImg"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <img className="cloudsBottom" src={CloudsBtm} alt="Swagstay" />
    </div>
  );
}
