import { useEffect, useState } from "react";
import MobileImg from "../Assets/MobileImg.svg";
import SupportCharacter from "../Assets/SupportCharacter.svg";
import Building from "../Assets/buildingIMg.png";
import chatImg from "../Assets/chatImg.svg";
import CloudImg from "../Assets/cloudsBtm.png";
import House from "../Assets/houseImg.png";
import increaseDivRightImg from "../Assets/increaseDivRightImg.svg";
import listPropLeft from "../Assets/listFOrmImg.png";
import orengerightArrow from "../Assets/orengerightArrow.svg";
import BookingLoader from "./BookingLoader.js";
import {
  get_website_faq,
  save_property_url,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import "./Css/ListYourHotel.css";
import Footer from "./Footer";
import Header from "./Header";
import HeroBtmListData from "./HeroBtmListData.js";
import Faq from "./Reapeting Componets/Faq";
import SuccessFullModal from "./SuccessFullModal";
import Testimonial from "./Testimonial";

import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";
let click_time = 0;
export default function ListYourHotel() {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const increaseVisiData = [
    {
      imgSrc: MobileImg,
      title: "Direct booking",
      description:
        "Enjoy direct bookings without the hassle of third-party intermediaries. Our user-friendly platform makes it easy for guests to book directly with you, saving you on commission fees.",
    },
    {
      imgSrc: chatImg,
      title: "Advance analytics",
      description:
        "Stay ahead of the competition with our robust analytics dashboard. Track your hotel's performance, understand guest preferences, and make informed decisions to optimize your offerings.",
    },
    {
      imgSrc: SupportCharacter,
      title: "Dedicated support",
      description:
        "Our team is here to support you every step of the way. From setting up your listing to optimizing your profile, we provide expert guidance to ensure your success.",
    },
  ];
  const steps = [
    {
      number: "01",
      title: "Sign-up process",
      description: "Create an account and fill in basic property details.",
    },
    {
      number: "02",
      title: "Upload your property information",
      description: "Create an account and fill in basic property details.",
    },
    {
      number: "03",
      title: "Sign an e-contract",
      description: "Create an account and fill in basic property details.",
    },
    {
      number: "04",
      title: "Welcome your first guests",
      description: "Create an account and fill in basic property details.",
    },
  ];

  const [testinomial, settestinomial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [testimonial_image_link, settestimonial_image_link] = useState("");
  // const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        // console.log(Response.data.message[0].privacy_policy);
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            click_time = 0;
            handleShowSucessModal();
            empty_form(form_data);
            setTimeout(() => {
              handleLinkClick("/");
            }, 2000);
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };
  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 250,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="contList">
        {" "}
        <div className="ListYourHotelCont">
          <div className="OverListHeroHome"></div>
          <div className="heroUnderListHead">
            {/* <Header /> */}
            <div className="HeroLIstUnderHeading heaCop">
              <h1>
                Unlock new revenue streams{" "}
                <span className="d-sm-none"> list your hotel today</span>
              </h1>
              <span className="d-sm-block d-none"> list your hotel today</span>

              <label>
                Join our growing network of hospitality partners and experience
                increased visibility
              </label>

              <button
                type="button"
                className="processBtttn"
                onClick={() => handleActive("formCOntainerCreate")}
              >
                Processed
              </button>
            </div>

            {/*--------hero bottm content Section -------*/}
            <HeroBtmListData />
          </div>
        </div>{" "}
        <div className="container-lg" style={{ marginBottom: "3.5rem" }}>
          <div className="QuicklyHotl">
            {" "}
            <p>Why partner with us</p>
            <div className="quickHotlsubhading">
              {" "}
              <h2>
                Maximize your <span> hotel's potential </span>
              </h2>
            </div>
          </div>

          <div className="increaseDiv">
            <div className="DataIncre">
              <div className="row">
                <div className="col-lg-9">
                  <div className="LeftIncreaseDiv">
                    <div className="RIghtIncreaseDiv d-lg-none">
                      <img
                        src={increaseDivRightImg}
                        alt="increaseDivRightImg"
                      ></img>
                    </div>
                    <div className="d-flex align-items-center p-0">
                      {" "}
                      <img src={orengerightArrow} alt="orengerightArrow"></img>
                      <h2>Increased visibility</h2>
                    </div>

                    <p>
                      Gain access to a vast audience of travelers who are
                      actively searching for places to stay. Our platform ranks
                      high in search results, ensuring your property gets the
                      attention it deserves.
                    </p>
                  </div>
                </div>
                <div className="col-2 d-lg-flex d-none">
                  <div className="RIghtIncreaseDiv">
                    <img
                      src={increaseDivRightImg}
                      alt="increaseDivRightImg"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="increaseVisiImgCOnt">
              <div className="row">
                {increaseVisiData.map((item, index) => (
                  <div
                    className="col-lg-4"
                    style={{
                      borderRight:
                        index === 2
                          ? "none"
                          : "1px solid var(--border-line-hotel)",
                    }}
                    key={index}
                  >
                    <div className="increseCnt">
                      <div className="mobileImg">
                        <img
                          src={item.imgSrc}
                          alt={item.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="d-flex align-items-center p-0 arrowImgOrenge">
                        <img
                          src={orengerightArrow}
                          alt="orengerightArrow"
                          className="me-2"
                        />
                        <h2>{item.title}</h2>
                      </div>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/**----------How it work for hotel ------*/}
        </div>
        <div className="howItworkDivHotel">
          <div className="container-lg">
            <div className="QuicklyHotl">
              {" "}
              <p>How it works</p>
              <div className="quickHotlsubhading">
                {" "}
                <h2>
                  Listing <span> your hotel </span>is simple
                </h2>
              </div>
            </div>

            <div className="howItWorksHotel__steps">
              <div className="row">
                {steps.map((step, index) => (
                  <div key={index} className="col-12 col-sm-6 col-lg-4 mb-5">
                    <div className="howItWorksHotel__stepBox">
                      <h1 className="PaddingCLass">{step.number}</h1>
                      <div className="leftBorderLine">
                        <div className="col-lg-11">
                          <h3>{step.title}</h3>
                          <p>{step.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <img
            src={Building}
            alt="Swagstay"
            className="buildingImg d-lg-block d-none"
          />
          <img src={CloudImg} alt="Swagstay" className="cloudesImg" />
        </div>
        {/**------------- Ready To grow your Bussiness-------*/}
        <div className="ReadyBussiNessDiv">
          <div className="container-lg">
            <div className="row ReadyDivContent">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="leftReadyDiv">
                  <div className="readyHead">
                    <h2>Ready to grow your business?</h2>
                    <p>
                      Join hundreds of successful hotel owners who are growing
                      their businesses by listing on our platform
                    </p>
                  </div>

                  <button
                    style={{ width: "fit-content" }}
                    className="primaryBtn"
                  >
                    List Your Hotel Today
                  </button>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12  text-center mt-4 mt-md-0 p-0">
                <div className="RIghtReadyDiv">
                  <img src={House} alt="property"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------Testimonial--- */}
        <Testimonial
          testinomial={testinomial}
          testimonial_image_link={testimonial_image_link}
        />
        {/*--------Ready to boost-----------*/}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p>Increase your hotel's visibility today</p>
            <div className="quickHotlsubhading">
              {" "}
              <h2>
                Ready to boost <span> your bookings?</span>
              </h2>
            </div>
          </div>

          <div className="row listPropFOrm">
            {/* Left Column - Image and Heading */}
            <div className="col-lg-4 d-lg-flex d-none mb-3 mb-md-0 FormLftdata">
              <img
                src={listPropLeft}
                alt="Property Image"
                className="img-fluid w-100"
              />
            </div>

            {/* Right Column - Form */}
            <div
              className="col-lg-6 col-md-7 col-12 formCOntainerCreate"
              id="formCOntainerCreate"
            >
              <form className="rightFOrm" id="listmyhotel">
                <div className="inputContainer mb-3">
                  <label htmlFor="fullName">Full name</label>
                  <input
                    type="text"
                    className="trio_mandatory form-control"
                    id="namee"
                    name="namee"
                    placeholder="Name"
                    onChange={handleAphabetsChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="inputContainer mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="trio_mandatory form-control trio_email"
                    id="email"
                    name="email"
                    placeholder="abc@xyz.com"
                    onChange={handleEmailChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="row">
                  <div className="inputContainer col-12 col-md-6 mb-3 mb-md-0">
                    <label htmlFor="contactNumber">Contact number</label>
                    <input
                      type="text"
                      className="trio_mandatory form-control "
                      id="contactNumber"
                      name="phone"
                      maxLength={10}
                      placeholder="+91-XXXXXXXXXX"
                      onChange={handleNumbersChange}
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="inputContainer col-12 col-md-6">
                    <label htmlFor="cityName">City name</label>
                    <input
                      type="text"
                      className="trio_mandatory form-control"
                      id="cityName"
                      name="city"
                      placeholder="City name"
                      onChange={handleAphabetsChange}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="inputContainer mb-4">
                  <label htmlFor="propertyName">Property name</label>
                  <textarea
                    type="text"
                    className="trio_mandatory reasonTextarea form-control"
                    id="propertyName"
                    name="property"
                    placeholder="Type here"
                  />
                  <span className="condition_error"></span>
                </div>
                <button
                  className="DarkButtonClass"
                  style={{ width: "130px" }}
                  type="button"
                  onClick={() => {
                    handleSaveChangesdynamic("listmyhotel", save_property_url);
                  }}
                  // onClick={handleShowSucessModal}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>{" "}
        {/*------Testimonial--- */}
        <div className="container-lg">
          <Faq faq={faq} />
        </div>
        <Footer />
      </div>
      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
