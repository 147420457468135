import React, { useState } from "react";
import "./Css/OurService.css";
import { useLocation, useNavigate } from "react-router-dom";
import deleveryimg1 from "../Assets/deleveryimg1.svg";
import deleveryimg2 from "../Assets/deleveryimg2.svg";
import deleveryimg3 from "../Assets/deleveryimg3.svg";
import deleveryimg4 from "../Assets/deleveryimg4.svg";
import deleveryimg5 from "../Assets/deleveryimg5.svg";
import deleveryimg6 from "../Assets/deleveryimg6.svg";
export default function OurService() {
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const services = [
    { title: "Corporate stay", icon: deleveryimg1, description: "✈" },
    { title: "Family stay", icon: deleveryimg2, description: "👩‍👩‍👦" },
    { title: "Couple stay", icon: deleveryimg3, description: "💏" },
    { title: "Travel agent", icon: deleveryimg4, description: "👨‍✈️" },
    { title: "Influencer partnership", icon: deleveryimg5, description: "✨" },
    { title: "Banquet", icon: deleveryimg6, description: "🍾" },
  ];

  return (
    <div className={"HomeServeDiv mt-4"}>
      <div className="ourServiceContainer">
        <div className="container-lg p-0">
          <div className="ourserviceDiv">
            {/* <h2>Our Service</h2> */}
            <div className="quickHotlsubhading">
              <p>Delivering excellence in every aspect of your stay</p>
            </div>
          </div>
          <div className="row m-0 p-0">
            {services.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6 m-0 py-3">
                <div
                  className="service-item"
                  style={
                    screenWidth > 991
                      ? index === 1 || index === 4
                        ? { margin: "0 auto" }
                        : index === 2 || index === 5
                        ? { marginLeft: "auto" }
                        : {}
                      : {}
                  }
                >
                  <img src={service.icon} alt={service.title} />
                  <p>
                    {service.title} {service.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
