// Upcoming.js
import React, { useEffect, useState } from "react";
import {
  booking_all,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";
import BookingCard from "./BookingCard";
import Shimmer from "./Shimmer";
import NodataFound from "../Assets/NodataFound.png";
function Noshow() {
  const [ImageLink, setImageLink] = useState("");
  const [cancelledData, setcancelledData] = useState([]);
  const [rupeyIcon, setRupeyIcon] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "5");
    await server_post_data(booking_all, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcancelledData(final_data.no_show_list);
          setImageLink(final_data.hotel_image_link);
          setRupeyIcon(final_data.rupees_icon);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  return (
    <div>
      {showLoaderAdmin && <Shimmer />}

      {cancelledData && cancelledData.length > 0 ? (
        cancelledData.map((item, index) => (
          <BookingCard
            key={index}
            buttonIcon={
              <img
                src={require("../Assets/NextIcon.png")}
                alt="Upcoming Icon"
                className="icon"
              />
            }
            datass={item}
            image={ImageLink}
            rupey={rupeyIcon}
            route={"bookings_details"}
          />
        ))
      ) : (
        <div className="nobookingDiv">
          <img
            src={NodataFound}
            alt="No booking data available"
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Noshow;
