import React, { useEffect, useState } from "react";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";
import {
  booking_all,
  report_hotel_reactNative,
  server_post_data,
  update_rating_reactNative,
} from "../ServiceConnection/serviceconnection";
import BookingCard from "./BookingCard";
import "./Css/Modal.css";
import Shimmer from "./Shimmer.js";
import NodataFound from "../Assets/NodataFound.png";
function Inhouse() {
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [activeCard, setActiveCard] = useState(null);
  const [booking_id_data, setBooking_id] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedTripType, setSelectedTripType] = useState("Business");
  const [selectedCompanion, setSelectedCompanion] = useState("Family");
  const [selectedHotelDescription, setSelectedHotelDescription] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [otherReason, setOtherReason] = useState("");
  const [cancelledData, setCancelledData] = useState([]);
  const [ImageLink, setImageLink] = useState("");
  const [rupeyIcon, setRupeyIcon] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [additionalSuggestions, setAdditionalSuggestions] = useState("");
  // const [selectedTripType, setSelectedTripType] = useState('Business'); // Single select for trip type
  const [selectedTravelWith, setSelectedTravelWith] = useState("Family"); // Single select for travel with
  // const [selectedHotelDescription1, setSelectedHotelDescription1] = useState([
  //   'Luxury',
  // ]); // Multi-select for hotel description
  useEffect(() => {
    master_data_get();
  }, []);

  const feedbackDisabled = booking_id_data.total_rating > 0;
  const reportDisabled =
    booking_id_data.hotel_report !== null &&
    booking_id_data.hotel_report !== undefined;
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", "4");
    try {
      const response = await server_post_data(booking_all, fd);
      const data = response.split("~@~");
      if (parseInt(data[0]) === 1) {
        handleError(data[1]);
      } else {
        const finalData = JSON.parse(data[1]);
        setCancelledData(finalData.process_list);
        setImageLink(finalData.hotel_image_link);
        setRupeyIcon(finalData.rupees_icon);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoaderAdmin(false);
    }
  };

  const handleToggleModal = (event, cardIndex, data) => {
    // console.log(id)
    setBooking_id(data);
    const rect = event.currentTarget.getBoundingClientRect();
    const position = {
      top: Math.min(rect.top + window.scrollY, window.innerHeight - 300),
      left: Math.min(rect.left + window.scrollX, window.innerWidth - 300),
    };
    setModalPosition(position);
    setActiveCard(cardIndex);
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveCard(null);
  };

  const handleciclcheck = (value) => {
    setReportData((prevData) =>
      prevData.includes(value)
        ? prevData.filter((item) => item !== value)
        : [...prevData, value]
    );
  };
  const handleCancelBooking = () => {
    const reasonsString = reportData.join(", ");
    const finalReasons = otherReason
      ? `${reasonsString}, ${otherReason}`
      : reasonsString;
    handleSaveChangesdynamic(finalReasons);
  };

  const handleSaveChangesdynamic = async (finalReasons) => {
    const form_data = new FormData();
    form_data.append("booking_id", booking_id_data.booking_id);
    form_data.append("reason", finalReasons);
    console.log(booking_id_data.booking_id, finalReasons);
    try {
      const response = await server_post_data(
        report_hotel_reactNative,
        form_data
      );
      const data = response.split("~@~");
      if (parseInt(data[0]) === 1) {
        handleError(data[1]);
      } else {
        handleSuccess(data[1]);
        handleCloseReportModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenReportModal = () => setShowReportModal(true);
  const handleCloseReportModal = () => {
    setShowReportModal(false);
    setShowModal(false);
    setActiveCard(null);
    setReportData([]);
    setOtherReason("");
  };

  const handleOpenFeedbackModal = () => setShowFeedbackModal(true);
  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
    setShowModal(false);
    setActiveCard(null);
    setSelectedFeedback(null);
    setSelectedTripType(null);
    setSelectedCompanion(null);
    setSelectedHotelDescription([]);
  };

  const handleSingleSelect = (setter, value) => {
    setter((prev) => (prev === value ? null : value));
  };

  const handleMultiSelect = (value) => {
    setSelectedHotelDescription((prev) =>
      prev.includes(value)
        ? prev.filter((desc) => desc !== value)
        : [...prev, value]
    );
  };
  let report_data = [
    {
      id: 1,
      reasion: "Inaccurate information",
      desc: "The hotel description, amenities, or photos do not match the actual experience.",
    },
    {
      id: 2,
      reasion: "Cleanliness issues",
      desc: "The hotel does not meet cleanliness standards..",
    },
    {
      id: 3,
      reasion: "Safety concerns",
      desc: "Any safety hazards or concerns you encountered during your stay..",
    },
    {
      id: 4,
      reasion: "Unprofessional behavior",
      desc: "Staff behavior that was unprofessional or disrespectful..",
    },
    {
      id: 5,
      reasion: "Service quality",
      desc: "Poor service or lack of promised amenities..",
    },
    {
      id: 6,
      reasion: "Fraudulent activity",
      desc: "Any signs of fraudulent or suspicious activities..",
    },
  ];

  const submitFeedback = () => {
    const feedbackData = {
      emojiFeedback: selectedFeedback,
      tripType: selectedTripType,
      travelWith: selectedTravelWith,
      hotelDescription: selectedHotelDescription,
      additionalSuggestions: additionalSuggestions,
    };

    handleSaveChangesdynamicaa(
      booking_id_data.booking_id,
      booking_id_data.cbh_id,
      feedbackData.emojiFeedback,
      feedbackData.tripType,
      feedbackData.travelWith,
      feedbackData.hotelDescription.join(","),
      feedbackData.additionalSuggestions
    );
  };

  const handleSaveChangesdynamicaa = async (
    booking_id,
    cbh_id,
    feedback,
    trip_type,
    travel_with,
    hotel_type,
    review
  ) => {
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("cbh_id", cbh_id);
    form_data.append("trip_type", trip_type);
    form_data.append("travel_with", travel_with);
    form_data.append("hotel_type", hotel_type);
    form_data.append("feedback", feedback);
    form_data.append("review", review);
    form_data.append("location_str", 0);
    form_data.append("cleaning_str", 0);
    form_data.append("services_str", 0);
    form_data.append("price_str", 0);
    await server_post_data(update_rating_reactNative, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleCloseFeedbackModal();
          handleSuccess(data[1]);
          // navigation.navigate('Main');
        }
      })
      .catch((error) => {
        // handleError('network');
        console.log(error);
      });
  };

  return (
    <div>
      {showLoaderAdmin && <Shimmer />}

      {cancelledData && cancelledData.length > 0 ? (
        cancelledData.map((item, index) => (
          <BookingCard
            key={index}
            buttonIcon={
              <img
                src={require("../Assets/OptionIcon.png")}
                alt="Option Icon"
                className="icon"
                onClick={(event) => handleToggleModal(event, index, item)}
              />
            }
            route={"bookings_details"}
            datass={item}
            image={ImageLink}
            rupey={rupeyIcon}
          />
        ))
      ) : (
        <div className="nobookingDiv">
          <img
            src={NodataFound}
            alt="No booking data available"
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      )}
      {showModal && activeCard !== null && (
        <div
          className="modal-overlay option"
          onClick={handleCloseModal}
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: modalPosition.top,
            right: 20,
            padding: "10px 20px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "12px",
          }}
        >
          <div
            className="modal-content option"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="modal-option"
              disabled={feedbackDisabled}
              style={{
                opacity: feedbackDisabled ? 0.5 : 1,
                cursor: feedbackDisabled ? "not-allowed" : "pointer",
              }}
              onClick={handleOpenReportModal}
            >
              <img
                src={require("../Assets/reportIcon.png")}
                alt="Report Icon"
                className="modal-icon"
              />
              <span className="modal-text report-text">Report Hotel</span>
            </button>
            <button
              className="modal-option"
              disabled={reportDisabled}
              style={{
                opacity: reportDisabled ? 0.5 : 1,
                cursor: reportDisabled ? "not-allowed" : "pointer",
              }}
              onClick={handleOpenFeedbackModal}
            >
              <img
                src={require("../Assets/feedbackIcon.png")}
                alt="Feedback Icon"
                className="modal-icon"
              />
              <span className="modal-text feedback-text">Give Feedback</span>
            </button>
          </div>
        </div>
      )}
      {/* Report Modal */}
      {showReportModal && (
        <div className="report-modal-overlay" onClick={handleCloseReportModal}>
          <div
            className="report-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="report-modal-heading">Report Hotel</h2>
            <p className="detailsTxt">
              If you encounter any issues during your stay or notice any
              discrepancies, please let us know.
            </p>
            <h5 className="headingh5 mb-3">Reasons to Report</h5>
            <div className="reasons-container mb-4">
              {report_data.map((reason) => (
                <label key={reason.id} className="custom-checkbox">
                  <input
                    type="checkbox"
                    value={reason}
                    onChange={() => handleciclcheck(reason.reasion)}
                  />
                  <span className="checkbox-checkmark"></span>
                  <div className="reason-text">
                    <p className="reason-title">{reason.reasion}</p>
                    <p className="reason-description">{reason.desc}</p>
                  </div>
                </label>
              ))}
            </div>
            <h5 className="headingh5 mb-3">Other reason (type here)</h5>
            <textarea
              className="reasonTextarea"
              placeholder="Other reason..."
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
            />
            <button className="DarkButtonClass" onClick={handleCancelBooking}>
              Submit Report
            </button>
          </div>
        </div>
      )}
      {/* Feedback Modal */}
      {showFeedbackModal && (
        <div
          className="feedback-modal-overlay"
          onClick={handleCloseFeedbackModal}
        >
          <div
            className="feedback-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="feedback-modal-heading">Feedback</h2>
            <div className="feedback-header">
              <img
                src={`${ImageLink}${booking_id_data.image_name}`}
                alt="Hotel"
                className="feedbackhotelimage"
              />
              <div>
                <h3 className="hotel-name">{booking_id_data.hotel_name}</h3>
                <p className="hotel-address">{booking_id_data.address}</p>
              </div>
            </div>

            <div className="feedback-section">
              <h5>Give feedback</h5>
              <div className="feedback-options">
                {[
                  { emoji: "😀", description: "Amazing", value: 5.0 },
                  { emoji: "😊", description: "Good", value: 4.0 },
                  { emoji: "😐", description: "Okay", value: 3.0 },
                  { emoji: "😕", description: "Bad", value: 2.0 },
                  { emoji: "😢", description: "Terrible", value: 1.0 },
                ].map((text, index) => (
                  <button
                    key={index}
                    className={
                      selectedFeedback === text.value ? "selected" : ""
                    }
                    onClick={() =>
                      handleSingleSelect(setSelectedFeedback, text.value)
                    }
                  >
                    {text.emoji} {text.description}
                  </button>
                ))}
              </div>
            </div>

            <textarea
              placeholder="Any additional suggestion"
              className="feedback-textarea"
              onChange={(e) => setAdditionalSuggestions(e.target.value)}
            ></textarea>

            <div className="feedback-detail-section">
              <h5>What kind of trip was it?</h5>
              {["Business", "Vacation"].map((type, index) => (
                <button
                  key={index}
                  className={selectedTripType === type ? "selected" : ""}
                  onClick={() => handleSingleSelect(setSelectedTripType, type)}
                >
                  {type}
                </button>
              ))}

              <h5>Who did you travel with?</h5>
              {["Family", "Friends", "Couple", "Solo"].map(
                (companion, index) => (
                  <button
                    key={index}
                    className={
                      selectedCompanion === companion ? "selected" : ""
                    }
                    onClick={() =>
                      handleSingleSelect(setSelectedCompanion, companion)
                    }
                  >
                    {companion}
                  </button>
                )
              )}

              <h5>How would you describe the hotel?</h5>
              {[
                "Luxury",
                "Prime location",
                "Cleanliness",
                "Budget friendly",
                "Family friendly",
              ].map((desc, index) => (
                <button
                  key={index}
                  className={
                    selectedHotelDescription.includes(desc) ? "selected" : ""
                  }
                  onClick={() => handleMultiSelect(desc)}
                >
                  {desc}
                </button>
              ))}
            </div>

            <div className="submit-btn-container">
              <button className="submit-btn" onClick={() => submitFeedback()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Inhouse;
