import React, { useEffect, useState } from "react";
import "./Css/SideBar.css";
import DummyUser from "../Assets/dummyUser.png";
import ArrowSide from "../Assets/downIconWhite.svg";
import WhiteCross from "../Assets/whiteCross.svg";
import RightAngle from "../Assets/rightAngle.png";
import OrgnRight from "../Assets/orngRight.png";
import SwagQR from "../Assets/qrcode.png";
import Bed from "../Assets/bed.png";
import Mobile from "../Assets/mobile.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection";

function SideBar({ isOpen, onClose, handleLogOutClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [fullName, setfullname] = useState(null);
  const [ProfileImg, setProfileImage] = useState(null);

  useEffect(() => {
    getDatafromlocalstorage();
  }, []);

  const getDatafromlocalstorage = () => {
    const first_name = retrieveData("customer_first_name");
    const last_name = retrieveData("customer_last_name");
    const userImage = retrieveData("customer_image");

    setfullname(first_name + " " + last_name);
    setProfileImage(userImage);
  };

  return (
    <>
      {isOpen && <div className="sideoverlay" onClick={onClose}></div>}
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-content">
          <div className="sidebarTop">
            <div className="sidePRofile">
              <img
                onError={(e) => {
                  e.target.src = DummyUser;
                }}
                src={ProfileImg ? ProfileImg : DummyUser}
                alt="Swagstay"
                className="profile-image"
              />
              <div className="sideProfileDetail">
                <p className="proName">
                  {fullName && fullName.length > 0 && fullName !== " "
                    ? fullName
                    : "User"}
                </p>
                <button
                  className="proEditSIde"
                  onClick={() => navigate("/profile")}
                >
                  <p>Edit Profile</p>
                  <img
                    src={ArrowSide}
                    alt="Swagstay"
                    className="arrowImgSide"
                  />
                </button>
              </div>
            </div>
            <div className="sideClose">
              <button onClick={onClose} className="Sideclose-button">
                <img src={WhiteCross} alt="Swagstay" className="closeImgSide" />
              </button>
            </div>
          </div>
          <div className="sidebarBottom">
            {/* My Bookings */}
            <div className="sideList">
              <Link
                to="/bookings"
                className={`sideListItem ${
                  location.pathname === "/bookings" ||
                  location.pathname.includes("bookings_details") ||
                  location.pathname.includes("bookings_details")
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>My Bookings</p>
                {location.pathname === "/bookings" ||
                location.pathname.includes("bookings_details") ||
                location.pathname.includes("bookings_details") ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Swag Wallet */}
              <Link
                to="/swag_stay_wallet"
                className={`sideListItem ${
                  location.pathname === "/swag_stay_wallet"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Swag Wallet</p>
                {location.pathname === "/swag_stay_wallet" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Favorite Properties */}
              <Link
                to="/favorites"
                className={`sideListItem ${
                  location.pathname === "/favorites" ? "selectedListItem" : ""
                }`}
              >
                <p>Favorite Properties</p>
                {location.pathname === "/favorites" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Bookin Request */}
              <Link
                to="/booking_request"
                className={`sideListItem ${
                  location.pathname === "/booking_request"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Booking Request</p>
                {location.pathname === "/booking_request" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Refund status */}
              <Link
                to="/refund_status"
                className={`sideListItem ${
                  location.pathname === "/refund_status"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Refund Status</p>
                {location.pathname === "/refund_status" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* My rewards */}
              <Link
                to="/reward_page"
                className={`sideListItem ${
                  location.pathname === "/reward_page" ? "selectedListItem" : ""
                }`}
              >
                <p>My Rewards</p>
                {location.pathname === "/reward_page" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Refer and earn */}
              <Link
                to="/refer_earn_page"
                className={`sideListItem ${
                  location.pathname === "/refer_earn_page"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Refer and Earn</p>
                {location.pathname === "/refer_earn_page" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Travel agent */}
              <Link
                to="/travel_agent"
                className={`sideListItem ${
                  location.pathname === "/travel_agent"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Travel Agent</p>
                {location.pathname === "/travel_agent" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Banquet inquiry */}
              <Link
                to="/banquet"
                className={`sideListItem ${
                  location.pathname === "/banquet" ? "selectedListItem" : ""
                }`}
              >
                <p>Banquet Inquiry</p>
                {location.pathname === "/banquet" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Influencer */}
              <Link
                to="/influencer"
                className={`sideListItem ${
                  location.pathname === "/influencer" ? "selectedListItem" : ""
                }`}
              >
                <p>Influencer</p>
                {location.pathname === "/influencer" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Privacy policies */}
              <Link
                to="/privacy_policy"
                className={`sideListItem ${
                  location.pathname === "/privacy_policy"
                    ? "selectedListItem"
                    : ""
                }`}
              >
                <p>Privacy Policies</p>
                {location.pathname === "/privacy_policy" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Help */}
              <Link
                to="/help"
                className={`sideListItem ${
                  location.pathname === "/help" ? "selectedListItem" : ""
                }`}
              >
                <p>Contact Us</p>
                {location.pathname === "/help" ? (
                  <img
                    src={OrgnRight}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                ) : (
                  <img
                    src={RightAngle}
                    alt="Swagstay"
                    className="arrowListSide"
                  />
                )}
              </Link>

              {/* Logout */}
              <Link
                className={`sideListItem `}
                onClick={() => handleLogOutClick()}
              >
                <p>Logout</p>
                <img
                  src={RightAngle}
                  alt="Swagstay"
                  className="arrowListSide"
                />
              </Link>
            </div>

            <div className="sidebarFooter">
              <div className="sideFooterText">
                <p className="sideHead">
                  Enjoy a superior experience on our Mobile App!
                </p>
                <p className="sideSubHead">
                  Get instant 200Swag coins when you signup on our app
                </p>
                <div className="appandHotelCount">
                  <div className="d-flex align-items-center gap-1">
                    <img src={Mobile} alt="Swagstay" />
                    <p>50K+ App download</p>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <img src={Bed} alt="Swagstay" />
                    <p>100+ Hotels</p>
                  </div>
                </div>
              </div>
              <div className="SwagQR">
                <img src={SwagQR} alt="Swagstay" className="SwagQRImg" />
              </div>
              <div className="SwagQR">
                <p>
                  Download the <span>SwagStay App</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
