import React, { useState, useEffect } from "react";
import "../Css/Faq.css";
import { useLocation } from "react-router-dom";
import Header from "../Header";
export default function Faq({ faq }) {
  const [openFaq, setOpenFaq] = useState(null);

  const location = useLocation();
  const isHomeRoute = location.pathname === "/hotel_detail";
  const isHeaderShow = location.pathname === "/faq";

  const toggleFaq = (index) => {
    if (openFaq === index) {
      setOpenFaq(null); // Close if already open
    } else {
      setOpenFaq(index); // Open selected
    }
  };

  return (
    <>
      {isHeaderShow && <Header />}{" "}
      <div className={`FaqCont ${isHeaderShow ? "container-lg" : ""}`}>
        <div className="">
          <div className="QuicklyHotl testiHead">
            {" "}
            <div>
              {" "}
              {!isHomeRoute && (
                <p style={{ marginTop: "0.3rem", fontFamily: "Satoshi" }}>
                  FAQs
                </p>
              )}
              <div className="d-flex align-items-center gap-1">
                <h2
                  className="santosiFOntCLass"
                  style={{ marginTop: "0.4rem" }}
                >
                  Frequently asked questions
                </h2>
              </div>
              <div className="faqData">
                {faq &&
                  faq.length > 0 &&
                  faq.map((item, index) => (
                    <div
                      key={index}
                      className={`faqItem ${openFaq === index ? "open" : ""}`}
                    >
                      <div
                        className="faqQuestion d-flex justify-content-between align-items-center"
                        onClick={() => toggleFaq(index)}
                      >
                        <p>{item.question_add}</p>
                        <span
                          className={`arrow ${
                            openFaq === index ? "rotate" : ""
                          }`}
                        >
                          &#9662;
                        </span>
                      </div>
                      <div className="faqAnswer">
                        <p>{item.answer_add}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}
