import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Css/BlogDetail.css";
import Footer from "./Footer.js";
import Header from "./Header.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import { useLocation } from "react-router-dom";
import {
  blog_detail_website,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
function BlogDetail() {
  const [ImageLink, setImageLink] = useState("");
  const [blogPosts, setcancelledData] = useState([]);
  const [DetailsData, setDetailsData] = useState([]);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  useEffect(() => {
    master_data_get_details();
  }, []);
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "blog/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    await server_post_data(blog_detail_website, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcancelledData(final_data.details);
          setDetailsData(final_data.blogss[0]);
          setSEOloop(final_data.seo_list);
          setImageLink(final_data.blog_image_link);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  return (
    <div className="shoblog">
      <div className="header_container">
        <Header />
      </div>

      <div className="shoblog_container container-lg">
        <div className="shoblogHead col-lg-11 mx-auto">
          <div className="blogprofileinfo">
            {/* <img
              src="/Assets/dummyUser.png"
              alt="Profile"
              className="blogdetails_icon_user"
            /> */}
            <div className="blogprofileinfoText">
              <h5>{DetailsData.author}</h5>
              <div className="Post_date">
                <p>Posted on {formatDate(DetailsData.entry_date)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="blogheading1 col-lg-10 mx-auto">
          <h3>{DetailsData.title_name}</h3>
        </div>
        <div className="blogImg11 col-12">
          <img src={`${ImageLink}${DetailsData.banner_image}`} alt="Header" />
        </div>

        <div className="Robo_content col-md-10 m-auto">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(DetailsData.description),
            }}
          />
        </div>

        <div className="whattoread">
          <div className="blogupfooter col-xl-12">
            <h3>What to Read Next</h3>
          </div>

          <section className="blog blogSHowSEction">
            <div className="blog_section container-lg">
              <div className="card_container">
                <div className="row BlogData">
                  {blogPosts &&
                    blogPosts.length > 0 &&
                    blogPosts.map((post, index) => (
                      <Link
                        onClick={() =>
                          handleLinkClick("/" + match_and_return_seo_link(post.b_id))
                        }
                        key={index}
                        className="col-sm-6 col-lg-4  mb-4 text-dark"
                      >
                        <div className="blogcard">
                          <img
                            src={`${ImageLink}${post.banner_image}`}
                            className="blogcard-img-top"
                            alt={`Blog post ${index + 1}`}
                          />

                          <div className="blogcard-body">
                            <small className="text-muted">
                              {/* {onchange={handleDateChange}} */}
                              {post.author} • {post.entry_date}
                            </small>
                            <h5 className="blogcard-title mt-2">
                              {truncateWords(post.title_name, 3)}
                            </h5>
                            <p
                              className="blogcard-text"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  truncateWords(post.description, 6)
                                ),
                              }}
                            ></p>

                            <button className="blogReadmore" type="button">
                              {" "}
                              Read more
                            </button>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BlogDetail;
