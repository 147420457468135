import React, { useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import successImage from '../Assets/successimg.png'; // Update this path as per your project structure

const BookingSuccessScreen = () => {
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);

  return (
    <div
      style={{
        backgroundColor: '#F86855', // Ensures the background color
        width: '100vw', // Full viewport width
        height: '100vh', // Full viewport height
        display: 'flex', // Use flexbox for centering
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center', // Center horizontally
        justifyContent: 'center', // Center vertically
      }}
    >
      {/* Success Image */}
      <img
        src={successImage}
        alt="Success"
        style={{
          width: '175px',
          height: '175px',
          marginBottom: '16px', // Space between image and text
        }}
      />

      {/* Confirmation Text */}
      <h1
        style={{
          fontSize: '24px',
          color: '#FFFFFF',
          textAlign: 'center',
        }}
      >
        Your booking has been confirmed
      </h1>

      {/* Confetti Animation */}
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={true}
          numberOfPieces={100}
          gravity={0.2}
          colors={[
            '#FFE347', // yellow
            '#54D3C2', // green
            '#FF89BB', // pink
            '#7CD0FF', // blue
            '#FFB443', // orange
            '#A18BFF', // purple
          ]}
          confettiSource={{
            x: 0, // Start confetti from the full width
            y: height, // Start from the bottom
            w: width,
            h: 0,
          }}
        />
      )}
    </div>
  );
};

export default BookingSuccessScreen;
