import React from "react";
import { Modal } from "react-bootstrap";
import Filter from "./Filter";
import "./Css/FilterModal.css";
import crossicon from "../Assets/crossicon.svg";

export default function FilterModal({
  FilterModalShow,
  HandleCloseFilterModal,
  priceInRangemin,
  priceInRangemax,
  Amenities,
  matchesLocality,
  FilterLandmark,
  handleFilters, // Receive the handleFilters function as a prop
}) {
  return (
    <div>
      <Modal
        show={FilterModalShow}
        onHide={() => HandleCloseFilterModal()}
        size="md"
        centered
      >
        <Modal.Body style={{ position: "relative" }}>
          <div className="closeBtnFIlter">
            <button type="button" onClick={HandleCloseFilterModal}>
              <img src={crossicon} alt="crossicon"></img>
            </button>
          </div>
          <Filter
            onClose={() => HandleCloseFilterModal()}
            onApplyFilters={(filterData) => handleFilters(filterData)}
            priceInRangemin={priceInRangemin}
            priceInRangemax={priceInRangemax}
            Amenities={Amenities}
            matchesLocality={matchesLocality}
            FilterLandmark={FilterLandmark}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
