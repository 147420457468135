import React from "react";
import { Modal } from "react-bootstrap";
import "./Css/SharePopup.css";

// Import icons from assets folder
import FacebookIcon from "../Assets/Facebook.svg";
import InstagramIcon from "../Assets/Instagram.svg";
import TwitterIcon from "../Assets/Twitter.svg";
import WhatsAppIcon from "../Assets/whatAPpIcn.svg";
function SharePopup({ isOpen, onRequestClose, Content }) {
  const shareOnPlatform = (platform) => {
    const encodedContent = encodeURIComponent(Content);
    const platforms = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedContent}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodedContent}`,
      whatsapp: `https://wa.me/?text=${encodedContent}`,
      instagram: `https://www.instagram.com/`, // Instagram doesn't support direct sharing
    };

    window.open(platforms[platform], "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(Content)
      .then(() => alert("Copied successfully!"))
      .catch((err) => console.error("Copy failed", err));
  };

  return (
    <Modal
      show={isOpen}
      onHide={onRequestClose}
      centered
      className="share-popup-modal"
    >
      <Modal.Body>
        <div className="share-popup">
          <h2>Share with your friend</h2>
          <div className="share-icons">
            <div
              className="iconShareP"
              onClick={() => shareOnPlatform("whatsapp")}
            >
              <img src={WhatsAppIcon} alt="WhatsApp" />
              <span>WhatsApp</span>
            </div>
            <div
              className="iconShareP"
              onClick={() => shareOnPlatform("facebook")}
            >
              <img src={FacebookIcon} alt="Facebook" />
              <span>Facebook</span>
            </div>
            {/* <div
              className="iconShareP"
              onClick={() => shareOnPlatform("instagram")}
            >
              <img src={InstagramIcon} alt="Instagram" />
              <span>Instagram</span>
            </div>
            <div
              className="iconShareP"
              onClick={() => shareOnPlatform("twitter")}
            >
              <img src={TwitterIcon} alt="Twitter" />
              <span>Twitter</span>
            </div> */}
          </div>
          <div className="share-link">
            <input type="text" value={Content} readOnly />
            <button onClick={handleCopy}>Copy</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SharePopup;
