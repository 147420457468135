import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Css/TermsCondition.css";
import DOMPurify from "dompurify";
import {
  server_post_data,
  website_content,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";
import Footer from "./Footer";

export const TermsCondition = () => {
  const [testinomial, settestinomial] = useState([]);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("which_page", 1);
    await server_post_data(website_content, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        console.log(data1);
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.website_content.application_text);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  console.log(testinomial);
  return (
    <>
      <Header />
      <div className="containersssss ">
        <div className="heroPrivacy">
          <h1>Term & Conditions</h1>
        </div>

        <div className="container-lg">
          <div className="privacy-policy-link">
            <h2 className="pppp">General</h2>
          </div>
          <div
            className="privacy-policy-link1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(testinomial),
            }}
          >
            {/* <h3>Eligibility:</h3> */}
          </div>
          {/* <div className="section">
          <h3>Referral rewards:</h3>
          
        </div>
        
        <div className="section">
          <h3>Referral rewards:</h3>
          
        </div>
        <div className="section">
          <h3>Referral rewards:</h3>
         
        </div>
        <div className="section">
          <h3>Referral rewards:</h3>
         
        </div> 
        */}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default TermsCondition;
