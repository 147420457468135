import React from "react";
import "./Css/ReferEarnPage.css";
import Header from "./Header";
import rightRfrr from "../Assets/rightRfrr.svg";
import orengeRightIcN from "../Assets/orengeRightIcN.svg";
import whatAPP from "../Assets/whatAPpIcn.svg";
import instIcn from "../Assets/instIcn.svg";
import moreBtn from "../Assets/moreBtn.svg";
import rewrdImg from "../Assets/rewrdImg.svg";
import {
  server_post_data,
  get_refer_earn_page,
} from "../ServiceConnection/serviceconnection";
import { useEffect, useState } from "react";
import { handleError } from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import { retrieveData } from "../LocalConnection/LocalConnection";

export default function ReferEarnPage() {
  const [ShareAbleLink, setShareAbleLink] = useState("");
  const [customerid, setcustomerid] = useState("");
  const [referralcode, setreferralcode] = useState("ABCDG1234");
  const [totalcoin, settotalcoin] = useState("0");
  const [shareablecoin, setshareablecoin] = useState("0");
  const [faq, setfaq] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const referralSteps = [
    { step: "01", description: "Invite your friend & get rewarded" },
    {
      step: "02",
      description: `They get ${shareablecoin} swag coin on their first booking`,
    },
    {
      step: "03",
      description: `You get ${shareablecoin} swag coin once their booking is completed`,
    },
  ];

  const socialMedia = [
    {
      icon: whatAPP,
      name: "WhatsApp",
      action: () => sendMessageWhatsApp(ShareAbleLink),
    },
    // {
    //   icon: instIcn,
    //   name: "Instagram",
    //   action: () => sendMessageInstagram(ShareAbleLink),
    // },
    { icon: moreBtn, name: "More", action: () => setSharePopupOpen(true) },
  ];

  const sendMessageWhatsApp = (message) => {
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const sendMessageInstagram = (message) => {
    // Copy the message to the clipboard
    navigator.clipboard
      .writeText(message)
      .then(() => {
        // Alert the user that the message is copied
        alert(
          "Message copied to clipboard! Please log in to Instagram and paste it in Direct Messages."
        );

        // Open Instagram's Direct Messages page
        const url = "https://www.instagram.com/direct/inbox/";
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        alert("Failed to copy message. Please copy it manually: " + message);
      });
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_refer_earn_page, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setShareAbleLink(final_data.shareable_text);
          setisSharePopupContent(final_data.shareable_text);
          setreferralcode(final_data.reffer_code);
          settotalcoin(final_data.wallet_amount);
          setshareablecoin(final_data.reffer_coin);
          setfaq(final_data.faq_data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();

    const customer_id = retrieveData("customer_id");
    if (customer_id === null || customer_id === 0) {
      customer_id = 0;
    }
    setcustomerid(customer_id);
  }, []);

  console.log(customerid);

  const handleCopy = () => {
    if (parseInt(customerid) > 0) {
      navigator.clipboard
        .writeText(referralcode)
        .then(() => {
          alert("Referral code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy the referral code: ", err);
        });
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-lg">
        <div className="refrerEarnBg">
          <div className="row">
            <div className="col lefRefer">
              <h1>Refer your friend and earn</h1>
              <p>
                Your friend gets {shareablecoin} swagcoin on signup, and you get{" "}
                {shareablecoin}
                swagcoin too every time!
              </p>

              <div className="ReferCOde">
                <div className="underCont">
                  <p>Your referral code</p>
                  <h1>{referralcode}</h1>
                </div>
                <div className="refrVericleLine"></div>
                <div
                  className="underCont2"
                  onClick={handleCopy}
                  style={{ cursor: "pointer" }}
                >
                  <h1>Copy </h1>
                  <h1>Code</h1>
                </div>
              </div>
            </div>
            <div className="col rightRfrrSIde">
              <img src={rightRfrr} alt="rightRfrr" />
            </div>
          </div>
        </div>

        {/*-----------------Check Swag Balance---------------- */}
        <div className="checkSggBlanc">
          <h1>Check Swagcoin balance</h1>
          <Link to="/reward_page" className="coinsDIv">
            <span>{totalcoin} Coins</span>
            <img src={orengeRightIcN} alt="orengeRightIcN" />
          </Link>
        </div>

        {/*------------------ Refer Via---------------- */}
        <div className="referViaDiv">
          <h1>Refer via</h1>
          <div className="SOcialMDiv">
            {socialMedia.map((media, index) => (
              <div
                key={index}
                className="whatAPpdiv"
                onClick={media.action}
                style={{ cursor: "pointer" }}
              >
                <img src={media.icon} alt={media.name} />
                <p>{media.name}</p>
              </div>
            ))}
          </div>
        </div>

        {/*------------- How it works---------------- */}
        <div className="howITWrk">
          <h1>How it works:</h1>
          <div className="listData">
            {referralSteps.map((step, index) => (
              <div key={index} className="circleDiv">
                <button type="button">{step.step}</button>
                <p>{step.description}</p>
              </div>
            ))}
          </div>

          <div className="trmCOnd">
            <Link to="/refer_terms_page"> • Terms & Conditions</Link>
            <Link to="/refer_faq_page"> • FAQs</Link>
          </div>
        </div>

        {/*------------You Are Get Section---------------- */}

        <div className="youAReGet">
          <div className="youAReHead">
            <h1>You are yet to earn any scratch cards</h1>
            <p>start referring to get surprise</p>
          </div>
        </div>

        {/*------------You Are Get Section---------------- */}
        <div className="EarndCOins">
          <div className="col leftCOin">
            <h1>
              Earn <span>{shareablecoin} Swag Coin</span> on every successful
              referral
            </h1>
          </div>
          <div className="col RIghtCOin">
            <img src={rewrdImg} alt="rewrdImg"></img>
          </div>
        </div>
      </div>
      <SharePopup
        isOpen={isSharePopupOpen}
        onRequestClose={() => setSharePopupOpen(false)}
        Content={isSharePopupContent}
      />
    </div>
  );
}
