import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Css/GuestPolicy.css";
import LeftArrow from "../Assets/arrowicon.svg";
import {
  server_post_data,
  website_information,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export const GuestPolicy = () => {
  const [helpdata, settestinomial] = useState([]);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    // fd.append("which_page", 1);
    await server_post_data(website_information, fd)
      .then((Response) => {
        console.log(Response);
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.website_content[0]);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  return (
    <>
      <Header />
      <div className="container-lg">
        <div className="simpleheader">
          <div className="GuesHedddd">
            <img className="guestarrow" src={LeftArrow} alt="Left Arrow" />
            <p>GuestPolicy</p>
            <div></div>
          </div>
        </div>
        <div className="guestsectionGuesPol">
          <h1>Check-in & Check-out</h1>
          <li>
            Early check-in and late check-out may be available upon request and
            subject to additional charges.
          </li>
        </div>
        <div className="guestsectionGuesPol">
          <h1>Age Requirements</h1>
          <li>
          Guests must be at least 18 years old to book a room.
          </li>
          <li>Children under the age of 12 stay free when using existing bedding with parents.</li>
        </div>
        <div className="guestsectionGuesPol">
          <h1>Guest Capacity & Extra Beds</h1>
          <li>
          Room rates are based on the occupancy limits of each room.
          </li>
          <li>
          Extra beds are available for an additional charge (subject to availability).
          </li>
        </div>
      </div>
    </>
  );
};

export default GuestPolicy;
