import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Css/Home.css";
import QuickHotel from "./QuickHotel";
import SearchData from "./SearchData";
import ExpressCheck from "../Assets/ExpressCheck.svg";
import SecurePayment from "../Assets/SecurePayment.svg";

import WhatsNew from "./WhatsNew";
import OurService from "./OurService";
import Discount from "./Discount";
import ReferEarn from "./ReferEarn";
import WhyChoose from "./WhyChoose";
import NewsLatter from "./NewsLatter";
import HeroBtmListData from "./HeroBtmListData.js";
import Shimmer from "./Shimmer.js";
import BookingLoader from "./BookingLoader.js";
import {
  server_post_data,
  city_list_website,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";
export default function Home() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [CityImage, setCityImage] = useState("");
  const [distance_lat, setdistance_lat] = useState("");
  const [SEOloop, setSEOloop] = useState([]);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(city_list_website, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          const final_data = JSON.parse(data[1]);
          setSEOloop(final_data.seo_list);
          setCityList(final_data.hotel_citylist);
          setCityImage(final_data.city_image_link);
          setdistance_lat(final_data.distance_lat);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <>
      {" "}
      <Header />
      {/* <BookingLoader /> */}
      {showLoaderAdmin && <Shimmer />}
      {/*--------HeroSection -------*/}
      <div className="heroSectionHome">
        <div className="OverFLowHome"></div>
        <div className="heroUnderHead">
          <div className="middleHeroContent">
            <div className="HeroUnderHeading">
              <h1>Experience the new way of </h1>
              <span>hotel booking</span>
            </div>
            {/*--------hero Payment content Section -------*/}
            <div className="paymentText d-flex align-items-center flex-wrap">
              <div className="d-flex gap-2">
                <img src={SecurePayment} alt="ExpressCheck"></img>

                <label>Secure payment method</label>
              </div>
              <div className="d-flex gap-2">
                <img src={ExpressCheck} alt="ExpressCheck"></img>
                <label>Express check-in faciity</label>
              </div>
            </div>

            {/*--------Search Data Section -------*/}
            <SearchData />
          </div>

          {/*--------hero bottm content Section -------*/}

          <HeroBtmListData />
        </div>
      </div>
      {CityList && CityList.length > 0 && CityImage && CityImage !== "" && (
        <QuickHotel
          citylist={CityList}
          distance_lat={distance_lat}
          cityimage={CityImage}
          seoData={SEOloop}
        />
      )}
      {/*----------Whats New Hotel Section--------*/}
      <WhatsNew />
      {/*----------Our Service Section--------*/}
      <OurService />
      {/*----------Discount  Section--------*/}
      <Discount />
      {/*----------WhyChoose Section--------*/}
      <WhyChoose />
      {/*----------Refer  and Earn  Section--------*/}
      <ReferEarn />
      {/*----------News Letter  Section--------*/}
      <NewsLatter />
      <Footer />
    </>
  );
}
