import React from "react";
import carIcon from "../Assets/carIcon.svg";
import buildingIcon from "../Assets/buildingIcon.svg";
import mobileAppicon from "../Assets/mobileAppicon.svg";
import CorporateIcon from "../Assets/CorporateIcon.svg";
import NetworkIcon from "../Assets/NetworkIcon.svg";
import { useLocation } from "react-router-dom";
import "./Css/ListYourHotel.css";
import "./Css/CoprativeSolution.css";
import "./Css/TravelAgent.css";
export default function HeroBtmListData() {
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";
  return (
    <div
      className={`flex-wrap ListHeroBottm ${
        isHomeRoute ? "HeroBottmHeroContentHome" : "HeroBottmHeroContent"
      }`}
    >
      {/* Marquee content with div wrapper inside */}
      <div className="marquee-content">
        <marquee>
          <div className="marquee-wrapper d-flex gap-4">
            <div className="d-flex ImgsIcon gap-2">
              <img src={carIcon} alt="ExpressCheck" />
              <label>50K+ App download</label>
            </div>
            <div className="d-flex gap-2">
              <img src={buildingIcon} alt="ExpressCheck" />
              <label>100+ Hotels</label>
            </div>
            <div className="d-flex gap-2">
              <img src={mobileAppicon} alt="ExpressCheck" />
              <label>10+ Cities</label>
            </div>
            <div className="d-flex gap-2">
              <img src={CorporateIcon} alt="ExpressCheck" />
              <label>250+ Corporate tie-ups</label>
            </div>
            <div className="d-flex gap-2">
              <img src={NetworkIcon} alt="ExpressCheck" />
              <label>250+ Travel agent network</label>
            </div>
          </div>
        </marquee>
      </div>

      <div className="normal-content flex items-center gap-4">
        <div className="d-flex ImgsIcon gap-2">
          <img src={carIcon} alt="ExpressCheck" />
          <label>50K+ App download</label>
        </div>
        <div className="d-flex gap-2">
          <img src={buildingIcon} alt="ExpressCheck" />
          <label>100+ Hotels</label>
        </div>
        <div className="d-flex gap-2">
          <img src={mobileAppicon} alt="ExpressCheck" />
          <label>10+ Cities</label>
        </div>
        <div className="d-flex gap-2">
          <img src={CorporateIcon} alt="ExpressCheck" />
          <label>250+ Corporate tie-ups</label>
        </div>
        <div className="d-flex gap-2">
          <img src={NetworkIcon} alt="ExpressCheck" />
          <label>250+ Travel agent network</label>
        </div>
      </div>
    </div>
  );
}
