import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import SuccessFullModalTopImg from "../Assets/SuccessFullModalTopImg.svg";
import "./Css/SuccessModal.css";

export default function SuccessFullModal({ show, handleCloseSuccModal }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        handleCloseSuccModal();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show, handleCloseSuccModal]);
  return (
    <div className="SuccsMOdalcont">
      <Modal show={show} onHide={handleCloseSuccModal} centered>
        <Modal.Body className="text-center">
          <div className="contentContainerSUccesModal">
            <div className="col-6">
              {/* Top image */}
              <div>
                <img src={SuccessFullModalTopImg} alt="Success" width="100" />
              </div>
            </div>
            <div className="col-7">
              <div className="SuccCOntent">
                <h1>Success</h1>
                <p>
                  Your information has been submitted successfully. Our
                  administration team will contact you soon.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
