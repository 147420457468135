import React, { useEffect, useState } from "react";
import "./Css/HelpSection.css";
import Header from "./Header";
import {
  server_post_data,
  website_information,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export const HelpSection = () => {
  const [helpdata, settestinomial] = useState([]);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    // fd.append("which_page", 1);
    await server_post_data(website_information, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.website_content[0]);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  return (
    <>
      <Header />
      <div className="container-lg">
        <div className="help-section">
          <div className="mb-5">
            <h2>SwagStay help</h2>
            <p>
              We're happy to hear your problems and will do our best to solve
              all of your queries
            </p>
          </div>
          <div className="contact-card">
            <div className="mb-5">
              <p className="contact-label">Contact number</p>
              <p className="contact-detail">+91-{helpdata.customer_care}</p>
            </div>
            <div>
              <p className="email-label">Email id</p>
              <p className="contact-detail">{helpdata.customer_email}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpSection;
