import React from "react";
import "./Css/Testimonial..css";
import { useLocation } from "react-router-dom";

export default function Testimonial({ testimonial_image_link, testinomial }) {
  const location = useLocation();
  const isHomeRoute =
    location.pathname === "/list_your_hotel" ||
    location.pathname === "/coprative_solution" ||
    location.pathname === "/travel_agent";

  return (
    <>
      {" "}
      <div className={isHomeRoute ? "testiListHotel" : "testimonialCont"}>
        <div className="container-lg">
          <div className="QuicklyHotl testiHead santosiFOntCLass">
            {" "}
            <div>
              {" "}
              <p style={{ marginTop: "0.3rem" }}>Testimonial</p>
              <div
                className="d-flex align-items-center gap-1"
                style={{ flexWrap: "wrap" }}
              >
                <h2>
                  Success stories from <span>our partners</span>
                </h2>
              </div>
            </div>
            <div className="col-10"> </div>
          </div>
          <div className="row flex-nowrap" style={{ overflowX: "scroll" }}>
            {testinomial &&
              testinomial.length > 0 &&
              testinomial.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                  <div className="TestimonialData">
                    <div className="testiImg">
                      {/* <img src={testimonial_image_link + item.image} alt="test1"></img>{" "} */}
                      <img
                        src={testimonial_image_link + item.image}
                        alt="test1"
                        className="imagessss"
                      ></img>{" "}
                      <div className="rightTesthead">
                        <h1>{item.c_name}</h1>
                        <p>{item.c_position}</p>
                      </div>
                    </div>

                    <p className="threelineElepsis">{item.msg}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>{" "}
    </>
  );
}
