import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/ExpressChecking.css";
import HotelData from "./Reapeting Componets/HotelData";
import QuickHotel from "./QuickHotel";
import Discount from "./Discount";
import Footer from "./Footer";
import SearchData from "./SearchData";
import AQIBar from "./AQIBar";
import "./Css/AqiPage.css";
import {
  server_post_data,
  city_list_website,
  front_city_click,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export default function AqiPage() {
  const [CityList, setCityList] = useState([]);
  const [CityImage, setCityImage] = useState("");
  const [distance_lat, setdistance_lat] = useState("");
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [cityindex, setcityindex] = useState(0);
  const [hotel, sethotel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(city_list_website, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          const final_data = JSON.parse(data[1]);
          setCityList(final_data.hotel_citylist);
          setCityImage(final_data.city_image_link);
          setdistance_lat(final_data.distance_lat);
          setSEOloop(final_data.seo_list);
          setshowLoaderAdmin(false);
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
    // master_data_get_hotel();
  }, []);

  useEffect(() => {
    if (CityList.length > 0) {
      master_data_get_hotel(0);
    }
  }, [CityList]);

  const master_data_get_hotel = async (index) => {
    setIsLoading(true);
    if (CityList) {
      const fd = new FormData();
      fd.append("custom_latitude", CityList[index].city_lat);
      fd.append("custom_longitude", CityList[index].city_long);
      fd.append("city_id", CityList[index].city_long);
      fd.append("distance_call", CityList[index].distance_in_km);
      await server_post_data(front_city_click, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setSilderHotelImage(final_data.hotel_image_link);
            sethotel(final_data.hotel_all);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          handleError("network");
          setIsLoading(false);
        });
    }
  };

  const handleCityClick = (index) => {
    master_data_get_hotel(index);
    setcityindex(index);
  };

  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 120,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Header />
      {/* Hero Section */}
      <div className="container-lg">
        <div className="row  heroSection HeroAqiSec">
          <div className="col-md-4">
            <div className="headingAqiPage">
              <h1 className="display-4 font-weight-bold">
                Stay in <span>fresh</span>{" "}
              </h1>
              <span>air</span>
            </div>

            <div className="bookursty">
              <p>
                Book your stay by <span>AQI</span>
              </p>
            </div>
          </div>
          <div className="col-md-6 exproreSaYtbn">
            <button
              type="button"
              onClick={() => handleActive("formCOntainerCreate")}
            >
              Explore Stays
            </button>
          </div>
        </div>
      </div>
      {/* Search Section */}
      <SearchData />
      {/* Description Section */}
      {/*-----------------------AQI Spectrum---------------------*/}
      <div className="container-lg  my-4">
        <div className="whatExpress">
          {" "}
          <h2>AQI spectrum</h2>
        </div>
        <AQIBar currentcategory={"Good"} currentAaqi={38} />
      </div>
      {/* Hotel Section */}
      <QuickHotel
        citylist={CityList}
        distance_lat={distance_lat}
        cityimage={CityImage}
        func={handleCityClick}
        isaqi={true}
        seoData={SEOloop}
      />

      {/* HotelData Section */}
      <div className="container-lg" id="formCOntainerCreate">
        <div className="whatExpress">
          <h2>Top Hotels in {CityList?.[cityindex]?.city_name || "Nagpur"} </h2>
        </div>
        <HotelData
          image={SilderHotelImage}
          isaqi={true}
          hotelData={hotel}
          seoData={SEOloop}
        />
      </div>

      {/* Discount Section */}
      <div className="marginTOpCLass2">
        {" "}
        <Discount />
      </div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
