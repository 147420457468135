import React, { useEffect, useState } from "react";
import "./Css/RefundStatus.css";
import "./Css/Booking.css";
import Header from "./Header";
import CompleteRefund from "./CompleteRefund";
import OnGoingRefund from "./OnGoingRefund";
import {
  server_post_data,
  refund_status_all,
} from '../ServiceConnection/serviceconnection';
import { handleError } from "../CommonJquery/CommonJquery";
function RefundStatus() {
  const [activeTab, setActiveTab] = useState("Completed Refund");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [refundCompletesList, setrefundCompletesList] = useState([]);
  const [refundsOngoingList, setrefundsOngoingList] = useState([]);
  const [hotel_image_link, sethotel_image_link] = useState('');
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };


  const master_data_get = async () => {
    setShowLoaderAdmin(true);

    await server_post_data(refund_status_all, null)
      .then(Response => {
        let data = Response.split('~@~');
        if (parseInt(data[0]) === 1) {
          handleError( data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          // Filter hotels with 'Complete' refund status
          const hotelDataWithComplete = final_data.get_all_booking.filter(
            hotel => hotel.refund_status === 'Complete',
          );

          // Filter hotels with any refund status other than 'Complete'
          const hotelDataWithOngoing = final_data.get_all_booking.filter(
            hotel => hotel.refund_status !== 'Complete',
          );
          console.log(hotelDataWithComplete)
          console.log(hotelDataWithOngoing)
          // Set the respective lists 
          setrefundCompletesList(hotelDataWithComplete);
          setrefundsOngoingList(hotelDataWithOngoing);
          sethotel_image_link(final_data.hotel_image_link)
        }
        setShowLoaderAdmin(false);
      })
      .catch(error => {
        setShowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get()
  }, [])
  return (
    <div>
      <Header />
      <div
        className="container-lg subHeadStick"
        style={{ backgroundColor: "var(--white)" }}
      >
        <h2>Refund Status</h2>
        <div className="tabs">
          {["Completed Refund", "On Going Refund"].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="container-lg">
        <div className="booking-details">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="booking-cards">
              {activeTab === "Completed Refund" && <CompleteRefund hotel_image_link={hotel_image_link} refundCompletesList={refundCompletesList} />}
              {activeTab === "On Going Refund" && <OnGoingRefund hotel_image_link={hotel_image_link} refundsOngoingList={refundsOngoingList} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundStatus;
