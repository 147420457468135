import React from "react";
import Header from "./Header";
import { useNavigate, useLocation } from "react-router-dom";
import "./Css/SucessPayment.css";
import "./Css/FailledPayment.css";
import mdi_tick from "../Assets/mdi_tick.svg";
import blackCross from "../Assets/blackCross.svg";
import cuttedLineHorizontle from "../Assets/cuttedLineHorizontle.svg";
import whiteCross from "../Assets/whiteCross.svg";
import try_againImg from "../Assets/try_againImg.svg";
export default function SuccessPayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentSuccess, wallet_offer, amount_add, payment_id } =  location.state || {};
  console.log(wallet_offer, amount_add, payment_id,paymentSuccess)
  const handleSucessPaymentModalCLose = () => {
    setTimeout(() => {
      navigate("/swag_stay_wallet");
    }, 2000);
    // window.location.reload();
  };
  return (
    <>
      <Header />
      {paymentSuccess ? <>
        <div className="pymentCOntnr">
          <div className="success-modal">
            <button
              className="success-modal__close"
              onClick={handleSucessPaymentModalCLose}
            >
              <img src={blackCross} alt="blackCross"></img>
            </button>
            <div className="success-modal__body">
              <div className="topSUcessData">
                {" "}
                <div className="SuccesssImg">
                  {
                    paymentSuccess ?<img src={mdi_tick} alt="mdi_tick"></img> :<img src={whiteCross} alt="mdi_tick"></img>
                  }
                  
                </div>
                <h2 className="success-modal__title">{paymentSuccess ? 'Payment success!' : 'Payment failed!'}</h2>
                <p className="success-modal__text">
                  You added <span className="success-modal__amount">{amount_add}</span>{" "}
                  successfully in your swagwallet
                </p>
                <p className="success-modal__reference">
                  Reference ID : {payment_id}
                </p>
                <div className="cirleLeftpaymnt"></div>
                <div className="cirleRIghtpaymnt"></div>
              </div>
              <img src={cuttedLineHorizontle} alt="cuttedLineHorizontle"></img>
              <div className="success-modal__discount">
                <h3>
                  Upto <br /> {wallet_offer} Off
                </h3>
                <p>For all upcoming booking</p>
                <p>T&C apply*</p>

                <div className="circlesCutted">
                  <div className="cutCir1"></div>
                  <div className="cutCir2"></div>
                  <div className="cutCir3"></div>
                  <div className="cutCir4"></div>
                  <div className="cutCir5"></div>
                  <div className="cutCir6"></div>
                  <div className="cutCir7"></div>
                  <div className="cutCir8"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> : <>
        <div className="pymentCOntnr">
          <div className="success-modal">
            <button
              className="success-modal__close"
              onClick={handleSucessPaymentModalCLose}
            >
              <img src={blackCross} alt="blackCross"></img>
            </button>
            <div className="success-modal__body">
              <div className="topSUcessData">
                {" "}
                <div className="SuccesssImg   Faild">
                  <img src={whiteCross} alt="mdi_tick"></img>
                </div>
                <h2 className="success-modal__title">Payment failed!</h2>
                <p className="FaildTextt_P">your payment has failed </p>
                <span className="success-modal__amount FaildTextt">
                {amount_add} !!
                </span>{" "}
                <p className="success-modal__reference">
                  Reference ID : {payment_id}
                </p>
                <div className="cirleLeftpaymnt"></div>
                <div className="cirleRIghtpaymnt"></div>
              </div>
              <img src={cuttedLineHorizontle} alt="cuttedLineHorizontle"></img>
              <div className="botttmFaildData">
                <img src={try_againImg} alt="try_againImg"></img>
                <button type="button">Try Again</button>

                <div className="circlesCutted">
                  <div className="cutCir1"></div>
                  <div className="cutCir2"></div>
                  <div className="cutCir3"></div>
                  <div className="cutCir4"></div>
                  <div className="cutCir5"></div>
                  <div className="cutCir6"></div>
                  <div className="cutCir7"></div>
                  <div className="cutCir8"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
}
