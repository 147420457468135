import React, { useState } from "react";
import Cancelled from "./Cancelled";
import Completed from "./Completed";
import "./Css/Booking.css";
import Header from "./Header";
import Inhouse from "./Inhouse";
import Noshow from "./Noshow";
import Upcoming from "./Upcoming";

function Booking() {
  const [activeTab, setActiveTab] = useState("Upcoming");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <div
        className="container-lg subHeadStick"
        style={{ backgroundColor: "var(--white)" }}
      >
        <h2>Booking details</h2>
        <div className="tabs">
          {["Upcoming", "Inhouse", "Completed", "Cancelled", "No show"].map(
            (tab) => (
              <button
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            )
          )}
        </div>
      </div>
      <div className="container-lg">
        <div className="booking-details ">
          <div className="col-xl-7 col-lg-9 col-md-11">
            <div className="booking-cards">
              {activeTab === "Upcoming" && <Upcoming />}
              {activeTab === "Inhouse" && <Inhouse />}
              {activeTab === "Completed" && <Completed />}
              {activeTab === "Cancelled" && <Cancelled />}
              {activeTab === "No show" && <Noshow />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
