import React from "react";
import "./Css/BookingCard.css";
import { Link } from "react-router-dom";

function BookingCard({
  buttonLabel,
  buttonIcon,
  onButtonClick,
  route,
  datass,
  image,
  rupey,
  HotelID,
}) {
  return (
    <>
      {/* {datass && datass.length > 0 && ( */}

      <div className="booking-card justify-content-between">
        <div className="d-flex align-items-center">
          <Link
            // to={`/${route}`}
            to={`/bookings_details`}
            state={{
              data: {
                HotelID: datass.HotelID,
                booking_id: datass.booking_id,
                book_id: datass.book_id,
                hotel_id: datass.hotel_id,
                cd_id: datass.cd_id,
              },
            }}
          >
            <img
              src={`${image}${datass.image_name}`}
              alt="Hotel"
              className="hotel-image"
            />
          </Link>
          <Link
            to={`/${route}`}
            state={{
              data: {
                HotelID: datass.HotelID,
                booking_id: datass.booking_id,
                book_id: datass.book_id,
                hotel_id: datass.hotel_id,
                cd_id: datass.cd_id,
              },
            }}
          >
            <div className="booking-info">
              <p className="elepsisClassPTop">
                {datass.check_in_date} - {datass.check_out_date} •{" "}
                {datass.adult} A | {datass.child} C
              </p>
              <h3 className="elepsisClass">{datass.hotel_name}</h3>
              <p className="elepsisClassP">{datass.address}</p>
            </div>
          </Link>
        </div>
        <button className="action-button" onClick={onButtonClick}>
          {buttonIcon} {buttonLabel}
        </button>
      </div>
      {/* )} */}
    </>
  );
}

export default BookingCard;
