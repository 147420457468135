import React from "react";
import "./Css/LogoutModal.css";
import { Modal } from "react-bootstrap";
export default function Logoutmodal({
  showLogOut,
  handleCloseLogOutModal,
  handleCloseLogOutModalYes,
}) {
  return (
    <div>
      {" "}
      <Modal
        className="logOutModlll"
        show={showLogOut}
        onHide={handleCloseLogOutModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="MOdleCenterDttaa  text-center">
          <h4>Are you sure you want to logout?</h4>
          <div className="d-flex justify-content-center gap-3  logUtBtttn">
            <button
              variant="danger"
              onClick={handleCloseLogOutModalYes}
              className="px-4"
            >
              yes, Logout
            </button>

            <button
              variant="secondary"
              onClick={handleCloseLogOutModal}
              className="px-4 NoBtttn"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
