import React from "react";
import Header from "./Header";
import "./Css/PageNotFound.css";
import pageNotFoundIcon from "../Assets/pageNotFoundIcon.svg";

export default function PageNotFound() {
  return (
    <div>
      <Header />

      <div className="container">
        <div className="foundDiv">
          <div className="contndDIvFound">
            <img src={pageNotFoundIcon} alt="pageNotFoundIcon"></img>

            <h1>Uh-oh! You’ve Taken a Wrong Turn!</h1>
            <p>
              Looks like this page checked out early... but don’t worry, we’ve
              got plenty more rooms!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
