import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../Assets/backIcon.svg";
import resto from "../Assets/brunch_dining.svg";
import corporatefirendly from "../Assets/corporatefirendly.png";
import coupleSvg from "../Assets/coupleSvg.svg";
import {
  default as hospitalicon,
  default as othericon,
  default as transport,
} from "../Assets/directions_bus.svg";
import downIcon from "../Assets/downIcon.svg";
import familyfirendly from "../Assets/familyfirendly.png";
import geeenDot from "../Assets/geeenDot.svg";
import NextIcon from "../Assets/NextimgIcon.png";
import orengePluse from "../Assets/orengePluse.svg";
import PrevIcon from "../Assets/prevIcon.png";
import BookingLoader from "./BookingLoader.js";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";

import shereIcon from "../Assets/shereIcon.svg";
import solar_tag from "../Assets/solar_tag.svg";
import stockHeart from "../Assets/stockHeart.svg";
import stockHeartRedFiiled from "../Assets/stockHeartRedFiiled.svg";
import AQIBar from "./AQIBar";
import "./Css/Hoteldetail.css";
import Header from "./Header";
import Faq from "./Reapeting Componets/Faq";

import { handleError } from "../CommonJquery/CommonJquery";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection";
import {
  add_remove_favorite_hotel,
  booking_calculation,
  get_website_faq_hotel,
  getNearbyTransitStations,
  GOOGLE_PLACES_API_KEY,
  hotel_details,
  hotel_details_next_data,
  server_get_airquality,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
export default function HotelDetail() {
  const navigate = useNavigate();

  const MAX_ADULTS = 3;
  const MAX_CHILDREN = 3;
  const [totalAdults, settotalAdults] = useState(1);
  const [totalChildren, settotalChildren] = useState(0);
  const [SelectedImagedata, setSelectedImagedata] = useState([]);
  const [SelectedImagedataLink, setSelectedImagedataLink] = useState([]);
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [categories, setcategories] = useState([
    {
      id: "transit_station",
      key: "Transportation",
      title: "Transport",
      icon: transport,
      data: [],
    },
    {
      id: "restaurant",
      key: "Restaurants",
      title: "Restaurants",
      icon: resto,
      data: [],
    },
    {
      id: "hospital|health|doctor|clinic|pharmacy",
      key: "Hospitals",
      title: "Hospital",
      icon: hospitalicon,
      data: [],
    },
    {
      id: "tourist_attraction",
      key: "Other",
      title: "Other",
      icon: othericon,
      data: [],
    },
  ]);

  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const FromDate = new Date().toISOString().split("T")[0];
  const ToDateddd = new Date();
  ToDateddd.setDate(ToDateddd.getDate() + 1);
  const ToDate = ToDateddd.toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(ToDateddd);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [CateID, setCateID] = useState(0);
  const [CateIDSOLDOUT, setCateIDSOLDOUT] = useState(0);
  const [HotelDetails, setHotelDetails] = useState([]);
  const [HotelImagesDetails, setHotelImagesDetails] = useState([]);
  const [ShareAbleLink, setShareAbleLink] = useState("");
  const [HotelImageLink, setHotelImageLink] = useState("");
  const [FacilityList, setFacilityList] = useState([]);
  const [FacilityImageLink, setFacilityImageLink] = useState("");
  const [RoomsList, setRoomsList] = useState([]);
  const [RoomsListSoldOut, setRoomsListSoldOut] = useState([]);
  const [RoomsImageLink, setRoomsImageLink] = useState("");
  const [currentCategory, setcurrentCategory] = useState(200);
  const [currentAQI, setcurrentAQI] = useState(0);
  const [badges, setBadges] = useState([]);
  const [activeCategoryindex, setActiveCategoryindex] = useState(0);
  const [cancellationPolicy, setCancellationPolicy] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [additionalInfo1, setAdditionalInfo1] = useState("");
  const [PriceShow, setPriceShow] = useState("");
  const [CateIDName, setCateIDName] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [faqData, setfaqData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const facilitiesToShow = showAll ? FacilityList : FacilityList.slice(0, 6);
  const [rooms, setRooms] = useState([{ id: 1, adults: 1, children: 0 }]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // To control visibility of the calendar
  const [isCheckInMode, setIsCheckInMode] = useState(true);
  const [AddRoom, setAddRoom] = useState(false);
  const [AddCatgry, setAddCatgry] = useState(false);

  const [selectedImageIdx, setSelectedImageIdx] = useState(null);
  const [startX, setStartX] = useState(0);

  const handleImageClick = (idx) => {
    setSelectedImageIdx(idx);
  };

  const handleBackClick = () => {
    setSelectedImageIdx(null);
  };

  const handleCloseClick = () => {
    setSelectedImageIdx(null);
    handleClose();
  };

  const handleNextImage = () => {
    if (
      selectedImageIdx !== null &&
      selectedImageIdx < SelectedImagedata.length - 1
    ) {
      setSelectedImageIdx((prevIdx) => prevIdx + 1);
    }
  };

  const handlePrevImage = () => {
    if (selectedImageIdx !== null && selectedImageIdx > 0) {
      setSelectedImageIdx((prevIdx) => prevIdx - 1);
    }
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const diffX = startX - endX;

    if (diffX > 50) {
      // Swipe left: Next image
      handleNextImage();
    } else if (diffX < -50) {
      // Swipe right: Previous image
      handlePrevImage();
    }
  };

  const handleBooking = () => {
    if (parseInt(customerId) > 0) {
      storeData("RoomData", JSON.stringify(rooms));
      storeData("CheckIndate", checkInDate.toISOString().split("T")[0]);
      storeData("CheckOutdate", checkOutDate.toISOString().split("T")[0]);
      storeData(
        "TotalAdultAndChild",
        rooms.length + "~@~" + totalAdults + "~@~" + totalChildren
      );
      storeData("CateID", CateID);
      storeData("CateName", CateIDName);
      navigate(
        "/create_booking/" + HotelDetails.HotelID + "/" + HotelDetails.hotel_id
      );
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const handleCheckInClick = () => {
    setIsCheckInMode(true);
    setIsCalendarOpen(true);
  };

  const handleCheckOutClick = () => {
    if (checkInDate) {
      setIsCheckInMode(false);
      setIsCalendarOpen(true);
    }
  };

  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setCheckOutDate(null);
    setIsCheckInMode(false); // Switch to check-out selection
  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
    setIsCalendarOpen(false); // Close the calendar after selecting check-out
  };
  const dropdownRefs = [useRef(null), useRef(null)];
  const dateContrRef = useRef(null);
  const handleShowAddroom = () => {
    setAddRoom(!AddRoom);
    setAddCatgry(false);
  };

  //----------------------------------Calander DropDOwn-----------------------------------
  const handleClickOutside2 = (event) => {
    if (dateContrRef.current && !dateContrRef.current.contains(event.target)) {
      setIsCalendarOpen(false); // Close the calendar
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside2);
    return () => {
      document.removeEventListener("click", handleClickOutside2);
    };
  }, []);
  const handleClickOutside = (event) => {
    const isClickInsideDropdown =
      dropdownRefs[0].current && dropdownRefs[0].current.contains(event.target);

    const isClickInsideCategoryDropdown =
      dropdownRefs[1].current && dropdownRefs[1].current.contains(event.target);

    const isClickInsideDecrementButton = event.target.closest(".decrement-btn");

    if (
      isClickInsideDropdown ||
      isClickInsideCategoryDropdown ||
      isClickInsideDecrementButton
    ) {
      return;
    }

    if (dropdownRefs[0].current && !isClickInsideDropdown) {
      setAddRoom(false);
    }

    if (dropdownRefs[1].current && !isClickInsideCategoryDropdown) {
      setAddCatgry(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleShowsetAddCatgry = () => {
    setAddCatgry(!AddCatgry);
    setAddRoom(false);
  };

  const handleAdultChildIncrementDecrement = (index, min_plus, adultchild) => {
    setRooms((prev) => {
      let newRooms = [...prev];
      let shouldCallHitRoom = true;
      if (adultchild === "adult") {
        if (min_plus === 1 && newRooms[index].adults < MAX_ADULTS) {
          newRooms[index].adults += 1;
        } else if (min_plus === 1 && newRooms[index].adults >= MAX_ADULTS) {
          shouldCallHitRoom = false;
        } else if (min_plus === -1) {
          if (index === 0 && newRooms[index].adults > 1) {
            newRooms[index].adults -= 1;
          } else if (index !== "0" && newRooms[index].adults > 0) {
            newRooms[index].adults -= 1;
          }
        }
      } else if (adultchild === "child") {
        if (min_plus === 1) {
          if (newRooms[index].children < MAX_CHILDREN) {
            newRooms[index].children += 1;
          } else {
            // Prevent function_hit_room from being called when MAX_CHILDREN is exceeded
            shouldCallHitRoom = false;
          }
        } else if (min_plus === -1 && newRooms[index].children > 0) {
          newRooms[index].children -= 1;
        } else {
          shouldCallHitRoom = false;
        }
      } else {
        // Adding a new room
        newRooms = [...newRooms, { id: Date.now(), adults: 1, children: 0 }];
      }
      // Filter out empty rooms, except for Room 1
      newRooms = newRooms.filter(
        (room, idx) => idx === 0 || room.adults > 0 || room.children > 0
      );

      let total_adults_count = 0;
      let total_children_count = 0;
      Object.entries(newRooms).forEach(([key, value]) => {
        total_adults_count = total_adults_count + parseInt(value.adults);
        total_children_count = total_children_count + parseInt(value.children);
      });
      settotalAdults(total_adults_count);
      settotalChildren(total_children_count);
      // Call function_hit_room with the updated room data only if shouldCallHitRoom is true
      if (shouldCallHitRoom) {
        function_hit_room(
          1,
          CateID,
          newRooms,
          HotelDetails.hotel_id,
          HotelDetails.HotelID
        );
      }

      return newRooms;
    });
  };

  const aqiCategories = [
    { min: 0, max: 50, label: "Good" },
    { min: 51, max: 100, label: "Satisfactory" },
    { min: 101, max: 200, label: "Moderate" },
    { min: 201, max: 300, label: "Poor" },
    { min: 301, max: 400, label: "Very Poor" },
    { min: 401, max: 500, label: "Severe" },
  ];

  const getAQICategory = (currentAQI) => {
    return aqiCategories.find(
      (category) => currentAQI >= category.min && currentAQI <= category.max
    );
  };

  useEffect(() => {
    let customer_id = retrieveData("customer_id");
    if (customer_id === null || customer_id === 0) {
      customer_id = 0;
    }
    let customer_rooms = retrieveData("RoomData");
    let customer_checkInDate = retrieveData("CheckIndate");
    let customer_checkOutDate = retrieveData("CheckOutdate");
    let TotalAdultAndChild = retrieveData("TotalAdultAndChild");

    const currentDate = new Date(); // Current date
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + 1); // Next day's date
    const FromDate = new Date();

    if (customer_rooms === null || customer_rooms === 0) {
      customer_rooms = 0;
    }
    const ToDateddd = new Date();

    if (customer_rooms !== "0") {
      setRooms(JSON.parse(customer_rooms));
    } else {
      customer_rooms = rooms;
    }
    if (customer_checkInDate !== "0") {
      const parsedCheckInDate = new Date(customer_checkInDate);

      if (parsedCheckInDate < currentDate) {
        setCheckInDate(currentDate);
      } else {
        setCheckInDate(parsedCheckInDate);
      }
    } else {
      setCheckInDate(FromDate);
    }

    if (customer_checkOutDate !== "0") {
      const parsedCheckOutDate = new Date(customer_checkOutDate);
      if (parsedCheckOutDate < currentDate) {
        const updatedCheckOutDate = new Date(currentDate);
        updatedCheckOutDate.setDate(currentDate.getDate() + 1);
        setCheckOutDate(updatedCheckOutDate);
      } else {
        setCheckOutDate(parsedCheckOutDate);
      }
    } else {
      setCheckOutDate(ToDateddd);
    }
    if (TotalAdultAndChild === null || TotalAdultAndChild === 0) {
      TotalAdultAndChild = 0;
    }
    if (TotalAdultAndChild !== "0") {
      let splite_data = TotalAdultAndChild.split("~@~");
      settotalAdults(parseInt(splite_data[1]));
      settotalChildren(parseInt(splite_data[2]));
    }
    setCustomerId(customer_id);
    const url = currentUrl;
    const parts = url.split("/");
    let hotel_id = "0";
    let HotelID = "0";
    let cate_id = "0";
    if (parts.length === 3) {
      hotel_id = parts[2];
      HotelID = parts[1];
    }
    master_data_get(
      hotel_id,
      HotelID,
      FromDate.toISOString().split("T")[0],
      ToDate,
      cate_id,
      url,
      customer_rooms
    );
  }, []);

  const master_data_get = async (
    hotel_id,
    HotelID,
    from_date,
    to_date,
    cate_id,
    url_data,
    customer_rooms
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    fd.append("from_date", from_date);
    fd.append("to_date", to_date);
    fd.append("cate_id", cate_id);
    fd.append("current_url", url_data);
    await server_post_data(hotel_details, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        let latitude = "";
        let longitude = "";
        let hotel_id_id = "";
        let HotelID_id = "";
        let on_prime_status = 0;
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setCancellationPolicy(final_data.cancellationpolicy);
          setAdditionalInfo1(final_data.terms_conditions);
          setAdditionalInfo(final_data.refund_policy);
          setHotelDetails(final_data.hotel_details[0]);
          latitude = final_data.hotel_details[0].latitude;
          longitude = final_data.hotel_details[0].longitude;
          hotel_id_id = final_data.hotel_details[0].hotel_id;
          HotelID_id = final_data.hotel_details[0].HotelID;
          console.log(final_data.hotel_category);
          setRoomsList(final_data.hotel_category);
          setRoomsListSoldOut(final_data.hotel_category);
          setHotelImageLink(final_data.hotel_image_link);
          let cate_id_id = 0;
          setShareAbleLink(final_data.share_link);
          setRupessIcon(final_data.rupees_icon);
          if (
            final_data.hotel_category &&
            final_data.hotel_category.length > 0
          ) {
            const firstRoom = final_data.hotel_category[0];
            setCateID(firstRoom.cat_id);
            cate_id_id = firstRoom.cat_id;
            setCateIDName(firstRoom.category_name);
            setPriceShow(firstRoom.room_price);
            setCateIDSOLDOUT(firstRoom.status_sold_out);
          }
          try {
            const updatedRoomsObject = JSON.parse(customer_rooms);
            function_hit_room(
              1,
              cate_id_id,
              updatedRoomsObject,
              hotel_id_id,
              HotelID_id
            );
          } catch (error) {
            //err
          }
        }
        setshowLoaderAdmin(false);

        master_data_get_next(
          hotel_id_id,
          HotelID_id,
          FromDate,
          ToDate,
          CateID,
          latitude,
          longitude
        );
        get_air_data(latitude, longitude, on_prime_status);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        console.log(error);
      });
  };

  const get_air_data = async (latitude, longitude, on_prime_status) => {
    await server_get_airquality(latitude, longitude)
      .then((Response) => {
        let air_quality = 0;
        try {
          if (Response.indexes.length == 2) {
            air_quality = Response.indexes[1].aqi;
          } else {
            air_quality = Response.indexs[0].aqi;
          }
          setcurrentAQI(air_quality);
          const currentCategory = getAQICategory(air_quality);
          setcurrentCategory(currentCategory);
          setBadges([
            on_prime_status === 1 ? "On Prime Location" : "", // Badge 1: 'On Prime Location' if air_quality is 0, otherwise empty string
            "AQI " + air_quality + " • " + currentCategory.label, // Badge 2: Always showing AQI and category
          ]);
        } catch (error) {
          //err
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const get_neardata_data = async (latitude, longitude, index) => {
    const type = categories[index].key;
    const type_id = categories[index].id;
    // Check if the category already has data
    if (categories[index].data.length === 0) {
      const fd = new FormData();
      fd.append("type", type_id.toLowerCase());
      fd.append("apiKey", GOOGLE_PLACES_API_KEY);
      fd.append("latitude_dddd", latitude);
      fd.append("longitude_dddd", longitude);
      await server_post_data(getNearbyTransitStations, fd)
        .then((Response) => {
          let data = Response.split("~@~");

          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            const places = final_data.results
              .slice(0, 7)
              .map((place, index) => {
                const distanceInKm =
                  (place.geometry.location.lat - latitude) ** 2 +
                  (place.geometry.location.lng - longitude) ** 2;
                return {
                  name: place.name,
                  distance: (Math.sqrt(distanceInKm) * 111).toFixed(2), // Approximate distance in KM
                };
              });

            // Update the categories state with the new places data
            setcategories((prevCategories) =>
              prevCategories.map(
                (category) =>
                  category.key === type
                    ? { ...category, data: places } // Update the current category data
                    : category // Keep the rest of the categories unchanged
              )
            );

            // Set active category and index
            setActiveCategoryindex(index);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          console.log(error);
        });
    } else {
      // If data already exists, just set the active category
      setActiveCategoryindex(index);
    }
  };

  const master_data_get_next = async (
    hotel_id,
    HotelID,
    from_date,
    to_date,
    cate_id,
    latitude,
    longitude
  ) => {
    const fd = new FormData();
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    fd.append("from_date", from_date);
    fd.append("to_date", to_date);
    fd.append("cate_id", cate_id);
    await server_post_data(hotel_details_next_data, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setFacilityList(final_data.facility_list);
          setFacilityImageLink(final_data.facility_image_link);
          setRoomsImageLink(final_data.categry_image_link);
          setHotelImagesDetails(final_data.hotelimages_list);
          get_neardata_data(latitude, longitude, 0);
          if (parseInt(final_data.hotel_favourite.total_count) > 0) {
            setIsFavorite(true);
          }
        }
        master_data_get_faq(hotel_id, HotelID);
      })
      .catch((error) => {});
  };

  const master_data_get_faq = async (hotel_id, HotelID) => {
    const fd = new FormData();
    fd.append("flag", "2");
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    await server_post_data(get_website_faq_hotel, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setfaqData(final_data.faq_data);
        }
      })
      .catch((error) => {
        // setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (hotel_id, HotelID) => {
    if (parseInt(customerId) > 0) {
      const form_data = new FormData();
      form_data.append("hotel_id", hotel_id);
      form_data.append("HotelID", HotelID);
      form_data.append("show_flag", "c");
      await server_post_data(add_remove_favorite_hotel, form_data)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            setIsFavorite(!isFavorite);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const function_hit_room = (
    click_from,
    cate_id_id,
    updatedRooms,
    hotel_id_id,
    HotelID_id
  ) => {
    master_data_get_calculation(
      // HotelDetails.hotel_id,
      // HotelDetails.HotelID,
      hotel_id_id,
      HotelID_id,
      cate_id_id,
      checkInDate.toISOString().split("T")[0],
      checkOutDate.toISOString().split("T")[0],
      false,
      false,
      false,
      "10:00 AM",
      "11:00 AM",
      "",
      "",
      updatedRooms,
      click_from
    );
  };

  const master_data_get_calculation = async (
    hotel_id,
    HotelID,
    cate_id,
    checkInDate,
    checkOutDate,
    breakfast,
    lunch,
    dinner,
    checkin_time,
    checkout_time,
    pickup_location,
    dropoff_location,
    rooms_data,
    click_from
  ) => {
    if (click_from == 0) {
      setshowLoaderAdmin(true);
    } else {
      setIsLoading(true);
    }

    const fd = new FormData();
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    fd.append("from_date", checkInDate);
    fd.append("to_date", checkOutDate);
    fd.append("beackfast_check", breakfast ? "1" : "0");
    fd.append("lunch_check", lunch ? "1" : "0");
    fd.append("dinner_check", dinner ? "1" : "0");
    fd.append("cate_id", cate_id);
    fd.append("checkin_time", checkin_time);
    fd.append("checkout_time", checkout_time);
    fd.append("pickup_location", pickup_location);
    fd.append("dropoff_location", dropoff_location);
    fd.append("express_checkin", "0");
    fd.append("self_other", "0");
    fd.append("swagcoin_check", "0");
    fd.append("real_amountcheck", "0");
    let total_rooms = 0;
    let total_adults_loop = "";
    let total_children_loop = "";
    let total_adults_count = 0;
    let total_children_count = 0;
    Object.entries(rooms_data).forEach(([key, value]) => {
      total_rooms++;
      total_adults_loop = total_adults_loop + value.adults + "~@~";
      total_adults_count = total_adults_count + parseInt(value.adults);
      total_children_loop = total_children_loop + value.children + "~@~";
      total_children_count = total_children_count + parseInt(value.children);
    });

    if (total_children_count === NaN || total_adults_count === NaN) {
      settotalChildren(total_children_count);
      settotalAdults(total_adults_count);
    }
    fd.append("total_rooms", total_rooms);
    fd.append("total_adults_loop", total_adults_loop);
    fd.append("total_children_loop", total_children_loop);

    await server_post_data(booking_calculation, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setRupessIcon(final_data.rupees_icon);
          setPriceShow(final_data.final_amount_to_be_pay_full);
        }
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const handleroomChange = (roomLabel) => {
    if (roomLabel.cat_id === CateID) {
      // Prevent multiple clicks on the same room
      return;
    }

    setCateID(roomLabel.cat_id);
    setCateIDName(roomLabel.category_name);
    setPriceShow(roomLabel.room_price);
    setCateIDSOLDOUT(roomLabel.status_sold_out);

    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      roomLabel.cat_id,
      checkInDate.toISOString().split("T")[0],
      checkOutDate.toISOString().split("T")[0],
      false,
      false,
      false,
      "10:00 AM",
      "11:00 AM",
      "",
      "",
      rooms,
      1
    );
  };

  /**shubham jain */

  /*----Tarun Mandal---- */
  const [activeIndex, setActiveIndex] = useState(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const [showBookButton, setShowBookButton] = useState(false);
  const handleActive = (index) => {
    setActiveIndex(index);
    const element = document.getElementById(bardata[index]);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 180,
        behavior: "smooth",
      });
    }
  };

  const bardata = [
    "Description",
    "Popular facilities",
    "Category",
    "Near by",
    "AQI",
    "Additional information",
    "Cancellation policy",
  ];
  useEffect(() => {
    const handleScroll = () => {
      // Get the position of the .borderClr element
      const element = document.querySelector(".borderClr");
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= -490) {
          setIsAtTop(true);
        } else {
          setIsAtTop(false);
        }
      }
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      // Get the position of the .borderClr element
      const element = document.querySelector(".borderClr");
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= -1050) {
          setShowBookButton(true);
        } else {
          setShowBookButton(false);
        }
      }
    };

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToHotelCard = () => {
    const cardElement = document.getElementById("HotelCardData");
    if (cardElement) {
      const topOffset = window.innerHeight / 2 - cardElement.offsetHeight / 2;
      window.scrollTo({
        top:
          cardElement.getBoundingClientRect().top + window.scrollY - topOffset,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Header />

      {showLoaderAdmin && <BookingLoader />}
      <div className="hotelDetailHeroCont">
        <div className="">
          {/* <div className="col-12 borderClr"> */}
          <div className="container-lg">
            <div className="flowPAth">
              <Link to="/">Home</Link>
              <span>
                <img src={NextIcon} alt="Swagstay" />
              </span>
              <Link onClick={() => navigate(-1)}>
                Hotels in {HotelDetails.city_name}
              </Link>
              <span>
                <img src={NextIcon} alt="Swagstay" />
              </span>
              <Link style={{ color: "var(--primary-color)" }}>
                {HotelDetails.hotel_name}
              </Link>
            </div>
            <div className="col-12 borderClr">
              <div className="d-flex align-items-center gap-2">
                <div className="locationSTatus">
                  {badges.map((badge, index) => {
                    if (badge != "") {
                      return (
                        <button
                          className="d-flex align-items-center  gap-2"
                          key={index}
                        >
                          <img
                            src={index === 0 ? solar_tag : geeenDot}
                            alt={HotelDetails.hotel_name}
                          ></img>
                          {badge}
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="locationDetl">
                <div className="lefDetl">
                  <h2>{HotelDetails.hotel_name}</h2>
                  <p>{HotelDetails.address}</p>
                </div>
                <div className="right">
                  <div className="revieWText">
                    <h1>{HotelDetails.total_rating}/5</h1>{" "}
                    <span>({HotelDetails.total_reviews} Review)</span>
                  </div>
                  <div className="verticalLine"></div>
                  <div
                    className="heart-container"
                    onClick={() => {
                      setisSharePopupContent(ShareAbleLink);
                      setSharePopupOpen(true);
                    }}
                  >
                    <img src={shereIcon} alt={HotelDetails.hotel_name} />
                  </div>
                  <div className="verticalLine"></div>
                  <div
                    onClick={() =>
                      handleSaveChangesdynamic(
                        HotelDetails.hotel_id,
                        HotelDetails.HotelID
                      )
                    }
                    className="heart-container"
                  >
                    <img
                      src={isFavorite ? stockHeartRedFiiled : stockHeart}
                      alt={HotelDetails.hotel_name}
                      className={`heart-icon ${isFavorite ? "filled" : ""}`}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 mb-3">
                  <div className="lefHotelDetailimgs">
                    <div className="gallery-grid">
                      {/* Main large image */}
                      <div className="main-image">
                        <img
                          src={HotelImageLink + HotelDetails.image_name}
                          alt={HotelDetails.hotel_name}
                          className="gallery-image"
                        />
                        <button
                          className="view-all-btn"
                          onClick={() => {
                            setSelectedImagedata(HotelImagesDetails);
                            setSelectedImagedataLink(HotelImageLink);
                            handleShow();
                          }}
                        >
                          View all
                          <br />
                          <strong>{HotelImagesDetails.length} photos</strong>
                        </button>
                        <div className="overlayHotelHotel"></div>
                      </div>
                      {/* Right column */}
                      {HotelImagesDetails && HotelImagesDetails.length > 0 && (
                        <div className="right-column">
                          {HotelImagesDetails.map((hotelImage, index) => {
                            if (index < 2) {
                              return (
                                <div className="image-container" key={index}>
                                  <img
                                    src={HotelImageLink + hotelImage.image_name} // Assuming hotelImage is a URL, adjust if needed
                                    alt={HotelDetails.hotel_name}
                                    className="gallery-image"
                                    loading="lazy"
                                  />
                                  {/* <LazyLoadImage
                                    src={HotelImageLink + hotelImage.image_name}
                                    alt={HotelDetails.hotel_name}
                                    className="gallery-image"
                                    effect="blur"
                                    style={{ maxHeight: "200px" }}
                                  /> */}
                                  <div className="overlayHotel"></div>
                                  {index === 1 && (
                                    <button
                                      className="view-all-btn"
                                      onClick={() => {
                                        setSelectedImagedata(
                                          HotelImagesDetails
                                        );
                                        setSelectedImagedataLink(
                                          HotelImageLink
                                        );
                                        handleShow();
                                      }}
                                    >
                                      View all
                                      <br />
                                      <strong>
                                        {HotelImagesDetails.length} photos
                                      </strong>
                                    </button>
                                  )}
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-12 mb-3"
                  style={{ position: "relative" }}
                >
                  <div
                    id="HotelCardData"
                    className={`hotel-booking-card ${
                      isAtTop ? "fixDivClass" : ""
                    }`}
                  >
                    {/* Header Section */}
                    <div className="card-header">
                      <div className="hotel-info">
                        <h2>{HotelDetails.hotel_name}</h2>
                        {HotelDetails.friendly_tag === "1" ? (
                          <div className="badge-container">
                            <span className="couple-badge">
                              <img
                                src={coupleSvg}
                                alt={HotelDetails.hotel_name}
                              ></img>
                              Couple friendly hotel
                            </span>
                          </div>
                        ) : HotelDetails.friendly_tag === "2" ? (
                          <div className="badge-container">
                            <span className="couple-badge">
                              <img
                                src={familyfirendly}
                                alt={HotelDetails.hotel_name}
                              ></img>
                              Family friendly hotel
                            </span>
                          </div>
                        ) : HotelDetails.friendly_tag === "3" ? (
                          <div className="badge-container">
                            <span className="couple-badge">
                              <img
                                src={corporatefirendly}
                                alt={HotelDetails.hotel_name}
                              ></img>
                              Corporate friendly hotel
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* Price Section */}
                      <div className="price-section">
                        {parseInt(HotelDetails.discount) > 0 && (
                          <span className="discount-badge">
                            {HotelDetails.discount}% off
                          </span>
                        )}
                        {parseInt(HotelDetails.discount) > 0 && (
                          <span className="original-price">
                            {RupessIcon}
                            {PriceShow * (100 / HotelDetails.discount)}
                          </span>
                        )}
                        <span className="current-price">
                          {RupessIcon}
                          {PriceShow}
                        </span>
                      </div>
                    </div>

                    <div ref={dateContrRef} className="dateCOntrrr">
                      <div className="date-section">
                        <div className="check-in" onClick={handleCheckInClick}>
                          <p className="Detltitile">Check-in</p>
                          <p className="dateTxt">
                            {checkInDate
                              ? checkInDate.toLocaleDateString()
                              : "Select date"}
                          </p>
                        </div>
                        <div className="nightsNumber">
                          <div
                            className={`nightsNumberLine nightsNumberLineDetaIL `}
                          ></div>
                          <div className={`nightN9Black nightN9Detaial`}>
                            <p>1N</p>
                          </div>
                        </div>
                        <div
                          className="check-out"
                          onClick={handleCheckOutClick}
                        >
                          <p className="Detltitile">Check-out</p>
                          <p className="dateTxt">
                            {checkOutDate
                              ? checkOutDate.toLocaleDateString()
                              : "Select date"}
                          </p>
                        </div>
                        {isCalendarOpen && (
                          <div className="checkInCalanderHotelDEtail">
                            <DatePicker
                              selected={
                                isCheckInMode ? checkInDate : checkOutDate
                              }
                              onChange={
                                isCheckInMode
                                  ? handleCheckInDateChange
                                  : handleCheckOutDateChange
                              }
                              className="form-control border-0 p-0 text-muted"
                              placeholderText={
                                isCheckInMode
                                  ? "Select check-in date"
                                  : "Select check-out date"
                              }
                              dateFormat="dd MMM yyyy"
                              inline
                              selectsStart={isCheckInMode}
                              selectsEnd={!isCheckInMode}
                              startDate={checkInDate}
                              endDate={checkOutDate}
                              minDate={isCheckInMode ? new Date() : checkInDate} // For check-out, start from check-in date
                              monthsShown={2} // Show two months side-by-side
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Room Details */}
                    <div className="details-section">
                      <div className="roomSHowcontainer" ref={dropdownRefs[0]}>
                        <div className="detail-row" onClick={handleShowAddroom}>
                          <div>
                            <p className="Detltitile">
                              {" "}
                              Room: {rooms.length} | Guest{" "}
                              {parseInt(totalAdults) + parseInt(totalChildren)}
                            </p>
                            <p className="sub-text">
                              Adults -{" "}
                              {totalAdults < 10
                                ? `0${totalAdults}`
                                : totalAdults}{" "}
                              | Children -{" "}
                              {totalChildren < 10
                                ? `0${totalChildren}`
                                : totalChildren}
                            </p>
                          </div>

                          <div className="dropDOwnArrowDOwn">
                            <img
                              src={downIcon}
                              alt="downIcon"
                              style={{
                                transform: AddRoom
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                              }}
                            ></img>
                          </div>
                        </div>
                        <div className={`roomDrodown ${AddRoom ? "open" : ""}`}>
                          {rooms.map((room, roomIndex) => (
                            <div key={roomIndex}>
                              <div className="roomHead">
                                <h1>Room {roomIndex + 1}</h1>
                              </div>

                              {/* Adults Section */}
                              <div className="roomHeadData">
                                <div className="leftROomCOnt">
                                  <h1>Adults</h1>
                                  <p>Ages 5 or above</p>
                                </div>
                                <div className="rightROoomtCOnt">
                                  <button
                                    className={`minsssBttm decrement-btn ${
                                      room.adults === 1 && roomIndex === 0
                                        ? "ENableMinusbtn"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleAdultChildIncrementDecrement(
                                        roomIndex,
                                        -1,
                                        "adult"
                                      )
                                    }
                                    style={{
                                      opacity:
                                        roomIndex === 0
                                          ? room.adults > 1
                                            ? 1
                                            : 0.5
                                          : 1,
                                    }}
                                    disabled={
                                      roomIndex === 0 && room.adults <= 1
                                    }
                                  >
                                    -
                                  </button>

                                  <label>{room.adults}</label>

                                  <button
                                    className={`plusBttm ${
                                      room.adults === 3 ? "ENablePluse" : ""
                                    }`}
                                    onClick={() =>
                                      handleAdultChildIncrementDecrement(
                                        roomIndex,
                                        1,
                                        "adult"
                                      )
                                    }
                                    style={{
                                      opacity: room.adults < 3 ? 1 : 0.5,
                                    }}
                                    disabled={room.adults >= 3}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>

                              <div className="roomHeadData">
                                <div className="leftROomCOnt">
                                  <h1>Children</h1>
                                  <p>Age 0-5</p>
                                </div>
                                <div className="rightROoomtCOnt">
                                  <button
                                    className={`minsssBttm ${
                                      room.children === 0
                                        ? "ENableMinusbtn"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleAdultChildIncrementDecrement(
                                        roomIndex,
                                        -1,
                                        "child"
                                      )
                                    }
                                    style={{
                                      opacity: room.children > 0 ? 1 : 0.5,
                                    }}
                                    disabled={room.children <= 0}
                                  >
                                    -
                                  </button>

                                  <label>{room.children}</label>

                                  <button
                                    className={`plusBttm ${
                                      room.children === 3 ? "ENablePluse" : ""
                                    }`}
                                    onClick={() =>
                                      handleAdultChildIncrementDecrement(
                                        roomIndex,
                                        1,
                                        "child"
                                      )
                                    }
                                    style={{
                                      opacity: room.children < 3 ? 1 : 0.5,
                                    }}
                                    disabled={room.children >= 3}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              {roomIndex === rooms.length - 1 && (
                                <div
                                  className="addrromBotttm"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent click event from propagating to the outside
                                    handleAdultChildIncrementDecrement(
                                      0,
                                      0,
                                      "add"
                                    );
                                  }}
                                  style={{ marginBottom: "0.5rem" }}
                                >
                                  <img src={orengePluse} alt="orengePluse" />
                                  <p>Add room</p>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        ref={dropdownRefs[1]}
                        className="detail-row"
                        // onClick={handleShowsetAddCatgry}
                        style={{ position: "relative" }}
                      >
                        <div>
                          <p className="Detltitile">Category</p>
                          <p className="sub-text">
                            {CateIDName}{" "}
                            <span className="fontWEight">
                              {RupessIcon}
                              {PriceShow}
                            </span>
                          </p>
                        </div>
                        <div className="dropDOwnArrowDOwn">
                          {/* <img
                            src={downIcon}
                            alt="downIcon"
                            style={{
                              transform: AddCatgry
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                          ></img> */}
                        </div>

                        <div
                          className={`catgryDropDwnn ${
                            AddCatgry ? "open" : ""
                          }`}
                        >
                          <div className="roomHead">
                            <h1>Category</h1>
                          </div>

                          {/* Adults Section */}
                          <div className="roomHeadData">
                            <div className="leftROomCOnt">
                              <h1>Adults</h1>
                              <p>Ages 5 or above</p>
                            </div>
                            <div className="rightROoomtCOnt"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Book Button */}
                    <button
                      className={`book-button ${
                        CateIDSOLDOUT === "0" ? "SOldOutClass" : ""
                      }`}
                      onClick={
                        CateIDSOLDOUT !== "0" ? handleBooking : undefined
                      }
                    >
                      {CateIDSOLDOUT === "0" ? "Sold Out" : "Book Now"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`discbriBaar ${isAtTop ? "show" : ""}`}>
            <div className="container-lg">
              <div className="discribtCOntList">
                {bardata.map((item, index) => (
                  <div key={index}>
                    <p
                      onClick={() => handleActive(index)}
                      className={activeIndex === index ? "active" : ""}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={`bookingSCreenShowMob ${showBookButton ? "active" : ""}`}
          >
            <button
              type="button"
              className="primaryBtn"
              onClick={scrollToHotelCard}
            >
              Book Now
            </button>
          </div>

          <div className="container-lg">
            {/*---------------Discribtion----------*/}
            <div className="col-lg-6 col-12 mb-3">
              <div
                id="Description"
                className={`DiscribtionCOnt ${isAtTop ? "BarMrginActiv" : ""}`}
              >
                <h1>Discription</h1>
                <p>{HotelDetails.about_us}</p>
              </div>
            </div>

            {/*---------------Populer Facilities----------*/}
            {facilitiesToShow &&
              facilitiesToShow.length > 0 &&
              FacilityImageLink !== "" && (
                <div id="Popular facilities" className="col-lg-6 col-12 mb-3">
                  <div className="pupulrFecHeading">
                    <h1>Popular facilities</h1>
                  </div>
                  <div className="PopulerFectilitie">
                    {facilitiesToShow.map((facility, index) => (
                      <div key={index}>
                        <div className="PupllrText">
                          <img
                            src={FacilityImageLink + facility.animatie_code}
                            alt={HotelDetails.hotel_name}
                          ></img>

                          <span className="text-sm text-gray-600">
                            {facility.animatie_name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {FacilityList.length > 6 && (
                    <button
                      className="sHO_ALLL"
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? "Show Less" : "Show All"}
                    </button>
                  )}
                </div>
              )}
            {/*---------------Catagory  Section----------*/}
            <div id="Category" className="col-lg-6 col-12 mb-3">
              <div className="pupulrFecHeading">
                <h1>Catagory</h1>
              </div>

              {RoomsImageLink !== "" &&
                RoomsList.length > 0 &&
                RoomsList.map((room, index) => (
                  <div
                    key={index}
                    className={
                      room.status_sold_out === "0"
                        ? "CatagoriDIv  GrayClass"
                        : "CatagoriDIv"
                    }
                  >
                    <div
                      className={
                        room.cat_id === CateID ? "premiumAcRoom" : "Luxuryroom"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => handleroomChange(room)}
                    >
                      <p>{room.category_name}</p>
                    </div>
                    <div className="catSections">
                      <div
                        className="lefSection"
                        onClick={() => handleroomChange(room)}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <h2>Room details</h2>
                          <p>
                            Min Occupancy:{" "}
                            {parseInt(room.base_inventory) > 0
                              ? room.base_inventory
                              : "1"}
                          </p>
                          <p>
                            Max Occupancy:{" "}
                            {parseInt(room.max_occupancy) > 0
                              ? room.max_occupancy
                              : "1"}
                          </p>
                          <p> {room.room_description}</p>
                        </div>

                        <div className="price-section priCeData">
                          {parseInt(HotelDetails.discount) > 0 && (
                            <>
                              <span className="discount-badge">
                                {HotelDetails.discount}% Off
                              </span>
                              <span className="original-price">
                                {RupessIcon}
                                {room.room_price *
                                  (100 / HotelDetails.discount)}
                              </span>
                            </>
                          )}
                          <span className="current-price">
                            {RupessIcon}
                            {room.room_price}
                          </span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelectedImagedata(room.room_images_mutiple);
                          setSelectedImagedataLink(RoomsImageLink);
                          handleShow();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={RoomsImageLink + room.room_images}
                          alt={HotelDetails.hotel_name}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>

            {/*--------------Near BY---------*/}
            <div id="Near by" className="col-lg-6 col-12 mb-3">
              <div className="pupulrFecHeading">
                <h1>Near by</h1>
              </div>

              <div className="gogleMap">
                {HotelDetails &&
                  HotelDetails.longitude &&
                  HotelDetails.latitude &&
                  HotelDetails.hotel_name && (
                    <div style={{ width: "100%", height: "100%" }}>
                      <iframe
                        src={
                          `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_PLACES_API_KEY}` +
                          `&q=${encodeURIComponent(
                            HotelDetails.hotel_name || ""
                          )}` +
                          `&center=${HotelDetails.latitude || 0},${
                            HotelDetails.longitude || 0
                          }` +
                          `&zoom=14`
                        }
                        style={{ width: "100%", height: "450px" }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title={"Location Map for " + HotelDetails.hotel_name}
                      ></iframe>
                    </div>
                  )}

                <div className="TravllButtons">
                  {categories.map((category, index) => (
                    <button
                      key={index}
                      className={`TransportBtn ${
                        activeCategoryindex === index ? "active" : ""
                      }`}
                      onClick={() =>
                        get_neardata_data(
                          HotelDetails.latitude,
                          HotelDetails.longitude,
                          index
                        )
                      }
                    >
                      <img src={category.icon} alt={`${category.title} Icon`} />
                      {category.title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="locationsSHowDiv">
                <div>
                  {categories[activeCategoryindex].data.map((place, index) => (
                    <div key={index} className="travellText">
                      <p>
                        {"\u2022"}
                        {place.name.length > 25
                          ? place.name.slice(0, 25) + "..."
                          : place.name}
                      </p>
                      <h4>{place.distance}KM</h4>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>
            {/*----------------AQI Bar-------------- */}

            <div id="AQI" className="col-lg-6 col-12 mb-3">
              {currentAQI && parseInt(currentAQI) > 0 && (
                <AQIBar
                  currentcategory={currentCategory}
                  currentAaqi={currentAQI}
                />
              )}
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>
            {/*---------------Additional information-------------- */}
            <div className="AdditionlInf">
              <div id="Additional information" className="pupulrFecHeading">
                <h1>Additional information</h1>
              </div>

              <div
                className="AdditionlData"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(additionalInfo),
                }}
              ></div>
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>
            {/*---------------Hotel policy------------ */}
            <div className="AdditionlInf">
              <div className="pupulrFecHeading">
                <h1>Hotel policy</h1>
              </div>

              <div
                className="AdditionlData"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(additionalInfo1),
                }}
              ></div>
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>
            {/*---------------Cancellation policy ------------ */}
            <div id="Cancellation policy" className="AdditionlInf">
              <div className="pupulrFecHeading">
                <h1>Cancellation policy</h1>
              </div>

              <div
                className="AdditionlData"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(cancellationPolicy),
                }}
              ></div>
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <div className="LuxryCont">
                <hr />
              </div>
            </div>
            {/*---------------Faq------------ */}
            {faqData && faqData.length > 0 && (
              <div className="col-lg-6 col-12 mb-3">
                <Faq faq={faqData} />
              </div>
            )}
          </div>
          {/* --------------------------Similar Hotels------------------------- */}
          {/* <div className="container-lg">
            <div id="Cancellation policy" className="AdditionlInf">
              <div className="pupulrFecHeading">
                <h1>Similar hotel in Indore</h1>
              </div>
            </div>
          </div> */}
        </div>

        <SharePopup
          i
          sOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>
      <Modal
        className="imgShowModal"
        backdrop="static"
        show={show}
        onHide={handleCloseClick}
        size="lg"
        centered
      >
        <Modal.Header closeButton={!selectedImageIdx}>
          <Modal.Title>
            {selectedImageIdx === null && <h1>All Images</h1>}

            {selectedImageIdx !== null && (
              <button className="backbutton" onClick={handleBackClick}>
                <img src={BackBtn} alt="BackBtn"></img>Back
              </button>
            )}
          </Modal.Title>
        </Modal.Header>

        {/* Modal Body */}
        <Modal.Body>
          {selectedImageIdx === null ? (
            <div className="d-flex flex-wrap justify-content-start">
              {SelectedImagedata.map((image, idx) => (
                <div
                  key={idx}
                  className="d-flex flex-column align-items-center"
                  onClick={() => handleImageClick(idx)}
                  style={{ cursor: "pointer" }}
                >
                  <LazyLoadImage
                    className="roWHtlImgs"
                    src={SelectedImagedataLink + image.image_name}
                    alt={HotelDetails.hotel_name}
                    effect="blur" // Apply blur effect while loading
                    loading="lazy" // Lazy load the image
                  />
                </div>
              ))}
            </div>
          ) : (
            <div
              className="clickdImgHotel"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="prevCarauselImgSHow">
                <LazyLoadImage
                  src={
                    SelectedImagedataLink +
                    SelectedImagedata[selectedImageIdx].image_name
                  }
                  alt={HotelDetails.hotel_name}
                  className="d-block AFterCLickddata"
                  effect="blur"
                  loading="lazy"
                />
              </div>
              <div className="imageNavigation">
                <button
                  className="prevBtn"
                  onClick={handlePrevImage}
                  disabled={selectedImageIdx === 0}
                >
                  <img src={PrevIcon} alt="Previous" className="btn-icon" />
                </button>
                <button
                  className="nextBtn"
                  onClick={handleNextImage}
                  disabled={selectedImageIdx === SelectedImagedata.length - 1}
                >
                  <img src={NextIcon} alt="Next" className="btn-icon" />
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
