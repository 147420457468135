import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ScrollToTop from "./CommonJquery/ScrollToTop";
import AboutUs from "./Componets/AboutUs";
import AqiPage from "./Componets/AqiPage";
import Banquet from "./Componets/Banquet";
import Blog from "./Componets/Blog";
import Booking from "./Componets/Booking";
import BookingConfirm from "./Componets/BookingConfirm";
import BookingReciept from "./Componets/BookingReciept";
import BookingRequest from "./Componets/BookingRequest";
import CancelBooking from "./Componets/CancelBooking";
import CancelledBooking from "./Componets/CancelledBooking";
import Career from "./Componets/Career";
import CoprativeSolution from "./Componets/CoprativeSolution";
import CreateBooking from "./Componets/CreateBooking";
import ExpressChecking from "./Componets/ExpressChecking";
import FailledPayment from "./Componets/FailledPayment";
import Favorite from "./Componets/Favorite";
import GuestPolicy from "./Componets/GuestPolicy";
import HelpSection from "./Componets/HelpSection";
import Home from "./Componets/Home";
import HotelDetail from "./Componets/HotelDetail";
import HotelListLocation from "./Componets/HotelListLocation";
import Influencer from "./Componets/Influencer";
import InhoueBookings from "./Componets/InhoueBookings";
import ListYourHotel from "./Componets/ListYourHotel";
import Offer from "./Componets/Offer";
import PrivacyPolicy from "./Componets/PrivacyPolicy";
import Profile from "./Componets/Profile";
import Faq from "./Componets/Reapeting Componets/Faq";
import ReferEarnPage from "./Componets/ReferEarnPage";
import RefundStatus from "./Componets/RefundStatus";
import RefundSuccess from "./Componets/RefundSuccess";
import RewardPage from "./Componets/RewardPage";
import SuccessPayment from "./Componets/SuccessPayment";
import SwagStayWallet from "./Componets/SwagStayWallet";
import TermsCondition from "./Componets/TermsCondition";
import TravelAgent from "./Componets/TravelAgent";
import UpComingBooking from "./Componets/UpComingBooking";
import WelcomeBonus from "./Componets/WelcomeBonus";

import BlogDetail from "./Componets/BlogDetail";
import CareerDetails from "./Componets/CareerDetails";
import CompletedBooking from "./Componets/CompletedBooking";
import FeedbackForm from "./Componets/FeedbackForm";
import FlatDiscountPage from "./Componets/FlatDiscountPage";
import HomeHotelMapView from "./Componets/HomeHotelMapView";
import NoSHowBooking from "./Componets/NoSHowBooking";
import PageNotFound from "./Componets/PageNotFound";
import ReferFaq from "./Componets/ReferFaq";
import ReferTermsandCondition from "./Componets/ReferTermsandCondition";
import RazorpaySubscriptionButton from "./Componets/SubsButton";
import {
  get_seo_data_website,
  server_post_data,
} from "./ServiceConnection/serviceconnection.js";
function App() {
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name.includes("blog/blogdetail/")) {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/express_checking") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/welcome_bonus") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name === "/about_us") {
      data_seo_link_final = pretty_function_name + "~@~6";
    } else if (call_function_name === "/faq") {
      data_seo_link_final = pretty_function_name + "~@~7";
    } else if (call_function_name.includes("hotel_detail/")) {
      data_seo_link_final = pretty_function_name + "~@~8";
    } else if (call_function_name === "/privacy_policy") {
      data_seo_link_final = pretty_function_name + "~@~9";
    } else if (call_function_name === "/terms_condition") {
      data_seo_link_final = pretty_function_name + "~@~10";
    } else if (call_function_name.includes("create_booking/")) {
      data_seo_link_final = pretty_function_name + "~@~11";
    } else if (call_function_name.includes("Confirm_booking/")) {
      data_seo_link_final = pretty_function_name + "~@~12";
    } else if (call_function_name === "/booking_reciept") {
      data_seo_link_final = pretty_function_name + "~@~13";
    } else if (call_function_name === "/cancel_booking") {
      data_seo_link_final = pretty_function_name + "~@~14";
    } else if (call_function_name === "/cancelled_booking") {
      data_seo_link_final = pretty_function_name + "~@~15";
    } else if (call_function_name === "/career") {
      data_seo_link_final = pretty_function_name + "~@~16";
    } else if (call_function_name === "/offer") {
      data_seo_link_final = pretty_function_name + "~@~17";
    } else if (call_function_name === "/aqi_page") {
      data_seo_link_final = pretty_function_name + "~@~18";
    } else if (call_function_name === "/profile") {
      data_seo_link_final = pretty_function_name + "~@~19";
    } else if (call_function_name === "/bookings") {
      data_seo_link_final = pretty_function_name + "~@~20";
    } else if (call_function_name === "/bookings_details") {
      data_seo_link_final = pretty_function_name + "~@~21";
    } else if (call_function_name === "/inhouse_bookings") {
      data_seo_link_final = pretty_function_name + "~@~22";
    } else if (call_function_name === "/completed_bookings") {
      data_seo_link_final = pretty_function_name + "~@~23";
    } else if (call_function_name === "/noshow_bookings") {
      data_seo_link_final = pretty_function_name + "~@~24";
    } else if (call_function_name === "/refund_status") {
      data_seo_link_final = pretty_function_name + "~@~25";
    } else if (call_function_name === "/refund_success") {
      data_seo_link_final = pretty_function_name + "~@~26";
    } else if (call_function_name === "/swag_stay_wallet") {
      data_seo_link_final = pretty_function_name + "~@~27";
    } else if (call_function_name === "/success_payment") {
      data_seo_link_final = pretty_function_name + "~@~28";
    } else if (call_function_name === "/help") {
      data_seo_link_final = pretty_function_name + "~@~29";
    } else if (call_function_name === "/guest_policy") {
      data_seo_link_final = pretty_function_name + "~@~30";
    } else if (call_function_name.includes("hotel_list_location/")) {
      data_seo_link_final = pretty_function_name + "~@~31";
    } else if (call_function_name === "/home_hotel_map_view") {
      data_seo_link_final = pretty_function_name + "~@~32";
    } else if (call_function_name === "/favorites") {
      data_seo_link_final = pretty_function_name + "~@~33";
    } else if (call_function_name === "/refer_earn_page") {
      data_seo_link_final = pretty_function_name + "~@~34";
    } else if (call_function_name === "/refer_terms_page") {
      data_seo_link_final = pretty_function_name + "~@~35";
    } else if (call_function_name === "/refer_faq_page") {
      data_seo_link_final = pretty_function_name + "~@~36";
    } else if (call_function_name === "/reward_page") {
      data_seo_link_final = pretty_function_name + "~@~37";
    } else if (call_function_name === "/booking_request") {
      data_seo_link_final = pretty_function_name + "~@~38";
    } else if (call_function_name === "/list_your_hotel") {
      data_seo_link_final = pretty_function_name + "~@~39";
    } else if (call_function_name === "/coprative_solution") {
      data_seo_link_final = pretty_function_name + "~@~40";
    } else if (call_function_name === "/travel_agent") {
      data_seo_link_final = pretty_function_name + "~@~41";
    } else if (call_function_name === "/banquet") {
      data_seo_link_final = pretty_function_name + "~@~42";
    } else if (call_function_name === "/influencer") {
      data_seo_link_final = pretty_function_name + "~@~43";
    } else if (call_function_name === "/flat_discount_page") {
      data_seo_link_final = pretty_function_name + "~@~44";
    } else if (call_function_name === "/testApi") {
      data_seo_link_final = pretty_function_name + "~@~45";
    } else if (call_function_name.includes("career_details/")) {
      data_seo_link_final = pretty_function_name + "~@~46";
    } else if (call_function_name === "/feedbackform") {
      data_seo_link_final = pretty_function_name + "~@~47";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <Home />;
    } else if (page_number === "2") {
      data_seo_link_final = <Blog />;
    } else if (page_number === "3") {
      data_seo_link_final = <BlogDetail />;
    } else if (page_number === "4") {
      data_seo_link_final = <ExpressChecking />;
    } else if (page_number === "5") {
      data_seo_link_final = <WelcomeBonus />;
    } else if (page_number === "6") {
      data_seo_link_final = <AboutUs />;
    } else if (page_number === "7") {
      data_seo_link_final = <Faq />;
    } else if (page_number === "8") {
      data_seo_link_final = <HotelDetail />;
    } else if (page_number === "9") {
      data_seo_link_final = <PrivacyPolicy />;
    } else if (page_number === "10") {
      data_seo_link_final = <TermsCondition />;
    } else if (page_number === "11") {
      data_seo_link_final = <CreateBooking />;
    } else if (page_number === "12") {
      data_seo_link_final = <BookingConfirm />;
    } else if (page_number === "13") {
      data_seo_link_final = <BookingReciept />;
    } else if (page_number === "14") {
      data_seo_link_final = <CancelBooking />;
    } else if (page_number === "15") {
      data_seo_link_final = <CancelledBooking />;
    } else if (page_number === "16") {
      data_seo_link_final = <Career />;
    } else if (page_number === "17") {
      data_seo_link_final = <Offer />;
    } else if (page_number === "18") {
      data_seo_link_final = <AqiPage />;
    } else if (page_number === "19") {
      data_seo_link_final = <Profile />;
    } else if (page_number === "20") {
      data_seo_link_final = <Booking />;
    } else if (page_number === "21") {
      data_seo_link_final = <UpComingBooking />;
    } else if (page_number === "22") {
      data_seo_link_final = <InhoueBookings />;
    } else if (page_number === "23") {
      data_seo_link_final = <CompletedBooking />;
    } else if (page_number === "24") {
      data_seo_link_final = <NoSHowBooking />;
    } else if (page_number === "25") {
      data_seo_link_final = <RefundStatus />;
    } else if (page_number === "26") {
      data_seo_link_final = <RefundSuccess />;
    } else if (page_number === "27") {
      data_seo_link_final = <SwagStayWallet />;
    } else if (page_number === "28") {
      data_seo_link_final = <SuccessPayment />;
    } else if (page_number === "29") {
      data_seo_link_final = <HelpSection />;
    } else if (page_number === "30") {
      data_seo_link_final = <GuestPolicy />;
    } else if (page_number === "31") {
      data_seo_link_final = <HotelListLocation />;
    } else if (page_number === "32") {
      data_seo_link_final = <HomeHotelMapView />;
    } else if (page_number === "33") {
      data_seo_link_final = <Favorite />;
    } else if (page_number === "34") {
      data_seo_link_final = <ReferEarnPage />;
    } else if (page_number === "35") {
      data_seo_link_final = <ReferTermsandCondition />;
    } else if (page_number === "36") {
      data_seo_link_final = <ReferFaq />;
    } else if (page_number === "37") {
      data_seo_link_final = <RewardPage />;
    } else if (page_number === "38") {
      data_seo_link_final = <BookingRequest />;
    } else if (page_number === "39") {
      data_seo_link_final = <ListYourHotel />;
    } else if (page_number === "40") {
      data_seo_link_final = <CoprativeSolution />;
    } else if (page_number === "41") {
      data_seo_link_final = <TravelAgent />;
    } else if (page_number === "42") {
      data_seo_link_final = <Banquet />;
    } else if (page_number === "43") {
      data_seo_link_final = <Influencer />;
    } else if (page_number === "44") {
      data_seo_link_final = <FlatDiscountPage />;
    } else if (page_number === "45") {
      data_seo_link_final = <RazorpaySubscriptionButton />;
    } else if (page_number === "46") {
      data_seo_link_final = <CareerDetails />;
    } else if (page_number === "47") {
      data_seo_link_final = <FeedbackForm />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(get_seo_data_website, null)
      .then((Response) => {
        setSEOloop(Response);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  // Memoize route generation to prevent unnecessary re-renders
  const dynamicRoutes = useMemo(() => {
    try {
      // Defensive programming: Add error handling
      if (!SEOloop || SEOloop.length === 0) {
        console.warn("SEOloop is empty or undefined");
        return [];
      }

      return SEOloop.map((data, index) => {
        try {
          // Safe route generation with extensive error checking
          const for_loop_come = match_and_return_seo_link_and_page(
            data.call_function_name,
            data.pretty_function_name,
            data.title,
            data.favicon
          );

          if (!for_loop_come) {
            return null;
          }

          const stringArray = for_loop_come.split("~@~");

          // Validate route parts
          if (stringArray.length < 2) {
            return null;
          }

          return (
            <Route
              key={index}
              path={stringArray[0]}
              element={get_page_name(stringArray[1])}
            />
          );
        } catch (routeError) {
          return null;
        }
      }).filter((route) => route !== null);
    } catch (error) {
      return [];
    }
  }, [SEOloop]); // Dependency on SEOloop

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="blog" element={<Blog />} />

          <Route path="express_checking" element={<ExpressChecking />} />
          <Route path="welcome_bonus" element={<WelcomeBonus />} />
          <Route path="about_us" element={<AboutUs />} />
          <Route path="faq" element={<Faq />} />

          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="terms_condition" element={<TermsCondition />} />
          <Route
            path="create_booking/:HotelID/:hotel_id"
            element={<CreateBooking />}
          />
          <Route
            path="Confirm_booking/:HotelID/:hotel_id"
            element={<BookingConfirm />}
          />
        

          <Route path="career" element={<Career />} />
          <Route path="offer" element={<Offer />} />
          <Route path="aqi_page" element={<AqiPage />} />
          <Route path="profile" element={<Profile />} />

          {/* ---------------------Bookings Section --------------------------------------------- */}
          <Route path="bookings" element={<Booking />} />
          <Route path="bookings_details" element={<UpComingBooking />} />
          {/**not work on this  */}
          <Route path="inhouse_bookings" element={<InhoueBookings />} />
          <Route path="completed_bookings" element={<CompletedBooking />} />
          <Route path="noshow_bookings" element={<NoSHowBooking />} />
          <Route path="cancelled_booking" element={<CancelledBooking />} />
          <Route path="refund_success" element={<RefundSuccess />} />
          <Route path="booking_reciept" element={<BookingReciept />} />
          <Route path="cancel_booking" element={<CancelBooking />} />
          <Route path="failled_payment" element={<FailledPayment />} />
          {/**not work on this  */}
          {/* ---------------------Bookings Section --------------------------------------------- */}

          {/* ---------------------Refund Status Section --------------------------------------------- */}
          <Route path="refund_status" element={<RefundStatus />} />

          {/* ---------------------Refund Status Section --------------------------------------------- */}

          <Route path="swag_stay_wallet" element={<SwagStayWallet />} />
          <Route path="success_payment" element={<SuccessPayment />} />
        
          <Route path="help" element={<HelpSection />} />
          <Route path="guest_policy" element={<GuestPolicy />} />

          <Route path="home_hotel_map_view" element={<HomeHotelMapView />} />
          <Route path="favorites" element={<Favorite />} />

          {/* ---------------------Refer and Earn Section --------------------------------------------- */}
          <Route path="refer_earn_page" element={<ReferEarnPage />} />
          <Route path="refer_terms_page" element={<ReferTermsandCondition />} />
          <Route path="refer_faq_page" element={<ReferFaq />} />

          {/* --------------------My Reward --------------------------------------------- */}
          <Route path="reward_page" element={<RewardPage />} />

          {/* ------------------Forms Section--------------------------------------------- */}
          <Route path="booking_request" element={<BookingRequest />} />
          <Route path="list_your_hotel" element={<ListYourHotel />} />
          <Route path="coprative_solution" element={<CoprativeSolution />} />
          <Route path="travel_agent" element={<TravelAgent />} />
          <Route path="banquet" element={<Banquet />} />
          <Route path="influencer" element={<Influencer />} />
          <Route path="flat_discount_page" element={<FlatDiscountPage />} />
          <Route path="testApi" element={<RazorpaySubscriptionButton />} />

          <Route path="feedbackform" element={<FeedbackForm />} />

          <Route
            path="hotel_detail/:HotelID/:hotel_id"
            element={<HotelDetail />}
          />
          <Route
            path="hotel_list_location/:id"
            element={<HotelListLocation />}
          />
          <Route path="blog/blogdetail/:id" element={<BlogDetail />} />
          <Route path="career_details/:id" element={<CareerDetails />} />
          <Route path="search" element={<HotelListLocation />} />

          {dynamicRoutes}
          {SEOloop && SEOloop.length > 0 && (
            <Route path="*" element={<PageNotFound />} />
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}
/**
 * OnBoarding.js
 * SearchBar.js
 * Venue.js
 */
export default App;
