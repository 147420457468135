import React, { useEffect, useState, useRef } from "react";
import "./Css/CreateBooking.css";
import { handleLinkClick } from "../CommonJquery/CommonJquery";
import Header from "./Header";
import BackBar from "./Reapeting Componets/BackBar";
import DateIcon from "../Assets/calendar.svg"; // Add icon paths here
import PersonIcon from "../Assets/door.svg";
import RoomIcon from "../Assets/roomCata.svg";
import EditIcon from "../Assets/editIcon.png";
import AppQR from "../Assets/appQR.png";
import ClockIcon from "../Assets/ClockIcon.svg";
import DownIcon from "../Assets/downIcon.svg";
import NoteIcon from "../Assets/noteIcon.png";
import RightArrow from "../Assets/rightAngle.png";
import GradientImg from "../Assets/gradeintBottom.png";
import Cloud from "../Assets/expressCloud.png";
import ErrorImg from "../Assets/ErrorImg.png";
import GreenCorrect from "../Assets/correctGreen.png";
import UploadImg from "../Assets/uploadImg.png";
import ApplyCoupon from "./ApplyCoupon";
import OfferSign from "../Assets/offerSign.png";
import BookingLoader from "./BookingLoader.js";

import { useLocation, useNavigate } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { v4 as uuidv4 } from "uuid";
import {
  booking_calculation,
  offers_list,
  save_express_checkin_reactnative,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import {
  check_vaild_save,
  combiled_form_data,
  formatDateweekdate,
  handleAphabetsChange,
  handleError,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery";

export default function CreateBooking() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isExpressCheckIn, setisExpressCheckIn] = useState(false);
  const [forSelf, setforSelf] = useState(false);
  const [SelfCheckin, setSelfCheckin] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [guestCount, setGuestCount] = useState(0);
  const [openAccordion, setOpenAccordion] = useState(0); // Keeps track of which accordionExp is open
  const [dynaicimage, setDynaicimage] = useState(null);
  const [selectedMeals, setSelectedMeals] = useState({
    breakfast: false,
    lunch: false,
    dinner: false,
  });

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        handleError("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        handleError("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();
      console.log(new_file_name);
      reader.onload = (e) => {
        const img = new Image();
        img.src = reader.result;
        console.log(new_file_name);
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  const handleExpressCheckin = () => {
    setisExpressCheckIn(!isExpressCheckIn);
    if (!isExpressCheckIn) {
      setforSelf(true);
    } else {
      setforSelf(false);
    }
    // setIsProfileComplete(false);
    setSelfCheckin(false);
  };

  const [rooms, setRooms] = useState([]);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [CustomerName, setCustomerName] = useState("");
  const [totalAdults, settotalAdults] = useState(1);
  const [totalChildren, settotalChildren] = useState(0);
  const [CateID, setCateID] = useState(0);
  const [CateIDName, setCateIDName] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [HotelDetails, setHotelDetails] = useState([]);
  const [HotelImageLink, setHotelImageLink] = useState("");
  const [PriceShow, setPriceShow] = useState("");
  const [checkInTime, setCheckInTime] = useState("10:00 AM");
  const [checkOutTime, setCheckOutTime] = useState("11:00 AM");
  const [selectedPickupLocation, setSelectedPickupLocation] =
    useState("Pickup location");
    console.log(selectedPickupLocation)
  const [selectedDropLocation, setSelectedDropLocation] =
    useState("Drop location");
  const [selectPickLocation, setSelectPickLocation] =
    useState("Pick-up location");
  const [selectDropLocation, setSelectDropLocation] =
    useState("Drop-off location");
  const [bookingData, setbookingData] = useState([]);
  const [isswagCoinBalance, setIsswagCoinBalance] = useState(false);
  const [isswagWalletBalance, setIsswagWalletBalance] = useState(false);

  const [couponId, setcouponId] = useState(0);
  const [couponname, setcouponname] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponslist, setcouponslist] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(null); // Store applied coupon
  const [guestDetails, setGuestDetails] = useState([]);
  useEffect(() => {
    let customer_rooms = retrieveData("RoomData");
    let customer_checkInDate = retrieveData("CheckIndate");
    let customer_checkOutDate = retrieveData("CheckOutdate");
    let customer_id = retrieveData("customer_id");
    let customer_name = retrieveData("customer_full_name");
    let TotalAdultAndChild = retrieveData("TotalAdultAndChild");
    let cate_id = retrieveData("CateID");
    if (customer_rooms === null || customer_rooms === 0) {
      customer_rooms = 0;
    }

    if (customer_checkInDate === null || customer_checkInDate === 0) {
      customer_checkInDate = 0;
    }
    if (customer_checkOutDate === null || customer_checkOutDate === 0) {
      customer_checkOutDate = 0;
    }

    if (TotalAdultAndChild === null || TotalAdultAndChild === 0) {
      TotalAdultAndChild = 0;
    }

    if (customer_rooms !== "0") {
      setRooms(JSON.parse(customer_rooms));
    }
    if (customer_name !== "0") {
      setCustomerName(customer_name);
    }
    if (customer_checkInDate !== "0") {
      setCheckInDate(customer_checkInDate);
    }
    if (customer_checkOutDate !== "0") {
      setCheckOutDate(customer_checkOutDate);
    }
    if (TotalAdultAndChild !== "0") {
      let splite_data = TotalAdultAndChild.split("~@~");
      settotalAdults(splite_data[1]);
      settotalChildren(splite_data[2]);
      setGuestDetails(
        Array.from({ length: parseInt(splite_data[1]) }, (_, index) => ({
          fullName: "",
          mobileNumber: "",
          aadharNumber: "",
          identityProofImages: [
            { label: "Upload Front Side of Aadhaar", uri: null },
            { label: "Upload Back Side of Aadhaar", uri: null },
          ],
          showGuestDetails: index === 0, // Show only the first guest's details by default
        }))
      );
    }
    if (cate_id !== "0") {
      setCateID(cate_id);
    }
    setCustomerId(customer_id);

    const url = currentUrl;
    const parts = url.split("/");
    let hotel_id = "0";
    let HotelID = "0";
    if (parts.length === 3) {
      hotel_id = parts[2];
      HotelID = parts[1];
    }
    master_data_get_calculation(
      hotel_id,
      HotelID,
      cate_id,
      customer_checkInDate,
      customer_checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      JSON.parse(customer_rooms),
      isswagCoinBalance,
      isswagWalletBalance,
      couponId,
      couponname,
      0,
      url
    );

    if (customerId == 0) {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  }, []);

  const selectTime = (event, isCheckIn) => {
    let time_data = checkInTime;
    let time_data2 = checkOutTime;
    if (isCheckIn) {
      time_data = event.target.value;
      setCheckInTime(time_data); // Append AM for check-in
    } else {
      time_data2 = event.target.value;

      setCheckOutTime(time_data2); // Append AM for check-in
    }
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      time_data,
      time_data2,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      isswagWalletBalance,
      couponId,
      couponname,
      1,
      "0"
    );
  };

  const handleMealChange = (event) => {
    const { name, checked } = event.target;

    const updatedMeals = { ...selectedMeals, [name]: checked };
    setSelectedMeals(updatedMeals);
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      updatedMeals.breakfast,
      updatedMeals.lunch,
      updatedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      isswagWalletBalance,
      couponId,
      couponname,
      1,
      "0"
    );
  };

  const handleLocationSelect = (event, isPickup) => {
    let time_data = selectedPickupLocation;
    let time_data2 = selectedDropLocation;
    if (isPickup) {
      time_data = event.target.value;
      setSelectedPickupLocation(time_data);
      setSelectPickLocation(time_data);
    } else {
      time_data2 = event.target.value;
      setSelectedDropLocation(time_data2);
      setSelectDropLocation(time_data2);
    }

    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      isswagWalletBalance,
      couponId,
      couponname,
      1,
      "0"
    );
  };
  const applyCoupon = (couponId) => {
    const coupondata = couponslist[couponId];
    setIsCoupon(false);
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      isswagWalletBalance,
      coupondata.p_id,
      coupondata.promo_code,
      1,
      "0"
    );
  };

  const removeCoupon = () => {
    setAppliedCoupon(null);
    setcouponId(0);
    setcouponname("");
    setIsCoupon(false);
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      isswagWalletBalance,
      "0",
      "",
      1,
      "0"
    );
  };

  const applyManualCoupon = (couponCodesss) => {
    if (couponCodesss) {
      setCouponCode(couponCodesss);
      setIsCoupon(false);
      master_data_get_calculation(
        HotelDetails.hotel_id,
        HotelDetails.HotelID,
        CateID,
        checkInDate,
        checkOutDate,
        selectedMeals.breakfast,
        selectedMeals.lunch,
        selectedMeals.dinner,
        checkInTime,
        checkOutTime,
        selectedPickupLocation,
        selectedDropLocation,
        rooms,
        isswagCoinBalance,
        isswagWalletBalance,
        0,
        couponCodesss,
        1,
        "0"
      );
    }
  };

  const handleSwagCoinChange = (event) => {
    const { checked } = event.target;
    setIsswagCoinBalance(checked);
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      checked,
      isswagWalletBalance,
      couponId,
      couponCode,
      1,
      "0"
    );
  };
  const handleSwagWalletChange = (event) => {
    const { checked } = event.target;
    setIsswagWalletBalance(checked);
    master_data_get_calculation(
      HotelDetails.hotel_id,
      HotelDetails.HotelID,
      CateID,
      checkInDate,
      checkOutDate,
      selectedMeals.breakfast,
      selectedMeals.lunch,
      selectedMeals.dinner,
      checkInTime,
      checkOutTime,
      selectedPickupLocation,
      selectedDropLocation,
      rooms,
      isswagCoinBalance,
      checked,
      couponId,
      couponCode,
      1,
      "0"
    );
  };

  console.log(rooms);

  const master_data_get_calculation = async (
    hotel_id,
    HotelID,
    cate_id,
    checkInDate,
    checkOutDate,
    breakfast,
    lunch,
    dinner,
    checkin_time,
    checkout_time,
    pickup_location,
    dropoff_location,
    rooms_data,
    swagcoin_check,
    real_amountcheck,
    discount_id,
    discount_name,
    click_from,
    url_current
  ) => {
    if (click_from == 0) {
      setshowLoaderAdmin(true);
    } else {
      setIsLoading(true);
    }

    const fd = new FormData();
    fd.append("hotel_id", hotel_id);
    fd.append("HotelID", HotelID);
    fd.append("from_date", checkInDate);
    fd.append("to_date", checkOutDate);
    fd.append("beackfast_check", breakfast ? "1" : "0");
    fd.append("lunch_check", lunch ? "1" : "0");
    fd.append("dinner_check", dinner ? "1" : "0");
    fd.append("cate_id", cate_id);
    fd.append("checkin_time", checkin_time);
    fd.append("checkout_time", checkout_time);
    fd.append("pickup_location", pickup_location);
    fd.append("dropoff_location", dropoff_location);
    fd.append("express_checkin", isExpressCheckIn ? "1" : "0");
    fd.append("self_other", SelfCheckin ? "1" : "0");
    fd.append("swagcoin_check", swagcoin_check ? "1" : "0");
    fd.append("real_amountcheck", real_amountcheck ? "1" : "0");
    fd.append("discount_id", discount_id);
    fd.append("coupon_name", discount_name);
    fd.append("url_current", url_current);
    let total_rooms = 0;
    let total_adults_loop = "";
    let total_children_loop = "";
    Object.entries(rooms_data).forEach(([key, value]) => {
      total_rooms++;
      total_adults_loop = total_adults_loop + value.adults + "~@~";
      total_children_loop = total_children_loop + value.children + "~@~";
    });
    fd.append("total_rooms", total_rooms);
    fd.append("total_adults_loop", total_adults_loop);
    fd.append("total_children_loop", total_children_loop);

    await server_post_data(booking_calculation, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setHotelDetails(final_data.hotel_details[0]);
          setHotelImageLink(final_data.hotel_image_link);
          setPriceShow(final_data.room_booking_price);
          setRupessIcon(final_data.rupees_icon);
          setGuestCount(final_data.total_adult);
          if (parseInt(final_data.self_aadhar_status) === 1) {
            setIsProfileComplete(true);
          }
          setCateIDName(final_data.category_name);
          setbookingData({
            bookingFor: CustomerName,
            guestCount: final_data.guest_count_show,
            roomType: final_data.category_name,
            dates: final_data.dates_for_show,
            breakfastMeal: final_data.breakfast_price,
            lunchMeal: final_data.lunch_price,
            dinnerMeal: final_data.dinner_price,
            checkinprice: final_data.checkinprice,
            checkoutprice: final_data.checkoutprice,
            discount_user_id: final_data.discount_user_id,
            discount_name: final_data.discount_name,
            discount_description: final_data.discount_description,
            discount_from_wallet: final_data.discount_from_wallet,
            discount_from_wallet_actual: final_data.discount_from_wallet_actual,
            discount_from_real_wallet: final_data.discount_from_real_wallet,
            total_use_wallet: final_data.total_use_wallet,
            rupees_icon: final_data.rupees_icon,
            swagCoinBalance: final_data.discount_from_wallet,
            RealBalance: final_data.discount_from_real_wallet,
            roomBooking: final_data.room_booking_price,
            extracharge: final_data.extra_amount,
            couponDiscount: final_data.discount_charge,
            totalPayableAmount: final_data.final_amount_to_be_pay_full,
            used_swag_coin: final_data.used_swag_coin,
            used_swag_real: final_data.used_swag_real,
            advance_amount: final_data.advance_amount,
            total_room: final_data.total_room,
            total_adult: final_data.total_adult,
            total_child: final_data.total_child,
            total_plus_amount: final_data.only_plus_data,
          });

          if (
            discount_id === 0 &&
            discount_name !== "" &&
            final_data.discount_description !== ""
          ) {
            setAppliedCoupon({
              p_id: 0,
              promo_code: discount_name,
              disc_msg: final_data.discount_description,
            });

            setcouponId(0);
            setcouponname(discount_name);
          }

          if (
            discount_id !== "0" &&
            discount_name !== "" &&
            final_data.discount_description !== ""
          ) {
            setAppliedCoupon({
              p_id: discount_id,
              promo_code: discount_name,
              disc_msg: final_data.discount_description,
            });

            setcouponId(discount_id);
            setcouponname(discount_name);
          }
        }
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (click_from == 0) {
          setshowLoaderAdmin(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  const master_data_get_all_promo = async () => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("hotel_id", HotelDetails.hotel_id);
    await server_post_data(offers_list, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcouponslist(final_data);
          setIsCoupon(!isCoupon);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleOpenModal = () => {
    if (couponslist.length == 0) {
      master_data_get_all_promo();
    } else {
      setIsCoupon(!isCoupon);
    }
  };

  const handleExpressClick = async () => {
    let id_uuid = 0;
    let checked = false;
    if (isExpressCheckIn) {
      id_uuid = uuidv4();
    }

    let fd = new FormData();

    if (SelfCheckin) {
      if (!isProfileComplete) {
        checked = false;
        console.log("1====false");
      }
    }
    if (isExpressCheckIn) {
      if (SelfCheckin && isProfileComplete) {
        checked = true;
        console.log("2====true");
      } else {
        checked = false;
        console.log("2====false");
      }
    }

    if (!isExpressCheckIn) {
      checked = true;
    }

    if (isExpressCheckIn && !SelfCheckin) {
      checked = check_vaild_save("listmyhotel");
      fd = combiled_form_data("listmyhotel", dynaicimage);
    }
    // checked=true;
    console.log("582,", checked);
    if (checked) {
      fd.append("total_guest", totalAdults);
      fd.append("hotel_id", HotelDetails.hotel_id);
      fd.append("HotelID", HotelDetails.HotelID);
      fd.append("id_uuid", id_uuid);
      setIsLoading(true);
      await server_post_data(save_express_checkin_reactnative, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            navigate(
              "/Confirm_booking/" +
                HotelDetails.HotelID +
                "/" +
                HotelDetails.hotel_id,
              {
                state: {
                  data: {
                    hotel_id: HotelDetails.hotel_id,
                    HotelID: HotelDetails.HotelID,
                    from_date: checkInDate,
                    to_date: checkOutDate,
                    beackfast_check: selectedMeals.breakfast ? "1" : "0",
                    lunch_check: selectedMeals.lunch ? "1" : "0",
                    dinner_check: selectedMeals.dinner ? "1" : "0",
                    cate_id: CateID,
                    checkin_time: checkInTime,
                    checkout_time: checkOutTime,
                    pickup_location: selectedPickupLocation,
                    dropoff_location: selectedDropLocation,
                    express_checkin: isExpressCheckIn ? "1" : "0",
                    self_other: SelfCheckin ? "1" : "0",
                    swagcoin_check: isswagCoinBalance ? "1" : "0",
                    real_amountcheck: isswagWalletBalance ? "1" : "0",
                    roomdates: rooms,
                    uuiddata: id_uuid,
                    discount_id: bookingData.discount_user_id,
                    coupon_name: bookingData.discount_name,
                  },
                },
              }
            );
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      handleError("Please Fill For Express Check-in");
    }
  };

  const handleBookingForSelf = (isSelf) => {
    setforSelf(!forSelf);
    if (isSelf) {
      setSelfCheckin(true);
    } else {
      setSelfCheckin(false);
    }
  };
  const handleInputChange = (guestNumber, field, e) => {
    if (field === "mobileNumber" || field === "aadharNumber") {
      e.target.value = e.target.value.slice(0, 14).replace(/[^0-9]/g, "");
    } else {
      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
    }
    setGuestDetails((prev) => {
      const newData = [...prev];
      newData[guestNumber] = {
        ...newData[guestNumber],
        [field]: e.target.value,
      };
      return newData;
    });
  };

  function GuestForm({ guestNumber }) {
    return (
      <div className="earlyLateColumn mb-4">
        <div
          className=" accordionExp-header "
          onClick={() => toggleAccordion(guestNumber)}
        >
          <div className="d-flex align-items-center">
            <img className="greenTick" src={GreenCorrect} alt="Swagstay" />
            <p className="guestNo">Guest {guestNumber + 1} details</p>
          </div>
          {/* <span>{openAccordion === guestNumber ? "▲" : "▼"}</span> */}
        </div>

        <div
        // className={`accordionExp-body ${
        //   openAccordion === guestNumber ? "" : "display_no"
        // }`}
        >
          <div className="formCOntainerCreate">
            <div className="inputContainer">
              <label>Full Name</label>
              <div className="w-100">
                <input
                  type="text"
                  placeholder="Full name"
                  // defaultValue={
                  //   guestDetails &&
                  //   guestDetails[guestNumber] &&
                  //   guestDetails[guestNumber].fullName &&
                  //   guestDetails[guestNumber].fullName
                  // }
                  // onInput={(e) => handleInputChange(guestNumber, "fullName", e)}
                  onChange={handleAphabetsChange}
                  className="form-control trio_mandatory"
                  id={"guest_" + guestNumber}
                  name={"guest_" + guestNumber}
                />
                <span className="condition_error"></span>
              </div>
            </div>
            <div className="inputContainer">
              <label>Mobile Number</label>
              <div className="w-100 position-relative">
                <span className="flag-icon flagIocnPos">🇮🇳 +91</span>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  // defaultValue={
                  //   guestDetails &&
                  //   guestDetails[guestNumber] &&
                  //   guestDetails[guestNumber].mobileNumber &&
                  //   guestDetails[guestNumber].mobileNumber
                  // }
                  className="form-control trio_mandatory phonNoCl"
                  id={"mobileNumber_" + guestNumber}
                  name={"mobileNumber_" + guestNumber}
                  maxLength={10}
                  // onInput={(e) =>
                  //   handleInputChange(guestNumber, "mobileNumber", e)
                  // }
                  onChange={handleNumbersChange}
                />
                <span className="condition_error"></span>
              </div>
            </div>
            <div className="inputContainer">
              <label>Aadhar Card Details</label>
              <div className="w-100">
                <input
                  type="text"
                  className="form-control trio_mandatory"
                  placeholder="Type here"
                  // defaultValue={
                  //   guestDetails &&
                  //   guestDetails[guestNumber] &&
                  //   guestDetails[guestNumber].aadharNumber &&
                  //   guestDetails[guestNumber].aadharNumber
                  // }
                  id={"aadharNumber_" + guestNumber}
                  name={"aadharNumber_" + guestNumber}
                  // onInput={(e) =>
                  //   handleInputChange(guestNumber, "aadharNumber", e)
                  // }

                  onChange={handleNumbersChange}
                  maxLength={12}
                  minLength={11}
                />
                <span className="condition_error"></span>
              </div>
            </div>
          </div>
          <div className="inputContainer mt-3">
            <div className="addharImags">
              <input
                type="file"
                id={"front_image_" + guestNumber}
                name={"front_image_" + guestNumber}
                hidden
                onChange={handleFileChangedynamic("front_image_" + guestNumber)}
              />
              <input
                id={"back_image_" + guestNumber}
                name={"back_image_" + guestNumber}
                type="file"
                hidden
                onChange={handleFileChangedynamic("back_image_" + guestNumber)}
              />

              <div className="accordionExpform">
                <label htmlFor={"front_image_" + guestNumber}>
                  <img
                    src={
                      dynaicimage &&
                      dynaicimage[`front_image_${guestNumber}_show`]
                        ? dynaicimage[`front_image_${guestNumber}_show`]
                        : UploadImg
                    }
                    onError={(e) => (e.target.src = UploadImg)}
                    alt="Swagstay"
                    className={
                      dynaicimage &&
                      dynaicimage[`front_image_${guestNumber}_show`]
                        ? "selectedImg"
                        : "upImg"
                    }
                  />
                  {dynaicimage &&
                  dynaicimage[`front_image_${guestNumber}_show`] ? (
                    <></>
                  ) : (
                    <p>Aadhar Front side</p>
                  )}
                </label>
              </div>

              <div className="accordionExpform">
                <label htmlFor={"back_image_" + guestNumber}>
                  <img
                    src={
                      dynaicimage &&
                      dynaicimage[`back_image_${guestNumber}_show`]
                        ? dynaicimage[`back_image_${guestNumber}_show`]
                        : UploadImg
                    }
                    onError={(e) => (e.target.src = UploadImg)}
                    alt="Swagstay"
                    className={
                      dynaicimage &&
                      dynaicimage[`back_image_${guestNumber}_show`]
                        ? "selectedImg"
                        : "upImg"
                    }
                  />
                  {dynaicimage &&
                  dynaicimage[`back_image_${guestNumber}_show`] ? (
                    <></>
                  ) : (
                    <p>Aadhar Back side</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          {/* <button
              className="DarkButtonClass mt-3"
              style={{ height: "40px", width: "100px" }}
            >
              Save
            </button> */}
        </div>
      </div>
    );
  }

  const toggleAccordion = (guestNumber) => {
    setOpenAccordion(guestNumber);
  };
  const handleBackbttn = () => {
    navigate(-1);
  };

  const [ShowCheckIntime, SetCheckIntime] = useState(false);
  const [ShowCheckOuttime, SetCheckOuttime] = useState(false);
  const [ShowPickup, SetShowPickup] = useState(false);
  const [ShowPickuDrop, SetShowPickupDrop] = useState(false);
  const [selectedTime, setSelectedTime] = useState("10:00 AM");
  const [selectedOutTime, setSelectedOutTime] = useState("11:00 AM");

  console.log("selected in Timimng", selectedTime);
  console.log("selected Out Timimng", selectedOutTime);
  //Check In timing data
  const CheckIntimings = [
    "10:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
  ];

  const handleTimeSelect = (checkIn) => {
    setSelectedTime(checkIn);
    SetCheckIntime(false);
  };

  //Check Out timing data

  const checkOutTimes = [
    "11:00 AM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
  ];
  const handleTimeOutSelect = (checkIn) => {
    setSelectedOutTime(checkIn);
    SetCheckOuttime(false);
  };

  //Pick Up location  data
  const PickUpLocationData = ["Airport", "Railway station", "Other location"];
  // Dowm off Place  data
  const PickDownData = ["Airport", "Railway station", "Other location"];
  //---------------Check In  Time DropDown Function------------//
  const handleShowCheckIntime = () => {
    SetCheckIntime(!ShowCheckIntime);
  };
  //---------------Check Out  Time DropDown Function------------//
  const handleShowCheckOuttime = () => {
    SetCheckOuttime(!ShowCheckOuttime);
  };
  //----------------Pick Up DropDown Function------------//
  const handleShowPickup = () => {
    SetShowPickup(!ShowPickup);
  };
  //----------------Pick Down DropDown Function------------//
  const handleShowPickupDrop = () => {
    SetShowPickupDrop(!ShowPickuDrop);
  };
  const checkTime = useRef(null);
  const checkOutTimeRef = useRef(null);
  const dateContrRef = useRef(null);
  const dateContrRef2 = useRef(null);

  const handleClickOutside = (event) => {
    if (checkTime.current && !checkTime.current.contains(event.target)) {
      SetCheckIntime(false);
    }
    if (
      checkOutTimeRef.current &&
      !checkOutTimeRef.current.contains(event.target)
    ) {
      SetCheckOuttime(false);
    }
    if (dateContrRef.current && !dateContrRef.current.contains(event.target)) {
      SetShowPickup(false);
    }
    if (
      dateContrRef2.current &&
      !dateContrRef2.current.contains(event.target)
    ) {
      SetShowPickupDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  console.log("Hotel", HotelDetails.express_status);

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
        {showLoaderAdmin && <BookingLoader />}
        <BackBar label={"Booking details"} />
      </div>
      <div className="container-xl">
        <div className="createBookingContainer margibttm">
          {/* Booking Details COntianer */}
          <section className="bookDetails">
            <div className="bookDetailsContainer mb-4">
              <div className="booldetailTop">
                <div className="left_detail">
                  <div className="hotelImg">
                    <img
                      src={HotelImageLink + HotelDetails.image_name}
                      alt={HotelDetails.hotel_name}
                    />
                  </div>
                  <div className="hotelText">
                    <p className="cardHeading">{HotelDetails.hotel_name}</p>
                    <p className="cardText mb-2">{HotelDetails.address}</p>
                    <div className="discount_price">
                      {parseInt(HotelDetails.discount) > 0 && (
                        <span className="discountTxt">
                          {HotelDetails.discount}% off
                        </span>
                      )}
                      {parseInt(HotelDetails.discount) > 0 && (
                        <p className="oldPrice">
                          {RupessIcon}
                          {PriceShow * (100 / HotelDetails.discount)}
                        </p>
                      )}
                      <h4 className="current-price">
                        {RupessIcon}
                        {PriceShow}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="right_detail">
                  <button
                    className="primaryBtn"
                    onClick={() => {
                      if (customerId == 0) {
                        var event = new CustomEvent("customEvent");
                        document
                          .getElementById("login_check_jquery")
                          .dispatchEvent(event);
                      } else {
                        handleExpressClick();
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
              <div className="bookDetailsBottom">
                <div className="leftSection">
                  <div className="bookingInfo">
                    <img
                      src={DateIcon}
                      alt="Booking Date"
                      className="cardicon"
                    />
                    <p>
                      {formatDateweekdate(checkInDate)} -{" "}
                      {formatDateweekdate(checkOutDate)}
                    </p>
                  </div>
                  <div className="bookingInfo">
                    <img
                      src={PersonIcon}
                      alt="Number of Persons"
                      className="cardicon"
                    />
                    <p>
                      Room {rooms?.length ?? 0} : Adults -{" "}
                      {rooms?.[0]?.adults ?? 0} | Children -{" "}
                      {rooms?.[0]?.children ?? 0}
                    </p>
                  </div>
                  <div className="bookingInfo">
                    <img
                      src={RoomIcon}
                      alt="Room Category"
                      className="cardicon"
                    />
                    <p>Category: {CateIDName}</p>
                  </div>
                </div>

                <div className="rightSection">
                  <button className="editBtn" onClick={handleBackbttn}>
                    <img
                      src={EditIcon}
                      alt={HotelDetails.hotel_name}
                      className="cardicon"
                    />
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* Express check-in and Mobile App*/}
          {HotelDetails && parseInt(HotelDetails.express_status) === 1 && (
            <section>
              <div className="checkInSection">
                <div className="row m-0">
                  <div className="col-md-6 pad000 paddinLeftAfter768 mb-4">
                    <div className="checkInColumn">
                      <div style={{ width: "100%", position: "relative" }}>
                        <h5 className="headingh5">Check-in option</h5>
                        <div className="checkInCard">
                          <div className="checInLeft">
                            <div className="checkboxContainer">
                              <input
                                type="checkbox"
                                id="expressCheckIn"
                                className="customCheckbox"
                                name="expressCheckIn"
                                checked={isExpressCheckIn}
                                onChange={handleExpressCheckin}
                              />
                              <label
                                className="cardLabel d-flex align-items-center"
                                htmlFor="expressCheckIn"
                              >
                                <span className="customCheckboxIcon"></span>
                                Express Check-in
                              </label>
                            </div>
                            {!isProfileComplete &&
                            SelfCheckin &&
                            isExpressCheckIn ? (
                              <div className="d-flex align-items-center">
                                <img
                                  src={ErrorImg}
                                  alt="Swagstay"
                                  className="errorMarkImg"
                                />
                                <p
                                  className="cardText"
                                  style={{ color: "var(--light-red)" }}
                                >
                                  Please complete your profile first
                                </p>
                              </div>
                            ) : (
                              <p className="cardText">
                                Check into hotel in just 90 second
                              </p>
                            )}
                          </div>
                          {!isProfileComplete && !SelfCheckin && (
                            <img
                              src={Cloud}
                              alt="Swagstay"
                              className="cloudImg"
                            />
                          )}
                          {isExpressCheckIn &&
                            SelfCheckin &&
                            !isProfileComplete && (
                              <p
                                className="completeText"
                                onClick={() => handleLinkClick("/profile")}
                              >
                                Complete Your Profile
                              </p>
                            )}
                          <img
                            src={GradientImg}
                            alt="Swagstay"
                            className="gradientBottom"
                          />
                        </div>
                        {forSelf && isExpressCheckIn && (
                          <div className="selfBooking">
                            <div className="checkInCard selfBookingCard">
                              <p className="cardLabel">
                                Are you booking for your self ?
                              </p>
                              <div className="selfBookingBtns">
                                <button
                                  className="DarkButtonClass"
                                  onClick={() => handleBookingForSelf(true)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="secondaryBtn"
                                  onClick={() => handleBookingForSelf(false)}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 pad000 paddinRightAfter768 mb-4">
                    <div className="appQr">
                      <div className="qrCard h-100">
                        <img src={AppQR} alt="QR Code" className="qrImage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Left Section Items  */}
          <div className="row m-0">
            <section className="col-md-6 pad000 paddinLeftAfter768">
              {isExpressCheckIn && !SelfCheckin && (
                <div>
                  <form id="listmyhotel">
                    {guestCount > 0 &&
                      Array.from({ length: guestCount }, (_, index) => {
                        return (
                          <div key={index}>
                            {/* <GuestForm guestNumber={index} /> */}
                            <div className="earlyLateColumn mb-4">
                              <div
                                className=" accordionExp-header "
                                onClick={() => toggleAccordion(index)}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    className="greenTick"
                                    src={GreenCorrect}
                                    alt="Swagstay"
                                  />
                                  <p className="guestNo">
                                    Guest {index + 1} details
                                  </p>
                                </div>
                                {/* <span>{openAccordion === guestNumber ? "▲" : "▼"}</span> */}
                              </div>
                              <div className="formCOntainerCreate">
                                <div className="inputContainer">
                                  <label>Full Name</label>
                                  <div className="w-100">
                                    <input
                                      type="text"
                                      placeholder="Full name"
                                      defaultValue={
                                        guestDetails &&
                                        guestDetails[index] &&
                                        guestDetails[index].fullName &&
                                        guestDetails[index].fullName
                                      }
                                      // value={ExpressName}
                                      onInput={(e) =>
                                        handleInputChange(index, "fullName", e)
                                      }
                                      // onChange={(e) =>
                                      //   setExpressname(e.target.value)
                                      // }
                                      className="form-control trio_mandatory"
                                      id={"guest_" + index}
                                      name={"guest_" + index}
                                    />
                                  </div>
                                </div>
                                <div className="inputContainer">
                                  <label>Mobile Number</label>
                                  <div className="w-100 position-relative">
                                    <span className="flag-icon flagIocnPos">
                                      🇮🇳 +91
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Mobile Number"
                                      // value={mobileExpress}
                                      defaultValue={
                                        guestDetails &&
                                        guestDetails[index] &&
                                        guestDetails[index].mobileNumber &&
                                        guestDetails[index].mobileNumber
                                      }
                                      className="form-control trio_mandatory phonNoCl"
                                      // id={"mobileNumber_" + guestNumber}
                                      // name={"mobileNumber_" + guestNumber}
                                      maxLength={10}
                                      onInput={(e) =>
                                        handleInputChange(
                                          index,
                                          "mobileNumber",
                                          e
                                        )
                                      }
                                      // onChange={(e) =>
                                      //   handleModileExpress(e.target.value)
                                      // }
                                      id={"mobileNumber_" + index}
                                      name={"mobileNumber_" + index}
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                                <div className="inputContainer">
                                  <label>Aadhar Card Details</label>
                                  <div className="w-100">
                                    <input
                                      type="text"
                                      className="form-control trio_mandatory"
                                      placeholder="Type here"
                                      // value={aadharNumber}
                                      defaultValue={
                                        guestDetails &&
                                        guestDetails[index] &&
                                        guestDetails[index].aadharNumber &&
                                        guestDetails[index].aadharNumber
                                      }
                                      // onChange={(e) =>
                                      //   setAadharNumber(e.target.value)
                                      // }
                                      onInput={(e) =>
                                        handleInputChange(
                                          index,
                                          "aadharNumber",
                                          e
                                        )
                                      }
                                      maxLength={12}
                                      minLength={11}
                                      id={"aadharNumber_" + index}
                                      name={"aadharNumber_" + index}
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className="inputContainer mt-3">
                                <div className="addharImags">
                                  <input
                                    type="file"
                                    id={"front_image_" + index}
                                    name={"front_image_" + index}
                                    hidden
                                    onChange={handleFileChangedynamic(
                                      "front_image_" + index
                                    )}
                                  />
                                  <input
                                    id={"back_image_" + index}
                                    name={"back_image_" + index}
                                    type="file"
                                    hidden
                                    onChange={handleFileChangedynamic(
                                      "back_image_" + index
                                    )}
                                  />

                                  <div className="accordionExpform">
                                    <label htmlFor={"front_image_" + index}>
                                      <img
                                        src={
                                          dynaicimage &&
                                          dynaicimage[
                                            `front_image_${index}_show`
                                          ]
                                            ? dynaicimage[
                                                `front_image_${index}_show`
                                              ]
                                            : UploadImg
                                        }
                                        onError={(e) =>
                                          (e.target.src = UploadImg)
                                        }
                                        alt="Swagstay"
                                        className={
                                          dynaicimage &&
                                          dynaicimage[
                                            `front_image_${index}_show`
                                          ]
                                            ? "selectedImg"
                                            : "upImg"
                                        }
                                      />
                                      {dynaicimage &&
                                      dynaicimage[
                                        `front_image_${index}_show`
                                      ] ? (
                                        <></>
                                      ) : (
                                        <p>Aadhar Front side</p>
                                      )}
                                    </label>
                                  </div>

                                  <div className="accordionExpform">
                                    <label htmlFor={"back_image_" + index}>
                                      <img
                                        src={
                                          dynaicimage &&
                                          dynaicimage[
                                            `back_image_${index}_show`
                                          ]
                                            ? dynaicimage[
                                                `back_image_${index}_show`
                                              ]
                                            : UploadImg
                                        }
                                        onError={(e) =>
                                          (e.target.src = UploadImg)
                                        }
                                        alt="Swagstay"
                                        className={
                                          dynaicimage &&
                                          dynaicimage[
                                            `back_image_${index}_show`
                                          ]
                                            ? "selectedImg"
                                            : "upImg"
                                        }
                                      />
                                      {dynaicimage &&
                                      dynaicimage[
                                        `back_image_${index}_show`
                                      ] ? (
                                        <></>
                                      ) : (
                                        <p>Aadhar Back side</p>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </form>
                </div>
              )}

              {/* Early Check-in Late Check-out Section */}
              {HotelDetails &&
                parseInt(HotelDetails.early_checkin_price) > 0 &&
                parseInt(HotelDetails.late_check_out_price) > 0 && (
                  <div className="earlyLateColumn mb-4">
                    <div className="earlyLatecontainer">
                      <h5 className="headingh5">
                        ⭐ Early/Late check-in/out request
                      </h5>
                      <p className="cardText mb-3">
                        Now you can set your check-in and check-out times at
                        your convenience if the hotel accepts your request.
                      </p>
                      <div className="selectTimeWrapper">
                        <div className="checkInTime">
                          <div>
                            <p className="cardLabel mb-2">Set check-in time</p>
                            <p className="cardText">
                              Standard check-in time 10:00 AM
                            </p>
                          </div>
                          <div
                            className="timeDropdown"
                            style={{ position: "relative" }}
                            // ref={checkTime}
                          >
                            <div
                              className="timeSelect"
                              onClick={handleShowCheckIntime}
                            >
                              <img
                                src={ClockIcon}
                                alt="Clock Icon"
                                className="clockIcon"
                              />
                              <select
                                value={checkInTime}
                                onChange={(e) => selectTime(e, true)}
                              >
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="6:00 AM">06:00 AM</option>
                                <option value="7:00 AM">07:00 AM</option>
                                <option value="8:00 AM">08:00 AM</option>
                                <option value="9:00 AM">09:00 AM</option>
                              </select>

                              {/* <button type="button"> {selectedTime}</button> */}
                              <img
                                src={DownIcon}
                                alt="Clock Icon"
                                className="downArrow"
                              />
                            </div>
                            {/**--------------------Temporary Comment ----------------- */}
                            {/* <div
                          className={`TimingDropDown ${
                            ShowCheckIntime ? "show" : ""
                          }`}
                        >
                          {CheckIntimings.map((time, index) => (
                            <div className="timinBttnsw" key={index}>
                              <button onClick={() => handleTimeSelect(time)}>
                                {time.split(" ")[0]}
                              </button>
                              {time.includes("AM") && <span>AM</span>}
                            </div>
                          ))}
                        </div> */}
                          </div>
                        </div>
                        <div className="separator"></div>

                        {/**-----------------Check Out timing Drop Down=----------- */}
                        <div className="checkInTime">
                          <div>
                            <p className="cardLabel mb-2">Set check-out time</p>
                            <p className="cardText">
                              Standard check-in time 11:00 AM
                            </p>
                          </div>
                          <div
                            className="timeDropdown"
                            style={{ position: "relative" }}
                            // ref={checkOutTimeRef}
                          >
                            <div
                              className="timeSelect"
                              onClick={handleShowCheckOuttime}
                            >
                              <img
                                src={ClockIcon}
                                alt="Clock Icon"
                                className="clockIcon"
                              />
                              <select
                                value={checkOutTime}
                                onChange={(e) => selectTime(e, false)}
                              >
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="01:00 PM">01:00 PM</option>
                                <option value="02:00 PM">02:00 PM</option>
                                <option value="03:00 PM">03:00 PM</option>
                                <option value="04:00 PM">04:00 PM</option>
                                <option value="05:00 PM">05:00 PM</option>
                              </select>
                              <img
                                src={DownIcon}
                                alt="Clock Icon"
                                className="downArrow"
                              />

                              {/* <button type="button"> {selectedOutTime}</button> */}
                            </div>
                            {/**--------------------Temporary Comment ----------------- */}
                            {/* <div
                          className={`TimingDropDown ${
                            ShowCheckOuttime ? "show" : ""
                          }`}
                        >
                          {checkOutTimes.map((time, index) => (
                            <div className="timinBttnsw" key={index}>
                              <button onClick={() => handleTimeOutSelect(time)}>
                                {time.split(" ")[0]}
                              </button>
                              {time.includes("AM") && <span>AM</span>}
                            </div>
                          ))}
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="noteContainer">
                        <img src={NoteIcon} className="noteImg" alt="Note" />
                        <p className="cardText">
                          Note: Additional charges will apply*
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              {/* Meals Section */}
              {((HotelDetails && parseInt(HotelDetails.breakfast_price) > 0) ||
                parseInt(HotelDetails.dinner_price) > 0 ||
                parseInt(HotelDetails.lunch_price) > 0) && (
                <div className="earlyLateColumn mb-4">
                  <div className="earlyLatecontainer">
                    <h5 className="headingh5">Additional service</h5>
                    <div className="selectTimeWrapper">
                      <div className="checkInTime w-100">
                        <div className="w-100">
                          <p className="cardLabel">Add meals to your stay?</p>
                          <div className="mealCheckbox">
                            {parseInt(HotelDetails.breakfast_price) > 0 && (
                              <div className="mealOption">
                                <input
                                  type="checkbox"
                                  id="breakfast"
                                  name="breakfast"
                                  checked={selectedMeals.breakfast}
                                  onChange={handleMealChange}
                                  className="customCheckbox"
                                />
                                <label htmlFor="breakfast">
                                  <span className="customCheckboxIcon"></span>
                                  Breakfast meal
                                </label>
                                <span className="mealPrice">
                                  + {RupessIcon} {HotelDetails.breakfast_price}
                                </span>
                              </div>
                            )}
                            {parseInt(HotelDetails.lunch_price) > 0 && (
                              <div className="mealOption">
                                <input
                                  type="checkbox"
                                  id="lunch"
                                  name="lunch"
                                  checked={selectedMeals.lunch}
                                  onChange={handleMealChange}
                                  className="customCheckbox"
                                />
                                <label htmlFor="lunch">
                                  <span className="customCheckboxIcon"></span>
                                  Lunch meal
                                </label>
                                <span className="mealPrice">
                                  {" "}
                                  + {RupessIcon} {HotelDetails.lunch_price}
                                </span>
                              </div>
                            )}
                            {parseInt(HotelDetails.dinner_price) > 0 && (
                              <div className="mealOption">
                                <input
                                  type="checkbox"
                                  id="dinner"
                                  name="dinner"
                                  checked={selectedMeals.dinner}
                                  onChange={handleMealChange}
                                  className="customCheckbox"
                                />
                                <label htmlFor="dinner">
                                  <span className="customCheckboxIcon"></span>
                                  Dinner meal
                                </label>
                                <span className="mealPrice">
                                  + {RupessIcon} {HotelDetails.dinner_price}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="noteContainer">
                      <img src={NoteIcon} className="noteImg" alt="Note" />
                      <p className="cardText">
                        All these meals are chargeable, and the price per person
                        is mentioned. The total payment for these meals will be
                        added to the final booking payment.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/*---------------------- Pick up and Drop Section ------------------ */}
              {HotelDetails &&
                parseInt(HotelDetails.pick_drop_status) === 1 && (
                  <div className="earlyLateColumn">
                    <div className="earlyLatecontainer">
                      <h5 className="headingh5">Pickup & drop facility</h5>
                      <p className="cardText mb-3">
                        Now you can set your pickup and drop-off at your
                        convenience
                      </p>
                      <div className="selectTimeWrapper">
                        <div className="checkInTime">
                          <div>
                            <p className="cardLabel mb-2">Pick-up place</p>
                            <p className="cardText">Set pick-up location</p>
                          </div>
                          <div
                            className="timeDropdown"
                            style={{ position: "relative" }}
                            // ref={dateContrRef}
                          >
                            <div
                              className="timeSelect"
                              onClick={handleShowPickup}
                            >
                              <select
                                value={selectedPickupLocation}
                                onChange={(e) => handleLocationSelect(e, true)}
                              >
                                <option value="Pickup Location" hidden>
                                  Pickup Location
                                </option>
                                <option value="Airport">Airport</option>
                                <option value="Railway station">
                                  Railway station
                                </option>
                                <option value="Other location">
                                  Other location
                                </option>
                              </select>
                              <img
                                src={DownIcon}
                                alt="Clock Icon"
                                // className={`downArrow transform transition-transform duration-300 ${
                                //   ShowPickup ? "rotateClass" : ""
                                // }`}
                                className="downArrow"
                              />

                              {/* <button type="button"> Pickup Location</button> */}
                            </div>

                            {/**--------------------Temporary Comment ----------------- */}
                            {/* <div
                          className={`pickUpLocationDIv ${
                            ShowPickup ? "show" : ""
                          }`}
                        >
                          {PickUpLocationData.map((location, index) => (
                            <button key={index}>{location}</button>
                          ))}
                        </div> */}
                          </div>
                        </div>
                        {selectPickLocation === "Other location" && (
                          <div className="customPlace">
                            <input
                              type="text"
                              placeholder="Enter Pick-up Location"
                              onChange={(e) =>
                                setSelectedPickupLocation(e.target.value)
                              }
                            />
                          </div>
                        )}

                        <div className="separator"></div>

                        {/*----------------------Drop off place Drop Section ------------------ */}
                        <div className="checkInTime">
                          <div>
                            <p className="cardLabel mb-2">Drop off place</p>
                            <p className="cardText">Set drop location</p>
                          </div>
                          <div
                            className="timeDropdown"
                            style={{ position: "relative" }}
                            // ref={dateContrRef2}
                          >
                            <div
                              className="timeSelect"
                              onClick={handleShowPickupDrop}
                            >
                              <select
                                value={selectedDropLocation}
                                onChange={(e) => handleLocationSelect(e, false)}
                                className="custom-select"
                              >
                                <option value="Pickup Location" hidden>
                                  Drop-off Location
                                </option>
                                <option value="Airport">Airport</option>
                                <option value="Railway station">
                                  Railway station
                                </option>
                                <option value="Other location">
                                  Other location
                                </option>
                              </select>

                              <img
                                src={DownIcon}
                                alt="Clock Icon"
                                // className={`downArrow transform transition-transform duration-300 ${
                                //   ShowPickuDrop ? "rotateClass" : ""
                                // }`}
                                className="downArrow"
                              />

                              {/* <button type="button"> Pickup Location</button> */}
                            </div>
                            {/* <div
                          className={`pickUpLocationDIv ${
                            ShowPickuDrop ? "show" : ""
                          }`}
                        >
                          {PickDownData.map((location, index) => (
                            <button key={index}>{location}</button>
                          ))}
                        </div> */}
                          </div>
                        </div>
                        {selectDropLocation === "Other location" && (
                          <div className="customPlace">
                            <input
                              type="text"
                              placeholder="Enter Drop-off Location"
                              onChange={(e) =>
                                setSelectedDropLocation(e.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="noteContainer">
                        <img src={NoteIcon} className="noteImg" alt="Note" />
                        <p className="cardText">
                          Note: Additional charges will apply*
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </section>

            <section className="col-md-6 pad000 paddinRightAfter768">
              {/* Discount, Coin and Payment Section */}
              <div className="earlyLateColumn position-relative mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5 className="headingh5 mb-3">Discount coupon</h5>
                  {appliedCoupon === null ? (
                    <button
                      className="selectTimeWrapper couponBtn"
                      onClick={handleOpenModal}
                    >
                      <p className="couponBtnLabel">Apply coupon code</p>
                      <img src={RightArrow} className="rightArrow" alt="Icon" />
                    </button>
                  ) : (
                    <div className="couponItem">
                      <div className="couponLeft">
                        <div className="couponName">
                          <img src={OfferSign} alt="Swagstay" />
                          <p className="couponCode">
                            {appliedCoupon.promo_code}
                          </p>
                        </div>
                        <p className="couponDetail">{appliedCoupon.disc_msg}</p>
                      </div>
                      <div className="couponRight">
                        <button
                          className="applyCBtn"
                          onClick={() => removeCoupon()}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {parseInt(bookingData.swagCoinBalance) > 0 && (
                  <div className="earlyLatecontainer mb-4">
                    <h5 className="headingh5 mb-3">Coin balance</h5>
                    <div className="selectTimeWrapper coinBlnc">
                      <div className="mealOption couponBtn m-0">
                        <input
                          type="checkbox"
                          id="swagCoin"
                          name="swagCoin"
                          checked={isswagCoinBalance}
                          onChange={handleSwagCoinChange}
                          className="customCheckbox"
                        />
                        <label
                          htmlFor="swagCoin"
                          style={{
                            fontWeight: 600,
                            color: "var(--text-black)",
                          }}
                        >
                          <span className="customCheckboxIcon"></span>
                          Swag coin balance
                        </label>
                        <span className="swagCoins">
                          <span style={{ fontWeight: 700 }}>
                            {bookingData.swagCoinBalance}
                          </span>{" "}
                          Coins
                        </span>
                      </div>
                      <p className="blueItalic">
                        • Only {bookingData.total_use_wallet}% of the total coin
                        balance can be redeemed now.
                      </p>
                    </div>
                  </div>
                )}

                {parseInt(bookingData.RealBalance) > 0 && (
                  <div className="earlyLatecontainer mb-3">
                    <h5 className="headingh5 mb-3">Payment option</h5>
                    <div className="selectTimeWrapper">
                      <div className="mealOption couponBtn m-0">
                        <input
                          type="checkbox"
                          id="swagWallet"
                          name="swagWallet"
                          checked={isswagWalletBalance}
                          onChange={handleSwagWalletChange}
                          className="customCheckbox"
                        />
                        <label htmlFor="swagWallet">
                          <span className="customCheckboxIcon"></span>
                          Use swag wallet balance {bookingData.rupees_icon}
                          {bookingData.RealBalance}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <div className="roundsGreyLeft"></div>
                <div className="roundsGreyLeft1"></div>
                <div className="roundsGreyRight"></div>
                <div className="roundsGreyRight1"></div>
              </div>

              {/* Bill Details Section */}
              <div className="earlyLateColumn mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Bill details
                  </h5>
                  <div className="billDetails">
                    <div className="billDescp">
                      <p className="billLabl">Booking for</p>
                      <p className="cardText">{bookingData.bookingFor}</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Room booking</p>
                      <p className="cardText">{`${bookingData.rupees_icon}${bookingData.roomBooking}`}</p>
                    </div>
                    {parseInt(bookingData.breakfastMeal) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Breakfast meal</p>
                          <p className="cardText">{`${bookingData.rupees_icon}${bookingData.breakfastMeal}`}</p>
                        </div>
                      </>
                    )}

                    {parseInt(bookingData.lunchMeal) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Lunch meal</p>
                          <p className="cardText">{`${bookingData.rupees_icon}${bookingData.lunchMeal}`}</p>
                        </div>
                      </>
                    )}

                    {parseInt(bookingData.dinnerMeal) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Dinner meal</p>
                          <p className="cardText">{`${bookingData.rupees_icon}${bookingData.dinnerMeal}`}</p>
                        </div>
                      </>
                    )}
                    {parseInt(bookingData.checkinprice) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Early Check-in</p>
                          <p className="cardText">{`${bookingData.rupees_icon}${bookingData.checkinprice}`}</p>
                        </div>
                      </>
                    )}
                    {parseInt(bookingData.checkoutprice) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Late Check-Out</p>
                          <p className="cardText">{`${bookingData.rupees_icon}${bookingData.checkoutprice}`}</p>
                        </div>
                      </>
                    )}
                    {parseInt(bookingData.couponDiscount) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Coupon discount</p>
                          <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.couponDiscount}`}</p>
                        </div>
                      </>
                    )}
                    {parseInt(bookingData.used_swag_coin) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">SwagCoin discount</p>
                          <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.used_swag_coin}`}</p>
                        </div>
                      </>
                    )}
                    {parseInt(bookingData.used_swag_real) > 0 && (
                      <>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Wallet discount</p>
                          <p className="cardText">{`-${bookingData.rupees_icon}${bookingData.used_swag_real}`}</p>
                        </div>
                      </>
                    )}

                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl total">Total payable amount</p>
                      <p className="cardText total">{`${bookingData.rupees_icon}${bookingData.totalPayableAmount}`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="continueBtn_container">
                {HotelDetails.booking_type == 7 ? (
                  <button className="DarkButtonClass secondaryBtn">
                    Sold Out
                  </button>
                ) : (
                  <button
                    className="primaryBtn"
                    onClick={() => {
                      if (customerId == 0) {
                        var event = new CustomEvent("customEvent");
                        document
                          .getElementById("login_check_jquery")
                          .dispatchEvent(event);
                      } else {
                        handleExpressClick();
                      }
                    }}
                  >
                    Continue
                  </button>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      {couponslist.length != 0 && (
        <ApplyCoupon
          isSidebarOpen={isCoupon}
          coupondata={couponslist}
          setcoupon={(index) => applyCoupon(index)}
          applyManualCouponAddon={(data_call) => {
            if (data_call !== "" && data_call.length > 3) {
              applyManualCoupon(data_call);
            }
          }}
        />
      )}
    </div>
  );
}
