import { useState ,useEffect} from "react";
import "./Css/Aboutus.css";
import arrowTOp from "../Assets/arrowTOp.svg";
import aboutProfile from "../Assets/aboutProfile.svg";
import aboutProfilebefore from "../Assets/aboutProfilebefore.svg";
import circleImgtop from "../Assets/circleImgtop.svg";
import Header from "./Header";
import Footer from "./Footer";
import Testimonial from "./Testimonial";
import Faq from "./Reapeting Componets/Faq";
import Discount from "./Discount";
import {server_post_data,save_forms_enquiry,get_website_faq} from '../ServiceConnection/serviceconnection'
import { handleError } from "../CommonJquery/CommonJquery";

export default function AboutUs() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const valueItems = [
    {
      title: "Trust",
      description:
        "We ensure transparency in pricing, bookings, and policies, building lasting relationships with our clients",
    },
    {
      title: "Trust",
      description:
        "We ensure transparency in pricing, bookings, and policies, building lasting relationships with our clients",
    },
    {
      title: "Trust",
      description:
        "We ensure transparency in pricing, bookings, and policies, building lasting relationships with our clients",
    },
  ];
  const trustedPatner = [
    {
      title: "Vast hotel network",
      countNom: "01",
      description:
        "We partner with a wide range of handpicked hotels, resorts, and event spaces, ensuring that you always have access to top-notch accommodations.",
    },
    {
      title: "Customized solutions",
      countNom: "02",
      description:
        "From corporate travel solutions to tailored packages for travel agents, Swagstay offers services designed to meet various client needs.",
    },
    {
      title: "Earn customer-centric approach",
      countNom: "03",
      description:
        "Our dedicated support teams and easy-to-use platform ensure a smooth booking experience from start to finish.",
    },
  ];
  const [testinomial, settestinomial] = useState([])
  const [faq, setfaq] = useState([])
  const [testimonial_image_link, settestimonial_image_link] = useState('')
  // add by sumit 
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        let data1 = Response.split('~@~');
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <>
      <Header />
      <div className="AboutSection">
        <div className="AboutHeroSec">
          <div className="container-lg">
            <img src={arrowTOp} alt="arrowTOp"></img>
            <p>
              {" "}
              At Swagstay, we're more than just a booking platform – we are your
              partners in making travel stress-free and enjoyable. Whether
              you're planning a business trip, a family getaway, or managing
              travel for your company, trust Swagstay to deliver the perfect
              stay, every time.
            </p>

            <div className="BttmbuttonAbouthero d-sm-flex d-none">
              <button>Vast hotel network</button>
              <button>Earn customer-centric approach</button>
              <button>Customized solutions</button>
            </div>
          </div>
        </div>

        {/*-------about content--------*/}
        <div className="journeyAboutContainer">
          <div className="container-lg">
            <div className="aboutHeading">
              <p>SwaggggStay</p>
              <h1>Journey of SwagStay</h1>
            </div>
            <div className="aboutProfileCOnt">
              <div className="leftProfile">
                <img
                  src={aboutProfile}
                  className="aboutProfileaFter"
                  alt="aboutProfileaFter"
                ></img>
                <img
                  src={aboutProfilebefore}
                  className="aboutProfilebefore"
                  alt="aboutProfilebefore"
                ></img>
              </div>
              <div className="abotutimgright">
                <h1>Sonu Meena</h1>
                <p>Chief executive officer</p>
              </div>
            </div>
            <div className="aboutBoxProfiletex">
              <p>
                Lorem ipsum dolor sit amet consectetur. Mattis aenean malesuada
                sit magna. Pharetra elit netus placerat vel ultrices vitae.
                Ultrices semper eleifend adipiscing pretium nec venenatis. Sit
                etiam eros eget velit.Lorem ipsum dolor sit amet consectetur.
                Mattis aenean malesuada sit magna. Pharetra elit netus placerat
                vel ultrices vitae. Ultrices semper eleifend adipiscing pretium
                nec venenatis. Sit etiam eros eget velit.
              </p>
            </div>
          </div>
        </div>
        {/*------our value ----*/}
        <div className="container-lg">
          <div className="QuicklyHotl marginTop">
            {" "}
            <div className="d-flex gap-2 align-items-center">
              {" "}
              <h2>Our</h2>
              <span>Value </span>
            </div>
            <div className="col-10">
              {" "}
              <p style={{ marginTop: "0.3rem" }}>
                We’re looking for passionate travel enthusiasts, bloggers,
                vloggers, and social media influencers who love to explore new
                destinations. to join, you should have
              </p>
            </div>
          </div>
          <div className="row  ourValueBox">
            {valueItems.map((item, index) => (
              <div key={index} className="col-lg-4 col-sm-12 mb-5">
                <div className="boxcontant">
                  <img src={circleImgtop} alt="circleImgtop" />
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*------Trusted Patner  ----*/}
        <div className="container-lg">
          <div className="QuicklyHotl marginTop">
            {" "}
            <div className="col-10">
              {" "}
              <p style={{ marginTop: "0.3rem" }}>
                Your trusted partner for seamless stays
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              {" "}
              <h2>Why choose</h2>
              <span>swagstay </span>
            </div>
          </div>
          <div className="row  ourValueBox">
            {trustedPatner.map((item, index) => (
              <div key={index} className="col-lg-4 col-sm-12 mb-5">
                <div className="truestedpatner">
                  <div className="numberCOunt">
                    <h1>{item.countNom}</h1>
                  </div>
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*---testinomial--------*/}
        <Testimonial  testinomial={testinomial} testimonial_image_link={testimonial_image_link}/>
        {/*--------------Discount-----------*/}
        <Discount />

        {/*--------------Faq-----------*/}
        <div className="container-lg">
          <Faq faq={faq}/>
        </div>
      </div>
      {/**/}
      <Footer />
    </>
  );
}
