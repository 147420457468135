import { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/ListYourHotel.css";
import "./Css/CoprativeSolution.css";
import "./Css/Banqueet.css";
import HeroBtmListData from "./HeroBtmListData.js";
import BookingLoader from "./BookingLoader.js";
import leftImgFormBanquet from "../Assets/leftImgFormBanquet.svg";
import Faq from "./Reapeting Componets/Faq";
import Footer from "./Footer";

import {
  server_post_data,
  save_forms_enquiry,
  get_website_faq,
} from "../ServiceConnection/serviceconnection";

import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleNumbersChange,
  handleIaphabetnumberChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";

import SuccessFullModal from "./SuccessFullModal";
let click_time = 0;
export default function Banquet() {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const increaseVisiData = [
    {
      title: "Spacious halls",
      description: " Banquet rooms to accommodate up to 100+ guests.",
    },
    {
      title: "Customizable setup",
      description: "Tailor the venue’s décor to match your event theme.",
    },
    {
      title: "Exclusive catering",
      description: "Gourmet food options for every occasion.",
    },
    {
      title: "Prime locations",
      description: "Centrally located venues for your convenience.",
    },
  ];
  const steps = [
    {
      number: "01",
      title: "Sign-up process",
      description: "Create an account and fill in basic details.",
    },

    {
      number: "02",
      title: "Select your venue",
      description:
        "Browse through our wide selection of beautiful banquet halls.",
    },
    {
      number: "03",
      title: "Book effortlessly",
      description:
        "Review all the details of your event and venue, and book with ease.",
    },
  ];

  //---------------------------------------
  const [testinomial, settestinomial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [testimonial_image_link, settestimonial_image_link] = useState("");
  // add by sumit
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);

  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("enquiry_type", "4");
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          let data1 = Response.split("~@~");
          setshowLoaderAdmin(false);
          if (parseInt(data1[0]) === 1) {
            handleError(data1[1]);
          } else {
            click_time = 0;
            // const final_data = JSON.parse(data1[1]);
            handleShowSucessModal();
            empty_form(form_data);
            setTimeout(() => {
              handleLinkClick("/");
            }, 2000);
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };
  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 250,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="contList">
        {" "}
        <div className="ListYourHotelCont  HeroBanqueet">
          <div className="heroUnderListHead">
            {/* <Header /> */}
            <div className="HeroLIstUnderHeading  heaCop  ">
              <div className="TailHead  ">
                <p
                  className="text36"
                  style={{ color: "var(--white)", textAlign: "center" }}
                >
                  Book Your Perfect Banquet
                </p>
              </div>

              <label>
                Celebrate your events in style with personalized setups
              </label>

              <button
                type="button"
                style={{ fontSize: "16px" }}
                onClick={() => handleActive("formCOntainerCreate")}
              >
                Book banquet
              </button>
            </div>
            {/*--------hero bottm content Section -------*/}
            <HeroBtmListData />
          </div>
        </div>{" "}
        <div className="container-lg">
          <div className="QuicklyHotl santosiFOntCLass">
            {" "}
            <p>Why choose us?</p>
            <div className="quickHotlsubhading CopHotlSubHead ">
              {" "}
              <h2> Why choose our</h2>
              <span> banquets?</span>
            </div>
          </div>

          <div className="increaseVisiImgCOnt containerlg ContnrCoprateStay">
            <div className="row">
              {increaseVisiData.map((item, index) => (
                <div className="col-md-6 col-sm-12 p-0" key={index}>
                  <div className="increseCnt Databanquet">
                    <div className="LeftBorderCl">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/**----------How it work for hotel ------*/}
        </div>
        <div className="howItworkDivHotel">
          <div className="container-lg">
            <div className="QuicklyHotl santosiFOntCLass">
              {" "}
              <p>How it works</p>
              <div className="quickHotlsubhading CopHotlSubHead">
                {" "}
                <h2> Step-by-step</h2>
                <span> process</span>
              </div>
            </div>

            <div className="howItWorksHotel__steps">
              <div className="row">
                {steps.map((step, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4">
                    <div className=" coprtHowitwork">
                      <h1 className="fontSizingBanqueet">{step.number}</h1>
                      <div className="horIzontlist"></div>
                      <h3>{step.title}</h3>
                      <p>{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/*--------Ready to boost-----------*/}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p>Unlock exclusive banquet</p>
            <div className="quickHotlsubhading CopHotlSubHead">
              {" "}
              <h2 className="marginCLss">Banquet enquiry </h2>
            </div>
          </div>

          <div className="row listPropFOrm">
            {/* Left Column - Image and Heading */}
            <div className="col-md-5 col-12 mb-3 mb-md-0  FormLftdata  ">
              <img
                src={leftImgFormBanquet}
                alt="Property Image"
                className="img-fluid"
              />
            </div>

            {/* Right Column - Form */}
            <div className="col-md-7 col-12" id="formCOntainerCreate">
              <form className="rightFOrm" id="listmyhotel">
                <div className="form-group mb-3">
                  <label htmlFor="fullName">Full name</label>
                  <input
                    type="text"
                    className="form-control trio_mandatory"
                    id="fullName"
                    name="fullName"
                    placeholder="Name"
                    onChange={handleAphabetsChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control trio_mandatory"
                    id="email"
                    name="email"
                    placeholder="abc@xyz.com"
                    onChange={handleEmailChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="contactNumber">Contact number</label>
                  <input
                    type="text"
                    className="form-control trio_mandatory"
                    id="contactNumber"
                    name="contactNumber"
                    placeholder="+91-XXXXXXXXXX"
                    onChange={handleNumbersChange}
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="propertyName">
                    Brief description of your requirement
                  </label>
                  <textarea
                    type="text"
                    className="form-control trio_mandatory"
                    id="propertyName"
                    name="companyName"
                    placeholder="Type here"
                    onChange={handleIaphabetnumberChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <button
                  className="suFOrm"
                  type="button"
                  onClick={() => {
                    handleSaveChangesdynamic("listmyhotel", save_forms_enquiry);
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>{" "}
        {/*------Testimonial--- */}
        <div className="container-lg">
          <Faq faq={faq} />
        </div>
        <Footer />
      </div>

      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
