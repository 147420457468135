import React, { useState } from "react";
import "./Css/Newslatter.css";
import rightCircleCenter from "../Assets/rightCircleCenter.svg";
import CirleRightTOpImg from "../Assets/CirleRightTOpImg.svg";
import CirleLeftTOpImg from "../Assets/CirleLeftTOpImg.svg";
import CirleLeftCenterImg from "../Assets/CirleLeftCenterImg.svg";
import CirleLeftBottmImg from "../Assets/CirleLeftBottmImg.svg";
import CirlerightBottmImg from "../Assets/CirlerightBottmImg.svg";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleError,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import {
  save_subscribe_website,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let click_time = 0;
export default function NewsLatter() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            handleSuccess(data[1]);
            empty_form(form_data);
          }
          click_time = 0;
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          click_time = 0;
          setshowLoaderAdmin(false);
        });
    }
  };
  return (
    <div className="container-lg my-4">
      <div className="NewsLettercontainer">
        {/* <p>News Letter</p> */}
        <div className="quickHotlsubhading">
          <h2>
            Sign up for
            <span> our newsletter</span>
          </h2>
        </div>
      </div>
      {/*---signUpbox*/}
      <div className="NewLetterBox">
        <div className="row CirleCOntainr">
          {/* Left Circle */}
          <div
            className="col-lg-2 col-md-3 col-sm-4   LeftDiv"
            style={{ position: "relative" }}
          >
            <div className="CirleLeftTOp">
              <img src={CirleLeftTOpImg} alt="rightCircleCenter"></img>
            </div>
            <div className="CirleLeftCenter">
              <img src={CirleLeftCenterImg} alt="rightCircleCenter"></img>
            </div>
            <div className="circleLeftBottm">
              <img src={CirleLeftBottmImg} alt="rightCircleCenter"></img>
            </div>
          </div>

          {/* Main Content */}
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="joinCOm">
              <h2>Join our community</h2>
              <span>
                Be the first to know about our latest news, events, and
                promotions by subscribing to our newsletter.
              </span>
              <form id="communitylist">
                <div className="NotiDiv">
                  <div className="displayinline">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="trio_mandatory form-control trio_email"
                      placeholder="Enter your email"
                    />
                    <span className="condition_error"></span>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      handleSaveChangesdynamic(
                        "communitylist",
                        save_subscribe_website
                      );
                    }}
                  >
                    Notify Me
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Right Circle */}
          <div
            className="col-lg-2 col-md-3 col-sm-4 RightDiv"
            style={{ position: "relative" }}
          >
            <div className="CirleRightTOp">
              <img src={CirleRightTOpImg} alt="rightCircleCenter"></img>
            </div>
            <div className="circleRightCenter">
              <img src={rightCircleCenter} alt="rightCircleCenter"></img>
            </div>
            <div className="circleRightBottm">
              <img src={CirlerightBottmImg} alt="rightCircleCenter"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
