import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Header from "./Header";
import "./Css/Career.css";
import Footer from "./Footer";
import UploadCVImg from "../Assets/uplaodCV.svg";

import SuccessFullModal from "./SuccessFullModal";
import {
  handleAphabetsChange,
  check_vaild_save,
  empty_form,
  handleError,
  combiled_form_data,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  job_apply_website,
} from "../ServiceConnection/serviceconnection";
import { useLocation } from "react-router-dom";
// let captch_check = "dd6y7";
let click_time = 0;
export default function CareerDetails() {
  const imageInputRef = useRef(null);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [inputFilled, setInputFilled] = useState(false);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const [showSucessModal, setShowSucessModal] = useState(false);
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file.name);
    setFileName(file.name);
    if (file && file.size > 5 * 1024 * 1024) {
      setFileError("File size exceeds 5MB limit.");
      setFileName("");
    } else {
      setFileError("");
      //   handleFileChangedynamic("profile_picture")(e);
    }
  };

  console.log(currentUrl);
  const handleFormClick = async (form, url) => {
    if (inputFilled) {
      let vaild_data = check_vaild_save(form);
      if (vaild_data && click_time === 0) {
        click_time = 1;
        setshowLoaderAdmin(true);
        let fd_from = combiled_form_data(form, null);
        fd_from.append("current_url", "/" + currentUrl);
        await server_post_data(url, fd_from)
          .then((Response) => {
            let data1 = Response.split("~@~");
            if (parseInt(data1[0]) === 1) {
              handleError(data1[1]);
            } else {
              click_time = 0;
              handleShowSucessModal();
              empty_form(form);
            }
            setshowLoaderAdmin(false);
          })
          .catch((error) => {
            click_time = 0;
            console.log(error);
            setshowLoaderAdmin(false);
          });
      }
    } else {
      handleError("Click terms and conditions");
    }
  };

  const handleCheckboxChange = (event) => {
    setInputFilled(event.target.checked);
  };
  return (
    <>
      <Header />
      <div>
        <div className="applyCareer">
          <h1 className="AppCareerHEad">Enter Below Details To Apply</h1>

          <div className="container">
            <form id="CarrerformData" className="col-lg-10 m-auto">
              <div className="row">
                <div className="inputFormCareer col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name">
                      <b>Full Name</b>
                    </label>
                    <input
                      type="text"
                      className="trio_mendate trio_name  form-control trio_mandatory "
                      id="user_name"
                      name="user_name"
                      maxLength="50"
                      placeholder="Enter Your First and Last Name"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /^\s+|[^A-Za-z\s]/g,
                          ""
                        );
                      }}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className=" inputFormCareer col-md-6">
                  <div className="mb-3">
                    <label htmlFor="email">
                      <b>Email Address</b>
                    </label>
                    <input
                      type="test"
                      className="trio_mendate trio_email  form-control trio_mandatory"
                      id="email"
                      name="email"
                      maxLength="100"
                      placeholder="Enter Your Email Address"
                      onInput={(e) => {
                        if (!/^\s+|[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                          e.target.value = ""; // Clear the input if it contains any other character
                        }
                      }}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="inputFormCareer col-md-6">
                  <div className="mb-3">
                    <label htmlFor="mobile">
                      <b>Phone Number</b>
                    </label>
                    <input
                      type="text"
                      className="trio_mendate trio_no form-control trio_mandatory"
                      id="mobile"
                      name="mobile"
                      maxLength="10"
                      placeholder="Enter Your Phone Number"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
                <div className="inputFormCareer col-md-6">
                  <div className="mb-3">
                    <label htmlFor="skills">
                      <b>skills </b>
                    </label>
                    <input
                      type="text"
                      className="trio_mendate  form-control trio_mandatory"
                      id="skills"
                      name="skills"
                      placeholder="Enter Your Work skills "
                      // onChange={handleAphabetsChange}
                    />
                    <span className="condition_error"></span>
                  </div>
                </div>
              </div>

              <div className="inputFormCareer CVuPLOAD col-md-12">
                <div className="uploadTextImg">
                  <img src={UploadCVImg} alt="upload photo" />
                  <div className="uploadText">
                    <>
                      <h6>UPload CV</h6>
                      <p>(Pdf, doc, PPT upto 5MB)</p>
                    </>

                    {/* <h6> Uploaded</h6> */}
                  </div>
                </div>
                <label
                  htmlFor="profile_picture"
                  style={{ cursor: "pointer" }}
                  className="primaryBtn profile_picture_brows"
                >
                  Browse
                </label>
                <input
                  className="d-none "
                  type="file"
                  name="profile_picture"
                  id="profile_picture"
                  accept=".pdf, .doc, .ppt, .pptx"
                  ref={imageInputRef}
                  onChange={handleFileChange}
                />
              </div>
              {fileError && (
                <span className="fileUpload_error text-danger">
                  {fileError}
                </span>
              )}
              {fileName}

              <div className="row">
                <div className="inputFormCareer mb-3">
                  <label htmlFor="message" className="form-label">
                    <b>Your Message to Hiring Manager (Cover Letter)</b>{" "}
                  </label>
                  <textarea
                    className="  form-control trio_mandatory"
                    id="message"
                    name="message"
                    maxLength="300"
                    rows="4"
                    placeholder="Please Type Your Message Here..."
                  ></textarea>
                  <span className="condition_error"></span>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="FooterCheckBxx">
                        <label
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            I agree to the
                            <Link
                              // onClick={() => handleLinkClick("/Terms_Of_Use")}
                              style={{ margin: "0 3px" }}
                            >
                              Terms Of Use
                            </Link>
                            <Link
                            // onClick={() => handleLinkClick("/Privacy_Policy")}
                            >
                              Privacy Policy
                            </Link>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sbmtbtnDiv">
                  <button
                    className="primaryBtn "
                    type="button"
                    onClick={() =>
                      handleFormClick("CarrerformData", job_apply_website)
                    }
                  >
                    <p>Submit</p>
                    <span className="spancheckmaker">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.293 4.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 10.586l6.293-6.293a1 1 0 0 1 1.414 0z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />

      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
