import React, { useState, useEffect } from "react";
import "./Css/AqiBar.css";

export default function AQIBar({ currentcategory, currentAaqi }) {
  const [aqiValue] = useState(currentAaqi);
  const segments = [
    { range: [0, 50], color: "#55a84f" },
    { range: [51, 100], color: "#a3c853" },
    { range: [101, 200], color: "#fff833" },
    { range: [201, 300], color: "#f29c33" },
    { range: [301, 400], color: "#e93f33" },
    { range: [401, 500], color: "#af2d24" },
  ];
  const calculatePointerPosition = () => {
    let pointerPosition = 0;
    const totalRange =
      segments[segments.length - 1].range[1] - segments[0].range[0];

    // Find the correct segment
    for (let i = 0; i < segments.length; i++) {
      const { range } = segments[i];
      const [min, max] = range;

      if (aqiValue >= min && aqiValue <= max) {
        // Calculate position within the segment
        const segmentWidth = (100 / segments.length);
        const segmentStart = segmentWidth * i;
        const relativePosition =
          ((aqiValue - min) / (max - min)) * segmentWidth;
        pointerPosition = segmentStart + relativePosition;
        break;
      } else if (aqiValue > max) {
        // Add full segment width if we're past this segment
        pointerPosition += 100 / segments.length;
      }
    }

    // Ensure pointer stays within bounds
    return `${Math.max(0, Math.min(100, pointerPosition))}%`;
  };

  const categories = [
    { label: "Good", className: "circ1" },
    { label: "Satisfactory", className: "circ2" },
    { label: "Moderate", className: "circ3" },
    { label: "Poor", className: "circ4" },
    { label: "Very Poor", className: "circ5" },
    { label: "Severe", className: "circ6" },
  ];
  return (
    <div>
      <div className="pupulrFecHeading AqiHeading">
        <h1>
          Air quality index (AQI) :{aqiValue} {currentcategory.label}
        </h1>
      </div>

      <div
        className="d-flex align-items-center mt-2 position-relative"
        style={{ width: "100%" }}
      >
        {segments.map((segment, index) => (
          <div
            key={index}
            className="h-3 rounded-lg transition-all duration-300 flex-1"
            style={{
              backgroundColor: segment.color,
              height: "10px",
              width: `${100 / segments.length}%`,
              marginRight: index < segments.length - 1 ? "2px" : "0",
              borderRadius: "12px",
            }}
          />
        ))}

        {/* Pointer */}
        <div
          className="position-absolute"
          style={{
            width: "2px",
            height: "20px",
            backgroundColor: "#333",
            left: calculatePointerPosition(),
            top: "-5px",
            transition: "left 0.3s ease",
            marginLeft: "-1rem",
          }}
        ></div>
      </div>

      {/* Labels */}
      <div
        className="d-flex justify-content-between mt-2"
        style={{ width: "100%" }}
      >
        <span>0</span>
        <span>50</span>
        <span>100</span>
        <span>200</span>
        <span>300</span>
        <span>400</span>
        <span>500</span>
      </div>

      <div className="CategrySec2">
        <div className="pupulrFecHeading">
          <h1>Catagory</h1>
        </div>

        <div className="catagryList">
          {categories.map(({ label, className }) => (
            <div
              className={`d-flex align-items-center gap-2 ${className}`}
              key={label}
            >
              <span></span>
              <p>{label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
