import React, { useEffect, useState } from "react";

import "./Css/Booking.css";
import Header from "./Header";
import HotelData from "./Reapeting Componets/HotelData";
import {
  server_post_data,
  favorite_details_all,
  add_remove_favorite_hotel,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";
function Favorite() {
  const [currentPage, setCurrentPage] = useState(1);
  const hotelsPerPage = 6;
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [hotelData, setHotelFavouriteList] = useState([]);
  const [scaleAnims, setScaleAnims] = useState({});
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [RupessIcon, setRupessIcon] = useState("");
  const [SEOloop, setSEOloop] = useState([]);
  const totalPages = Math.ceil(hotelData.length / hotelsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const startIndex = (currentPage - 1) * hotelsPerPage;
  const paginatedData = hotelData.slice(startIndex, startIndex + hotelsPerPage);

  const master_data_get = async () => {
    setShowLoaderAdmin(true);

    await server_post_data(favorite_details_all, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setSilderHotelImage(final_data.hotel_image_link);
          setRupessIcon(final_data.rupess_icon);
          const hotelDataWithFlags = final_data.favorite_list.map((hotel) => {
            let tab = "";

            return {
              ...hotel,
              tab,
              styleType: "home",
            };
          });

          setHotelFavouriteList(hotelDataWithFlags);
          setScaleAnims(hotelDataWithFlags);
          setSEOloop(final_data.seo_list)
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const handleSaveChangesdynamic = async (hotel_id, HotelID) => {
    const form_data = new FormData();
    form_data.append("hotel_id", hotel_id);
    form_data.append("HotelID", HotelID);
    form_data.append("show_flag", "c");
    console.log(hotel_id, HotelID);
    await server_post_data(add_remove_favorite_hotel, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          master_data_get();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // console.log("hotel data ", hotelData)
  return (
    <div>
      <Header />
      <div
        className="container-lg subHeadStick"
        style={{ backgroundColor: "var(--white)" }}
      >
        <h2>Favorites</h2>
      </div>
      <div className="container-lg">
        <div className="booking-details">
          <HotelData
            image={SilderHotelImage}
            isfav={true}
            handleSaveChangesdynamic={handleSaveChangesdynamic}
            hotelData={paginatedData}
            seoData={SEOloop}
          />
        </div>
      </div>
      {/* Pagination Section */}
      {/* Pagination Section */}
      <div className="pagination-container ">
        <div className="pagination-controls">
          <button
            className="pagination-arrow"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <span className="pagination-text">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="pagination-arrow"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Favorite;
