import React from "react";
import "./Css/Shimmer.css";
import { useLocation } from "react-router-dom";
export default function Shimmer() {
  const location = useLocation();
  const isHomeRoute = location.pathname === "/bookings";
  return (
    <div>
      <div
        className={isHomeRoute ? "shimmerContainerBooking" : "shimmerContainer"}
      >
        <div className="shimmerBG media"></div>
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke animate title"></div>
            <div className="stroke animate link"></div>
            <div className="stroke animate description"></div>
          </div>
        </div>
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke animate title"></div>
            <div className="stroke animate link"></div>
            <div className="stroke animate description"></div>
          </div>
        </div>
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke animate title"></div>
            <div className="stroke animate link"></div>
            <div className="stroke animate description"></div>
          </div>
        </div>
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke animate title"></div>
            <div className="stroke animate link"></div>
            <div className="stroke animate description"></div>
          </div>
        </div>
        <div className="shimmer">
          <div className="wrapper">
            <div className="image-card animate"></div>
            <div className="stroke animate title"></div>
            <div className="stroke animate link"></div>
            <div className="stroke animate description"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
