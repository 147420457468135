import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Css/RewardPage.css";
import BackBtn from "../Assets/backIcon.svg";
import { useNavigate } from "react-router-dom";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  website_content,
} from '../ServiceConnection/serviceconnection';
import DOMPurify from "dompurify";
export default function ReferTermsandCondition() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(true);
  const [testinomial, settestinomial] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/refer_earn_page");
    window.location.reload();
  };

  useEffect(() => {
    master_data_get()
  }, [])


  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("which_page", 1);
    await server_post_data(website_content, fd)
      .then((Response) => {
        console.log(Response);
        let data1 = Response.split("~@~");
        console.log(data1);
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.website_content.application_text);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    }

  return (
    <div>
      <Header />
      <div className="containersssss ">

        <div className="container-lg">
        <div
          className="backBtttn"
          onClick={handleNavigate}
          style={{ cursor: "pointer" }}
        >
          <img src={BackBtn} alt="BackBtn"></img>
          <p>Terms and Condition</p>
        </div>
          <div
            className="privacy-policy-link1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(testinomial),
            }}
          >
            {/* <h3>Eligibility:</h3> */}
          </div>
          {/* <div className="section">
          <h3>Referral rewards:</h3>
          
        </div>
        
        <div className="section">
          <h3>Referral rewards:</h3>
          
        </div>
        <div className="section">
          <h3>Referral rewards:</h3>
         
        </div>
        <div className="section">
          <h3>Referral rewards:</h3>
         
        </div> 
        */}
        </div>
      </div>
    </div>
  );
}
