import React, { useState } from "react";
import "./Css/CreateBooking.css";
import Header from "./Header";
import RightArrow from "../Assets/rightAngle.png";
import CopyIcon from "../Assets/copyIcon.png";
import Download from "../Assets/DownloadICon.png";
import Share from "../Assets/ShareIcon.png";
import Location from "../Assets/locationIcon.png";
import CancelRound from "../Assets/cancelRound.png";
import Shield from "../Assets/sheild.png";
import CallImg from "../Assets/CallImg.png";
import ApplyCoupon from "./ApplyCoupon";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BookingReciept() {
  const [useSwagCoin, setUseSwagCoin] = useState(false);
  const [gstModal, setgstModal] = useState(false);
  const [guestModal, setGuestModal] = useState(false);
  const [hotelHelpModal, setHotelHelpModal] = useState(false);
  const [swagHelpModal, setSwagHelpModal] = useState(false);

  const handleSwagCoin = () => {
    setUseSwagCoin(!useSwagCoin);
  };

  const copyCode = () => {
    const code = "SWAG094847NR";
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
      </div>
      <div className="container-lg">
        <div className="createBookingContainer">
          {/* Booking Details COntianer */}
          <section className="bookDetails mt-4">
            <div className="StatusMsg">
              <h5>Your booking has been confirmed</h5>
            </div>
            <div className="bookDetailsContainer bookingReciptCon mb-4">
              <div className="booldetailTop">
                <div className="left_detail">
                  <div className="bookingId">
                    <p>
                      Booking id: <span>SWAG094847NR</span>
                    </p>
                    <img onClick={copyCode} src={CopyIcon} />
                  </div>
                </div>
                <div className="right_detail">
                  <div className="bookingId">
                    <p>
                      Status: <span>Confirmed</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="bookDetailsBottom">
                <div className="leftSection">
                  <div className="checkin-out-container">
                    <div className="check-section">
                      <p className="label">Check in</p>
                      <p className="date">03 Aug</p>
                      <p className="time">08:00 AM</p>
                    </div>

                    <div className="duration">
                      <span className="nights">1 N</span>
                    </div>

                    <div className="check-section">
                      <p className="label">Check out</p>
                      <p className="date">04 Aug</p>
                      <p className="time">11:00 PM</p>
                    </div>
                  </div>
                  <div className="saperatorVertical"></div>
                  <div className="hotelText">
                    <p className="cardHeading mb-2">
                      SwagStay lemon tree hotel, Indore
                    </p>
                    <p className="cardText mb-1">
                      Sch. no 54, Near brilliant palatinium, Vijay Nagar, Indore
                    </p>
                    <div className="discount_price">
                      <button className="reciptBtns">
                        <img src={Share} alt="Swagstay" />
                      </button>
                      <button className="reciptBtns">
                        <img src={Location} alt="Swagstay" />
                      </button>
                      <button className="reciptBtns">
                        <img src={Download} alt="Swagstay" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reciptBookingTxt">
                <p>
                  Ankit soni your booking has been <span>confirmed.</span>
                </p>
              </div>
            </div>
          </section>

          {/* Left Section Items  */}
          <div className="row m-0">
            <section className="col-md-6 paddinLeftAfter768">
              {/* Bill Details Section */}
              <div className="earlyLateColumn mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Booking details
                  </h5>
                  <div className="billDetails">
                    <div className="billDescp">
                      <p className="billLabl">Booking for</p>
                      <p className="cardText">Ankit soni</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Guest count</p>
                      <p className="cardText">2 Adults | 1 Room</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Room type</p>
                      <p className="cardText">Premium AC Room</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Booking payement</p>
                      <p className="cardText">₹1100</p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp">
                      <p className="billLabl">Advance payment</p>
                      <p className="cardText">₹0.0</p>
                    </div>
                    <div className="separator"></div>
                  </div>
                </div>
              </div>

              {/* GST Details Section */}
              <div className="earlyLateColumn position-relative mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5 className="headingh5 mb-3">GST details</h5>
                  <div className="selectTimeWrapper gstBtn">
                    <div className="mealOption couponBtn m-0">
                      <input
                        type="checkbox"
                        id="swagCoin"
                        name="swagCoin"
                        checked={useSwagCoin}
                        onChange={handleSwagCoin}
                        className="customCheckbox"
                      />
                      <label
                        htmlFor="swagCoin"
                        style={{ fontWeight: 600, color: "var(--text-black)" }}
                      >
                        <span className="customCheckboxIcon"></span>
                        Save GST on this booking
                      </label>
                      <span
                        className="swagCoins"
                        onClick={() => setgstModal(true)}
                      >
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="col-md-6 paddinRightAfter768">
              {/* Manage Booking Section */}
              <div className="earlyLateColumn pb-0 mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Manage your booking
                  </h5>
                  <div className="billDetails">
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setGuestModal(true)}
                    >
                      <p className="billLabl">Modify guest name</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div className="billDescp popUpBtns">
                      <p className="billLabl">Rescheduled your booking</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator"></div>
                    <Link to="/cancel_booking">
                      <div className="billDescp popUpBtns">
                        <p className="billLabl">Cancel booking</p>
                        <p className="cardText">
                          <img
                            src={RightArrow}
                            className="rightArrow"
                            alt="Icon"
                          />
                        </p>
                      </div>
                    </Link>
                    <div className="separator mb-0"></div>
                  </div>
                </div>
              </div>

              {/* Need Help Section */}
              <div className="earlyLateColumn pb-0 mb-4">
                <div className="earlyLatecontainer mb-4">
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Need Help
                  </h5>
                  <div className="billDetails">
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setHotelHelpModal(true)}
                    >
                      <p className="billLabl">Call hotel helpline</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div
                      className="billDescp popUpBtns"
                      onClick={() => setSwagHelpModal(true)}
                    >
                      <p className="billLabl">Call SwagStay customer care</p>
                      <p className="cardText">
                        <img
                          src={RightArrow}
                          className="rightArrow"
                          alt="Icon"
                        />
                      </p>
                    </div>
                    <div className="separator mb-0"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* Policies Section */}
          <div style={{ paddingBottom: "2rem" }}>
            <button className="policyBtn reciptBtns">
              <img src={Shield} alt="Swagstay" />
              <p>Hotel rules & policy</p>
            </button>
            <button className="policyBtn reciptBtns">
              <img src={CancelRound} alt="Swagstay" />
              <p>Cancellation policy</p>
            </button>
          </div>
        </div>
      </div>
      {/* Modals */}

      {/* GST Modal */}
      <Modal
        show={gstModal}
        onHide={() => setgstModal(false)}
        className="GSTModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4>GST details</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="formCOntainer">
            <div className="inputContainer">
              <label>Company name</label>
              <div className="w-100">
                <input type="text" placeholder="Type here" />
              </div>
            </div>
            <div className="inputContainer">
              <label>GSTIN number</label>
              <div className="w-100">
                <input type="text" placeholder="Type here" />
              </div>
            </div>
            <div className="inputContainer">
              <label>Address</label>
              <div className="w-100">
                <input type="text" placeholder="Type here" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="DarkButtonClass">Add</button>
        </Modal.Footer>
      </Modal>

      {/* Modify Guest Modal */}
      <Modal
        show={guestModal}
        onHide={() => setGuestModal(false)}
        className="GSTModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4>Modify guest name</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="formCOntainer">
            <div className="inputContainer">
              <label>Update guest name</label>
              <div className="w-100">
                <input type="text" placeholder="Type here" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="DarkButtonClass">Update</button>
        </Modal.Footer>
      </Modal>

      {/* Hotel HelpLine Modal */}
      <Modal
        show={hotelHelpModal}
        onHide={() => setHotelHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Hotel helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">07294- 232032</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>

      {/* Swagstay HelpLine Modal */}
      <Modal
        show={swagHelpModal}
        onHide={() => setSwagHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Swagstay helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">07294- 232032</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
