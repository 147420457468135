import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/ExpressChecking.css"; // We'll use this for custom styles
import expressHero from "../Assets/expressHero.svg";
import QuickHotel from "./QuickHotel";
import Discount from "./Discount";
import Footer from "./Footer";
import SearchData from "./SearchData";
import HotelData from "./Reapeting Componets/HotelData";
import {
  server_post_data,
  city_list_website,
  front_city_click,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export default function ExpressChecking() {
  const [CityList, setCityList] = useState([]);
  const [CityImage, setCityImage] = useState("");
  const [distance_lat, setdistance_lat] = useState("");
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [cityindex, setcityindex] = useState(0);
  const [hotel, sethotel] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    await server_post_data(city_list_website, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          const final_data = JSON.parse(data[1]);
          setCityList(final_data.hotel_citylist);
          setCityImage(final_data.city_image_link);
          setSEOloop(final_data.seo_list);
          setdistance_lat(final_data.distance_lat);
          setShowLoaderAdmin(false);
        }
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
    // master_data_get_hotel();
  }, []);

  useEffect(() => {
    if (CityList.length > 0) {
      master_data_get_hotel(0);
    }
  }, [CityList]);

  const master_data_get_hotel = async (index) => {
    setIsLoading(true);
    if (CityList) {
      const fd = new FormData();
      fd.append("custom_latitude", CityList[index].city_lat);
      fd.append("custom_longitude", CityList[index].city_long);
      fd.append("city_id", CityList[index].city_long);
      fd.append("distance_call", CityList[index].distance_in_km);
      await server_post_data(front_city_click, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setSilderHotelImage(final_data.hotel_image_link);
            sethotel(final_data.hotel_all);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handleCityClick = (index) => {
    master_data_get_hotel(index);
    setcityindex(index);
  };

  const benefits = [
    {
      title: "Time-Saving",

      description:
        "Guests can bypass long lines at the front desk, especially during peak check-in times.",
    },
    {
      title: "Convenience",
      description:
        "It allows guests to check in quickly using a mobile app, kiosk, or online, often before they even arrive at the hotel.",
    },
    {
      title: "Skip the Line",
      description:
        "No need to wait at the reception desk – check in quickly and easily.",
    },
    {
      title: "Contactless Experience",
      description:
        "It minimizes contact with hotel staff, which is particularly important for health and safety during pandemics.",
    },
    {
      title: "Customization",
      description:
        "Guests can often customize their check-in preferences, such as room selection or amenities, in advance",
    },
    {
      title: "24/7 Availability",
      description:
        "Guests can check in at any time, regardless of front desk hours, which is convenient for late-night or early-morning arrivals.",
    },
  ];
  const HowItwork = [
    {
      title: "Pre-check-in online",
      description:
        "Log in with your reservation number and complete the check-in steps from your phone or computer.",
    },
    {
      title: "Review your stay",
      description:
        "Confirm room type, add extras like breakfast or parking, and update personal details.",
    },
    {
      title: " Get your room key",
      description:
        "On arrival, simply use your phone to unlock your room or grab a key from our express kiosk.",
    },
    {
      title: "Enjoy your stay!",
      description:
        "Settle in without any delays and make the most of your time with us.",
    },
  ];

  return (
    <div>
      <Header />

      {/* Hero Section */}
      <div className="container-lg">
        <div className="row  heroSection ">
          <div className="col-md-6 text-md-left text-center">
            <h1 className="display-4 font-weight-bold">Express check-in</h1>
            <p>Get check-in in 90 seconds</p>
          </div>
        </div>
      </div>

      {/* Search Section */}
      <SearchData />
      {/* Description Section */}

      <div className="container-lg  my-4">
        <div className="whatExpress">
          {" "}
          <h2>What is express check-in</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <p>
            Lorem ipsum dolor sit amet consectetur. Non pellentesque sit eu
            suspendisse aliquet sem eu morbi scelerisque. Tincidunt fermentum
            pulvinar leo semper elit lorem purus in elit. Ut nisl rhoncus cursus
            gravida adipiscing ut est. Netus turpis tortor ut vestibulum
            tincidunt nunc cras senectus.
          </p>
        </div>
      </div>
      {/* Benifits Section */}

      <div className="container-lg my-4">
        <div className="whatExpress">
          {" "}
          <h2>Benifits of express check-in</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <ul className="space-y-6 LisData">
            {benefits.map((benefit, index) => (
              <li key={index}>
                <div className="text-gray-800">
                  <strong>{benefit.title}: </strong>

                  <span className="text-gray-600">{benefit.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* How  it  Section */}

      <div className="container-lg my-4">
        <div className="whatExpress">
          {" "}
          <h2>How it works</h2>
        </div>

        <div className="card p-4 shadow-sm">
          <ul className="space-y-6 LisData">
            {HowItwork.map((howit, index) => (
              <li key={index}>
                <div className="text-gray-800">
                  <strong>{howit.title}: </strong>

                  <span className="text-gray-600">{howit.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Hotel Section */}

      <QuickHotel
        citylist={CityList}
        distance_lat={distance_lat}
        cityimage={CityImage}
        func={handleCityClick}
        isaqi={true}
        seoData={SEOloop}
      />

      {/* HotelData Section */}
      <div className="container-lg">
        <div className="whatExpress">
          <h2>Top Hotels in {CityList?.[cityindex]?.city_name || "Nagpur"} </h2>
        </div>
        <HotelData
          image={SilderHotelImage}
          isaqi={true}
          hotelData={hotel}
          seoData={SEOloop}
        />
      </div>
      {/* Discount Section */}
      <Discount />

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
