import React, { useState } from "react";
import "../Css/HotelData.css";
import whiteStart from "../../Assets/whiteStart.svg";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import geeenDot from "../../Assets/geeenDot.svg";
// import HeartRed from "../../Assets/HeartRed.svg";

export default function HotelData({
  image,
  handleSaveChangesdynamic,
  hotelData,
  isfav,
  isaqi,
}) {
  const [HeartImg, setHeartImages] = useState([]);
  const isFavorite = (venueId) => {
    return hotelData.some((fav) => fav.HotelID === venueId);
  };
  console.log("hotelData", hotelData);
  const aqiCategories = [
    { min: 0, max: 50, label: "Good" },
    { min: 51, max: 100, label: "Satisfactory" },
    { min: 101, max: 200, label: "Moderate" },
    { min: 201, max: 300, label: "Poor" },
    { min: 301, max: 400, label: "Very Poor" },
    { min: 401, max: 500, label: "Severe" },
  ];

  const getAQICategory = (currentAQI) => {
    const category = aqiCategories.find(
      (category) => currentAQI >= category.min && currentAQI <= category.max
    );
    return category ? category.label : null; // Return null if no category is found
  };
  return (
    <div>
      {" "}
      <div className="OfferData marginTOpCLass2 FavrateDivs">
        <div className="row">
          {hotelData &&
            hotelData.length > 0 &&
            hotelData.map((hotel, index) => (
              <Link
                className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4 text-black"
                key={index}
                to={`/hotel_detail/${hotel.HotelID}/${hotel.hotel_id}`}
              >
                <div className="offer_card ">
                  <div className="position-relative  OfferUnderDiv">
                    <img
                      src={`${image}${hotel.image_name}`}
                      className="offer_card-img-top"
                      alt="Hotel Image"
                    />
                    <span>
                      <img src={whiteStart} alt="whiteStart" />
                      {hotel.total_rating}
                    </span>
                    {isfav === true && (
                      <>
                        <div className="absoHeartRed">
                          <FaHeart
                            className="heartIcon"
                            color="#ff3333"
                            size={20}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="offer_card_body">
                    <div className="d-flex align-items-center justify-content-between flex-wrap hotldataDownCont">
                      <div className="offerCardSpan">
                        {parseInt(hotel.discount) > 0 ? (
                          <span>{hotel.discount}% off</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="hotelAQI">
                        <img
                          src={geeenDot}
                          alt="AQI indicator"
                          className="GreenDotImggg"
                        />
                        <p style={{ margin: 0 }}>
                          AQI {hotel.air_quality}
                          <span className="dot">•</span>{" "}
                          {getAQICategory(hotel.air_quality)}
                        </p>
                      </div>
                    </div>
                    <h5>{hotel.hotel_name}</h5>

                    <div className="PriCeHottlDiv">
                      <div className="LEftPriceDiv">
                        <p className="d-none d-xl-block">
                          {hotel.address.length > 25
                            ? hotel.address.slice(0, 25) + "..."
                            : hotel.address}
                        </p>
                        <p className="d-xl-none">
                          {hotel.address.length > 20
                            ? hotel.address.slice(0, 20) + "..."
                            : hotel.address}
                        </p>
                      </div>
                      <div className="rightPriceDiv">
                        {parseInt(hotel.discount) > 0 ? (
                          <>
                            <small className="text-muted text-decoration-line-through">
                              ₹{hotel.lowest_price}
                            </small>
                            <span className="fs-5 fw-bold text-dark">
                              ₹{hotel.discount_price}
                            </span>
                          </>
                        ) : (
                          <span className="fs-5 fw-bold text-dark">
                            ₹{hotel.lowest_price}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
