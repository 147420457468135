import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/ExpressChecking.css";
import "./Css/FlatDiscount.css";
import HotelData from "./Reapeting Componets/HotelData";
import QuickHotel from "./QuickHotel";
import Discount from "./Discount";
import Footer from "./Footer";
import SearchData from "./SearchData";
import {
  server_post_data,
  city_list_website,
  front_city_click,
} from "../ServiceConnection/serviceconnection";
import { handleError } from "../CommonJquery/CommonJquery";

export default function ExpresFlatDiscountPageChecking() {
  const [CityList, setCityList] = useState([]);
  const [CityImage, setCityImage] = useState("");
  const [distance_lat, setdistance_lat] = useState("");
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [cityindex, setcityindex] = useState(0);
  const [hotel, sethotel] = useState("");
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    await server_post_data(city_list_website, null)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          const final_data = JSON.parse(data[1]);
          setCityList(final_data.hotel_citylist);
          setSEOloop(final_data.seo_list);
          setCityImage(final_data.city_image_link);
          setdistance_lat(final_data.distance_lat);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        setShowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
    // master_data_get_hotel();
  }, []);

  useEffect(() => {
    if (CityList.length > 0) {
      master_data_get_hotel(0);
    }
  }, [CityList]);

  const master_data_get_hotel = async (index) => {
    setIsLoading(true);
    if (CityList) {
      const fd = new FormData();
      fd.append("custom_latitude", CityList[index].city_lat);
      fd.append("custom_longitude", CityList[index].city_long);
      fd.append("city_id", CityList[index].city_long);
      fd.append("distance_call", CityList[index].distance_in_km);
      await server_post_data(front_city_click, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setSilderHotelImage(final_data.hotel_image_link);
            sethotel(final_data.hotel_all);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handleCityClick = (index) => {
    master_data_get_hotel(index);
    setcityindex(index);
  };

  const copyCodeToClipboard = () => {
    const couponCode = "FIRSTBK20";
    navigator.clipboard
      .writeText(couponCode)
      .then(() => {
        alert("Coupon code copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy code: ", err);
      });
  };

  return (
    <div>
      <Header />

      {/* Hero Section */}
      <div className="container-lg">
        <div className="row  heroSection FlatDiscountDIv">
          <label className="ApplyshowiNMobileOnly">
            Apply this coupon during booking
          </label>
          <h1>Flat 20% Off on your first booking</h1>
          <div className="ReferCOde FLastDiscountCOde">
            <div className="underCont">
              <p>Your coupon code</p>
              <h1>FIRSTBK20</h1>
            </div>
            <div className="refrVericleLine"></div>
            <div
              className="underCont2"
              style={{ cursor: "pointer" }}
              onClick={copyCodeToClipboard}
            >
              <h1>Copy </h1>
              <h1>Code</h1>
            </div>
          </div>

          <label className="webLblAPply">
            Apply this coupon during booking
          </label>
        </div>
      </div>

      {/* Search Section */}
      <SearchData />

      <QuickHotel
        citylist={CityList}
        distance_lat={distance_lat}
        cityimage={CityImage}
        func={handleCityClick}
        isaqi={true}
        seoData={SEOloop}
      />

      {/* HotelData Section */}
      <div className="container-lg">
        <div className="whatExpress">
          <h2>Top Hotels in {CityList?.[cityindex]?.city_name || "Nagpur"} </h2>
        </div>
        <HotelData
          image={SilderHotelImage}
          isaqi={true}
          hotelData={hotel}
          seoData={SEOloop}
        />
      </div>

      {/* Discount Section */}
      <Discount />

      {/* Footer Section */}
      <Footer />
    </div>
  );
}
