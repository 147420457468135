import React from "react";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../Assets/backIcon.svg";
import "../Css/BackBar.css";

function BackBar({label}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container-lg">
      <div className="backWrapper">
        <div className="backCOntainer" onClick={goBack}>
          <img src={BackBtn} className="backImg" alt="Swagstay" />
          <h4 className="backName">{label}</h4>
        </div>
      </div>
    </div>
  );
}

export default BackBar;
